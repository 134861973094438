import {
  CalendarLtr20Regular as DateIcon,
  Clock20Regular as TimeIcon,
  Play20Regular as Icon,
  Timer20Regular as CronIcon,
  ArrowWrapOff20Regular as ChannelCloseTriggerIcon,
  ArrowRouting20Regular as ChannelOpenTriggerIcon,
  ArrowEnter20Regular as HtlcInterceptionTriggerIcon,
  ArrowEnter20Regular as ChannelOpenInterceptionTriggerIcon,
  ArrowForward20Regular as ForwardTriggerIcon,
  Check20Regular as InvoiceTriggerIcon,
  MoneyHand20Regular as PaymentTriggerIcon,
  KeyMultiple20Regular as OnChainTransactionTriggerIcon,
} from "@fluentui/react-icons";

import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import { ChannelOpenInterceptionTriggerAction, WorkflowRunLogDetailAction } from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import styles from "components/workflow/LogDetails/TriggerLogs/TriggerLogs.module.scss";
import cronstrue from "cronstrue";
import { format } from "date-fns";
import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";

import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";
import { ConvertTimeUnits } from "components/common/durationInput/DurationInput";
import useTranslations from "services/i18n/useTranslations";

export function ManualTriggerLogs(props: WorkflowRunLogDetailAction) {
  return (
    <LogDetailsWrapper
      type={LogDetailType.trigger}
      name={"Manually Triggered"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      <TriggerDate date={new Date(props.changedOn)} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

export function ManualDryRunTriggerLogs(props: WorkflowRunLogDetailAction) {
  return (
    <LogDetailsWrapper
      type={LogDetailType.dryRun}
      name={"Dry run"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      <TriggerDate date={new Date(props.changedOn)} dryRun={true} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

export function CronTriggerLogs(props: WorkflowRunLogDetailAction) {
  let cronExplained = "";
  const cron = JSON.parse(props?.parameters || "{}") as { cronValue?: string };
  try {
    const cronDesc = cronstrue.toString(cron?.cronValue || "");
    cronExplained = "Triggers " + cronDesc[0].toLowerCase() + cronDesc.substring(1);
  } catch (err) {
    cronExplained = "Invalid cron value";
  }

  return (
    <LogDetailsWrapper
      type={LogDetailType.trigger}
      name={"Cron Triggered"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<CronIcon />}
    >
      <LogContainer title={cron?.cronValue} type={LogDetailType.trigger}>
        <div>{cronExplained}</div>
      </LogContainer>
      <TriggerDate date={new Date(props.changedOn)} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

type intervalParams = {
  seconds: number;
  timeUnit: number;
};

function isTimeTriggerParameters(result: unknown): result is intervalParams {
  return result !== null && typeof result === "object" && "seconds" in result && "timeUnit" in result;
}

export function IntervalTriggerLogs(props: WorkflowRunLogDetailAction) {
  const params = JSON.parse(props.parameters || "{}");

  return (
    <LogDetailsWrapper
      type={LogDetailType.trigger}
      name={"Interval Trigger"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<CronIcon />}
    >
      <LogContainer title={"Interval"} type={LogDetailType.trigger}>
        <div>{isTimeTriggerParameters(params) && ConvertTimeUnits(1, params.timeUnit, params.seconds)}</div>
      </LogContainer>
      <TriggerDate date={new Date(props.changedOn)} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

export function BalanceChangeTriggerLogs(props: WorkflowRunLogDetailAction) {
  return (
    <LogDetailsWrapper
      type={LogDetailType.trigger}
      name={"Channel Balance Trigger"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<CronIcon />}
    >
      <TriggerDate date={new Date(props.changedOn)} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

export function ChannelOpenTriggerLogs(props: WorkflowRunLogDetailAction) {
  return (
    <LogDetailsWrapper
      type={LogDetailType.trigger}
      name={"Channel Open Trigger"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<ChannelOpenTriggerIcon />}
    >
      <TriggerDate date={new Date(props.changedOn)} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
export function ChannelCloseTriggerLogs(props: WorkflowRunLogDetailAction) {
  return (
    <LogDetailsWrapper
      type={LogDetailType.trigger}
      name={"Channel Close Trigger"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<ChannelCloseTriggerIcon />}
    >
      <TriggerDate date={new Date(props.changedOn)} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
export function HtlcInterceptionTriggerLogs(props: WorkflowRunLogDetailAction) {
  return (
    <LogDetailsWrapper
      type={LogDetailType.trigger}
      name={"HTLC Intercept Trigger"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<HtlcInterceptionTriggerIcon />}
    >
      <TriggerDate date={new Date(props.changedOn)} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

function TriggerDate(props: { date: Date; dryRun?: boolean }) {
  let displayDate = "";
  let displayTime = "";
  if (props.date != null && props.date.valueOf() !== 0) {
    displayDate = format(props.date as Date, "yyyy-MM-dd");
    displayTime = format(props.date as Date, "HH:mm:ss");
  } else {
    displayDate = "";
  }

  return (
    <LogContainer title={"Triggered at"} type={props.dryRun ? LogDetailType.dryRun : LogDetailType.trigger}>
      <div className={styles.triggeredAt}>
        <div className={styles.dateRow}>
          {displayDate && <DateIcon />}
          {displayDate}
        </div>
        <div className={styles.timeRow}>
          {displayTime && <TimeIcon />}
          {displayTime}
        </div>
      </div>
    </LogContainer>
  );
}

export function HtlcInterceptionTriggerLog(props: WorkflowRunLogDetailAction) {
  return (
    <LogDetailsWrapper
      type={LogDetailType.trigger}
      name={"Htlc Interception Trigger"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<HtlcInterceptionTriggerIcon />}
    >
      <TriggerDate date={new Date(props.changedOn)} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

export function ChannelOpenInterceptionTriggerLog(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();
  const data = props?.actions ? (props.actions[0] as ChannelOpenInterceptionTriggerAction) : undefined;

  return (
    <LogDetailsWrapper
      type={LogDetailType.trigger}
      name={"Channel Open Interception Trigger"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<ChannelOpenInterceptionTriggerIcon />}
    >
      <LogContainer title={"Default Parameters"} type={LogDetailType.trigger}>
        {data?.defaultAccept
        ? <div>{t.workflowNodes.channelOpenInterceptionResultAccept}</div>
        : <div>{t.workflowNodes.channelOpenInterceptionResultDeny}</div>
        }
        {data?.defaultAccept == false && <div>{t.workflowNodes.channelOpenInterceptionResultRejectReason}: {data.defaultRejectReason}</div>}
      </LogContainer>
      <TriggerDate date={new Date(props.changedOn)} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

export function ForwardTriggerLog(props: WorkflowRunLogDetailAction) {
  return (
    <LogDetailsWrapper
      type={LogDetailType.trigger}
      name={"Forward Trigger"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<ForwardTriggerIcon />}
    >
      <TriggerDate date={new Date(props.changedOn)} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
export function InvoiceTriggerLog(props: WorkflowRunLogDetailAction) {
  return (
    <LogDetailsWrapper
      type={LogDetailType.trigger}
      name={"Invoice Trigger"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<InvoiceTriggerIcon />}
    >
      <TriggerDate date={new Date(props.changedOn)} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
export function PaymentTriggerLog(props: WorkflowRunLogDetailAction) {
  return (
    <LogDetailsWrapper
      type={LogDetailType.trigger}
      name={"Payment Trigger"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<PaymentTriggerIcon />}
    >
      <TriggerDate date={new Date(props.changedOn)} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
export function OnChainTransactionTriggerLog(props: WorkflowRunLogDetailAction) {
  return (
    <LogDetailsWrapper
      type={LogDetailType.trigger}
      name={"On-Chain Transaction Trigger"}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<OnChainTransactionTriggerIcon />}
    >
      <TriggerDate date={new Date(props.changedOn)} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
