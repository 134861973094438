import { ViewResponse } from "features/viewManagement/types";

import { FilterInterface } from "features/sidebar/sections/filter/filter";
import { ColumnMetaData } from "features/table/types";
import {
  AllWorkflowRunLogDetailColumns,
  WorkflowRunLogDetailFilterableColumns,
  WorkflowRunLogDetailSortableColumns,
} from "pages/workflowRunLogDetail/workflowRunLogDetail.generated";
import { OrderBy } from "features/sidebar/sections/sort/SortSection";
import { WorkflowRunLogDetailAction } from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";

const defaultColumns: Array<keyof WorkflowRunLogDetailAction> = [
  "changedOn",
  "stage",
  "iteration",
  "id",
  "parentIds",
  "type",
  "name",
  "parameters",
  "actions",
  "errors",
];

export const DefaultWorkflowLogDetailColumns = defaultColumns.map((col) =>
  AllWorkflowRunLogDetailColumns.find((x) => x.key === col),
) as Array<ColumnMetaData<WorkflowRunLogDetailAction>>;

export const FilterableWorkflowLogDetailColumns = AllWorkflowRunLogDetailColumns.filter(
  (column: ColumnMetaData<WorkflowRunLogDetailAction>) => WorkflowRunLogDetailFilterableColumns.includes(column.key),
);

export const SortableWorflowRunLogDetailColumns = AllWorkflowRunLogDetailColumns.filter(
  (column: ColumnMetaData<WorkflowRunLogDetailAction>) => {
    return WorkflowRunLogDetailSortableColumns.includes(column.key);
  },
);

export const WorkflowLogDetailFilterTemplate: FilterInterface = {
  funcName: "gte",
  category: "string",
  parameter: 0,
  key: "workflowName",
};

export const WorkflowRunLogDetailSortTemplate: Array<OrderBy> = [
  {
    key: "changedOn",
    direction: "desc",
  },
];

export const SortableWorkflowRunLogDetailColumns = AllWorkflowRunLogDetailColumns.filter((c) =>
  WorkflowRunLogDetailSortableColumns.includes(c.key),
);

export const DefaultWorkflowRunLogDetailView: ViewResponse<WorkflowRunLogDetailAction> = {
  page: "workflowRunLogDetail",
  dirty: true,
  view: {
    title: "Workflow Log Detail",
    columns: DefaultWorkflowLogDetailColumns,
    sortBy: WorkflowRunLogDetailSortTemplate,
  },
};
