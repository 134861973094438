import React, { useContext, useEffect, useState } from "react";
import { System20Regular as SystemIcon, Save16Regular as SaveIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import Form from "components/forms/form/Form";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import { useUpdateNodeMutation } from "pages/WorkflowPage/workflowApi";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { InputSizeVariant, Select } from "components/forms/forms";
import Spinny from "features/spinny/Spinny";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";

export type SystemOperationParameters = {
  operation: number;
};

type SystemOperationProps = Omit<WorkflowNodeProps, "colorVariant">;

export function SystemOperationNode({ ...wrapperProps }: SystemOperationProps) {
  const { t } = useTranslations();

  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;
  const toastRef = React.useContext(ToastContext);

  const [updateNode] = useUpdateNodeMutation();

  const [selectedOperation, _] = useState<number>(
    (wrapperProps.parameters as SystemOperationParameters).operation
  );

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    if (
      ((wrapperProps.parameters as SystemOperationParameters).operation || undefined) !== (selectedOperation || undefined)
    ) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [selectedOperation, wrapperProps.parameters]);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }

    setProcessing(true);
    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: {
        operation: selectedOperation,
      },
    }).finally(() => {
      setProcessing(false);
    });
  }

  return (
    <WorkflowNodeWrapper
      {...wrapperProps}
      heading={t.workflowNodes.systemOperation}
      headerIcon={<SystemIcon />}
      colorVariant={NodeColorVariant.accent3}
    >
      <Form onSubmit={handleSubmit}
            intercomTarget={"workflow-node-systemOperation-form"}
      >
        <Select
          intercomTarget={"workflow-node-systemOperation-select"}
          isMulti={true}
          options={[
            {
              label: t.intervalTriggers,
              id: "systemOperation-node-switch-" + wrapperProps.workflowVersionNodeId,
              checked: selectedOperation === 0,
            },
          ]}
          label={t.workflowNodes.systemOperation}
          sizeVariant={InputSizeVariant.small}
          value={selectedOperation}
          isDisabled={editingDisabled}
        />
        <Button
          intercomTarget={"workflow-node-systemOperation-save-button"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing || editingDisabled}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
      </Form>
    </WorkflowNodeWrapper>
  );
}
