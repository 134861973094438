import { Play20Regular as DataSourceHtlcEventIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeButtonWrapper from "components/workflow/nodeButtonWrapper/NodeButtonWrapper";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";

export function DataSourceHtlcEventNodeButton(props: { disabled?: boolean }) {
  const { t } = useTranslations();

  return (
    <WorkflowNodeButtonWrapper
      disabled={props.disabled}
      intercomTarget={"data-source-htlc-event-node-button"}
      colorVariant={NodeColorVariant.accent2}
      nodeType={WorkflowNodeType.DataSourceHtlcEvent}
      icon={<DataSourceHtlcEventIcon />}
      title={t.workflowNodes.htlcEvent}
    />
  );
}
