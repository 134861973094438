import { ClipboardNote20Regular as Icon } from "@fluentui/react-icons";
import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import {
  ChannelOpenInterceptionEventResultAction,
  WorkflowRunLogDetailAction,
} from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import ParentsLog from "components/workflow/LogDetails/ParentsLog/ParentsLog";
import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";
import useTranslations from "services/i18n/useTranslations";
import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";

export default function ChannelOpenInterceptionResultLog(props: WorkflowRunLogDetailAction) {
  const data = props?.actions ? (props.actions[0] as ChannelOpenInterceptionEventResultAction) : undefined;
  const { t } = useTranslations();

  return (
    <LogDetailsWrapper
      type={LogDetailType.action}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.action} />
      {data &&
      <LogContainer title={t.channelOpenInterceptionEventLog.result} type={LogDetailType.action}>
        {data?.accept
        ? <div>{t.workflowNodes.channelOpenInterceptionResultAccept}</div>
        : <div>{t.workflowNodes.channelOpenInterceptionResultDeny}</div>
        }
        {data?.accept == false && <div>{t.workflowNodes.channelOpenInterceptionResultRejectReason}: {data?.rejectReason}</div>}
      </LogContainer>
      }
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
