import { FlashCheckmark20Regular as Indicator } from "@fluentui/react-icons";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { userEvents } from "utils/userEvents";
import { useLocation, useNavigate } from "react-router-dom";

function ConnectionStatusOpen() {
  const { track } = userEvents();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Button
      intercomTarget={"nav-node-connection-status"}
      buttonColor={ColorVariant.ghost}
      buttonSize={SizeVariant.small}
      icon={<Indicator />}
      hideMobileText={true}
      onClick={() => {
        track("Navigate to Node Connection Status");
        navigate("/node-connection-status/", { state: { background: location } });
      }}
    ></Button>
  );
}

export default ConnectionStatusOpen;
