import { ReactComponent as addTag } from "components/workflow/logCanvas/workflowLogCanvasIcons/addTag.svg";
import { ReactComponent as addTagError } from "components/workflow/logCanvas/workflowLogCanvasIcons/addTagError.svg";
import { ReactComponent as addTagInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/addTagInactive.svg";
import { ReactComponent as channelBalanceTriger } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelBalanceTriger.svg";
import { ReactComponent as channelCloseTrigger } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelCloseTrigger.svg";
import { ReactComponent as channelFilter } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelFilter.svg";
import { ReactComponent as channelFilterError } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelFilterError.svg";
import { ReactComponent as channelFilterInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelFilterInactive.svg";
import { ReactComponent as channelOpenTrigger } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelOpenTrigger.svg";
import { ReactComponent as channelPolicyAutoRun } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelPolicyAutoRun.svg";
import { ReactComponent as channelPolicyAutoRunError } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelPolicyAutoRunError.svg";
import { ReactComponent as channelPolicyAutoRunInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelPolicyAutoRunInactive.svg";
import { ReactComponent as channelPolicyConfig } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelPolicyConfig.svg";
import { ReactComponent as channelPolicyConfigError } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelPolicyConfigError.svg";
import { ReactComponent as channelPolicyConfigInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelPolicyConfigInactive.svg";
import { ReactComponent as channelPolicyRun } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelPolicyRun.svg";
import { ReactComponent as channelPolicyRunError } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelPolicyRunError.svg";
import { ReactComponent as channelPolicyRunInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/channelPolicyRunInactive.svg";
import { ReactComponent as closeChannels } from "components/workflow/logCanvas/workflowLogCanvasIcons/closeChannels.svg";
import { ReactComponent as closeChannelsError } from "components/workflow/logCanvas/workflowLogCanvasIcons/closeChannelsError.svg";
import { ReactComponent as closeChannelsInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/closeChannelsInactive.svg";
import { ReactComponent as cronTrigger } from "components/workflow/logCanvas/workflowLogCanvasIcons/cronTrigger.svg";
import { ReactComponent as dataSourceIcon } from "components/workflow/logCanvas/workflowLogCanvasIcons/dataSource.svg";
import { ReactComponent as dataSourceError } from "components/workflow/logCanvas/workflowLogCanvasIcons/dataSourceError.svg";
import { ReactComponent as dataSourceInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/dataSourceInactive.svg";
import { ReactComponent as eventFilter } from "components/workflow/logCanvas/workflowLogCanvasIcons/eventFilter.svg";
import { ReactComponent as eventFilterError } from "components/workflow/logCanvas/workflowLogCanvasIcons/eventFilterError.svg";
import { ReactComponent as eventFilterInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/eventFilterInactive.svg";
import { ReactComponent as intervalTrigger } from "components/workflow/logCanvas/workflowLogCanvasIcons/intervalTrigger.svg";
import { ReactComponent as manualDryRun } from "components/workflow/logCanvas/workflowLogCanvasIcons/manualDryRun.svg";
import { ReactComponent as manualRun } from "components/workflow/logCanvas/workflowLogCanvasIcons/manualRun.svg";
import { ReactComponent as openChannels } from "components/workflow/logCanvas/workflowLogCanvasIcons/openChannels.svg";
import { ReactComponent as openChannelsError } from "components/workflow/logCanvas/workflowLogCanvasIcons/openChannelsError.svg";
import { ReactComponent as openChannelsInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/openChannelsInactive.svg";
import { ReactComponent as rebalanceAutoRun } from "components/workflow/logCanvas/workflowLogCanvasIcons/rebalanceAutoRun.svg";
import { ReactComponent as rebalanceAutoRunError } from "components/workflow/logCanvas/workflowLogCanvasIcons/rebalanceAutoRunError.svg";
import { ReactComponent as rebalanceAutoRunInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/rebalanceAutoRunInactive.svg";
import { ReactComponent as rebalanceConfig } from "components/workflow/logCanvas/workflowLogCanvasIcons/rebalanceConfig.svg";
import { ReactComponent as rebalanceConfigError } from "components/workflow/logCanvas/workflowLogCanvasIcons/rebalanceConfigError.svg";
import { ReactComponent as rebalanceConfigInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/rebalanceConfigInactive.svg";
import { ReactComponent as rebalanceRun } from "components/workflow/logCanvas/workflowLogCanvasIcons/rebalanceRun.svg";
import { ReactComponent as rebalanceRunInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/rebalanceRunInactive.svg";
import { ReactComponent as rebalanceRunError } from "components/workflow/logCanvas/workflowLogCanvasIcons/rebalanceRunError.svg";
import { ReactComponent as removeTag } from "components/workflow/logCanvas/workflowLogCanvasIcons/removeTag.svg";
import { ReactComponent as removeTagError } from "components/workflow/logCanvas/workflowLogCanvasIcons/removeTagError.svg";
import { ReactComponent as removeTagInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/removeTagInactive.svg";
import { ReactComponent as stageTrigger } from "components/workflow/logCanvas/workflowLogCanvasIcons/stageTrigger.svg";
import { ReactComponent as stageTriggerError } from "components/workflow/logCanvas/workflowLogCanvasIcons/stageTriggerError.svg";
import { ReactComponent as stageTriggerInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/stageTriggerInactive.svg";
import { ReactComponent as unknownIcon } from "components/workflow/logCanvas/workflowLogCanvasIcons/unknownIcon.svg";
import { ReactComponent as unknownIconError } from "components/workflow/logCanvas/workflowLogCanvasIcons/unknownIconError.svg";
import { ReactComponent as unknownIconInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/unknownIconInactive.svg";
import { ReactComponent as flowAnd } from "components/workflow/logCanvas/workflowLogCanvasIcons/flowAnd.svg";
import { ReactComponent as flowAndError } from "components/workflow/logCanvas/workflowLogCanvasIcons/flowAndError.svg";
import { ReactComponent as flowAndInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/flowAndInactive.svg";
import { ReactComponent as HTLCFilter } from "components/workflow/logCanvas/workflowLogCanvasIcons/HTLCFilter.svg";
import { ReactComponent as HTLCFilterError } from "components/workflow/logCanvas/workflowLogCanvasIcons/HTLCFilterError.svg";
import { ReactComponent as HTLCFilterInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/HTLCFilterInactive.svg";
import { ReactComponent as switchIcon } from "components/workflow/logCanvas/workflowLogCanvasIcons/switch.svg";
import { ReactComponent as switchError } from "components/workflow/logCanvas/workflowLogCanvasIcons/switchError.svg";
import { ReactComponent as switchInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/switchInactive.svg";
import { ReactComponent as htlcInterceptionBlocker } from "components/workflow/logCanvas/workflowLogCanvasIcons/htlcInterceptionBlocker.svg";
import { ReactComponent as htlcInterceptionBlockerError } from "components/workflow/logCanvas/workflowLogCanvasIcons/htlcInterceptionBlockerError.svg";
import { ReactComponent as htlcInterceptionBlockerInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/htlcInterceptionBlockerInactive.svg";
import { ReactComponent as notificationSender } from "components/workflow/logCanvas/workflowLogCanvasIcons/notificationSender.svg";
import { ReactComponent as notificationSenderError } from "components/workflow/logCanvas/workflowLogCanvasIcons/notificationSenderError.svg";
import { ReactComponent as notificationSenderInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/notificationSenderInactive.svg";
import { ReactComponent as apiClient } from "components/workflow/logCanvas/workflowLogCanvasIcons/apiClient.svg";
import { ReactComponent as apiClientError } from "components/workflow/logCanvas/workflowLogCanvasIcons/apiClientError.svg";
import { ReactComponent as apiClientInactive } from "components/workflow/logCanvas/workflowLogCanvasIcons/apiClientInactive.svg";
// TODO FIXME fix this! (should not use cronTrigger.svg)
import { ReactComponent as forwardTrigger } from "components/workflow/logCanvas/workflowLogCanvasIcons/cronTrigger.svg";
import { ReactComponent as invoiceTrigger } from "components/workflow/logCanvas/workflowLogCanvasIcons/cronTrigger.svg";
import { ReactComponent as paymentTrigger } from "components/workflow/logCanvas/workflowLogCanvasIcons/cronTrigger.svg";
import { ReactComponent as onChainTransactionTrigger } from "components/workflow/logCanvas/workflowLogCanvasIcons/cronTrigger.svg";
import { ReactComponent as exchangeFliter } from "components/workflow/logCanvas/workflowLogCanvasIcons/cronTrigger.svg";
import { ReactComponent as mempoolFilter } from "components/workflow/logCanvas/workflowLogCanvasIcons/cronTrigger.svg";
import { ReactComponent as paymentAutoRun } from "components/workflow/logCanvas/workflowLogCanvasIcons/cronTrigger.svg";

import { WorkflowNodeType } from "pages/WorkflowPage/constants";
import { renderToString } from "react-dom/server";
import { createElement } from "react";

export const regularIcons = new Map([
  [WorkflowNodeType.AddTag, addTag],
  [WorkflowNodeType.ChannelBalanceEventTrigger, channelBalanceTriger],
  [WorkflowNodeType.ChannelCloseEventTrigger, channelCloseTrigger],
  [WorkflowNodeType.ChannelFilter, channelFilter],
  [WorkflowNodeType.ChannelOpenEventTrigger, channelOpenTrigger],
  [WorkflowNodeType.RoutingPolicyAutoRun, channelPolicyAutoRun],
  [WorkflowNodeType.RoutingPolicyConfigurator, channelPolicyConfig],
  [WorkflowNodeType.RoutingPolicyRun, channelPolicyRun],
  [WorkflowNodeType.CloseChannels, closeChannels],
  [WorkflowNodeType.CronTrigger, cronTrigger],
  [WorkflowNodeType.DataSourceTorqChannels, dataSourceIcon],
  [WorkflowNodeType.ChannelBalanceEventFilter, eventFilter],
  [WorkflowNodeType.IntervalTrigger, intervalTrigger],
  [WorkflowNodeType.ManualDryRunTrigger, manualDryRun],
  [WorkflowNodeType.ManualTrigger, manualRun],
  [WorkflowNodeType.OpenChannels, openChannels],
  [WorkflowNodeType.RebalanceAutoRun, rebalanceAutoRun],
  [WorkflowNodeType.RebalanceConfigurator, rebalanceConfig],
  [WorkflowNodeType.RebalanceRun, rebalanceRun],
  [WorkflowNodeType.RemoveTag, removeTag],
  [WorkflowNodeType.StageTrigger, stageTrigger],
  [WorkflowNodeType.HtlcChannelJoin, flowAnd],
  [WorkflowNodeType.HtlcChannelSplit, switchIcon],
  [WorkflowNodeType.HtlcInterceptionTrigger, stageTrigger],
  [WorkflowNodeType.HtlcInterceptionEventFilter, HTLCFilter],
  [WorkflowNodeType.HtlcInterceptionBlocker, htlcInterceptionBlocker],
  [WorkflowNodeType.DataSourceHtlcEvent, dataSourceIcon],
  [WorkflowNodeType.Notification, notificationSender],
  [WorkflowNodeType.ApiClient, apiClient],
  [WorkflowNodeType.ForwardTrigger, forwardTrigger],
  [WorkflowNodeType.InvoiceTrigger, invoiceTrigger],
  [WorkflowNodeType.PaymentTrigger, paymentTrigger],
  [WorkflowNodeType.OnChainTransactionTrigger, onChainTransactionTrigger],
  [WorkflowNodeType.ExchangeFilter, exchangeFliter],
  [WorkflowNodeType.MempoolFilter, mempoolFilter],
  [WorkflowNodeType.PaymentAutoRun, paymentAutoRun],
]);

export const inactiveIcons = new Map([
  [WorkflowNodeType.AddTag, addTagInactive],
  [WorkflowNodeType.ChannelFilter, channelFilterInactive],
  [WorkflowNodeType.RoutingPolicyAutoRun, channelPolicyAutoRunInactive],
  [WorkflowNodeType.RoutingPolicyConfigurator, channelPolicyConfigInactive],
  [WorkflowNodeType.RoutingPolicyRun, channelPolicyRunInactive],
  [WorkflowNodeType.CloseChannels, closeChannelsInactive],
  [WorkflowNodeType.DataSourceTorqChannels, dataSourceInactive],
  [WorkflowNodeType.ChannelBalanceEventFilter, eventFilterInactive],
  [WorkflowNodeType.OpenChannels, openChannelsInactive],
  [WorkflowNodeType.RebalanceAutoRun, rebalanceAutoRunInactive],
  [WorkflowNodeType.RebalanceConfigurator, rebalanceConfigInactive],
  [WorkflowNodeType.RebalanceRun, rebalanceRunInactive],
  [WorkflowNodeType.RemoveTag, removeTagInactive],
  [WorkflowNodeType.StageTrigger, stageTriggerInactive],
  [WorkflowNodeType.HtlcChannelJoin, flowAndInactive],
  [WorkflowNodeType.HtlcChannelSplit, switchInactive],
  [WorkflowNodeType.HtlcInterceptionEventFilter, HTLCFilterInactive],
  [WorkflowNodeType.HtlcInterceptionBlocker, htlcInterceptionBlockerInactive],
  [WorkflowNodeType.DataSourceHtlcEvent, dataSourceInactive],
  [WorkflowNodeType.Notification, notificationSenderInactive],
  [WorkflowNodeType.ApiClient, apiClientInactive],
]);

export const errorIcons = new Map([
  [WorkflowNodeType.AddTag, addTagError],
  [WorkflowNodeType.ChannelFilter, channelFilterError],
  [WorkflowNodeType.RoutingPolicyAutoRun, channelPolicyAutoRunError],
  [WorkflowNodeType.RoutingPolicyConfigurator, channelPolicyConfigError],
  [WorkflowNodeType.RoutingPolicyRun, channelPolicyRunError],
  [WorkflowNodeType.CloseChannels, closeChannelsError],
  [WorkflowNodeType.DataSourceTorqChannels, dataSourceError],
  [WorkflowNodeType.ChannelBalanceEventFilter, eventFilterError],
  [WorkflowNodeType.OpenChannels, openChannelsError],
  [WorkflowNodeType.RebalanceAutoRun, rebalanceAutoRunError],
  [WorkflowNodeType.RebalanceConfigurator, rebalanceConfigError],
  [WorkflowNodeType.RebalanceRun, rebalanceRunError],
  [WorkflowNodeType.RemoveTag, removeTagError],
  [WorkflowNodeType.StageTrigger, stageTriggerError],
  [WorkflowNodeType.HtlcChannelJoin, flowAndError],
  [WorkflowNodeType.HtlcChannelSplit, switchError],
  [WorkflowNodeType.HtlcInterceptionEventFilter, HTLCFilterError],
  [WorkflowNodeType.HtlcInterceptionBlocker, htlcInterceptionBlockerError],
  [WorkflowNodeType.Notification, notificationSenderError],
  [WorkflowNodeType.ApiClient, apiClientError],
]);

export function GetRegularIcon(workflowNodeType: WorkflowNodeType) {
  const icon = regularIcons.get(workflowNodeType) || unknownIcon;
  return renderToString(createElement(icon));
}

export function GetInactiveIcon(workflowNodeType: WorkflowNodeType) {
  const icon = inactiveIcons.get(workflowNodeType) || unknownIconInactive;
  return renderToString(createElement(icon));
}

export function GetErrorIcon(workflowNodeType: WorkflowNodeType) {
  const icon = errorIcons.get(workflowNodeType) || unknownIconError;
  return renderToString(createElement(icon));
}

export const dataSources = [WorkflowNodeType.DataSourceTorqChannels, WorkflowNodeType.DataSourceHtlcEvent];

export const actions = [
  WorkflowNodeType.AddTag,
  WorkflowNodeType.RemoveTag,
  WorkflowNodeType.RebalanceRun,
  WorkflowNodeType.RebalanceAutoRun,
  WorkflowNodeType.RoutingPolicyRun,
  WorkflowNodeType.RoutingPolicyAutoRun,
  WorkflowNodeType.RoutingPolicyConfigurator,
  WorkflowNodeType.CloseChannels,
  WorkflowNodeType.OpenChannels,
  WorkflowNodeType.HtlcInterceptionBlocker,
  WorkflowNodeType.Notification,
  WorkflowNodeType.ApiClient,
  WorkflowNodeType.PaymentAutoRun,
];

export const triggers = [
  WorkflowNodeType.ChannelOpenEventTrigger,
  WorkflowNodeType.ChannelCloseEventTrigger,
  WorkflowNodeType.ChannelBalanceEventTrigger,
  WorkflowNodeType.CronTrigger,
  WorkflowNodeType.IntervalTrigger,
  WorkflowNodeType.ManualTrigger,
  WorkflowNodeType.ManualDryRunTrigger,
  WorkflowNodeType.StageTrigger,
  WorkflowNodeType.HtlcInterceptionTrigger,
  WorkflowNodeType.ForwardTrigger,
  WorkflowNodeType.InvoiceTrigger,
  WorkflowNodeType.PaymentTrigger,
  WorkflowNodeType.OnChainTransactionTrigger,
];

export const filters = [
  WorkflowNodeType.ChannelFilter,
  WorkflowNodeType.ChannelBalanceEventFilter,
  WorkflowNodeType.HtlcInterceptionEventFilter,
  WorkflowNodeType.HtlcChannelJoin,
  WorkflowNodeType.HtlcChannelSplit,
  WorkflowNodeType.ExchangeFilter,
  WorkflowNodeType.MempoolFilter,
];
