// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go


import { ColumnMetaData } from "features/table/types";
import { Forward } from "features/forwards/forwardsTypes";

export const AllForwardsColumns: ColumnMetaData<Forward>[] = [
	{
		heading: "Alias",
		type: "AliasCell",
		key: "alias",
		valueType: "string",
		suffix: "",
		locked: true,
		supportedBy: [0, 1],
	},
	{
		heading: "Inbound Amount",
		type: "BarCell",
		key: "amountIn",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Outbound Amount",
		type: "BarCell",
		key: "amountOut",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Total Amount",
		type: "BarCell",
		key: "amountTotal",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Capacity",
		type: "NumericCell",
		key: "capacity",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Channel Point",
		type: "LongTextCell",
		key: "channelPoint",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Closing Date",
		type: "DateCell",
		key: "closedOn",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Inbound Forwards",
		type: "BarCell",
		key: "countIn",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Outbound Forwards",
		type: "BarCell",
		key: "countOut",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Total Forwards",
		type: "BarCell",
		key: "countTotal",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Funding Date",
		type: "DateCell",
		key: "fundedOn",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Funding Tx Output Index",
		type: "TextCell",
		key: "fundingOutputIndex",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Funding Transaction",
		type: "LongTextCell",
		key: "fundingTransactionHash",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "LND Short Channel ID",
		type: "LongTextCell",
		key: "lndShortChannelId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Open",
		type: "BooleanCell",
		key: "open",
		valueType: "boolean",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Percentage of time open",
		type: "BarCell",
		key: "percentageOpen",
		valueType: "number",
		suffix: "%",
		supportedBy: [0, 1],
	},
	{
		heading: "Public key",
		type: "LongTextCell",
		key: "pubKey",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Revenue inbound",
		type: "BarCell",
		key: "revenueIn",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Revenue",
		type: "BarCell",
		key: "revenueOut",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Revenue total",
		type: "BarCell",
		key: "revenueTotal",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Short Channel ID",
		type: "LongTextCell",
		key: "shortChannelId",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Tags",
		type: "TagsCell",
		key: "tags",
		valueType: "tag",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node Name",
		type: "TextCell",
		key: "torqNodeName",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Turnover Inbound",
		type: "BarCell",
		key: "turnoverIn",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Turnover Outbound",
		type: "BarCell",
		key: "turnoverOut",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Total Turnover",
		type: "BarCell",
		key: "turnoverTotal",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const ForwardsSortableColumns: Array<keyof Forward> = [
	"capacity",
	"channelPoint",
	"closedOn",
	"fundedOn",
	"fundingTransactionHash",
	"lndShortChannelId",
	"shortChannelId",
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const ForwardsFilterableColumns: Array<keyof Forward> = [
	"capacity",
	"channelPoint",
	"closedOn",
	"fundedOn",
	"fundingTransactionHash",
	"lndShortChannelId",
	"shortChannelId",
	"tags",
];