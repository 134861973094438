import React from "react";
import styles from "features/templates/popoutPageTemplate/popoutPageTemplate.module.scss";
import { DismissCircle24Regular as DismissIcon } from "@fluentui/react-icons";
import classNames from "classnames";
import { SizeVariant } from "components/buttons/buttonVariants";

type PopoutPageTemplateProps = {
  children: React.ReactNode;
  show: boolean;
  title?: string;
  icon?: React.ReactNode;
  onClose: () => void;
  sizeVariant?: SizeVariant;
};
const PopoutPageTemplate = (props: PopoutPageTemplateProps) => {
  const handleClose = () => {
    props.onClose();
  };

  return (
    <div className={classNames(styles.modal, { [styles.show]: props.show })}>
      <div className={styles.modalBackdrop} onClick={handleClose} />
      <div
        className={classNames(styles.popoutWrapper, {
          [styles.xl]: props.sizeVariant === SizeVariant.xl,
          [styles.large]: props.sizeVariant === SizeVariant.large,
          [styles.normal]: props.sizeVariant === undefined || props.sizeVariant === SizeVariant.normal,
          [styles.small]: props.sizeVariant === SizeVariant.small,
          [styles.tiny]: props.sizeVariant === SizeVariant.tiny,
        })}
      >
        <div className={styles.header}>
          {props.icon && <span className={styles.icon}>{props.icon}</span>}
          <span className={styles.title}>{props.title}</span>
          <span className={styles.close} onClick={handleClose} data-testid={"close-popout"}>
            <DismissIcon />
          </span>
        </div>
        <div className={styles.contentWrapper}>{props.children}</div>
      </div>
    </div>
  );
};

export default PopoutPageTemplate;
