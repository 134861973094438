import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";
import styles from "components/workflow/LogDetails/ChannelList/ChannelList.module.scss";
import { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import { Channel } from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import FilterExectuionPopup from "components/workflow/LogDetails/FilterExecutionPopup/FilterExecutionPopup";

type ChannelListProps = {
  title: string;
  workflowNodeId: number;
  channels: Array<Channel>;
  actionType: LogDetailType;
  onRight?: boolean;
};

export default function ChannelList(props: ChannelListProps) {
  return (
    <LogContainer title={props.title} count={props.channels.length} type={props.actionType}>
      <div className={styles.channelList}>
        {props.channels.map((chan, index) => {
          return (
            <div key={"channel-" + chan.channelId + chan.shortChannelId + props.workflowNodeId + index}>
              <FilterExectuionPopup
                executions={chan.executions}
                headerName={chan.peerAlias + " " + chan.shortChannelId}
                rightSide={!props.onRight}
              >
                <div className={styles.channelItem}>
                  <div className={styles.channelItemAlias}>{chan.peerAlias}</div>
                  <div className={styles.channelItemChannelId}>{chan.shortChannelId}</div>
                </div>
              </FilterExectuionPopup>
            </div>
          );
        })}
      </div>
    </LogContainer>
  );
}
