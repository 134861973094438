import { ViewResponse } from "features/viewManagement/types";
import { ChannelRequest } from "features/lsp/lspTypes";
import { FilterInterface } from "features/sidebar/sections/filter/filter";
import { ColumnMetaData } from "features/table/types";
import { OrderBy } from "features/sidebar/sections/sort/SortSection";
import {
  AllChannelRequestsColumns,
  ChannelRequestsFilterableColumns,
  ChannelRequestsSortableColumns,
} from "features/lsp/channelRequest/client/channelRequestsColumns.generated";
import { Implementation } from "types/api";

const defaultColumns: Array<keyof ChannelRequest> = ["orderId", "bolt11Invoice", "errorResponse"];

export const DefaultChannelRequestsColumns = defaultColumns.map((col) =>
  AllChannelRequestsColumns.find((x) => x.key === col),
) as Array<ColumnMetaData<ChannelRequest>>;

export const FilterableChannelRequestsColumns = AllChannelRequestsColumns.filter(
  (column: ColumnMetaData<ChannelRequest>) => ChannelRequestsFilterableColumns.includes(column.key),
);

export const ChannelRequestsFilterTemplate: FilterInterface = {
  funcName: "gte",
  category: "number",
  parameter: "0",
  key: "value",
  supportedBy: [Implementation.LND],
};

export const ChannelRequestsSortTemplate: Array<OrderBy> = [
  {
    key: "orderId",
    direction: "desc",
  },
];

export const DefaultChannelRequestsView: ViewResponse<ChannelRequest> = {
  page: "channelRequests",
  dirty: true,
  view: {
    title: "Channel Requests",
    columns: DefaultChannelRequestsColumns,
    sortBy: ChannelRequestsSortTemplate,
  },
};

export const SortableChannelRequestsColumns = AllChannelRequestsColumns.filter((c) =>
  ChannelRequestsSortableColumns.includes(c.key),
);
