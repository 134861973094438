import React, { useContext, useEffect, useState } from "react";
import {
  MoneySettings20Regular as ChannelPolicyConfiguratorIcon,
  Save16Regular as SaveIcon,
} from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import Input from "components/forms/input/Input";
import { InputSizeVariant } from "components/forms/input/variants";
import Form from "components/forms/form/Form";
import Socket from "components/forms/socket/Socket";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import { SelectWorkflowNodeLinks, SelectWorkflowNodes, useUpdateNodeMutation } from "pages/WorkflowPage/workflowApi";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { NumberFormatValues } from "react-number-format";
import { useSelector } from "react-redux";
import Spinny from "features/spinny/Spinny";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import Note, { NoteType } from "features/note/Note";
import InputRow from "components/forms/row/InputRow";
import { WorkflowVersionNodeSystemVariablesMap } from "pages/WorkflowPage/workflowTypes";
import { checkNodeInputsDirty } from "components/workflow/nodes/nodeFunctions";
import { systemVariable, systemVariableDataType } from "apiTypes";
import SystemVariableInputWrapper from "components/common/systemVariableInputWrapper/SystemVariableInputWrapper";

type ChannelPolicyConfiguratorNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export type ChannelPolicyConfiguration = {
  feeBaseMsat?: number;
  feeRateMilliMsat?: number;
  inboundFeeBaseMsat?: number;
  inboundFeeRateMilliMsat?: number;
  maxHtlcMsat?: number;
  minHtlcMsat?: number;
  timeLockDelta?: number;
};

export function ChannelPolicyConfiguratorNode({ ...wrapperProps }: ChannelPolicyConfiguratorNodeProps) {
  const { t } = useTranslations();

  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;
  const toastRef = React.useContext(ToastContext);

  const [updateNode] = useUpdateNodeMutation();

  const [channelPolicy, setChannelPolicy] = useState<ChannelPolicyConfiguration>({
    feeBaseMsat: undefined,
    feeRateMilliMsat: undefined,
    inboundFeeBaseMsat: undefined,
    inboundFeeRateMilliMsat: undefined,
    maxHtlcMsat: undefined,
    minHtlcMsat: undefined,
    timeLockDelta: undefined,
    ...wrapperProps.parameters,
  });

  const [systemVariables, setSystemVariables] = useState<WorkflowVersionNodeSystemVariablesMap>(
    {} as WorkflowVersionNodeSystemVariablesMap,
  );

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!processing) {
      setSystemVariables(wrapperProps.systemVariables || {});
    }
  }, [wrapperProps.systemVariables, processing]);

  useEffect(() => {
    if (!processing) {
      setDirty(
        checkNodeInputsDirty(wrapperProps.parameters, channelPolicy, wrapperProps.systemVariables, systemVariables),
      );
    }
  }, [channelPolicy, wrapperProps.parameters, processing, wrapperProps.systemVariables, systemVariables]);

  const [feeBase, setFeeBase] = useState<number | undefined>(
    (wrapperProps.parameters as ChannelPolicyConfiguration).feeBaseMsat
      ? ((wrapperProps.parameters as ChannelPolicyConfiguration).feeBaseMsat || 0) / 1000
      : undefined,
  );
  const [inboundFeeBase, setInboundFeeBase] = useState<number | undefined>(
    (wrapperProps.parameters as ChannelPolicyConfiguration).inboundFeeBaseMsat
      ? ((wrapperProps.parameters as ChannelPolicyConfiguration).inboundFeeBaseMsat || 0) / 1000
      : undefined,
  );
  const [maxHtlc, setMaxHtlc] = useState<number | undefined>(
    (wrapperProps.parameters as ChannelPolicyConfiguration).maxHtlcMsat
      ? ((wrapperProps.parameters as ChannelPolicyConfiguration).maxHtlcMsat || 0) / 1000
      : undefined,
  );
  const [minHtlc, setMinHtlc] = useState<number | undefined>(
    (wrapperProps.parameters as ChannelPolicyConfiguration).minHtlcMsat
      ? ((wrapperProps.parameters as ChannelPolicyConfiguration).minHtlcMsat || 0) / 1000
      : undefined,
  );

  function createChangeMsatHandler(key: keyof ChannelPolicyConfiguration) {
    return (e: NumberFormatValues | undefined) => {
      if (key == "feeBaseMsat") {
        setFeeBase(e?.floatValue);
      }
      if (key == "inboundFeeBaseMsat") {
        setInboundFeeBase(e?.floatValue);
      }
      if (key == "maxHtlcMsat") {
        setMaxHtlc(e?.floatValue);
      }
      if (key == "minHtlcMsat") {
        setMinHtlc(e?.floatValue);
      }
      if (e?.floatValue === undefined) {
        setChannelPolicy((prev) => ({
          ...prev,
          [key]: undefined,
        }));
      } else {
        setChannelPolicy((prev) => ({
          ...prev,
          [key]: (e?.floatValue || 0) * 1000,
        }));
      }
    };
  }

  function createChangeHandler(key: keyof ChannelPolicyConfiguration) {
    return (e: NumberFormatValues | undefined) => {
      setChannelPolicy((prev) => ({
        ...prev,
        [key]: e?.floatValue,
      }));
    };
  }

  function setVariable(variable: systemVariable | undefined, fieldName: string) {
    setSystemVariables((prev) => ({
      ...prev,
      [fieldName]: variable,
    }));
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }

    setProcessing(true);
    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: channelPolicy,
      systemVariables: systemVariables,
    }).finally(() => {
      setProcessing(false);
    });
  }

  const { childLinks } = useSelector(
    SelectWorkflowNodeLinks({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeId: wrapperProps.workflowVersionNodeId,
      stage: wrapperProps.stage,
    }),
  );

  const channelIds =
    childLinks
      ?.filter((n) => {
        return n.childInput === "channels";
      })
      ?.map((link) => link.parentWorkflowVersionNodeId) ?? [];

  const channels = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: channelIds,
    }),
  );

  return (
    <WorkflowNodeWrapper
      {...wrapperProps}
      headerIcon={<ChannelPolicyConfiguratorIcon />}
      colorVariant={NodeColorVariant.accent4}
      outputName={"channels"}
    >
      <Form onSubmit={handleSubmit} intercomTarget={"channel-policy-configurator-form"}>
        <Socket
          collapsed={wrapperProps.visibilitySettings.collapsed}
          label={t.channels}
          selectedNodes={channels || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          inputName={"channels"}
          editingDisabled={editingDisabled}
        />

        <div>{t.updateChannelPolicy.feeRateMilliMsat}</div>
        <InputRow>
          <SystemVariableInputWrapper
            variable={systemVariables["InboundFeeRateMilliMsat"]}
            handleVariableChange={(variable) => {
              setVariable(variable, "InboundFeeRateMilliMsat");
              createChangeHandler("inboundFeeRateMilliMsat")(undefined);
            }}
            inputDataType={systemVariableDataType.number}
            editingDisabled={editingDisabled}
          >
            <Input
              intercomTarget={"channel-policy-configurator-inbound-fee-rate"}
              formatted={true}
              value={channelPolicy.inboundFeeRateMilliMsat}
              thousandSeparator={","}
              suffix={" ppm"}
              onValueChange={createChangeHandler("inboundFeeRateMilliMsat")}
              label={t.updateChannelPolicy.inbound}
              sizeVariant={InputSizeVariant.small}
              disabled={editingDisabled}
            />
          </SystemVariableInputWrapper>
          <SystemVariableInputWrapper
            variable={systemVariables["FeeRateMilliMsat"]}
            handleVariableChange={(variable) => {
              setVariable(variable, "FeeRateMilliMsat");
              createChangeHandler("feeRateMilliMsat")(undefined);
            }}
            inputDataType={systemVariableDataType.number}
            editingDisabled={editingDisabled}
          >
            <Input
              intercomTarget={"channel-policy-configurator-fee-rate"}
              formatted={true}
              value={channelPolicy.feeRateMilliMsat}
              thousandSeparator={","}
              suffix={" ppm"}
              onValueChange={createChangeHandler("feeRateMilliMsat")}
              label={t.updateChannelPolicy.outbound}
              sizeVariant={InputSizeVariant.small}
              disabled={editingDisabled}
            />
          </SystemVariableInputWrapper>
        </InputRow>

        <div>{t.updateChannelPolicy.feeBase}</div>
        <InputRow>
          <SystemVariableInputWrapper
            variable={systemVariables["InboundFeeBaseMsat"]}
            handleVariableChange={(variable) => {
              setVariable(variable, "InboundFeeBaseMsat");
              createChangeMsatHandler("inboundFeeBaseMsat")(undefined);
            }}
            inputDataType={systemVariableDataType.number}
            editingDisabled={editingDisabled}
          >
            <Input
              intercomTarget={"channel-policy-configurator-inbound-fee-base"}
              formatted={true}
              value={inboundFeeBase}
              thousandSeparator={","}
              suffix={" sat"}
              onValueChange={createChangeMsatHandler("inboundFeeBaseMsat")}
              label={t.updateChannelPolicy.inbound}
              sizeVariant={InputSizeVariant.small}
              disabled={editingDisabled}
            />
          </SystemVariableInputWrapper>
          <SystemVariableInputWrapper
            variable={systemVariables["FeeBaseMsat"]}
            handleVariableChange={(variable) => {
              setVariable(variable, "FeeBaseMsat");
              createChangeMsatHandler("feeBaseMsat")(undefined);
            }}
            inputDataType={systemVariableDataType.number}
            editingDisabled={editingDisabled}
          >
            <Input
              intercomTarget={"channel-policy-configurator-fee-base"}
              formatted={true}
              value={feeBase}
              thousandSeparator={","}
              suffix={" sat"}
              onValueChange={createChangeMsatHandler("feeBaseMsat")}
              label={t.updateChannelPolicy.outbound}
              sizeVariant={InputSizeVariant.small}
              disabled={editingDisabled}
            />
          </SystemVariableInputWrapper>
        </InputRow>

        <div>{t.updateChannelPolicy.htlcAmount}</div>
        <InputRow>
          <SystemVariableInputWrapper
            variable={systemVariables["MinHtlcMsat"]}
            handleVariableChange={(variable) => {
              setVariable(variable, "MinHtlcMsat");
              createChangeMsatHandler("minHtlcMsat")(undefined);
            }}
            inputDataType={systemVariableDataType.number}
            editingDisabled={editingDisabled}
          >
            <Input
              intercomTarget={"channel-policy-configurator-min-htlc"}
              formatted={true}
              value={minHtlc}
              thousandSeparator={","}
              suffix={" sat"}
              onValueChange={createChangeMsatHandler("minHtlcMsat")}
              label={t.updateChannelPolicy.minimum}
              sizeVariant={InputSizeVariant.small}
              disabled={editingDisabled}
            />
          </SystemVariableInputWrapper>
          <SystemVariableInputWrapper
            variable={systemVariables["MaxHtlcMsat"]}
            handleVariableChange={(variable) => {
              setVariable(variable, "MaxHtlcMsat");
              createChangeMsatHandler("maxHtlcMsat")(undefined);
            }}
            inputDataType={systemVariableDataType.number}
            editingDisabled={editingDisabled}
          >
            <Input
              intercomTarget={"channel-policy-configurator-max-htlc"}
              formatted={true}
              value={maxHtlc}
              thousandSeparator={","}
              suffix={" sat"}
              onValueChange={createChangeMsatHandler("maxHtlcMsat")}
              label={t.updateChannelPolicy.maximum}
              sizeVariant={InputSizeVariant.small}
              disabled={editingDisabled}
            />
          </SystemVariableInputWrapper>
        </InputRow>

        <SystemVariableInputWrapper
          variable={systemVariables["TimeLockDelta"]}
          handleVariableChange={(variable) => {
            setVariable(variable, "TimeLockDelta");
            createChangeHandler("timeLockDelta")(undefined);
          }}
          inputDataType={systemVariableDataType.number}
          editingDisabled={editingDisabled}
        >
          <Input
            formatted={true}
            value={channelPolicy.timeLockDelta}
            thousandSeparator={","}
            onValueChange={createChangeHandler("timeLockDelta")}
            intercomTarget={"channel-policy-configurator-time-lock-delta-input"}
            label={t.updateChannelPolicy.timeLockDelta}
            sizeVariant={InputSizeVariant.small}
            disabled={editingDisabled}
          />
        </SystemVariableInputWrapper>

        <Button
          intercomTarget={"channel-policy-configurator-save-button"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing || editingDisabled}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
        <Note title={t.note} noteType={NoteType.info}>
          <p>{t.updateChannelPolicy.inboundFeeHelpText}</p>
          <p>{t.workflowNodes.channelPolicyConfiguratorDescription}</p>
        </Note>
      </Form>
    </WorkflowNodeWrapper>
  );
}
