import { Receipt20Regular as ChannelRequestIcon, Save16Regular as SaveIcon } from "@fluentui/react-icons";
import { useGetActiveNodeSettingsQuery, useGetLsps1NodesQuery } from "apiSlice";
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import Button, { ButtonPosition, ColorVariant } from "components/buttons/Button";
import styles from "features/lsp/lsp.module.scss";
import { useNavigate } from "react-router";
import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import useTranslations from "services/i18n/useTranslations";
import { ActionMeta } from "react-select";
import { Form, Input, InputSizeVariant, RadioChips, Select } from "components/forms/forms";
import { userEvents } from "utils/userEvents";
import { IsNumericOption, IsServerErrorResult } from "utils/typeChecking";
import { useChannelRequestMutation } from "features/lsp/lspApi";
import { NumberFormatValues } from "react-number-format";
import { ChannelRequestParameters } from "features/lsp/lspTypes";
import Switch, { SwitchSize } from "components/forms/switch/Switch";
import Spinny from "features/spinny/Spinny";
import { FormErrors, mergeServerError, ServerErrorType } from "components/errors/errors";
import { toastCategory } from "features/toast/Toasts";
import ToastContext from "features/toast/context";
import { useParams } from "react-router-dom";
import ErrorSummary from "components/errors/ErrorSummary";

function ChannelRequestModal() {
  const { t } = useTranslations();
  const { track } = userEvents();

  const toastRef = useContext(ToastContext);

  const navigate = useNavigate();

  const [formErrorState, setFormErrorState] = useState<FormErrors>({});
  const [nodeConfigurationOptions, setNodeConfigurationOptions] = useState<
    Array<{ value: number; label: string | undefined }>
  >([]);
  const [peerNodeOptions, setPeerNodeOptions] = useState<Array<{ value: number; label: string | undefined }>>([]);

  const { data: nodeConfigurations } = useGetActiveNodeSettingsQuery();

  const [channelRequestParameters, setChannelRequestParameters] = useState<ChannelRequestParameters>({
    torqNodeId: 0,
    privateChannel: false,
  });

  const { data: peerNodes } = useGetLsps1NodesQuery(channelRequestParameters.torqNodeId, {
    skip: (channelRequestParameters.torqNodeId || 0) === 0,
  });

  useEffect(() => {
    if (nodeConfigurations) {
      setNodeConfigurationOptions(
        nodeConfigurations.map((nodeConfiguration) => {
          if (channelRequestParameters.torqNodeId === undefined) {
            setTorqNodeId(nodeConfiguration.nodeId);
          }
          return { value: nodeConfiguration.nodeId, label: nodeConfiguration.name };
        }),
      );
    }
  }, [nodeConfigurations]);

  useEffect(() => {
    if (peerNodes) {
      setPeerNodeOptions(
        peerNodes.map((peerNode) => {
          if (channelRequestParameters.peerNodeId === undefined) {
            setPeerNodeId(peerNode.nodeId);
          }
          if (peerNode.alias === undefined || peerNode.alias === "") {
            const publicKeyPrefix = peerNode.publicKey.slice(0, 21);
            return { value: peerNode.nodeId, label: publicKeyPrefix };
          }
          return { value: peerNode.nodeId, label: peerNode.alias };
        }),
      );
    }
  }, [peerNodes]);

  const { torqNodeId: torqNodeIdParam, peerNodeId: peerNodeIdParam } = useParams<{
    torqNodeId: string;
    peerNodeId: string;
  }>();
  const torqNodeId = torqNodeIdParam ? parseInt(torqNodeIdParam) : undefined;
  const peerNodeId = peerNodeIdParam ? parseInt(peerNodeIdParam) : undefined;

  const [
    channelRequestMutation,
    { error: channelRequestError, isLoading: channelRequestIsLoading, data: channelRequestResponse },
  ] = useChannelRequestMutation();

  const formRef = useRef<HTMLFormElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (channelRequestParameters.peerNodeId !== undefined && channelRequestParameters.peerNodeId !== 0) {
      setLsp(-2);
    }
    if (IsServerErrorResult(channelRequestError)) {
      setFormErrorState(mergeServerError(channelRequestError.data, formErrorState));
    }
    if (channelRequestError && "data" in channelRequestError && channelRequestError.data) {
      const err = mergeServerError(channelRequestError.data as ServerErrorType, {});
      if (err?.server && err.server.length > 0) {
        for (const se of err.server) {
          if (se.description !== undefined && se.description !== "") {
            toastRef?.current?.addToast(se.description || "", toastCategory.error);
          }
        }
      }
    }
  }, [channelRequestError]);

  useEffect(() => {
    if (channelRequestResponse && (channelRequestResponse.orderId || "") !== "") {
      toastRef?.current?.addToast("Order: " + (channelRequestResponse!.orderId || ""), toastCategory.success);
    }
  }, [channelRequestResponse]);

  useEffect(() => {
    if (torqNodeId || 0 !== 0) {
      setTorqNodeId(torqNodeId!);
    }
  }, [torqNodeId]);

  useEffect(() => {
    if (peerNodeId || 0 !== 0) {
      setLsp(-2);
      setPeerNodeId(peerNodeId!);
    }
  }, [peerNodeId]);

  const closeAndReset = () => {
    navigate(-1);
  };

  function setTorqNodeId(torqNodeId: number) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      torqNodeId: torqNodeId,
    }));
  }

  function setPeerNodeId(peerNodeId: number) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      peerNodeId: peerNodeId,
    }));
  }

  function setLsp(lsp?: number) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      lsp: lsp,
    }));
  }

  function setLspUrl(lspUrl?: string) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      lspUrl: lspUrl,
    }));
  }

  function setAutoPay(autoPay?: number) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      autoPay: autoPay,
    }));
  }

  function setAutoPayFeeRateMilliMsat(autoPayFeeRateMilliMsat?: number) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      autoPayFeeRateMilliMsat: autoPayFeeRateMilliMsat,
    }));
  }

  function setAutoPayFeeRate(autoPayFeeRate?: number) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      autoPayFeeRate: autoPayFeeRate,
    }));
  }

  function setAutoPaySatPerVbyte(autoPaySatPerVbyte?: number) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      autoPaySatPerVbyte: autoPaySatPerVbyte,
    }));
  }

  function setAutoPayMaximumTotalCost(autoPayMaximumTotalCost?: number) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      autoPayMaximumTotalCost: autoPayMaximumTotalCost,
    }));
  }

  function setLspBalance(lspBalance?: number) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      lspBalance: lspBalance,
    }));
  }

  function setClientBalance(clientBalance?: number) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      clientBalance: clientBalance,
    }));
  }

  function setChannelExpiryWeeks(channelExpiryWeeks?: number) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      channelExpiryWeeks: channelExpiryWeeks,
    }));
  }

  function setFundingTransactionFeeRate(fundingTransactionFeeRate?: number) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      fundingTransactionFeeRate: fundingTransactionFeeRate,
    }));
  }

  // function setFundingTransactionSatPerVbyte(fundingTransactionSatPerVbyte?: number) {
  //   return setChannelRequestParameters((prev) => ({
  //     ...prev,
  //     fundingTransactionSatPerVbyte: fundingTransactionSatPerVbyte,
  //   }));
  // }

  function setToken(token?: string) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      token: token,
    }));
  }

  function setRefundOnChainAddress(refundOnChainAddress?: string) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      refundOnChainAddress: refundOnChainAddress,
    }));
  }

  function setPrivateChannel(privateChannel: boolean) {
    return setChannelRequestParameters((prev) => ({
      ...prev,
      privateChannel: privateChannel,
    }));
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (channelRequestParameters.lsp !== undefined && channelRequestParameters.lsp < 0) {
      channelRequestParameters.lsp = undefined;
    }
    if (channelRequestParameters.lspUrl !== undefined && channelRequestParameters.lspUrl == "") {
      channelRequestParameters.lspUrl = undefined;
    }
    setFormErrorState({} as FormErrors);
    channelRequestMutation(channelRequestParameters);
    track("Channel Request", {
      nodeId: channelRequestParameters.torqNodeId,
      peerNodeId: channelRequestParameters.peerNodeId,
    });
  }

  return (
    <PopoutPageTemplate title={t.channelRequest} show={true} icon={<ChannelRequestIcon />} onClose={closeAndReset}>
      <div className={styles.activeColumns}>
        <Form onSubmit={handleSubmit} name={"channelRequestForm"} ref={formRef} intercomTarget={"channel-request-form"}>
          <Select
            intercomTarget={"channel-request-node-selection"}
            label={t.node}
            name={"torqNodeId"}
            autoFocus={true}
            onChange={(newValue: unknown, _: ActionMeta<unknown>) => {
              if (IsNumericOption(newValue)) {
                setTorqNodeId(newValue.value);
              }
            }}
            options={nodeConfigurationOptions}
            value={nodeConfigurationOptions.find((option) => option.value === channelRequestParameters.torqNodeId)}
            errors={formErrorState}
          />
          <RadioChips
            label={t.lsp}
            sizeVariant={InputSizeVariant.small}
            groupName={"channel-request-node-lsp-switch"}
            options={[
              {
                label: t.deezy,
                id: "channel-request-node-fee-switch-deezy",
                checked: channelRequestParameters.lsp === 0,
                onChange: () => {
                  setLsp(0);
                  setLspUrl("");
                },
              },
              {
                label: t.url,
                id: "channel-request-node-fee-switch-url",
                checked: channelRequestParameters.lsp === -1,
                onChange: () => {
                  setLsp(-1);
                },
              },
              {
                label: t.onion,
                id: "channel-request-node-fee-switch-onion",
                checked: channelRequestParameters.lsp === -2,
                onChange: () => {
                  setLsp(-2);
                  setLspUrl("");
                },
              },
            ]}
          />
          {channelRequestParameters.lsp === -1 && (
            <Input
              intercomTarget={"channel-request-node-lsp-url"}
              label={t.url}
              name={"lspUrl"}
              sizeVariant={InputSizeVariant.small}
              className={styles.single}
              value={channelRequestParameters.lspUrl}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLsp(undefined);
                setLspUrl(e.target.value);
              }}
              errors={formErrorState}
            />
          )}
          {channelRequestParameters.lsp === -2 && (
            <Select
              intercomTarget={"channel-request-node-lsp-onion"}
              label={t.onion}
              name={"peerNodeId"}
              sizeVariant={InputSizeVariant.small}
              onChange={(newValue: unknown, _: ActionMeta<unknown>) => {
                if (IsNumericOption(newValue)) {
                  setPeerNodeId(newValue.value);
                }
              }}
              options={peerNodeOptions}
              value={peerNodeOptions.find((option) => option.value === channelRequestParameters.peerNodeId)}
              errors={formErrorState}
            />
          )}
          <RadioChips
            label={t.autoPay}
            helpText={t.autoPayHelpText}
            sizeVariant={InputSizeVariant.small}
            groupName={"channel-request-node-autoPay-switch"}
            options={[
              {
                label: t.bolt11,
                id: "channel-request-node-autoPay-switch-bolt11",
                checked: channelRequestParameters.autoPay === 0,
                onChange: () => {
                  setAutoPay(0);
                  setAutoPayFeeRate(undefined);
                },
              },
              {
                label: t.lnurl,
                id: "channel-request-node-autoPay-switch-lnurl",
                checked: channelRequestParameters.autoPay === 1,
                onChange: () => {
                  setAutoPay(1);
                  setAutoPayFeeRate(undefined);
                },
              },
              {
                label: t.onChain,
                id: "channel-request-node-autoPay-switch-onchain-transaction",
                checked: channelRequestParameters.autoPay === 2,
                onChange: () => {
                  setAutoPay(2);
                  setAutoPayFeeRate(undefined);
                },
              },
            ]}
          />
          {channelRequestParameters.autoPay === 2 && (
            <>
              <RadioChips
                label={t.onChainFee}
                helpText={t.onChainFeeHelpText}
                sizeVariant={InputSizeVariant.small}
                vertical={true}
                groupName={"channel-request-node-autoPay-fee-switch"}
                options={[
                  {
                    label: t.mempool.fastest,
                    id: "channel-request-node-autoPay-fee-switch-fastestFee",
                    checked: channelRequestParameters.autoPayFeeRate === 0,
                    onChange: () => setAutoPayFeeRate(0),
                  },
                  {
                    label: t.mempool.halfHour,
                    id: "channel-request-node-autoPay-fee-switch-halfHourFee",
                    checked: channelRequestParameters.autoPayFeeRate === 1,
                    onChange: () => setAutoPayFeeRate(1),
                  },
                  {
                    label: t.mempool.hour,
                    id: "channel-request-node-autoPay-fee-switch-hourFee",
                    checked: channelRequestParameters.autoPayFeeRate === 2,
                    onChange: () => setAutoPayFeeRate(2),
                  },
                  {
                    label: t.mempool.economy,
                    id: "channel-request-node-autoPay-fee-switch-economyFee",
                    checked: channelRequestParameters.autoPayFeeRate === 3,
                    onChange: () => setAutoPayFeeRate(3),
                  },
                  {
                    label: t.mempool.minimum,
                    id: "channel-request-node-autoPay-fee-switch-minimumFee",
                    checked: channelRequestParameters.autoPayFeeRate === 4,
                    onChange: () => setAutoPayFeeRate(4),
                  },
                  {
                    label: t.Fixed,
                    id: "channel-request-node-autoPay-fee-switch-custom",
                    checked: channelRequestParameters.autoPayFeeRate === 5,
                    onChange: () => setAutoPayFeeRate(5),
                  },
                ]}
              />
              {channelRequestParameters.autoPayFeeRate === 5 && (
                <Input
                  intercomTarget={"channel-request-node-autoPay-sat-per-vbyte-input"}
                  label={t.Fixed + " sat/vbyte"}
                  name={"autoPaySatPerVbyte"}
                  sizeVariant={InputSizeVariant.small}
                  formatted={true}
                  className={styles.single}
                  thousandSeparator={","}
                  value={channelRequestParameters.autoPaySatPerVbyte}
                  suffix={" sat/vbyte"}
                  onValueChange={(values: NumberFormatValues) => {
                    setAutoPaySatPerVbyte(values.floatValue);
                  }}
                  errors={formErrorState}
                />
              )}
            </>
          )}
          {channelRequestParameters.autoPay === 0 && (
            <Input
              intercomTarget={"channel-request-node-autoPay-feeRateMilliMsat-input"}
              label={t.maximumCostMilliMsat}
              name={"maximumCostMilliMsat"}
              helpText={t.maximumCostMilliMsatHelpText}
              sizeVariant={InputSizeVariant.small}
              formatted={true}
              className={styles.single}
              thousandSeparator={","}
              value={channelRequestParameters.autoPayFeeRateMilliMsat}
              suffix={" ppm"}
              onValueChange={(values: NumberFormatValues) => {
                setAutoPayFeeRateMilliMsat(values.floatValue);
              }}
              errors={formErrorState}
            />
          )}
          {channelRequestParameters.autoPay !== undefined && (
            <Input
              intercomTarget={"channel-request-node-autoPay-maximumTotalCost-input"}
              label={t.maximumTotalCost}
              name={"maximumTotalCost"}
              helpText={t.maximumTotalCostHelpText}
              sizeVariant={InputSizeVariant.small}
              formatted={true}
              className={styles.single}
              thousandSeparator={","}
              value={channelRequestParameters.autoPayMaximumTotalCost}
              suffix={" sat"}
              onValueChange={(values: NumberFormatValues) => {
                setAutoPayMaximumTotalCost(values.floatValue);
              }}
              errors={formErrorState}
            />
          )}
          <Input
            intercomTarget={"channel-request-node-lspBalance-input"}
            label={t.lspBalance}
            name={"lspBalance"}
            helpText={t.lspBalanceHelpText}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={channelRequestParameters.lspBalance}
            suffix={" sats"}
            onValueChange={(values: NumberFormatValues) => {
              setLspBalance(values.floatValue);
            }}
            errors={formErrorState}
          />
          <Input
            intercomTarget={"channel-request-node-clientBalance-input"}
            label={t.clientBalance}
            name={"clientBalance"}
            helpText={t.clientBalanceHelpText}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={channelRequestParameters.clientBalance}
            suffix={" sats"}
            onValueChange={(values: NumberFormatValues) => {
              setClientBalance(values.floatValue);
            }}
            errors={formErrorState}
          />
          <Input
            intercomTarget={"channel-request-node-channelExpiryWeeks-input"}
            label={t.channelExpiry}
            name={"channelExpiryWeeks"}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={channelRequestParameters.channelExpiryWeeks}
            suffix={" weeks"}
            onValueChange={(values: NumberFormatValues) => {
              setChannelExpiryWeeks(values.floatValue);
            }}
            errors={formErrorState}
          />
          <RadioChips
            label={t.fundingTransactionFee}
            sizeVariant={InputSizeVariant.small}
            vertical={true}
            groupName={"channel-request-node-fundingTransaction-fee-switch"}
            options={[
              {
                label: t.mempool.fastest,
                id: "channel-request-node-fundingTransaction-fee-switch-fastestFee",
                checked: channelRequestParameters.fundingTransactionFeeRate === 0,
                onChange: () => setFundingTransactionFeeRate(0),
              },
              {
                label: t.mempool.halfHour,
                id: "channel-request-node-fundingTransaction-fee-switch-halfHourFee",
                checked: channelRequestParameters.fundingTransactionFeeRate === 1,
                onChange: () => setFundingTransactionFeeRate(1),
              },
              {
                label: t.mempool.hour,
                id: "channel-request-node-fundingTransaction-fee-switch-hourFee",
                checked: channelRequestParameters.fundingTransactionFeeRate === 2,
                onChange: () => setFundingTransactionFeeRate(2),
              },
              {
                label: t.mempool.economy,
                id: "channel-request-node-fundingTransaction-fee-switch-economyFee",
                checked: channelRequestParameters.fundingTransactionFeeRate === 3,
                onChange: () => setFundingTransactionFeeRate(3),
              },
              {
                label: t.mempool.minimum,
                id: "channel-request-node-fundingTransaction-fee-switch-minimumFee",
                checked: channelRequestParameters.fundingTransactionFeeRate === 4,
                onChange: () => setFundingTransactionFeeRate(4),
              },
              // {
              //   label: t.Fixed,
              //   id: "channel-request-node-fundingTransaction-fee-switch-custom",
              //   checked: channelRequestParameters.fundingTransactionFeeRate === 5,
              //   onChange: () => setFundingTransactionFeeRate(5),
              // },
            ]}
          />
          {/*{channelRequestParameters.fundingTransactionFeeRate === 5 && (*/}
          {/*  <Input*/}
          {/*    intercomTarget={"channel-request-node-fundingTransaction-sat-per-vbyte-input"}*/}
          {/*    label={t.Fixed + " sat/vbyte"}*/}
          {/*    sizeVariant={InputSizeVariant.small}*/}
          {/*    formatted={true}*/}
          {/*    className={styles.single}*/}
          {/*    thousandSeparator={","}*/}
          {/*    value={channelRequestParameters.fundingTransactionSatPerVbyte}*/}
          {/*    suffix={" sat/vbyte"}*/}
          {/*    onValueChange={(values: NumberFormatValues) => {*/}
          {/*      setFundingTransactionSatPerVbyte(values.floatValue);*/}
          {/*    }}*/}
          {/*    errors={formErrorState}*/}
          {/*  />*/}
          {/*)}*/}
          <Input
            intercomTarget={"channel-request-node-token-input"}
            label={t.token}
            name={"token"}
            sizeVariant={InputSizeVariant.small}
            className={styles.single}
            value={channelRequestParameters.token}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setToken(e.target.value);
            }}
            errors={formErrorState}
          />
          <Input
            intercomTarget={"channel-request-node-refundOnChainAddress-input"}
            label={t.refundOnChainAddress}
            name={"refundOnChainAddress"}
            sizeVariant={InputSizeVariant.small}
            className={styles.single}
            value={channelRequestParameters.refundOnChainAddress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setRefundOnChainAddress(e.target.value);
            }}
            errors={formErrorState}
          />
          <Switch
            intercomTarget={"channel-request-node-privateChannel-switch"}
            label={t.privateChannel}
            sizeVariant={SwitchSize.small}
            checked={channelRequestParameters.privateChannel}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setPrivateChannel(e.target.checked);
            }}
          />
          <Button
            intercomTarget={"channel-request-submit-button"}
            type={"submit"}
            buttonColor={ColorVariant.primary}
            buttonPosition={ButtonPosition.fullWidth}
            ref={buttonRef}
            disabled={channelRequestIsLoading}
            icon={channelRequestIsLoading ? <Spinny /> : <SaveIcon />}
          >
            {t.channelRequest}
          </Button>
          <ErrorSummary errors={formErrorState} />
        </Form>
      </div>
    </PopoutPageTemplate>
  );
}

export default ChannelRequestModal;
