import classNames from "classnames";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { DismissCircle24Regular as DeleteLinkIcon } from "@fluentui/react-icons";
import { SelectWorkflowLinks, useDeleteNodeLinkMutation } from "pages/WorkflowPage/workflowApi";
import { selectDisplayPreviewLink } from "pages/WorkflowPage/WorkflowSlice";
import { WorkflowVersionNodeLink } from "pages/WorkflowPage/workflowTypes";
import styles from "components/workflow/links/workflow_link.module.scss";
import { userEvents } from "utils/userEvents";

type WorkflowLinkProp = {
  link: WorkflowVersionNodeLink;
};

export type LinkPositionEventDetails = { x: number; y: number; nodeId: number };
export type PreviewLinkPositionEventDetails = { startX: number; startY: number; x: number; y: number; nodeId: number };

function PreviewWorkflowLink() {
  const linkPreviewRef = useRef<SVGLineElement>(null);
  function setPath(path: { x1: number; y1: number; x2: number; y2: number }) {
    if (linkPreviewRef === null) return;

    const { x1, y1, x2, y2 } = path;
    const t = 0.5;

    const controlPoint1X = x1 + t * (x2 - x1);
    const controlPoint2X = x2 - t * (x2 - x1);

    linkPreviewRef?.current &&
      linkPreviewRef.current.setAttribute(
        "d",
        `M ${x1} ${y1} C ${controlPoint1X} ${y1} ${controlPoint2X} ${y2} ${x2} ${y2}`
      );
  }

  function handlePreviewPositionUpdate(e: CustomEventInit<PreviewLinkPositionEventDetails>) {
    if (linkPreviewRef !== null && linkPreviewRef.current !== null) {
      const x1 = e.detail?.startX || 0;
      const y1 = e.detail?.startY || 0;

      const x2 = e.detail?.x || 0;
      const y2 = e.detail?.y || 0;

      setPath({ x1, y1, x2, y2 });
    }
  }

  useLayoutEffect(() => {
    window.addEventListener("previewLinkEvent", handlePreviewPositionUpdate);

    // cleanup event listeners
    return () => {
      window.removeEventListener("previewLinkEvent", handlePreviewPositionUpdate);
    };
  }, [linkPreviewRef]);

  return (
    <g className={styles.linkWrapper}>
      <path strokeDasharray="10,10" ref={linkPreviewRef} id={"previewLink"} className={styles.previewLink}>
        <animate attributeName="stroke-dashoffset" values="100;0" dur="2s" calcMode="linear" repeatCount="indefinite" />
      </path>
    </g>
  );
}

function WorkflowLink(props: WorkflowLinkProp) {
  const { track } = userEvents();
  const { link } = props;
  const shadowLinkRef = useRef<SVGLineElement>(null);
  const linkRef = useRef<SVGLineElement>(null);
  const endCircleRef = useRef<SVGCircleElement>(null);
  const iconRef = useRef<SVGGElement>(null);
  const parentEventName = `parentLinkMove-${props.link.parentWorkflowVersionNodeId.toString()}-${
    props.link.parentOutput
  }`;
  const childEventName = `childLinkMove-${props.link.childWorkflowVersionNodeId.toString()}-${props.link.childInput}`;
  const initialPath = "M 0 0 C 1 1 1 1 2 2";
  const [deleteLink] = useDeleteNodeLinkMutation();

  function handleDeleteLink() {
    track("Workflow Delete link", {
      workflowVersionId: link.workflowVersionId,
      workflowStageNumber: link.stage,
      workflowParentOutput: link.parentOutput,
      workflowChildInput: link.childInput,
    });
    deleteLink({ linkId: link.workflowVersionNodeLinkId });
  }
  function setPath(path: { x1: number; y1: number; x2: number; y2: number }) {
    if (linkRef === null) {
      return;
    }
    const { x1, y1, x2, y2 } = path;
    const t = 0.5;

    const controlPoint1X = x1 + t * (x2 - x1);
    const controlPoint2X = x2 - t * (x2 - x1);

    linkRef?.current &&
      linkRef.current.setAttribute("d", `M ${x1} ${y1} C ${controlPoint1X} ${y1} ${controlPoint2X} ${y2} ${x2} ${y2}`);
    shadowLinkRef?.current &&
      shadowLinkRef.current.setAttribute(
        "d",
        `M ${x1} ${y1} C ${controlPoint1X} ${y1} ${controlPoint2X} ${y2} ${x2} ${y2}`
      );
    // Place the icon in the middle of the link path and offset it py it's height
    iconRef?.current &&
      iconRef.current.setAttribute("transform", `translate(${(x1 + x2) / 2 - 24 / 2}, ${(y1 + y2) / 2 - 24 / 2})`);
  }

  function handleParentPositionUpdate(e: CustomEventInit<LinkPositionEventDetails>) {
    if (linkRef !== null && linkRef.current !== null) {
      const x1 = e.detail?.x || 0;
      const y1 = e.detail?.y || 0;

      const pathCommands = (linkRef?.current?.getAttribute("d") || initialPath).split(" ");
      const x2 = parseFloat(pathCommands[8]);
      const y2 = parseFloat(pathCommands[9]);

      setPath({ x1, y1, x2, y2 });
    }
  }

  function handleChildPositionUpdate(e: CustomEventInit<LinkPositionEventDetails>) {
    const x2 = e.detail?.x || 0;
    const y2 = e.detail?.y || 0;
    if (linkRef !== null && linkRef.current !== null) {
      const pathCommands = (linkRef?.current?.getAttribute("d") || initialPath).split(" ");
      const x1 = parseFloat(pathCommands[1]);
      const y1 = parseFloat(pathCommands[2]);
      setPath({ x1, y1, x2, y2 });
    }
    if (endCircleRef !== null && endCircleRef.current !== null) {
      endCircleRef.current.setAttribute("cx", x2.toString());
      endCircleRef.current.setAttribute("cy", y2.toString());
    }
  }

  useLayoutEffect(() => {
    window.addEventListener(parentEventName, handleParentPositionUpdate);
    window.addEventListener(childEventName, handleChildPositionUpdate);

    // cleanup event listeners
    return () => {
      window.removeEventListener(parentEventName, handleParentPositionUpdate);
      window.removeEventListener(childEventName, handleChildPositionUpdate);
    };
  }, [endCircleRef, linkRef, shadowLinkRef, iconRef]);

  return (
    <g className={styles.linkWrapper}>
      <path
        ref={linkRef}
        id={"link-" + link.workflowVersionNodeLinkId}
        key={"link-" + link.workflowVersionNodeLinkId}
        className={styles.link}
      />
      <path
        ref={shadowLinkRef}
        id={"shadow-link-" + link.workflowVersionNodeLinkId}
        key={"shadow-link-" + link.workflowVersionNodeLinkId}
        className={styles.shadowLink}
      />
      <g ref={iconRef} className={styles.deleteLinksWrapper} onClick={() => handleDeleteLink()}>
        <circle r={24} cx={12} cy={12} strokeWidth={1} stroke={"transparent"} className={styles.shadowDeleteLink} />
        <circle r={10} cx={12} cy={12} strokeWidth={1} stroke={"transparent"} />
        <DeleteLinkIcon />
      </g>
    </g>
  );
}

type WorkflowLinkProps = {
  workflowId: number;
  version: number;
  workflowVersionId: number;
  stage: number;
  selectedStage: boolean;
  style?: React.CSSProperties;
};

function WorkflowLinks(props: WorkflowLinkProps) {
  const links = useSelector(
    SelectWorkflowLinks({ workflowId: props.workflowId, version: props.version, stage: props.stage })
  );

  const svgRef = useRef<SVGSVGElement>(null);

  const displayPreviewLink = useSelector(selectDisplayPreviewLink);
  // const [scale, setScale] = useState(1 / window.devicePixelRatio || 1);

  const [initialDimensions, setInitialDimensions] = useState({width: 0, height: 0});

  useEffect(() => {
    // Assume your SVG is full screen or based on a specific container's size
    const updateInitialDimensions = () => {
      const svgContainer = document.getElementById('svgContainerId'); // Or however you're determining size
      if (svgContainer) {
        setInitialDimensions({
          width: svgContainer.offsetWidth,
          height: svgContainer.offsetHeight,
        });
      }
    };

    // Call once on mount to set initial dimensions
    updateInitialDimensions();

    // Optional: If your app needs to handle dynamic resizing (not just zoom/DPI changes), you can listen to resize events
    window.addEventListener('resize', updateInitialDimensions);
    return () => {
      window.removeEventListener('resize', updateInitialDimensions);
    };
  }, []);

  const adjustSvgForDPI = () => {
    const dpiScale = window.devicePixelRatio;
    const svgElement= svgRef.current

    if (svgElement && initialDimensions.width > 0 && initialDimensions.height > 0) {
      // Calculate new dimensions based on the initial size and current DPI scale
      // This example maintains the aspect ratio and scales both dimensions equally
      const newWidth = initialDimensions.width / dpiScale;
      const newHeight = initialDimensions.height / dpiScale;

      svgElement.setAttribute('viewBox', `0 0 ${newWidth} ${newHeight}`);
    }
  };

  useEffect(() => {
    // Adjust the SVG whenever the DPI changes, e.g., user zooms in/out
    window.addEventListener('resize', adjustSvgForDPI);

    // Initial adjustment
    adjustSvgForDPI();

    return () => {
      window.removeEventListener('resize', adjustSvgForDPI);
    };
  }, [initialDimensions]); // Re-run when initialDimensions change
  
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        overflow: "visible",
        pointerEvents: "none",
        zIndex: 900,
      }}
    >
      <svg
        ref={svgRef}
        className={classNames(styles.workflowLinks, { [styles.active]: props.selectedStage })}
        overflow={"visible"}
        style={props.style}
      >
        {displayPreviewLink && <PreviewWorkflowLink key="preview" />}

        {links.map((link) => {
          return <WorkflowLink key={"link-" + link.workflowVersionNodeLinkId} link={link} />;
        })}
      </svg>
    </div>
  );
}

export default WorkflowLinks;
