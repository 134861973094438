import { Tag24Regular as TagHeaderIcon, Tag20Regular as TagIcon, Add20Regular as AddIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useState } from "react";
import { useNavigate } from "react-router";
import {
  useGetChannelTagsQuery,
  useGetPeerTagsQuery,
  useGetTagsQuery,
  useTagChannelMutation,
  useTagNodeMutation,
} from "pages/tags/tagsApi";
import { Select } from "components/forms/forms";
import { useParams } from "react-router-dom";
import Tag, { TagSize } from "components/tags/Tag";
import Button, { ColorVariant, ButtonPosition } from "components/buttons/Button";
import styles from "pages/tags/manageTagsPopout/manageTags.module.scss";
import { IsNumericOption } from "utils/typeChecking";
import { SectionContainer } from "features/section/SectionContainer";

export default function MangeTagsPopout() {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { data: tagsResponse } = useGetTagsQuery();
  const {
    channelId: channelIdParam,
    peerId: peerIdParam,
    nodeId: nodeIdParam,
  } = useParams<{ channelId: string; peerId: string; nodeId: string }>();
  const channelId = channelIdParam ? parseInt(channelIdParam) : undefined;
  const nodeId = nodeIdParam ? parseInt(nodeIdParam) : undefined;
  const peerId = peerIdParam ? parseInt(peerIdParam) : undefined;

  const { data: channelTags } = useGetChannelTagsQuery(
    {
      channelId: channelId,
      torqNodeId: nodeId || 0,
    },
    { skip: !channelId }
  );

  const { data: peerTags } = useGetPeerTagsQuery(
    {
      peerNodeId: peerId,
      torqNodeId: nodeId || 0,
    },
    { skip: !peerId }
  );

  const [tagNode] = useTagNodeMutation();
  const [tagChannel] = useTagChannelMutation();

  const [selectedTagState, setSelectedTagState] = useState<number | undefined>();
  const [showAppliedTags, setShowAppliedTags] = useState<boolean>(true);

  // Loops through all tags and create options for the select component
  const tagOptions = tagsResponse?.map((tag) => ({
    value: tag.tagId,
    label: tag.name,
  }));

  const closeAndReset = () => {
    navigate(-1);
  };

  function handleTagSelect(tagOption: unknown) {
    if (!IsNumericOption(tagOption)) return;
    setSelectedTagState(tagOption.value);
  }

  function addTag(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!selectedTagState) {
      return;
    }
    if (channelId !== undefined && nodeId !== undefined) {
      tagChannel({
        tagId: selectedTagState,
        channelId: channelId,
        torqNodeId: nodeId,
      });
    }
    if (peerId !== undefined && nodeId !== undefined) {
      tagNode({
        tagId: selectedTagState,
        peerNodeId: peerId,
        torqNodeId: nodeId,
      });
    }
  }

  const title = channelId ? t.channel : t.peer;

  return (
    <PopoutPageTemplate title={`${t.tag} ${title}`} show={true} icon={<TagHeaderIcon />} onClose={closeAndReset}>
      <div className={styles.addTagWrapper}>
        <h4>Add new tag</h4>
        <form onSubmit={addTag}>
          <Select
            intercomTarget={"add-tag-select-tag"}
            label={t.tag}
            autoFocus={true}
            options={tagOptions}
            onChange={handleTagSelect}
            placeholder={"Select Tag"}
          />
          <Button
            intercomTarget={"add-tag-add-tag"}
            type={"submit"}
            icon={<AddIcon />}
            buttonColor={ColorVariant.success}
            buttonPosition={ButtonPosition.left}
          >
            {t.addTag}
          </Button>
        </form>
      </div>
      <SectionContainer
        title={"Applied Tags"}
        icon={TagIcon}
        expanded={showAppliedTags}
        intercomTarget={"applied-tags-section"}
        handleToggle={() => setShowAppliedTags(!showAppliedTags)}
      >
        <div>
          {peerId && peerTags && peerTags.length > 0 && (
            <div className={styles.tagWrapper}>
              {peerTags.map((nt) => (
                <Tag
                  key={"node" + nt.tagId}
                  tagId={nt.tagId}
                  label={nt.name}
                  nodeId={nodeId}
                  peerId={peerId}
                  colorVariant={nt.style}
                  sizeVariant={TagSize.small}
                  icon={<TagIcon />}
                  deletable={true}
                />
              ))}
            </div>
          )}
          {channelId && channelTags && channelTags.length > 0 && (
            <div className={styles.tagWrapper}>
              {channelTags.map((ct) => (
                <Tag
                  key={"channel" + ct.tagId}
                  tagId={ct.tagId}
                  label={ct.name}
                  nodeId={nodeId}
                  channelId={channelId}
                  colorVariant={ct.style}
                  sizeVariant={TagSize.small}
                  icon={<TagIcon />}
                  deletable={true}
                />
              ))}
            </div>
          )}
        </div>
      </SectionContainer>
    </PopoutPageTemplate>
  );
}
