import { CheckmarkCircle20Regular as Icon } from "@fluentui/react-icons";
import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import {
  ChainalysisStatus,
  ScorechainAction,
  WorkflowRunLogDetailAction,
} from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import ParentsLog from "components/workflow/LogDetails/ParentsLog/ParentsLog";
import useTranslations from "services/i18n/useTranslations";
import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";
import { LogParameters } from "components/workflow/LogDetails/Parameters/LogParameters";
import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";

function translateChainalysisStatus(status: ChainalysisStatus) {
  const { t } = useTranslations();
  let chainalysisStatus = "";
  switch (status) {
    case ChainalysisStatus.Pending:
      chainalysisStatus = t.chainalysisStatus.pending;
      break;
    case ChainalysisStatus.Disabled:
      chainalysisStatus = t.chainalysisStatus.disabled;
      break;
    case ChainalysisStatus.Fraud:
      chainalysisStatus = t.chainalysisStatus.fraud;
      break;
    case ChainalysisStatus.Suspicious:
      chainalysisStatus = t.chainalysisStatus.suspicious;
      break;
    case ChainalysisStatus.Verified:
      chainalysisStatus = t.chainalysisStatus.verified;
      break;
    default:
      chainalysisStatus = "";
  }
  return chainalysisStatus;
}

export default function ScorechainLog(props: WorkflowRunLogDetailAction) {
  const data = props?.actions ? (props.actions as ScorechainAction[]) : undefined;
  const { t } = useTranslations();

  return (
    <LogDetailsWrapper
      type={LogDetailType.action}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.action} />
      <LogParameters
        parameters={props.parameters || ""}
        systemVariables={props.systemVariables || ""}
        colorType={LogDetailType.action}
      />
      {data != null && data.map((value, index) => {
        return (
          <LogContainer title={t.channelOpenInterceptionEventLog.result} type={LogDetailType.action} key={index}>
            <div>{value.onChainTransactionData.transactionHash}</div>
            <div>{translateChainalysisStatus(value.chainalysisStatus)}</div>
          </LogContainer>
        );
      })}
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
