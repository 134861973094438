import { ColumnMetaData } from "features/table/types";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import { OnChainTx } from "features/transact/OnChain/types";
import ChannelLinkCell from "features/transact/OnChain/ChannelLinkCell";

export default function onChainCellRenderer(
  row: OnChainTx,
  rowIndex: number,
  column: ColumnMetaData<OnChainTx>,
  columnIndex: number,
  isTotalsRow?: boolean,
  maxRow?: OnChainTx,
): JSX.Element {
  switch (column.key) {
    case "fundedShortChannelId":
      if (row.fundedShortChannelId && row.fundedChannelId && row.fundedChannelNodeId) {
        return (
          <ChannelLinkCell
            shortChannelId={row.fundedShortChannelId}
            channelId={row.fundedChannelId}
            nodeId={row.fundedChannelNodeId}
          />
        );
      }
      break;
    case "closedShortChannelId":
      if (row.closedShortChannelId && row.closedChannelId && row.closedChannelNodeId) {
        return (
          <ChannelLinkCell
            shortChannelId={row.closedShortChannelId}
            channelId={row.closedChannelId}
            nodeId={row.closedChannelNodeId}
          />
        );
      }
      break;
  }
  return DefaultCellRenderer(row, rowIndex, column, columnIndex, false, maxRow);
}
