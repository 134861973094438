// import { uuid } from "uuidv4";
import { ViewResponse } from "features/viewManagement/types";
import { Invoice } from "features/transact/Invoices/invoiceTypes";
import {
  AllInvoicesColumns,
  InvoicesFilterableColumns,
  InvoicesSortableColumns,
} from "features/transact/Invoices/invoicesColumns.generated";
import { ColumnMetaData } from "features/table/types";
import { FilterInterface } from "features/sidebar/sections/filter/filter";
import { OrderBy } from "features/sidebar/sections/sort/SortSection";
import { Implementation } from "types/api";

const defaultColumns: Array<keyof Invoice> = [
  "creationDate",
  "settleDate",
  "invoiceState",
  "amtPaid",
  "memo",
  "value",
  "isRebalance",
  "isKeysend",
  "destinationPubKey",
];

export const DefaultInvoicesColumns = defaultColumns.map((col) =>
  AllInvoicesColumns.find((x) => x.key === col),
) as Array<ColumnMetaData<Invoice>>;

export const SortableInvoiceColumns = AllInvoicesColumns.filter((column: ColumnMetaData<Invoice>) => {
  return InvoicesSortableColumns.includes(column.key);
});

export const FilterableInvoiceColumns = AllInvoicesColumns.filter((column: ColumnMetaData<Invoice>) => {
  return InvoicesFilterableColumns.includes(column.key);
});

export const InvoiceSortTemplate: Array<OrderBy> = [
  {
    key: "creationDate",
    direction: "desc",
  },
];

export const InvoiceFilterTemplate: FilterInterface = {
  key: "value",
  funcName: "gte",
  parameter: 0,
  category: "number",
  supportedBy: [Implementation.LND, Implementation.CLN],
};

export const DefaultInvoiceView: ViewResponse<Invoice> = {
  page: "invoices",
  dirty: true,
  view: {
    title: "Draft View",
    columns: DefaultInvoicesColumns,
    sortBy: InvoiceSortTemplate,
  },
};
