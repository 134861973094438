import { Eye12Regular as InspectIcon } from "@fluentui/react-icons";
import cellStyles from "components/table/cells/cell.module.scss";
import styles from "components/table/cells/workflowRunLog/workflowRunLog_cell.module.scss";
import classNames from "classnames";
import { ColorVariant, LinkButton, SizeVariant } from "components/buttons/Button";
import useTranslations from "services/i18n/useTranslations";
import * as routes from "constants/routes";
import { userEvents } from "utils/userEvents";

interface WorkflowRunLogCellProps {
  workflowLogId: number;
  workflowId: number;
  workflowName: string;
  captureLogs: boolean;
  className?: string;
}

function WorkflowRunLogCell(props: WorkflowRunLogCellProps) {
  const { t } = useTranslations();
  const { track } = userEvents();
  const content = (
    <div>
      <div className={styles.name}>{props.workflowName}</div>
      <div className={styles.actionButtons}>
        {props.captureLogs && (
          <LinkButton
            intercomTarget={"inspect-workflow-run-navigate"}
            key={"buttons-worflow-run-inspect"}
            to={`/manage/${routes.WORKFLOW_RUN_LOGS}/${props.workflowLogId}`}
            icon={<InspectIcon />}
            hideMobileText={true}
            buttonSize={SizeVariant.tiny}
            buttonColor={ColorVariant.accent1}
            onClick={() => {
              track("Navigate to Inspect Workflow Run Log Detail", {
                workflowLogId: props.workflowLogId,
                workflowId: props.workflowId,
                workflowName: props.workflowName,
              });
            }}
          >
            {t.inspect}
          </LinkButton>
        )}
      </div>
    </div>
  );

  return (
    <div className={classNames(cellStyles.cell, cellStyles.alignLeft, cellStyles.locked, props.className)}>
      {content}
    </div>
  );
}
export default WorkflowRunLogCell;
