import LongTextWithCopy from "components/common/longTextWithCopy/LongTextWithCopy";
import cellStyles from "components/table/cells/cell.module.scss";

export type TextCellProps = {
  text: string | Array<string>;
  link?: string;
  copyText?: string;
  className?: string;
  totalCell?: boolean;
};

const LongTextCell = (props: TextCellProps) => {
  if (!props.totalCell) {
    return (
      <LongTextWithCopy text={props.text} link={props.link} copyText={props.copyText} className={cellStyles.cell} />
    );
  } else {
    return <div className={cellStyles.cell}></div>;
  }
};

export default LongTextCell;
