import React, { useEffect } from "react";
import { ReactComponent as TorqLogo } from "icons/torq-logo.svg";
import { useGetServicesQuery } from "apiSlice";
import { services } from "apiTypes";
import styles from "pages/bootstrapping/bootstrappingPage.module.scss";
import Button, { ColorVariant } from "components/buttons/Button";
import useTranslations from "services/i18n/useTranslations";
import { useNavigate } from "react-router-dom";

function BootstrappingPage() {
  const { t } = useTranslations();
  const { data: servicesData, refetch: getServices } = useGetServicesQuery(undefined, { pollingInterval: 1000 });

  const [servicesState, setServicesState] = React.useState({} as services);
  const [version = "Unknown"] = servicesState?.version?.split(" | ") ?? [];

  const navigate = useNavigate();

  const retryServices = () => {
    getServices();
    if (servicesState.mainService.status == 1) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (servicesState?.mainService?.status == 1) {
      navigate("/");
    }
  }, [servicesState]);

  useEffect(() => {
    if (servicesState?.mainService?.status == 1) {
      navigate("/");
    }
    if (servicesData) {
      setServicesState(servicesData);
    }
  }, [servicesData]);

  return (
    <div className={styles.bootstrappingPageWrapper}>
      <div className={styles.bootstrappingFormWrapper}>
        <div className={styles.logo}>
          <TorqLogo />
        </div>
        <div className={styles.bootstrappingDataWrapper}>
          Torq ({version}): {t.bootstrapping}
          <br />
          <Button
            buttonColor={ColorVariant.primary}
            onClick={retryServices}
            intercomTarget={"bootstrapping-page-retry-button"}
          >
            {t.retry}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BootstrappingPage;
