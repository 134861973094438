import { Link } from "react-router-dom";
import {
  Options20Regular as OptionsIcon,
  ArrowDownload20Regular as DownloadCsvIcon,
  ArrowSync20Regular as RefreshIcon,
} from "@fluentui/react-icons";
import TablePageTemplate, {
  TableControlSection,
  TableControlsButtonGroup,
} from "features/templates/tablePageTemplate/TablePageTemplate";
import * as Routes from "constants/routes";
import useTranslations from "services/i18n/useTranslations";
import Table from "features/table/Table";
import { AllWorkflowRunLogColumns } from "pages/workflowRunLogs/workflowRunLogs.generated";
import { useGetWorkflowRunLogsQuery } from "pages/WorkflowPage/workflowApi";
import { useAppSelector } from "store/hooks";
import { useGetTableViewsQuery, useUpdateTableViewMutation } from "features/viewManagement/viewsApiSlice";
import { selectWorkflowRunLogsView, selectViews } from "features/viewManagement/viewSlice";
import ViewsSidebar from "features/viewManagement/ViewsSidebar";
import { useState } from "react";
import { TableResponses, ViewResponse } from "features/viewManagement/types";
import {
  DefaultWorkflowRunLogsView,
  FilterableWorkflowLogsColumns,
  WorkflowLogsFilterTemplate,
  SortableWorkflowRunLogsColumns,
  WorkflowRunLogsSortTemplate,
} from "pages/workflowRunLogs/workflowRunLogsDefaults";
import { createCsvFile } from "utils/JsonTableToCsv";
import Button, { ColorVariant } from "components/buttons/Button";
import { userEvents } from "utils/userEvents";
import { WorkflowRunLog } from "pages/workflowRunLogs/workflowRunLogsTypes";
import workflowLogsCellRenderer from "pages/workflowRunLogs/workflowRunLogsCellRenderer";
import { usePagination } from "components/table/pagination/usePagination";
import useLocalStorage from "utils/useLocalStorage";
import useRefreshShortcut from "utils/refreshHook";

function WorkflowRunLogsPage() {
  const { t } = useTranslations();
  const { track } = userEvents();
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const { isSuccess } = useGetTableViewsQuery<{ isSuccess: boolean }>();
  const { viewResponse, selectedViewIndex } = useAppSelector(selectWorkflowRunLogsView);
  const workflowLogsView = useAppSelector(selectViews)("workflowRunLogs");
  const [updateTableView] = useUpdateTableViewMutation();

  const [getPagination, limit, offset] = usePagination("workflowRunLogs");
  const [viewRefreshInterval, setViewRefreshInterval] = useLocalStorage("workflowRunLogsRefreshInterval", 30);

  const workflowSummaryLogsResponse = useGetWorkflowRunLogsQuery(
    {
      limit: limit,
      offset: offset,
      order: viewResponse.view.sortBy,
      filter: viewResponse.view.filters ? viewResponse.view.filters : undefined,
    },
    { skip: !isSuccess, pollingInterval: viewRefreshInterval * 1000 },
  );

  useRefreshShortcut([workflowSummaryLogsResponse.refetch]);

  const data: Array<WorkflowRunLog> = workflowSummaryLogsResponse.data?.data || [];
  // Logic for toggling the sidebar
  const closeSidebarHandler = () => {
    setSidebarExpanded(false);
    track("Toggle Table Sidebar", { page: "WorkflowRunLogs" });
  };

  function handleNameChange(name: string) {
    const view = workflowLogsView.views[selectedViewIndex] as ViewResponse<TableResponses>;
    if (view.id) {
      updateTableView({
        id: view.id,
        view: { ...view.view, title: name },
      });
    }
  }

  const tableControls = (
    <TableControlSection intercomTarget={"work-flow-run-logs-section"}>
      <div />
      <TableControlsButtonGroup intercomTarget={"work-flow-run-logs-controls-right"}>
        <Button
          intercomTarget="download-table"
          buttonColor={ColorVariant.primary}
          title={t.download}
          hideMobileText={true}
          icon={<DownloadCsvIcon />}
          onClick={() => {
            track("Downloads Table as CSV", {
              downloadTablePage: "work-flow-run-logs",
              downloadTableViewTitle: viewResponse.view?.title,
              downloadTableColumns: viewResponse.view?.columns,
              downloadTableFilters: viewResponse.view?.filters,
              downloadTableSortBy: viewResponse.view?.sortBy,
            });
            createCsvFile(data, viewResponse.view.title || "workFlow Run Logs");
          }}
        />
        <Button
          intercomTarget="refresh-table"
          buttonColor={ColorVariant.primary}
          icon={<RefreshIcon />}
          onClick={() => {
            track("Refresh Table", { page: "Workflow Run Logs" });
            workflowSummaryLogsResponse.refetch();
          }}
        />
        <Button
          intercomTarget="table-settings"
          onClick={() => {
            track("Toggle Table Sidebar", { page: "Workflow Run Logs" });
            setSidebarExpanded(!sidebarExpanded);
          }}
          icon={<OptionsIcon />}
          id={"tableControlsButton"}
        >
          {t.Options}
        </Button>
      </TableControlsButtonGroup>
    </TableControlSection>
  );

  const sidebar = (
    <ViewsSidebar
      onExpandToggle={closeSidebarHandler}
      expanded={sidebarExpanded}
      viewResponse={viewResponse}
      selectedViewIndex={selectedViewIndex}
      allColumns={AllWorkflowRunLogColumns}
      defaultView={DefaultWorkflowRunLogsView}
      filterableColumns={FilterableWorkflowLogsColumns}
      filterTemplate={WorkflowLogsFilterTemplate}
      sortableColumns={SortableWorkflowRunLogsColumns}
      sortByTemplate={WorkflowRunLogsSortTemplate}
      setViewRefreshInterval={(seconds: number) => {
        setViewRefreshInterval(seconds);
      }}
      viewRefreshInterval={viewRefreshInterval}
    />
  );

  const breadcrumbs = [
    <span key="b1">{t.manage}</span>,
    <Link key="b2" to={`/${Routes.WORKFLOWS}/${Routes.WORKFLOW_RUN_LOGS}`}>
      {t.workflowRunLogs}
    </Link>,
  ];

  return (
    <TablePageTemplate
      title={viewResponse.view.title}
      titleContent={""}
      breadcrumbs={breadcrumbs}
      sidebarExpanded={sidebarExpanded}
      sidebar={sidebar}
      tableControls={tableControls}
      onNameChange={handleNameChange}
      isDraft={viewResponse.id === undefined}
      pagination={getPagination(workflowSummaryLogsResponse.data?.pagination?.total || 0)}
    >
      <Table
        intercomTarget={"workflow-logs-table"}
        data={data}
        cellRenderer={workflowLogsCellRenderer}
        activeColumns={viewResponse.view.columns || []}
        isLoading={
          workflowSummaryLogsResponse.isLoading ||
          workflowSummaryLogsResponse.isFetching ||
          workflowSummaryLogsResponse.isUninitialized
        }
      />
    </TablePageTemplate>
  );
}

export default WorkflowRunLogsPage;
