// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go


import { ColumnMetaData } from "features/table/types";
import { OnChainTx } from "features/transact/OnChain/types";

export const AllOnChainTransactionsColumns: ColumnMetaData<OnChainTx>[] = [
	{
		heading: "Amount",
		type: "NumericCell",
		key: "amountSat",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Output Address",
		type: "LongTextCell",
		key: "address",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node Name",
		type: "TextCell",
		key: "nodeName",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Output Lnd Lease ID",
		type: "LongTextCell",
		key: "lndLeaseId",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Output Locked At Date",
		type: "DateCell",
		key: "lockedAt",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Output Index",
		type: "NumericCell",
		key: "outputIndex",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Output Tx Hash",
		type: "LongTextCell",
		key: "hash",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Output Block Hash",
		type: "LongTextCell",
		key: "blockHash",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Output Block Height",
		type: "NumericCell",
		key: "blockHeight",
		valueType: "number",
		suffix: "",
		locked: true,
		supportedBy: [0, 1],
	},
	{
		heading: "Output Fees",
		type: "NumericCell",
		key: "totalFeesSat",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Output Raw Hex",
		type: "LongTextCell",
		key: "rawHex",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Output Chainalysis Status",
		type: "TextCell",
		key: "chainalysisStatus",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "Pending", value: "0" },
			{ label: "Disabled", value: "1" },
			{ label: "Verified", value: "2" },
			{ label: "Suspicious", value: "3" },
			{ label: "Fraud", value: "4" },
		],
	},
	{
		heading: "Output Block Time",
		type: "DateCell",
		key: "blockTime",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Input Index",
		type: "NumericCell",
		key: "inputIndex",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Input Tx Hash",
		type: "LongTextCell",
		key: "inputHash",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Input Block Hash",
		type: "LongTextCell",
		key: "inputBlockHash",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Input Block Height",
		type: "NumericCell",
		key: "inputBlockHeight",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Input Fees",
		type: "NumericCell",
		key: "inputTotalFeesSat",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Input Raw Hex",
		type: "LongTextCell",
		key: "inputRawHex",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Input Chainalysis Status",
		type: "TextCell",
		key: "inputChainalysisStatus",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "Pending", value: "0" },
			{ label: "Disabled", value: "1" },
			{ label: "Verified", value: "2" },
			{ label: "Suspicious", value: "3" },
			{ label: "Fraud", value: "4" },
		],
	},
	{
		heading: "Input Block Time",
		type: "DateCell",
		key: "inputBlockTime",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Funded Channel",
		type: "LongTextCell",
		key: "fundedShortChannelId",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Closed Channel",
		type: "LongTextCell",
		key: "closedShortChannelId",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const OnChainTransactionsSortableColumns: Array<keyof OnChainTx> = [
	"amountSat",
	"address",
	"nodeName",
	"lockedAt",
	"outputIndex",
	"hash",
	"blockHash",
	"blockHeight",
	"totalFeesSat",
	"rawHex",
	"chainalysisStatus",
	"blockTime",
	"inputIndex",
	"inputHash",
	"inputBlockHash",
	"inputBlockHeight",
	"inputTotalFeesSat",
	"inputRawHex",
	"inputChainalysisStatus",
	"inputBlockTime",
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const OnChainTransactionsFilterableColumns: Array<keyof OnChainTx> = [
	"amountSat",
	"address",
	"nodeName",
	"lockedAt",
	"outputIndex",
	"hash",
	"blockHash",
	"blockHeight",
	"totalFeesSat",
	"rawHex",
	"chainalysisStatus",
	"blockTime",
	"inputIndex",
	"inputHash",
	"inputBlockHash",
	"inputBlockHeight",
	"inputTotalFeesSat",
	"inputRawHex",
	"inputChainalysisStatus",
	"inputBlockTime",
	"fundedShortChannelId",
	"closedShortChannelId",
];