import ReactSelect, {
  Props,
  components,
  MultiValueRemoveProps,
  GroupBase,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  StylesConfig,
} from "react-select";
import {
  ChevronDown16Regular as ChevronDownIcon,
  Dismiss12Regular as MultiValueRemoveIcon,
  Dismiss16Regular as ClearIcon,
  WarningRegular as WarningIcon,
  ErrorCircleRegular as ErrorIcon,
  QuestionCircle16Regular as HelpIcon,
} from "@fluentui/react-icons";
import styles from "components/forms/select/select.module.scss";
import labelStyles from "components/forms/label/label.module.scss";
import classNames from "classnames";
import { GetColorClass, GetSizeClass, InputColorVaraint, InputSizeVariant } from "components/forms/variants";
import { useId } from "react";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import useTranslations from "services/i18n/useTranslations";
import { FormErrors, replaceMessageMergeTags } from "components/errors/errors";

export type SelectOption = { value: string | number; label: string; isDisabled?: boolean | undefined };

const customStyles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
  indicatorSeparator: () => {
    return {};
  },
  control: (provided, state) => ({
    ...provided,
    borderRadius: 4,
    backgroundColor: state.isFocused ? "var(--input-focus-background)" : "var(--input-default-background)",
    borderColor: state.isFocused ? "var(--input-focus-border-color)" : "transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: state.isFocused ? "var(--input-focus-background)" : "var(--input-hover-background)",
      boxShadow: "none",
    },
    fontSize: "var(--input-font-size)",
    minHeight: "var(--input-height)",
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: "var(--input-placeholder-color)",
      fontSize: "var(--input-font-size)",
    };
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "var(--input-color)",
    fontSize: "var(--input-font-size)",
    padding: "var(--indicator-padding)",
    alignItems: "flex-start",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "var(--input-color)",
    fontSize: "var(--input-font-size)",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
    padding: "0",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "var(--input-placeholder-color)" : "var(--input-color)",
    fontSize: "var(--input-font-size)",
    background: state.isFocused ? "var(--input-default-background)" : "#ffffff",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "var(--input-hover-background)",
    },
    borderRadius: "4px",
  }),
  menuList: (provided) => ({
    ...provided,
    borderColor: "transparent",
    boxShadow: "none",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  }),
  menu: (provided) => ({
    ...provided,
    margin: "8px 4px",
    clip: "initial",
    width: "100%",
    borderColor: "transparent",
    borderRadius: "4px",
    boxShadow: "var(--hover-box-shadow)",
    zIndex: "10",
  }),
};

export type SelectProps = Props & {
  label?: string;
  colorVariant?: InputColorVaraint;
  sizeVariant?: InputSizeVariant;
  helpText?: string;
  warningText?: string;
  errorText?: string;
  intercomTarget: string;
  selectComponents?: Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>> | undefined;
  errors?: FormErrors;
};

export default function Select({
  label,
  colorVariant,
  sizeVariant,
  helpText,
  warningText,
  errorText,
  intercomTarget,
  selectComponents,
  errors,
  ...selectProps
}: SelectProps) {
  const { t } = useTranslations();
  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon />
      </components.DropdownIndicator>
    );
  };
  const MultiValueRemove = (props: MultiValueRemoveProps<unknown, boolean, GroupBase<unknown>>) => {
    return (
      <components.MultiValueRemove {...props}>
        <MultiValueRemoveIcon />
      </components.MultiValueRemove>
    );
  };
  const ClearIndicator = (props: ClearIndicatorProps<unknown, boolean, GroupBase<unknown>>) => {
    return (
      <components.ClearIndicator {...props}>
        <ClearIcon />
      </components.ClearIndicator>
    );
  };
  if (errors && errors.fields && selectProps.name && errors.fields[selectProps.name]) {
    const codeOrDescription = errors.fields[selectProps.name][0];
    const translatedError = t.errors[codeOrDescription.code];
    const mergedError = replaceMessageMergeTags(translatedError, codeOrDescription.attributes);
    errorText = mergedError ?? codeOrDescription.description;
  }
  const inputId = useId();
  let inputColorClass = GetColorClass(colorVariant);
  if (warningText != undefined) {
    inputColorClass = GetColorClass(InputColorVaraint.warning);
  }
  if (errorText != undefined) {
    inputColorClass = GetColorClass(InputColorVaraint.error);
  }
  if (selectProps.isDisabled === true) {
    inputColorClass = GetColorClass(InputColorVaraint.disabled);
  }

  return (
    <div
      className={classNames(styles.inputWrapper, GetSizeClass(sizeVariant), inputColorClass)}
      data-intercom-target={intercomTarget} data-testid={intercomTarget}
    >
      {label && (
        <div className={classNames(labelStyles.labelWrapper)}>
          <label htmlFor={selectProps.inputId || inputId} className={styles.label} title={"Something"}>
            {label}
          </label>
          {/* Create a div with a circled question mark icon with a data label named data-title */}
          {helpText && (
            <div className={labelStyles.tooltip}>
              <HelpIcon />
              <div className={labelStyles.tooltipTextWrapper}>
                <div className={labelStyles.tooltipTextContainer}>
                  <div className={labelStyles.tooltipHeader}>{label}</div>
                  <div className={labelStyles.tooltipText}>{helpText}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <ReactSelect
        inputId={selectProps.id || inputId}
        components={{
          DropdownIndicator,
          MultiValueRemove,
          ClearIndicator,
          ...selectComponents,
        }}
        className={classNames(selectProps.className, selectProps.isDisabled ? styles.disabled : "")}
        styles={customStyles}
        {...selectProps}
        data-testid={intercomTarget}
      />
      {errorText && (
        <div className={classNames(styles.feedbackWrapper, styles.feedbackError)}>
          <div className={styles.feedbackIcon}>
            <ErrorIcon />
          </div>
          <div className={styles.feedbackText}>{errorText}</div>
        </div>
      )}
      {warningText && (
        <div className={classNames(styles.feedbackWrapper, styles.feedbackWarning)}>
          <div className={styles.feedbackIcon}>
            <WarningIcon />
          </div>
          <div className={styles.feedbackText}>{warningText}</div>
        </div>
      )}
    </div>
  );
}
