import useTranslations from "services/i18n/useTranslations";
import styles from "components/workflow/variableButton/workflow_variable_button.module.scss";
import { MutableRefObject, useContext, useRef, useState } from "react";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import { toastCategory } from "features/toast/Toasts";
import React from "react";
import ToastContext from "features/toast/context";
import classNames from "classnames";
import { ColorVariant } from "components/buttons/buttonVariants";
import Button, { ButtonRow } from "components/buttons/Button";
import { Edit20Regular as EditIcon } from "@fluentui/react-icons";
import { systemVariable } from "apiTypes";
import { useLocation, useNavigate } from "react-router";
import { SYSTEM_VARIABLE } from "constants/routes";
import { userEvents } from "utils/userEvents";

export type WorkflowVariableDragButtonProps = {
  variable: systemVariable;
  buttonColor: ColorVariant;
  disabled?: boolean;
};

export function WorkflowVariableButton(props: WorkflowVariableDragButtonProps) {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const location = useLocation();
  const { track } = userEvents();

  const dragButtonRef = useRef() as MutableRefObject<HTMLButtonElement>;

  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;
  const toastRef = React.useContext(ToastContext);

  const [isDragging, setIsDragging] = useState(false);

  function handleDragStart(e: React.DragEvent<HTMLButtonElement>) {
    if (props.disabled) {
      return;
    }

    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }

    setIsDragging(true);

    // Set the drag image to the button itself with the position of the mouse relative to the button
    e.dataTransfer.setDragImage(dragButtonRef.current, 0, 0);
    e.dataTransfer.effectAllowed = "move";

    e.dataTransfer.setData("systemVariable/event", "add");
    e.dataTransfer.setData("systemVariable/dataType", props.variable.dataType.toString());
    e.dataTransfer.setData("systemVariable/systemVariableId", props.variable.systemVariableId.toString());
    e.dataTransfer.setData("systemVariable/name", props.variable.name);
    e.dataTransfer.setData(
      "systemVariable/workflowId",
      props.variable.workflowId ? props.variable.workflowId.toString() : "",
    );
    e.dataTransfer.setData("systemVariable/dataTemplate", props.variable.dataTemplate || "");
  }

  function handleDragEnd() {
    setIsDragging(false);
  }
  return (
    <ButtonRow tight>
      <Button
        className={classNames({ [styles.disabled]: props.disabled || false })}
        buttonColor={
          props.variable.workflowId == undefined || props.variable.workflowId === 0
            ? ColorVariant.warning
            : ColorVariant.success
        }
        disabled={props.disabled}
        onClick={() => {
          track("Navigate to Edit System Variable", { location: location?.pathname });
          navigate(
            SYSTEM_VARIABLE +
              "/update/" +
              (props.variable.workflowId == undefined ? 0 : props.variable.workflowId) +
              "/" +
              props.variable.systemVariableId,
            {
              state: { background: location },
            },
          );
        }}
        intercomTarget={"system-variable-edit-button-" + props.variable.systemVariableId}
        icon={<EditIcon />}
      ></Button>
      <Button
        className={classNames(styles.variableDragButton, { [styles.dragging]: isDragging })}
        buttonColor={
          props.variable.workflowId == undefined || props.variable.workflowId === 0
            ? ColorVariant.warning
            : ColorVariant.success
        }
        disabled={props.disabled}
        draggable={true}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        ref={dragButtonRef}
        noOverflow
        intercomTarget={"system-variable-drag-button-" + props.variable.systemVariableId}
      >
        {props.variable.name}
      </Button>
    </ButtonRow>
  );
}
