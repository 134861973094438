import { DesktopArrowRight20Regular as Icon } from "@fluentui/react-icons";
import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import { ApiClientCallAction, WorkflowRunLogDetailAction } from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import ParentsLog from "components/workflow/LogDetails/ParentsLog/ParentsLog";
import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";
import useTranslations from "services/i18n/useTranslations";
import styles from "components/workflow/LogDetails/ApiClientLog/apiClientLog.module.scss";
import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";

export function ApiClientLog(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();
  //const params = JSON.parse(props.parameters || "{}");
  const data = props?.actions ? (props.actions[0] as ApiClientCallAction) : undefined;

  return (
    <LogDetailsWrapper
      type={LogDetailType.action}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.action} />
      {data && (
        <LogContainer title={t.requestInformation} type={LogDetailType.action}>
          <div className={styles.containerRow}>
            <div>{t.address}</div>
            <div>{data?.address}</div>
          </div>
          <div className={styles.containerRow}>
            <div>{t.apiClientMethod}</div>
            <div>{data?.method}</div>
          </div>
        </LogContainer>
      )}
      {data && (
        <LogContainer title={t.requestPayloadTemplate} type={LogDetailType.action}>
          <div>{data?.payloadTemplate}</div>
        </LogContainer>
      )}
      {data?.responses && data?.responses?.length > 0 && (
        <LogContainer title={t.requests} type={LogDetailType.action}>
          {data?.responses &&
            data?.responses.map((resp, i) => (
              <div key={"req" + i} className={styles.containerRequestRow}>
                <div className={styles.containerRow}>
                  <div>{t.requestPayload}</div>
                  <div>{resp.requestPayload}</div>
                </div>
                <div className={styles.containerRow}>
                  <div>{t.httpStatus}</div>
                  <div>{resp.httpStatus}</div>
                </div>
                <div className={styles.containerRow}>
                  <div>{t.response}</div>
                  <div>{resp.responseBody}</div>
                </div>
              </div>
            ))}
        </LogContainer>
      )}
      {data?.warnings && data?.warnings?.length > 0 && (
        <LogContainer title={t.warnings} type={LogDetailType.warning}>
          {data?.warnings &&
            data?.warnings.map((w, i) => (
              <div key={i} className={styles.containerRow}>
                {w}
              </div>
            ))}
        </LogContainer>
      )}
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
