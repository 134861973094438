import styles from "features/panel/panel.module.scss";
import classNames from "classnames";
import { PanelProps } from "features/panel/types";
import Panel from "features/panel/Panel";

function Panels<T>(props: PanelProps<T>) {
  // const numPanels = (props.data || []).length;

  const panelsClass = classNames(styles.panelsContent, { [styles.loading]: props.isLoading });
  return (
    <div className={styles.panelsWrapper} data-intercom-target={props.intercomTarget}>
      <div className={panelsClass}>
        {/* The main cells containing the data */}
        {props.data.map((row: T, index: number) => {
          return <Panel panel={row} panelIndex={index} panelRenderer={props.panelRenderer} key={"panel-" + index} />;
        })}
      </div>
    </div>
  );
}

export default Panels;
