import ChartCanvas from "features/charts/chartCanvas";
import { AbstractPlot, basePlotConfig } from "features/charts/plots/abstract";

type offLimitsXConfig = basePlotConfig & {
  key: string; // Use keyLow and keyHigh instead
  keyLow?: string; // The key before which the off limits area will be drawn
  keyHigh?: string; // The key after which the off limits area will be drawn
  offLimitsColor: string; // The color of the off limits area
  strokeColor: string; // The color of the off limits area border
};
type offLimitsXInputConfig = Partial<offLimitsXConfig> & Required<Pick<offLimitsXConfig, "id" | "key">>;

export class OffLimitXPlot extends AbstractPlot {
  config: offLimitsXConfig;

  /**
   * Plots bars on a chart canvas. To use it add it to the plots map on the Chart instance.
   *
   * @param chart - The Chart instance where Plot will be plotted on
   * @param config - Plot config, only required attributes are key and ID
   */

  constructor(chart: ChartCanvas, config: offLimitsXInputConfig) {
    super(chart, config);

    this.config = {
      offLimitsColor: "#e1d4b740", // warning faint(opacity 40%)
      strokeColor: "#968e7a", // warning faded
      ...config,
    };

    if (!this.config.keyLow && !this.config.keyHigh) {
      throw new Error("OffLimitXPlot requires keyLow or keyHigh");
    }
  }

  draw() {
    this.chart.context.fillStyle = this.config.offLimitsColor;
    this.chart.context.strokeStyle = this.config.strokeColor;

    //draw high
    if (this.config.keyHigh) {
      this.drawInternal(true);
    }
    //draw low
    if (this.config.keyLow) {
      this.drawInternal(false);
    }
  }

  drawInternal(drawHigh: boolean) {
    const key = drawHigh ? (this.config.keyHigh as string) : (this.config.keyLow as string);

    const xIndex = this.chart.data.findIndex((d) => d[key] === true);

    if (xIndex === -1) {
      return;
    }

    const xPoint = this.xPoint(this.chart.data[xIndex].date);
    const height = this.chart.config.yScale.domain()[0];
    const dataLength = this.chart.data.length;

    // draw before or after
    const width = drawHigh
      ? this.chart.tickWidth() * (dataLength + 2 - xIndex)
      : -this.chart.tickWidth() * (xIndex + 2); // add padding of 2 days to make sure the off limits area is drawn completely

    // draw
    this.chart.context.fillRect(xPoint, this.yPoint(height), width, this.chart.config.yScale(-height));
    this.chart.context.strokeRect(xPoint, this.yPoint(height), 0, this.chart.config.yScale(-height));
  }
}
