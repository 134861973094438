import React, { useEffect } from "react";
import Router from "Router";
import useTranslations from "services/i18n/useTranslations";
import ToastContext from "features/toast/context";
import Toasts, { addToastHandle } from "features/toast/Toasts";
import styles from "app.module.scss";
import LoadingApp from "components/loading/LoadingApp";
import classNames from "classnames";
import { polyfillFFDrag } from "utils/drag";
import Livechat from "components/chat/livechat";

function App() {
  const { init, status: i18nStatus } = useTranslations();
  const toastRef = React.useRef<addToastHandle>();

  useEffect(() => {
    init();
    polyfillFFDrag();
  }, []);

  return i18nStatus === "loading" ? (
    <LoadingApp />
  ) : (
    <>
      <Livechat />
      <ToastContext.Provider value={toastRef}>
        <div className={classNames(styles.app)}>
          <Toasts ref={toastRef} />
          <Router />
        </div>
      </ToastContext.Provider>
    </>
  );
}

export default App;
