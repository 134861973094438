import { WorkflowVersionNodeSystemVariablesMap } from "pages/WorkflowPage/workflowTypes";
import { AnyObject } from "utils/types";

export function checkNodeInputsDirty(
  parameters: AnyObject,
  configuration: AnyObject,
  paramsSystemVariables: WorkflowVersionNodeSystemVariablesMap,
  systemVariables: WorkflowVersionNodeSystemVariablesMap,
): boolean {
  const paramsDirty =
    JSON.stringify(parameters, Object.keys(parameters).sort()) !==
    JSON.stringify(configuration, Object.keys(configuration).sort());

  return paramsDirty || checkSystemVariablesDirty(paramsSystemVariables, systemVariables);
}

export function checkSystemVariablesDirty(
  existingSystemVariables: WorkflowVersionNodeSystemVariablesMap,
  currentSystemVariables: WorkflowVersionNodeSystemVariablesMap,
): boolean {
  const existingSystemVariablesLen =
    existingSystemVariables && typeof existingSystemVariables === "object"
      ? Object.keys(existingSystemVariables).length
      : 0;
  const currentSystemVariablesLen =
    currentSystemVariables && typeof currentSystemVariables === "object"
      ? Object.keys(currentSystemVariables).length
      : 0;

  if (existingSystemVariablesLen !== currentSystemVariablesLen) {
    return true;
  }

  for (const key in existingSystemVariables) {
    if (existingSystemVariables[key]?.systemVariableId !== currentSystemVariables[key]?.systemVariableId) {
      return true;
    }
  }

  // not dirty
  return false;
}
