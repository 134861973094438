import { useState } from "react";
import {
  ArrowRouting20Regular as ChannelsIcon,
  Molecule20Regular as NodesIcon,
  TargetArrow24Regular as TargetIcon,
} from "@fluentui/react-icons";
import styles from "features/targetsSection/targets_section.module.scss";
import TargetsHeader from "components/targets/TargetsHeader";
import Collapse from "features/collapse/Collapse";
import useTranslations from "services/i18n/useTranslations";
import { TaggedChannels, TaggedNodes } from "pages/tags/tagsTypes";
import Target from "components/targets/Target";

type TargetsSectionProps = {
  tagId: number;
  tagName: string;
  channels: TaggedChannels[];
  nodes: TaggedNodes[];
};

export default function TargetsSection(props: TargetsSectionProps) {
  const { t } = useTranslations();
  const [collapsedNode, setCollapsedNode] = useState<boolean>(false);
  const [collapsedChannel, setCollapsedChannel] = useState<boolean>(false);

  return (
    <div className={styles.targetsSection} data-intercom-target={"tag-applied-to-section"}>
      <div className={styles.target}>
        <div className={styles.targetIcon}>
          <TargetIcon />
        </div>
        <div className={styles.targetText}>Applied to</div>
      </div>

      <div className={styles.addTagWrapper} data-intercom-target={"tags-applied-to-nodes"}>
        <div className={styles.collapsGroup}>
          <TargetsHeader
            title={`${props.nodes?.length || 0} ${t.nodes}`}
            icon={<NodesIcon />}
            expanded={!collapsedNode}
            onCollapse={() => setCollapsedNode(!collapsedNode)}
          />
          <Collapse collapsed={collapsedNode} animate={true}>
            <div className={styles.targetsWrapper}>
              {!props.nodes?.length && (
                <div className={styles.noTargets}>
                  <span className={styles.noTargetsText}>{t.tagsModal.noNodesSelected}</span>
                </div>
              )}
              {props.nodes?.map((c) => {
                return (
                  <Target
                    key={"node-target-" + c.peerNodeId}
                    icon={<NodesIcon />}
                    details={"Open channels: " + c.openChannelCount + " (closed: " + c.closedChannelCount + ")"}
                    title={c.name}
                  />
                );
              })}
            </div>
          </Collapse>
        </div>
      </div>

      <div className={styles.addTagWrapper} data-intercom-target={"tags-applied-to-channels"}>
        <div className={styles.collapsGroup}>
          <TargetsHeader
            title={`${props.channels?.length || 0} ${t.channels}`}
            icon={<ChannelsIcon />}
            expanded={!collapsedChannel}
            onCollapse={() => setCollapsedChannel(!collapsedChannel)}
          />
          <Collapse collapsed={collapsedChannel} animate={true}>
            <div className={styles.targetsWrapper}>
              {!props.channels?.length && (
                <div className={styles.noTargets}>
                  <span className={styles.noTargetsText}>{t.tagsModal.noChannelsSelected}</span>
                </div>
              )}
              {props.channels?.map((c) => {
                return (
                  <Target
                    key={"channel-target-" + c.channelId}
                    icon={<ChannelsIcon />}
                    details={c.name}
                    title={c.shortChannelId}
                  />
                );
              })}
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}
