import { useParams } from "react-router-dom";
import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";
import { selectActiveNetwork } from "features/network/networkSlice";
import { useAppSelector } from "store/hooks";
import { useGetWorkflowRunLogDetailQuery } from "pages/WorkflowPage/workflowApi";
import { WorkflowRunLogDetailResponse } from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import Tag, { TagColor, TagSize } from "components/tags/Tag";
import { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import styles from "components/workflow/LogDetails/ParentsLog/parentsLog.module.scss";

interface ParentsLogProps {
  parentIds?: number[];
  title: string;
  type?: LogDetailType;
}

export default function ParentsLog(props: ParentsLogProps) {
  const activeNetwork = useAppSelector(selectActiveNetwork);

  const { workflowLogId } = useParams();

  const { data } = useGetWorkflowRunLogDetailQuery<{ data: WorkflowRunLogDetailResponse }>({
    workflowLogId: parseInt(workflowLogId ?? "0"),
    network: activeNetwork,
  });

  // Search the data for the parentIds and return the name of the node
  const parents: string[] = [];
  for (const stage in data.detail) {
    const iterations = data.detail[stage].iterations;
    for (const i in iterations) {
      for (const workflowNodeId in iterations[i]) {
        if (props.parentIds?.includes(parseInt(workflowNodeId))) {
          parents.push(iterations[i][workflowNodeId].name);
        }
      }
    }
  }

  return (
    <LogContainer title={props.title} type={props.type || LogDetailType.default}>
      <div className={styles.parentsList}>
        {parents.map((parent) => {
          return (
            <Tag
              key={parent}
              label={parent}
              tagId={0}
              deletable={false}
              sizeVariant={TagSize.small}
              colorVariant={TagColor.primary}
            />
          );
        })}
      </div>
    </LogContainer>
  );
}
