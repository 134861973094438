import { ColumnMetaData } from "features/table/types";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import { Invoice } from "features/transact/Invoices/invoiceTypes";
import DateNodeColourCell from "components/table/cells/dateNodeColourCell/DateNodeColourCell";

export default function invoicesCellRenderer(
  row: Invoice,
  rowIndex: number,
  column: ColumnMetaData<Invoice>,
  columnIndex: number,
  isTotalsRow?: boolean,
  maxRow?: Invoice,
): JSX.Element {
  if ((isTotalsRow === undefined || !isTotalsRow) && column.key === "creationDate") {
    return (
      <DateNodeColourCell
        key={column.key.toString() + rowIndex}
        nodeId={row.nodeId}
        color={row.nodeCssColour}
        value={row.creationDate}
      />
    );
  }
  return DefaultCellRenderer(row, rowIndex, column, columnIndex, false, maxRow);
}
