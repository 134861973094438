import { Database20Regular as Icon } from "@fluentui/react-icons";
import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import {
  DataSourceHtlcInterceptedEventAction,
  WorkflowRunLogDetailAction,
  DataSourceChannelOpenInterceptionEventAction,
  DataSourceOnChainTransactionAction,
} from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";
import styles from "components/workflow/LogDetails/DataSourceLogs/dataSourceLogs.module.scss";
import useTranslations from "services/i18n/useTranslations";
import LongTextWithCopy from "components/common/longTextWithCopy/LongTextWithCopy";
import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";
import { CommitmentTypeLabels } from "components/workflow/nodes/channelOpenInterceptionEventFilter/ChannelOpenInterceptionEventFilterNode";

export function ChannelDataSourceLog(props: WorkflowRunLogDetailAction) {
  return (
    <LogDetailsWrapper
      type={LogDetailType.dataSource}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    />
  );
}

export function HtlcEventDataSourceLog(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();

  const data = props?.actions ? (props.actions[0] as DataSourceHtlcInterceptedEventAction) : undefined;
  const htlcEvent = data?.htlcInterceptedEvent;
  const htlcParameters = {
    paymentHash: htlcEvent?.paymentHash,
    incomingAmountMsat: htlcEvent?.incomingAmountMsat,
    outgoingAmountMsat: htlcEvent?.outgoingAmountMsat,
    incomingExpirationBlockHeight: htlcEvent?.incomingExpirationBlockHeight,
    outgoingExpirationBlockHeight: htlcEvent?.outgoingExpirationBlockHeight,
  };

  return (
    <LogDetailsWrapper
      type={LogDetailType.dataSource}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      <LogContainer title={"Node"} type={LogDetailType.dataSource}>
        <div>{data?.incomingChannel?.nodeName}</div>
      </LogContainer>
      <LogContainer title={"Incoming Channel"} type={LogDetailType.dataSource}>
        <div>{data?.incomingChannel?.peerAlias}</div>
        <div>{data?.incomingChannel?.shortChannelId}</div>
      </LogContainer>
      <LogContainer title={"Outgoing Channel"} type={LogDetailType.dataSource}>
        <div>{data?.outgoingChannel?.peerAlias}</div>
        <div>{data?.outgoingChannel?.shortChannelId}</div>
      </LogContainer>
      <LogContainer title={"HTLC"} type={LogDetailType.dataSource}>
        <div className={styles.eventWrapper}>
          {Object.entries(htlcParameters).map(([key, value]) => {
            const label = t.htlcLog[key];

            return (
              <div key={key} className={styles.eventParameter}>
                <div className={styles.label}> {label ? label : key}</div>
                {key == "paymentHash" ? (
                  <LongTextWithCopy text={value as string} copyText={value as string} />
                ) : (
                  <div className={styles.value}>{value as string}</div>
                )}
              </div>
            );
          })}
        </div>
      </LogContainer>
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

export function ChannelOpenInterceptionEventDataSourceLog(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();

  const data = props?.actions ? (props.actions[0] as DataSourceChannelOpenInterceptionEventAction) : undefined;
  const channelOpenInterceptionEvent = data?.channelOpenInterceptionEvent;

  const channelOpenInterceptionEventParameters = {
    publicKey: channelOpenInterceptionEvent?.publicKey,
    peerPublicKey: channelOpenInterceptionEvent?.peerPublicKey,
    fundingAmountSat: channelOpenInterceptionEvent?.fundingAmountSat,
    pushAmountMsat: channelOpenInterceptionEvent?.pushAmountMsat,
    dustLimitSat: channelOpenInterceptionEvent?.dustLimitSat,
    maxValueInFlightMsat: channelOpenInterceptionEvent?.maxValueInFlightMsat,
    channelReserveSat: channelOpenInterceptionEvent?.channelReserveSat,
    feePerKw: channelOpenInterceptionEvent?.feePerKw,
    csvDelay: channelOpenInterceptionEvent?.csvDelay,
    maxAcceptedHtlcs: channelOpenInterceptionEvent?.maxAcceptedHtlcs,
    commitmentType: channelOpenInterceptionEvent && CommitmentTypeLabels.get(channelOpenInterceptionEvent?.commitmentType),
    wantsZeroConf: channelOpenInterceptionEvent?.wantsZeroConf,
    wantsScidAlias: channelOpenInterceptionEvent?.wantsScidAlias,
  };

  return (
    <LogDetailsWrapper
      type={LogDetailType.dataSource}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      <LogContainer title={"Channel Open Interception Event"} type={LogDetailType.dataSource}>
        <div className={styles.eventWrapper}>
          {Object.entries(channelOpenInterceptionEventParameters).map(([key, value]) => {
            const label = t.channelOpenInterceptionEventLog[key];

            return (
              <div key={key} className={styles.eventParameter}>
                <div className={styles.label}> {label ? label : key}</div>
                <div className={styles.value}>{value?.toString()}</div>
              </div>
            );
          })}
        </div>
      </LogContainer>
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

export function OnChainTransactionDataSourceLog(props: WorkflowRunLogDetailAction) {
  const data = props?.actions ? (props.actions[0] as DataSourceOnChainTransactionAction) : undefined;
  const onChainTransactionDataArray = data?.onChainTransactionData;

  return (
    <LogDetailsWrapper
      type={LogDetailType.dataSource}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      <LogContainer title={"On-Chain Transactions"} type={LogDetailType.dataSource}>
        <div className={styles.eventWrapper}>
          {onChainTransactionDataArray != null && onChainTransactionDataArray.map((value, index) => {
            return (
              <div key={index} className={styles.eventParameter}>
                <div className={styles.value}>{value?.transactionHash.toString()}</div>
              </div>
            );
          })}
        </div>
      </LogContainer>
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
