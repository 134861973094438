import {
  ArrowRouting20Regular as ChannelsIcon,
  ArrowSyncFilled as ProcessingIcon,
  CheckmarkRegular as SuccessIcon,
  DismissRegular as FailedIcon,
  Note20Regular as NoteIcon,
} from "@fluentui/react-icons";
import { useUpdateChannelMutation } from "apiSlice";
import { useEffect, useState } from "react";
import Button, { ButtonWrapper, ColorVariant, SizeVariant } from "components/buttons/Button";
import ProgressHeader, { ProgressStepState, Step } from "features/progressTabs/ProgressHeader";
import ProgressTabs, { ProgressTabContainer } from "features/progressTabs/ProgressTab";
import styles from "features/channels/updateChannel/updateChannel.module.scss";
import { useNavigate } from "react-router";
import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import useTranslations from "services/i18n/useTranslations";
import classNames from "classnames";
import { NumberFormatValues } from "react-number-format";
import clone from "clone";
import FormRow from "features/forms/FormWrappers";
import { useSearchParams } from "react-router-dom";
import Input from "components/forms/input/Input";
import { PolicyInterface } from "features/channels/channelsTypes";
import ErrorSummary from "components/errors/ErrorSummary";
import { FormErrors, mergeServerError, ServerErrorType } from "components/errors/errors";
import Note, { NoteType } from "features/note/Note";
import { userEvents } from "utils/userEvents";
import { InputSizeVariant } from "components/forms/input/variants";

const updateStatusClass = {
  PROCESSING: styles.processing,
  FAILED: styles.failed,
  SUCCEEDED: styles.success,
};

const updateStatusIcon = {
  PROCESSING: <ProcessingIcon />,
  FAILED: <FailedIcon />,
  SUCCEEDED: <SuccessIcon />,
  NOTE: <NoteIcon />,
};

function UpdateChannelModal() {
  const { t } = useTranslations();
  const { track } = userEvents();
  const [queryParams] = useSearchParams();
  const nodeId = parseInt(queryParams.get("nodeId") || "0");
  const channelId = parseInt(queryParams.get("channelId") || "0");

  const [updateChannelMutation, response] = useUpdateChannelMutation();
  const [policyState, setPolicyState] = useState(ProgressStepState.active);
  const [resultState, setResultState] = useState(ProgressStepState.disabled);
  const [formErrorState, setFormErrorState] = useState({} as FormErrors);
  const [feeBase, setFeeBase] = useState<number | undefined>(undefined);
  const [feeRateMilliMsat, setFeeRateMilliMsat] = useState<number | undefined>(undefined);
  const [inboundFeeBase, setInboundFeeBase] = useState<number | undefined>(undefined);
  const [inboundFeeRateMilliMsat, setInboundFeeRateMilliMsat] = useState<number | undefined>(undefined);
  const [timeLockDelta, setTimeLockDelta] = useState<number | undefined>(undefined);
  const [maxHtlc, setMaxHtlc] = useState<number | undefined>(undefined);
  const [minHtlc, setMinHtlc] = useState<number | undefined>(undefined);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (response && response.isError && response.error && "data" in response.error && response.error.data) {
      const mergedErrors = mergeServerError(response.error.data as ServerErrorType, clone(formErrorState));
      setFormErrorState(mergedErrors);
      setResultState(ProgressStepState.error);
    }
    if (response && response.isLoading) {
      setResultState(ProgressStepState.processing);
    }
    if (response.isSuccess) {
      setResultState(ProgressStepState.completed);
    }
  }, [response]);

  const closeAndReset = () => {
    setStepIndex(0);
    setPolicyState(ProgressStepState.active);
    setResultState(ProgressStepState.disabled);
    setFormErrorState({} as FormErrors);
  };

  const navigate = useNavigate();

  const updateChannel = () => {
    setStepIndex(1);
    setPolicyState(ProgressStepState.completed);
    setResultState(ProgressStepState.processing);
    const pi: PolicyInterface = {
      feeRateMilliMsat: feeRateMilliMsat,
      inboundFeeRateMilliMsat: inboundFeeRateMilliMsat,
      timeLockDelta: timeLockDelta,
      channelId: channelId,
      nodeId: nodeId,
    };
    track("Update Channel", {
      channelId: channelId,
      nodeId: nodeId,
    });

    if (feeBase !== undefined) {
      pi.feeBaseMsat = feeBase * 1000;
    }
    if (inboundFeeBase !== undefined) {
      pi.inboundFeeBaseMsat = inboundFeeBase * 1000;
    }
    if (maxHtlc !== undefined) {
      pi.maxHtlcMsat = maxHtlc * 1000;
    }
    if (minHtlc !== undefined) {
      pi.minHtlcMsat = minHtlc * 1000;
    }
    updateChannelMutation(pi);
  };

  return (
    <PopoutPageTemplate
      sizeVariant={SizeVariant.small}
      title={"Update Channel"}
      show={true}
      onClose={() => navigate(-1)}
      icon={<ChannelsIcon />}
    >
      <ProgressHeader modalCloseHandler={closeAndReset}>
        <Step label={"Policy"} state={policyState} last={false} />
        <Step label={"Result"} state={resultState} last={true} />
      </ProgressHeader>

      <ProgressTabs showTabIndex={stepIndex}>
        <ProgressTabContainer>
          <div className={styles.activeColumns}>
            <div className={styles.label}>{t.updateChannelPolicy.feeRateMilliMsat}</div>
            <FormRow>
              <Input
                intercomTarget={"update-channel-inbound-fee-rate-input"}
                formatted={true}
                className={styles.double}
                suffix={" ppm"}
                thousandSeparator={","}
                label={t.updateChannelPolicy.inbound}
                sizeVariant={InputSizeVariant.small}
                value={inboundFeeRateMilliMsat}
                onValueChange={(values: NumberFormatValues) => {
                  setInboundFeeRateMilliMsat(values.floatValue as number);
                }}
              />
              <Input
                intercomTarget={"update-channel-fee-rate-input"}
                formatted={true}
                className={styles.double}
                suffix={" ppm"}
                thousandSeparator={","}
                label={t.updateChannelPolicy.outbound}
                sizeVariant={InputSizeVariant.small}
                value={feeRateMilliMsat}
                onValueChange={(values: NumberFormatValues) => {
                  setFeeRateMilliMsat(values.floatValue as number);
                }}
              />
            </FormRow>

            <div className={styles.label}>{t.updateChannelPolicy.feeBase}</div>
            <FormRow>
              <Input
                intercomTarget={"update-channel-inbound-base-fee-input"}
                formatted={true}
                className={styles.double}
                suffix={" sat"}
                thousandSeparator={","}
                label={t.updateChannelPolicy.inbound}
                sizeVariant={InputSizeVariant.small}
                value={inboundFeeBase}
                onValueChange={(values: NumberFormatValues) => {
                  setInboundFeeBase(values.floatValue as number);
                }}
              />
              <Input
                intercomTarget={"update-channel-base-fee-input"}
                formatted={true}
                className={styles.double}
                suffix={" sat"}
                thousandSeparator={","}
                label={t.updateChannelPolicy.outbound}
                sizeVariant={InputSizeVariant.small}
                value={feeBase}
                onValueChange={(values: NumberFormatValues) => {
                  setFeeBase(values.floatValue as number);
                }}
              />
            </FormRow>

            <div className={styles.label}>{t.updateChannelPolicy.htlcAmount}</div>
            <FormRow>
              <Input
                intercomTarget={"update-channel-min-htlc-input"}
                formatted={true}
                className={styles.double}
                suffix={" sat"}
                thousandSeparator={","}
                label={t.updateChannelPolicy.minimum}
                sizeVariant={InputSizeVariant.small}
                value={minHtlc}
                onValueChange={(values: NumberFormatValues) => {
                  setMinHtlc(values.floatValue as number);
                }}
              />
              <Input
                intercomTarget={"update-channel-max-htlc-input"}
                formatted={true}
                className={styles.double}
                suffix={" sat"}
                thousandSeparator={true}
                label={t.updateChannelPolicy.maximum}
                sizeVariant={InputSizeVariant.small}
                value={maxHtlc}
                onValueChange={(values: NumberFormatValues) => {
                  setMaxHtlc(values.floatValue as number);
                }}
              />
            </FormRow>

            <div className={styles.label}>{t.updateChannelPolicy.timeLockDelta}</div>
            <FormRow>
              <Input
                intercomTarget={"update-channel-time-lock-delta-input"}
                formatted={true}
                className={styles.single}
                thousandSeparator={false}
                value={timeLockDelta}
                onValueChange={(values: NumberFormatValues) => {
                  setTimeLockDelta(values.floatValue as number);
                }}
              />
            </FormRow>

            <ButtonWrapper
              rightChildren={
                <Button
                  onClick={updateChannel}
                  buttonColor={ColorVariant.success}
                  intercomTarget={"update-channel-submit-button"}
                >
                  {t.updateChannelPolicy.update}
                </Button>
              }
            />

            <Note title={t.note} noteType={NoteType.info}>
              <p>{t.updateChannelPolicy.inboundFeeHelpText}</p>
            </Note>
          </div>
        </ProgressTabContainer>
        <ProgressTabContainer>
          <div
            className={classNames(
              styles.updateChannelResultIconWrapper,
              { [styles.failed]: !response.data },
              updateStatusClass[response.isLoading ? "PROCESSING" : response.isError ? "FAILED" : "SUCCEEDED"],
            )}
          >
            {updateStatusIcon[response.isLoading ? "PROCESSING" : response.isSuccess ? "SUCCEEDED" : "FAILED"]}
          </div>
          {response.isLoading && (
            <Note title={t.Processing} icon={<ProcessingIcon />} noteType={NoteType.warning}>
              {t.openCloseChannel.processingUpdate}
            </Note>
          )}
          <ErrorSummary errors={formErrorState} />
          {resultState === ProgressStepState.completed && (
            <Note title="Success">{t.updateChannelPolicy.confirmedMessage}</Note>
          )}
        </ProgressTabContainer>
      </ProgressTabs>
    </PopoutPageTemplate>
  );
}

export default UpdateChannelModal;
