import * as d3 from "d3";
import { ScaleLinear, Selection } from "d3";
import clone from "clone";
import {
  WorkflowRunLogDetail,
  WorkflowRunLogDetailActionDag,
} from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import { Graph, GraphLink, GraphNode, graphStratify, LayoutResult, sugiyama } from "d3-dag";
import { GetInactiveIcon, GetRegularIcon } from "components/workflow/logCanvas/logCanvasIcons";
import { toNumber } from "lodash";

type config = {
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  height: number;
  width: number;
  verticalGap: number;
  horizontalGap: number;
  verticalSpacing: number;
  horizontalSpacing: number;
  lineWidth: number;
  yScale: ScaleLinear<number, number, never>;
  xScale: ScaleLinear<number, number, number | undefined>;
  data?: WorkflowRunLogDetail;
  stage: number;
  onClick?: (selectedWorkflowNode: number, ancestors: number[]) => void;
  onHover?: (rows: WorkflowRunLogDetailActionDag[], selectedWorkflowNode: number, top?: number, left?: number) => void;
};

class LogCanvas {
  config: config = {
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    verticalGap: 20,
    horizontalGap: 40,
    verticalSpacing: 40,
    horizontalSpacing: 40,
    lineWidth: 4,
    stage: 0,
    height: 100,
    width: 100,
    yScale: d3.scaleLinear([0, 100]),
    xScale: d3.scaleLinear([0, 100]),
  };

  mouseOver?: { index: number; workflowNodeId: number };

  dataRaw: WorkflowRunLogDetail;
  data: WorkflowRunLogDetail;
  rows: WorkflowRunLogDetailActionDag[] = [];
  ancestors: number[] = [];
  layoutResult: LayoutResult | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  container: Selection<HTMLDivElement, Record<string, never>, HTMLElement, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartContainer: Selection<HTMLDivElement, Record<string, never>, HTMLElement, any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  canvas: Selection<HTMLCanvasElement, Record<string, never>, HTMLElement, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interactionLayer: Selection<HTMLCanvasElement, Record<string, never>, HTMLElement, any>;

  context: CanvasRenderingContext2D;
  interactionContext: CanvasRenderingContext2D;

  iterationCount = 0;
  maxChildCount = 0;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    container: Selection<HTMLDivElement, Record<string, never>, HTMLElement, any>,
    data: WorkflowRunLogDetail,
    config: Partial<config>,
  ) {
    if (container == undefined) {
      throw new Error("The chart container can't be null");
    }

    this.dataRaw = data;

    this.config = { ...this.config, ...config };

    // Configure the chart width and height based on the container
    // this.config.width = this.getWidth();
    // this.config.height = this.getHeight();

    this.data = clone(this.dataRaw);

    this.rows = Array<WorkflowRunLogDetailActionDag>();
    // Convert the data to an array of rows suitable for the dag diagram
    // meaning (id: string and parentIds: string[])
    if (this.data?.[this.config.stage]) {
      for (const iteration in this.data[this.config.stage]?.iterations) {
        for (const workflowNodeId in this.data[this.config.stage].iterations[iteration]) {
          if (!this.data[this.config.stage].iterations[iteration][workflowNodeId]) continue;
          const id = this.data[this.config.stage].iterations[iteration][workflowNodeId].id.toString();
          const parentIds =
            this.data[this.config.stage].iterations[iteration][workflowNodeId].parentIds?.map((parentId) =>
              parentId.toString(),
            ) || [];
          this.rows.push({
            ...this.data[this.config.stage].iterations[iteration][workflowNodeId],
            id,
            parentIds: parentIds,
          });
        }
      }
    }

    this.rows.forEach((row) => {
      const children = this.rows.filter((child) => child.parentIds?.includes(row.id));
      row.childIds = children.map((child) => child.id).map((id) => toNumber(id));
    });

    this.iterationCount = this.rows.reduce((acc, row) => Math.max(acc, row.iteration), 0);
    this.maxChildCount = this.rows.reduce(
      (acc, row) => Math.max(acc, row.childIds?.length || 0, row.parentIds?.length || 0),
      0,
    );

    if (this.rows.length) {
      this.createDag();
    }
    this.container = container
      .attr("style", `position: relative; height: calc(100% - 20px); width: ${this.config.width}px`)
      .html(null);
    this.config.xScale = d3.scaleLinear().range([0, this.config.width]);
    this.config.yScale = d3.scaleLinear().range([0, this.config.height - 16]);

    this.chartContainer = this.container
      .append("div")
      .attr("class", "chartContainer")
      .attr("width", this.config.width - this.config.margin.left - this.config.margin.right)
      .attr("height", this.config.height - this.config.margin.top - this.config.margin.bottom)
      .attr("style", `position: absolute; left: ${this.config.margin.left}px; top: ${this.config.margin.top}px;`);

    this.canvas = this.chartContainer
      .append("canvas")
      .attr("width", this.config.xScale.range()[1])
      .attr("height", this.config.yScale.range()[1])
      .attr("style", `position: absolute; left: 0; top: 0px;`);

    this.interactionLayer = this.chartContainer
      .append("canvas")
      .attr("width", this.config.xScale.range()[1])
      .attr("height", this.config.yScale.range()[1])
      .attr(
        "style",
        "position: absolute; left: 0; top: 0px; display: none;", // display: none;
      );

    this.context = this.canvas?.node()?.getContext("2d", { willReadFrequently: true }) as CanvasRenderingContext2D;
    this.interactionContext = this.interactionLayer
      ?.node()
      ?.getContext("2d", { willReadFrequently: true }) as CanvasRenderingContext2D;
    this.context.imageSmoothingEnabled = false;
    this.interactionContext.imageSmoothingEnabled = false;

    // this.addResizeListener();
    this.addHoverListener();
    this.addClickListener();
    this.addDoubleClickListener();
    this.addMouseOutListener();
  }

  setStage(stage: number) {
    this.config.stage = stage;
  }

  // getHeight(): number {
  //   return this.container?.node()?.getBoundingClientRect().height || this.config.height;
  // }

  // getWidth(): number {
  //   return this.container?.node()?.getBoundingClientRect().width || this.config.width;
  // }

  // getCenterPoint() {
  //   if (this.config.width > this.maxChildCount * 100) {
  //     return this.config.width / 2;
  //   } else {
  //     return 0;
  //   }
  // }

  // addResizeListener() {
  //   (d3.select(window).node() as EventTarget).addEventListener("resize", (_) => {
  //     this.resizeChart();
  //   });
  // }
  //
  // removeResizeListener() {
  //   (d3.select(window).node() as EventTarget).removeEventListener("resize", (_) => {
  //     this.resizeChart();
  //   });
  // }

  clearCanvas() {
    this.context.clearRect(0, 0, this.config.xScale.range()[1], this.config.yScale.range()[1]);
    this.interactionContext.clearRect(0, 0, this.config.xScale.range()[1], this.config.yScale.range()[1]);
  }

  resizeChart() {
    // this.config.width = this.getWidth();
    this.config.xScale = d3.scaleLinear().range([0, this.config.width]);

    // this.config.height = this.getHeight();
    this.config.yScale = d3.scaleLinear().range([0, this.config.height - 16]);

    this.chartContainer.attr("width", this.config.width).attr("height", this.config.height);

    this.canvas.attr("width", this.config.xScale.range()[1]).attr("height", this.config.yScale.range()[1]);
    this.interactionLayer.attr("width", this.config.xScale.range()[1]).attr("height", this.config.yScale.range()[1]);

    this.draw();
  }

  addAncestor(parents: string[] | undefined) {
    parents?.forEach((parentId) => {
      this.ancestors.push(toNumber(parentId));
      const parent = this.rows.find((r) => r.id === parentId.toString());
      if (parent) {
        this.addAncestor(parent.parentIds);
      }
    });
  }

  selectedWorkflowNodeId = 0;

  figures: Map<string, { index: number; workflowNodeId: number; top: number; left: number }> = new Map<
    string,
    { index: number; workflowNodeId: number; top: number; left: number }
  >();

  getFigure(
    xLocation: number,
    yLocation: number,
  ): { index: number; workflowNodeId: number; top: number; left: number } | undefined {
    const colorData = this.interactionContext.getImageData(xLocation, yLocation, 1, 1).data;

    return this.figures.get("rgb(" + [colorData[0], colorData[1], colorData[2]].join(",") + ")");
  }

  graph: Graph<WorkflowRunLogDetailActionDag> | undefined;

  createDag() {
    // https://github.com/erikbrinkman/d3-dag
    this.graph = graphStratify()(this.rows); // replace yourData with your actual data
    const layout = sugiyama().nodeSize([
      this.config.horizontalSpacing + this.config.horizontalGap,
      this.config.verticalSpacing + this.config.verticalGap,
    ]);
    this.layoutResult = layout(this.graph);
    this.config.width = this.layoutResult.width;
    this.config.height =
      this.layoutResult.height + this.config.verticalSpacing * this.iterationCount + this.config.verticalSpacing;
  }

  addHoverListener() {
    this.canvas.on("mousemove", (event) => {
      const [xPosition, yPosition] = d3.pointer(event);
      const figure = this.getFigure(xPosition, yPosition);
      if (this.mouseOver?.workflowNodeId === figure?.workflowNodeId) {
        return;
      }

      if (figure) {
        this.mouseOver = figure;
        this.canvas.attr("style", "cursor: pointer");
        this.config.onHover && this.config.onHover(this.rows, figure.workflowNodeId, figure?.top, figure?.left);
      } else if (figure !== this.mouseOver) {
        this.mouseOver = undefined;
        this.canvas.attr("style", "cursor: initial");
        this.config.onHover && this.config.onHover(this.rows, 0, 0, 0);
      }
    });
  }

  addClickListener() {
    this.canvas.on("click", (event) => {
      this.ancestors = [];
      const [xPosition, yPosition] = d3.pointer(event);
      const figure = this.getFigure(xPosition, yPosition);
      if (figure?.workflowNodeId !== undefined) {
        this.selectedWorkflowNodeId = figure.workflowNodeId;
        this.addAncestor([this.selectedWorkflowNodeId.toString()]);
        this.config?.onClick && this.config?.onClick(figure.workflowNodeId, this.ancestors);
      } else {
        this.selectedWorkflowNodeId = 0;
        this.config?.onClick && this.config?.onClick(0, this.ancestors);
      }
      this.draw();
    });
  }

  addDoubleClickListener() {
    this.canvas.on("dblclick", (event) => {
      this.ancestors = [];
      const [xPosition, yPosition] = d3.pointer(event);
      const figure = this.getFigure(xPosition, yPosition);
      if (figure?.workflowNodeId !== undefined) {
        this.selectedWorkflowNodeId = figure.workflowNodeId;
        this.ancestors = [figure.workflowNodeId];
        this.config?.onClick && this.config?.onClick(figure.workflowNodeId, this.ancestors);
      } else {
        this.selectedWorkflowNodeId = 0;
        this.config?.onClick && this.config?.onClick(0, this.ancestors);
      }
      this.draw();
    });
  }

  resetSelection() {
    if (this?.selectedWorkflowNodeId !== undefined) {
      this.selectedWorkflowNodeId = 0;
    }
    if (this.ancestors !== undefined) {
      this.ancestors = [];
    }
    this.draw();
  }

  addMouseOutListener() {
    this.canvas.on("mouseleave", (_) => {
      this.mouseOver = undefined;
      this.draw();
    });
  }

  drawWorkflowNode(
    context: CanvasRenderingContext2D,
    type: number,
    workflowNodeId: number,
    index: number,
    top: number,
    left: number,
    icon: string,
    selected?: boolean,
  ) {
    if (selected) {
      this.context.strokeStyle = "#85c4ff";
      context.beginPath();
      const originalStroke = context.lineWidth;
      context.lineWidth = 3;
      context.arc(left + 0.5, top, 24, 0, 2 * Math.PI);
      context.stroke();
      context.lineWidth = originalStroke;
    }

    // Create a new Image object
    context.beginPath();
    const img = new Image();
    img.onload = function () {
      context.drawImage(img, left - 20, top - 20);
    };
    img.src = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(icon);
    context.drawImage(img, left - 20, top - 20);
    context.closePath();
  }

  drawWorkflowNodeInteraction(
    context: CanvasRenderingContext2D,
    workflowNodeId: number,
    index: number,
    top: number,
    left: number,
  ) {
    context.beginPath();
    context.ellipse(left, top, 20, 20, 0, 0, 2 * Math.PI);
    const fillStyle = this.genColor();
    this.figures.set(fillStyle, {
      index: index,
      workflowNodeId: workflowNodeId,
      top: top,
      left: left,
    });
    context.closePath();
    context.fillStyle = fillStyle;
    context.fill();
  }

  linkLine = d3
    .line()
    .x((d) => d[0])
    .y((d) => d[1])
    .curve(d3.curveBumpY);

  /**
   * nextCol keeps track of the next unique color used to identify figures (drawn objects) on the canvas.
   */
  nextCol = 1;

  /**
   * @remarks concept taken from https://www.freecodecamp.org/news/d3-and-canvas-in-3-steps-8505c8b27444/
   */
  genColor() {
    const ret = [];
    if (this.nextCol < 16777215) {
      ret.push(this.nextCol & 0xff);
      ret.push((this.nextCol & 0xff00) >> 8);
      ret.push((this.nextCol & 0xff0000) >> 16);
      // Increase by 10 because the drawn figure changes color when it partially touches a pixel
      // when you increase by 10, the drawn color is different enough to prevent confusion between figures
      this.nextCol += 10;
    }
    return "rgb(" + ret.join(",") + ")";
  }

  drawLinks(link: GraphLink<WorkflowRunLogDetailActionDag>, centerPoint: number) {
    const points: [number, number][] = [
      [
        (link.source.x || 0) + centerPoint,
        (link.source.y || 0) +
          link.target.data.iteration * this.config.verticalSpacing +
          this.config.lineWidth -
          this.config.verticalGap,
      ],
      [
        (link.target.x || 0) + centerPoint,
        (link.target.y || 0) +
          link.target.data.iteration * this.config.verticalSpacing -
          this.config.lineWidth -
          this.config.verticalGap,
      ],
    ];
    // Default color
    this.context.strokeStyle = "#85c4ff";

    const row = this.rows.find((row) => row.id === link.target.data.id);

    if (this.ancestors.includes(toNumber(row?.id))) {
      this.context.strokeStyle = "#85c4ff";
    } else if (this.selectedWorkflowNodeId !== 0) {
      this.context.strokeStyle = "#C2E2FF";
    }

    this.linkLine.context(this.context)(points);
    this.context.stroke();
  }

  drawIcons(node: GraphNode<WorkflowRunLogDetailActionDag>, centerPoint: number) {
    const row = this.rows.find((row) => row.id === node.data.id);
    let SvgContent = GetRegularIcon(node.data.type);

    if (this.selectedWorkflowNodeId !== 0 && !this.ancestors.includes(toNumber(row?.id))) {
      SvgContent = GetInactiveIcon(node.data.type);
    }

    let selected = false;
    if (row?.id === this.selectedWorkflowNodeId.toString()) {
      selected = true;
    }

    // Draw the icons
    this.drawWorkflowNode(
      this.context,
      toNumber(node.data.id),
      node.data.type,
      node.data.iteration,
      (node.y || 0) + node.data.iteration * this.config.verticalSpacing,
      (node.x || 0) + centerPoint,
      SvgContent,
      selected,
    );
    // Draw the icon interactions
    this.drawWorkflowNodeInteraction(
      this.interactionContext,
      toNumber(node.data.id),
      node.data.type,
      (node.y || 0) + node.data.iteration * this.config.verticalSpacing,
      (node.x || 0) + centerPoint,
    );
  }

  draw() {
    this.clearCanvas();
    this.figures.clear();

    // const centerPoint = this.getCenterPoint();

    if (this.rows.length) {
      this.context.lineWidth = 4;

      if (this.graph !== undefined) {
        for (const node of this.graph.nodes()) {
          this.drawIcons(node, 0);
        }
        for (const link of this.graph.links()) {
          this.drawLinks(link, 0);
          this.drawIcons(link.target, 0);
        }
      }
    }

    return this;
  }
}

export default LogCanvas;
