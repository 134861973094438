export const DecodeInvoiceErrors = new Map<string, string>([
  ["CHECKSUM_FAILED", "Unable to decode invoice. Checksum failed."],
]);
export const PaymentProcessingErrors = new Map<string, string>([
  ["NONE", ""],
  ["UNKNOWN", "Unknown error occurred"],
  ["TIMEOUT", "Payment attempt timed out"],
  ["NO_ROUTE", "Could not find a route to the destination"],
  ["INCORRECT_PAYMENT_DETAILS", "Incorrect Payment Details"],
  ["INCORRECT_PAYMENT_AMOUNT", "Incorrect Payment Amount"],
  ["INVALID_PAYMENT_REQUEST", "Invalid Payment Request"],
  ["INSUFFICIENT_BALANCE", "Insufficient balance"],
  ["INVOICE_EXPIRED", "Invoice has expired"],
  ["ALREADY_PAID", "Invoice is already paid"],
  ["AMOUNT_REQUIRED", "Amount must be specified when paying a zero amount invoice"],
  ["AMOUNT_MUST_NOT_BE_SPECIFIED", "Amount must not be specified when paying a non-zero amount invoice"],
  ["WARNING_PARTIAL_COMPLETION", "Warning: Payment was only partially completed"],
]);
