import useTranslations from "services/i18n/useTranslations";
import classNames from "classnames";
import styles from "components/workflow/sidebar/workflow_sidebar.module.scss";
import Sidebar from "features/sidebar/Sidebar";
import { SectionContainer } from "features/section/SectionContainer";
import {
  AddSquare20Regular as AddIcon,
  ArrowRouting20Regular as ChannelsIcon,
  Play20Regular as DataSourcesIcon,
  Tag20Regular as TagsIcon,
  Timer20Regular as TriggersIcon,
  WindowBulletList20Regular as VariablesIcon,
} from "@fluentui/react-icons";
import { useState } from "react";
import {
  AddTagNodeButton,
  BalanceTriggerNodeButton,
  ChannelBalanceEventFilterNodeButton,
  ChannelCloseTriggerNodeButton,
  ChannelFilterNodeButton,
  ChannelOpenInterceptionTriggerNodeButton,
  ChannelOpenInterceptionEventNodeButton,
  ChannelOpenInterceptionEventFilterNodeButton,
  ChannelOpenInterceptionResultNodeButton,
  ChannelOpenTriggerNodeButton,
  ChannelPolicyAutoRunNodeButton,
  ChannelPolicyConfiguratorNodeButton,
  ChannelPolicyRunNodeButton,
  CloseChannelsNodeButton,
  CronTriggerNodeButton,
  DataSourceHtlcEventNodeButton,
  DataSourceTorqChannelsNodeButton,
  ExchangeFilterNodeButton,
  ForwardTriggerNodeButton,
  HtlcChannelJoinNodeButton,
  HtlcChannelSplitNodeButton,
  HtlcInterceptionBlockerNodeButton,
  HtlcInterceptionEventFilterNodeButton,
  HtlcInterceptionTriggerNodeButton,
  IntervalTriggerNodeButton,
  InvoiceTriggerNodeButton,
  MempoolFilterNodeButton,
  OnChainTransactionTriggerNodeButton,
  OpenChannelsNodeButton,
  PaymentAutoRunNodeButton,
  PaymentTriggerNodeButton,
  RebalanceAutoRunNodeButton,
  RebalanceConfiguratorNodeButton,
  RebalanceRunNodeButton,
  RemoveTagNodeButton,
  DataSourceOnChainTransactionNodeButton,
  ScorechainNodeButton,
} from "components/workflow/nodes/nodes";
import { userEvents } from "utils/userEvents";
import { WorkflowVersionNode } from "pages/WorkflowPage/workflowTypes";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";
import { NotificationNodeButton } from "components/workflow/nodes/notification/NotificationNodeButton";
import { ApiClientNodeButton } from "components/workflow/nodes/apiClient/ApiClientNodeButton";
import Button, { ColorVariant } from "components/buttons/Button";
import { SYSTEM_VARIABLE } from "constants/routes";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useGetSystemVariablesQuery, useGetWorkflowSystemVariablesQuery } from "apiSlice";
import { WorkflowVariableButton } from "components/workflow/variableButton/WorkflowVariableButton";

export type WorkflowSidebarProps = {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  triggers?: Array<WorkflowVersionNode>;
  workflowId: number;
};

export default function WorkflowSidebar(props: WorkflowSidebarProps) {
  const { track } = userEvents();
  const { expanded, setExpanded } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const location = useLocation();
  const closeSidebarHandler = () => {
    track("Workflow Toggle Sidebar");
    setExpanded(false);
  };

  const [sectionState, setSectionState] = useState({
    variables: true,
    triggers: true,
    dataSources: true,
    actions: true,
    filters: true,
    advanced: false,
  });

  const toggleSection = (section: keyof typeof sectionState) => {
    setSectionState({
      ...sectionState,
      [section]: !sectionState[section],
    });
  };

  const { data: systemVariables } = useGetSystemVariablesQuery(undefined, {
    skip: props.workflowId !== undefined && props.workflowId !== 0,
  });
  const { data: workflowVariables } = useGetWorkflowSystemVariablesQuery(props.workflowId, {
    skip: props.workflowId === undefined || props.workflowId === 0,
  });

  const variables = systemVariables !== undefined ? systemVariables : workflowVariables;

  const isHTLCTrigger =
    props.triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.HtlcInterceptionTrigger) !== -1;
  const isBalanceTrigger =
    props.triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.ChannelBalanceEventTrigger) !== -1;
  const isChannelOpenTrigger =
    props.triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.ChannelOpenEventTrigger) !== -1;
  const isChannelCloseTrigger =
    props.triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.ChannelCloseEventTrigger) !== -1;
  const isForwardTrigger =
    props.triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.ForwardTrigger) !== -1;
  const isInvoiceTrigger =
    props.triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.InvoiceTrigger) !== -1;
  const isPaymentTrigger =
    props.triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.PaymentTrigger) !== -1;
  const isOnChainTransactionTrigger =
    props.triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.OnChainTransactionTrigger) !== -1;
  const isChannelOpenInterceptionTrigger =
    props.triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.ChannelOpenInterceptionTrigger) !== -1;

  return (
    <div className={classNames(styles.pageSidebarWrapper, { [styles.sidebarExpanded]: expanded })}>
      <Sidebar title={t.configurations} closeSidebarHandler={closeSidebarHandler}>
        <SectionContainer
          intercomTarget={"workflow-variables-section"}
          title={t.variables}
          icon={VariablesIcon}
          expanded={sectionState.variables}
          handleToggle={() => toggleSection("variables")}
        >
          {variables?.map((variable) => (
            <WorkflowVariableButton
              key={variable.systemVariableId}
              variable={variable}
              buttonColor={
                variable.workflowId == undefined || variable.workflowId === 0
                  ? ColorVariant.warning
                  : ColorVariant.success
              }
            ></WorkflowVariableButton>
          ))}
          <div className={styles.divider}></div>
          <Button
            buttonColor={ColorVariant.success}
            onClick={() => {
              track("Navigate to Add Workflow Variable", { location: location?.pathname });
              navigate(SYSTEM_VARIABLE + "/new/" + props.workflowId, { state: { background: location } });
            }}
            icon={<AddIcon />}
            intercomTarget={"workflow-system-variable-add-button"}
          >
            {t.addWorkflowSystemVariable}
          </Button>
          <Button
            buttonColor={ColorVariant.warning}
            onClick={() => {
              track("Navigate to Add System Variable", { location: location?.pathname });
              navigate(SYSTEM_VARIABLE + "/new/0", { state: { background: location } });
            }}
            icon={<AddIcon />}
            intercomTarget={"system-variable-add-button"}
          >
            {t.addSystemVariable}
          </Button>
        </SectionContainer>
        <SectionContainer
          intercomTarget={"workflow-triggers-section"}
          title={t.triggers}
          icon={TriggersIcon}
          expanded={sectionState.triggers}
          handleToggle={() => toggleSection("triggers")}
        >
          <IntervalTriggerNodeButton disabled={isHTLCTrigger || isChannelOpenInterceptionTrigger} />
          <CronTriggerNodeButton disabled={isHTLCTrigger || isChannelOpenInterceptionTrigger} />
          <BalanceTriggerNodeButton disabled={isHTLCTrigger || isBalanceTrigger || isChannelOpenInterceptionTrigger} />
          <ChannelOpenTriggerNodeButton disabled={isHTLCTrigger || isChannelOpenTrigger || isChannelOpenInterceptionTrigger} />
          <ChannelCloseTriggerNodeButton disabled={isHTLCTrigger || isChannelCloseTrigger || isChannelOpenInterceptionTrigger} />
          <ChannelOpenInterceptionTriggerNodeButton disabled={!!props.triggers?.length} />
          <HtlcInterceptionTriggerNodeButton disabled={isHTLCTrigger || !!props.triggers?.length} />
          <ForwardTriggerNodeButton disabled={isHTLCTrigger || isForwardTrigger || isChannelOpenInterceptionTrigger} />
          <InvoiceTriggerNodeButton disabled={isHTLCTrigger || isInvoiceTrigger || isChannelOpenInterceptionTrigger} />
          <PaymentTriggerNodeButton disabled={isHTLCTrigger || isPaymentTrigger || isChannelOpenInterceptionTrigger} />
          <OnChainTransactionTriggerNodeButton disabled={isHTLCTrigger || isChannelOpenInterceptionTrigger || isOnChainTransactionTrigger} />
        </SectionContainer>
        <SectionContainer
          intercomTarget={"workflow-data-sources-section"}
          title={t.dataSources}
          icon={DataSourcesIcon}
          expanded={sectionState.dataSources}
          handleToggle={() => toggleSection("dataSources")}
        >
          <DataSourceTorqChannelsNodeButton disabled={!props.triggers?.length || isHTLCTrigger || isChannelOpenInterceptionTrigger} />
          <ChannelOpenInterceptionEventNodeButton disabled={!isChannelOpenInterceptionTrigger} />
          <DataSourceHtlcEventNodeButton disabled={!isHTLCTrigger} />
          <DataSourceOnChainTransactionNodeButton disabled={!props.triggers?.length || isChannelOpenInterceptionTrigger} />
        </SectionContainer>
        <SectionContainer
          intercomTarget={"workflow-filters-section"}
          title={t.filters}
          icon={ChannelsIcon}
          expanded={sectionState.filters}
          handleToggle={() => toggleSection("filters")}
        >
          <ChannelBalanceEventFilterNodeButton disabled={isHTLCTrigger || !isBalanceTrigger} />
          <HtlcInterceptionEventFilterNodeButton disabled={!isHTLCTrigger} />
          <HtlcChannelJoinNodeButton disabled={!isHTLCTrigger} />
          <HtlcChannelSplitNodeButton disabled={!isHTLCTrigger} />
          <ChannelFilterNodeButton disabled={!props.triggers?.length || isChannelOpenInterceptionTrigger} />
          <ChannelOpenInterceptionEventFilterNodeButton disabled={!isChannelOpenInterceptionTrigger} />
          <ExchangeFilterNodeButton disabled={!props.triggers?.length || isChannelOpenInterceptionTrigger} />
          <MempoolFilterNodeButton disabled={!props.triggers?.length || isChannelOpenInterceptionTrigger} />
        </SectionContainer>
        <SectionContainer
          intercomTarget={"workflow-actions-section"}
          title={t.actions}
          icon={ChannelsIcon}
          expanded={sectionState.actions}
          handleToggle={() => toggleSection("actions")}
        >
          <ChannelPolicyAutoRunNodeButton disabled={isHTLCTrigger || isChannelOpenInterceptionTrigger || !props.triggers?.length} />
          <RebalanceAutoRunNodeButton disabled={isHTLCTrigger || isChannelOpenInterceptionTrigger || !props.triggers?.length} />
          <AddTagNodeButton disabled={isChannelOpenInterceptionTrigger || !props.triggers?.length} />
          <RemoveTagNodeButton disabled={isChannelOpenInterceptionTrigger || !props.triggers?.length} />
          <CloseChannelsNodeButton disabled={isHTLCTrigger || isChannelOpenInterceptionTrigger || !props.triggers?.length} />
          <OpenChannelsNodeButton disabled={isHTLCTrigger || isChannelOpenInterceptionTrigger || !props.triggers?.length} />
          {/*<ChannelRequestNodeButton disabled={isHTLCTrigger || !props.triggers?.length} />*/}
          <ChannelOpenInterceptionResultNodeButton disabled={!isChannelOpenInterceptionTrigger} />
          <HtlcInterceptionBlockerNodeButton disabled={!isHTLCTrigger || isChannelOpenInterceptionTrigger || !props.triggers?.length} />
          <NotificationNodeButton disabled={isChannelOpenInterceptionTrigger || !props.triggers?.length}></NotificationNodeButton>
          <ApiClientNodeButton disabled={isChannelOpenInterceptionTrigger || !props.triggers?.length}></ApiClientNodeButton>
          <PaymentAutoRunNodeButton disabled={isChannelOpenInterceptionTrigger || !props.triggers?.length}></PaymentAutoRunNodeButton>
          <ScorechainNodeButton disabled={isChannelOpenInterceptionTrigger || isHTLCTrigger || !props.triggers?.length}></ScorechainNodeButton>
        </SectionContainer>
        <SectionContainer
          intercomTarget={"workflow-advanced-actions-section"}
          title={t.AdvancedActions}
          icon={TagsIcon}
          expanded={sectionState.advanced}
          handleToggle={() => toggleSection("advanced")}
        >
          <ChannelPolicyConfiguratorNodeButton disableda={isHTLCTrigger || isChannelOpenInterceptionTrigger || !props.triggers?.length} />
          <ChannelPolicyRunNodeButton disabled={isHTLCTrigger || isChannelOpenInterceptionTrigger || !props.triggers?.length} />
          <RebalanceConfiguratorNodeButton disableda={isHTLCTrigger || isChannelOpenInterceptionTrigger || !props.triggers?.length} />
          <RebalanceRunNodeButton disabled={isHTLCTrigger || isChannelOpenInterceptionTrigger || !props.triggers?.length} />
        </SectionContainer>
      </Sidebar>
    </div>
  );
}
