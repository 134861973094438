// map log parameter values into displayable strings

type LogParameterMapping = {
  [paramName: string]: {
    [valueKey: string]: string;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLogParameterMapping(t: any, nodeType: string | undefined): LogParameterMapping | undefined {
  switch (nodeType) {
    case undefined:
      return undefined;
    case "openChannel":
    // fallthrough to closeChannel
    case "closeChannel":
      return {
        feeRate: {
          "0": t.mempool.fastest,
          "1": t.mempool.halfHour,
          "2": t.mempool.hour,
          "3": t.mempool.economy,
          "4": t.mempool.minimum,
          "5": t.Fixed,
        },
      };
  }

  return undefined;
}
