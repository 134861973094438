import mixpanel from "mixpanel-browser";
import { AnyObject } from "utils/types";

export const userEvents = () => {
  const register = (properties: AnyObject) => {
    try {
      if (mixpanel.get_distinct_id()) {
        mixpanel.register(properties);
        //update({ customAttributes: properties });
      }
    } catch (error) {
      console.log("Error mixpanel not initialized: ", error);
    }
  };

  // Track with both mixpanel and Intercom
  const track = (eventName: string, properties?: AnyObject) => {
    try {
      if (mixpanel.get_distinct_id()) {
        mixpanel.track(eventName, properties);
        //trackEvent(eventName, properties);
      }
    } catch (error) {
      console.log("Error mixpanel not initialized: ", error);
    }
  };

  return {
    track,
    register,
  };
};
