// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go


import { ColumnMetaData } from "features/table/types";
import { ChannelRequest } from "features/lsp/lspTypes";

export const AllChannelRequestsColumns: ColumnMetaData<ChannelRequest>[] = [
	{
		heading: "Amount Paid",
		type: "NumericCell",
		key: "amountPaid",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "BTC Address",
		type: "LongTextCell",
		key: "btcAddress",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Channel Expiry Blocks",
		type: "NumericCell",
		key: "channelExpiryBlocks",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Channel Expiry Time",
		type: "DateCell",
		key: "channelExpiryTime",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "LSP Channel State",
		type: "LongTextCell",
		key: "channelState",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "Opening", value: "OPENING" },
			{ label: "Opened", value: "OPENED" },
			{ label: "Closed", value: "CLOSED" },
		],
	},
	{
		heading: "LSP Channel Request State",
		type: "LongTextCell",
		key: "channelRequestState",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "Created", value: "CREATED" },
			{ label: "Under Funded", value: "UNDER_FUNDED" },
			{ label: "Pending", value: "PENDING" },
			{ label: "Opening", value: "OPENING" },
			{ label: "Opened", value: "OPENED" },
			{ label: "Closing", value: "CLOSING" },
			{ label: "Closed", value: "CLOSED" },
			{ label: "Failed", value: "FAILED" },
			{ label: "Refunded", value: "REFUNDED" },
			{ label: "Offer Expired", value: "OFFER_EXPIRED" },
		],
	},
	{
		heading: "Client Balance",
		type: "BarCell",
		key: "clientBalance",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Created At",
		type: "DateCell",
		key: "createdAt",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Created On",
		type: "DateCell",
		key: "createdOn",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Error Response",
		type: "TextCell",
		key: "errorResponse",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Fee Per Payment",
		type: "NumericCell",
		key: "feePerPayment",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Fee Total",
		type: "NumericCell",
		key: "feeTotal",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Funding Tx Output Index",
		type: "TextCell",
		key: "fundingOutputIndex",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Funding Transaction",
		type: "LongTextCell",
		key: "fundingTransactionHash",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "LN Invoice",
		type: "LongTextCell",
		key: "bolt11Invoice",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "LNURL Channel",
		type: "LongTextCell",
		key: "lnurlChannel",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "LSP Channel Request State",
		type: "LongTextCell",
		key: "lsp",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "Deezy", value: "0" },
		],
	},
	{
		heading: "LSP Balance",
		type: "BarCell",
		key: "lspBalance",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "LSP Connection Info",
		type: "LongTextCell",
		key: "lspConnectionInfo",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "LSP",
		type: "TextCell",
		key: "lspName",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "Deezy", value: "Deezy" },
		],
	},
	{
		heading: "LSP URL",
		type: "LongTextCell",
		key: "lspUrl",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node Connection Info",
		type: "LongTextCell",
		key: "nodeConnectionInfo",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Order Expiry Time",
		type: "DateCell",
		key: "orderExpiryTime",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Order ID",
		type: "TextCell",
		key: "orderId",
		valueType: "number",
		suffix: "",
		locked: true,
		supportedBy: [0, 1],
	},
	{
		heading: "LSP Order State",
		type: "LongTextCell",
		key: "orderState",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "Created", value: "CREATED" },
			{ label: "Completed", value: "COMPLETED" },
			{ label: "Failed", value: "FAILED" },
		],
	},
	{
		heading: "Order Total",
		type: "NumericCell",
		key: "orderTotal",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Peer Node ID",
		type: "TextCell",
		key: "peerNodeId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Peer Node Name",
		type: "TextCell",
		key: "peerNodeName",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Peer Node Css Colour",
		type: "TextCell",
		key: "peerNodeCssColour",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Private Channel",
		type: "BooleanCell",
		key: "privateChannel",
		valueType: "boolean",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Client Torq Node ID",
		type: "TextCell",
		key: "requestingTorqNodeId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Client Torq Node Name",
		type: "TextCell",
		key: "requestingTorqNodeName",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Client Torq Node CSS Colour",
		type: "TextCell",
		key: "requestingTorqNodeCssColour",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "LSP Torq Node ID",
		type: "TextCell",
		key: "respondingTorqNodeId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "LSP Torq Node Name",
		type: "TextCell",
		key: "respondingTorqNodeName",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "LSP Torq Node CSS Colour",
		type: "TextCell",
		key: "respondingTorqNodeCssColour",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Short Channel ID",
		type: "LongTextCell",
		key: "shortChannelId",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Temporary Short Channel ID",
		type: "LongTextCell",
		key: "temporaryShortChannelId",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Token",
		type: "TextCell",
		key: "token",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const ChannelRequestsSortableColumns: Array<keyof ChannelRequest> = [
	"amountPaid",
	"btcAddress",
	"channelExpiryBlocks",
	"channelExpiryTime",
	"channelState",
	"channelRequestState",
	"clientBalance",
	"createdAt",
	"createdOn",
	"errorResponse",
	"feePerPayment",
	"feeTotal",
	"fundingTransactionHash",
	"bolt11Invoice",
	"lnurlChannel",
	"lsp",
	"lspBalance",
	"lspConnectionInfo",
	"lspName",
	"lspUrl",
	"nodeConnectionInfo",
	"orderExpiryTime",
	"orderId",
	"orderState",
	"orderTotal",
	"peerNodeId",
	"peerNodeName",
	"peerNodeCssColour",
	"privateChannel",
	"requestingTorqNodeId",
	"respondingTorqNodeId",
	"shortChannelId",
	"temporaryShortChannelId",
	"token",
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const ChannelRequestsFilterableColumns: Array<keyof ChannelRequest> = [
	"amountPaid",
	"btcAddress",
	"channelExpiryBlocks",
	"channelExpiryTime",
	"channelState",
	"channelRequestState",
	"clientBalance",
	"createdAt",
	"createdOn",
	"errorResponse",
	"feePerPayment",
	"feeTotal",
	"fundingTransactionHash",
	"bolt11Invoice",
	"lnurlChannel",
	"lsp",
	"lspBalance",
	"lspConnectionInfo",
	"lspName",
	"lspUrl",
	"nodeConnectionInfo",
	"orderExpiryTime",
	"orderId",
	"orderState",
	"orderTotal",
	"peerNodeId",
	"peerNodeName",
	"peerNodeCssColour",
	"privateChannel",
	"requestingTorqNodeId",
	"respondingTorqNodeId",
	"shortChannelId",
	"temporaryShortChannelId",
	"token",
];