import { Play20Regular as Icon } from "@fluentui/react-icons";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";

type DataSourceChannelOpenInterceptionEventNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export function DataSourceChannelOpenInterceptionEventNode({ ...wrapperProps }: DataSourceChannelOpenInterceptionEventNodeProps) {
  return (
    <WorkflowNodeWrapper
      {...wrapperProps}
      headerIcon={<Icon />}
      colorVariant={NodeColorVariant.accent2}
      outputName={"channelOpenInterceptionEvent"}
    >
    </WorkflowNodeWrapper>
  );
}
