import { ColumnMetaData } from "features/table/types";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import { WorkflowRunLog } from "pages/workflowRunLogs/workflowRunLogsTypes";
import WorkflowRunLogCell from "components/table/cells/workflowRunLog/WorkflowRunLogCell";
export default function workflowLogsCellRenderer(
  row: WorkflowRunLog,
  rowIndex: number,
  column: ColumnMetaData<WorkflowRunLog>,
  columnIndex: number,
  isTotalsRow?: boolean,
  maxRow?: WorkflowRunLog
): JSX.Element {
  if (column.key === "workflowName") {
    return (
      <WorkflowRunLogCell
        key={`workflowRunLogCell-${row.workflowName}`}
        workflowLogId={row.workflowLogId}
        workflowId={row.workflowId}
        workflowName={row.workflowName}
        captureLogs={row.captureLogs}
      />
    );
  }
  return DefaultCellRenderer(row, rowIndex, column, columnIndex, false, maxRow);
}
