import { ReactElement, useContext } from "react";
import { systemVariable } from "apiTypes";
import { userEvents } from "utils/userEvents";
import useTranslations from "services/i18n/useTranslations";
import { FormattedInputProps, InputProps } from "components/forms/input/Input";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import { WorkflowVersionNodeSystemVariablesMap } from "pages/WorkflowPage/workflowTypes";

export function getSystemVariablesFromText(text: string): WorkflowVersionNodeSystemVariablesMap {
  const systemVariables: WorkflowVersionNodeSystemVariablesMap = {};
  const regex = /{{ *System\.var_(\d+)_([a-zA-Z0-9_]*) *}}/g;
  let match;
  while ((match = regex.exec(text)) !== null) {
    const systemVariableId = Number(match[1]);
    systemVariables[`var_${match[1]}_${match[2]}`] = {
      systemVariableId: systemVariableId,
      name: match[2], // not real name
      dataType: 1, // unknown, but set text, should not matter
    } as systemVariable;
  }
  return systemVariables;
}

export function makeSystemVariableReference(systemVariable: systemVariable): string {
  const nameCleaned = systemVariable.name
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9_]/g, "_") // Replace all non-alphanumeric characters with underscores
    .replace(/_+/g, "_"); // remove multiple underscores

  return `var_${systemVariable.systemVariableId}_${nameCleaned}`;
}

type SystemVariableInputWrapperProps = {
  handleVariableChange?: (variable: systemVariable | undefined) => void;
  editingDisabled: boolean;
  disableVariableInput?: boolean; // if the workflow variable is disabled
  children?: ReactElement<HTMLInputElement | InputProps | FormattedInputProps>;
};

export default function SystemVariableTextAreaWrapper(props: SystemVariableInputWrapperProps) {
  const { track } = userEvents();
  const { t } = useTranslations();
  const toastRef = useContext(ToastContext);

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (props.editingDisabled) {
      // todo change translation to more generic
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }
    if (e.dataTransfer.getData("systemVariable/event") !== "add") {
      // todo change translation to more generic
      toastRef?.current?.addToast(t.workflowDetails.cannotDropOnVariableInput, toastCategory.warn);
      return;
    }

    const systemVariableId = Number(e.dataTransfer.getData("systemVariable/systemVariableId"));
    const name = e.dataTransfer.getData("systemVariable/name");
    const dataType = Number(e.dataTransfer.getData("systemVariable/dataType"));
    const wfId = e.dataTransfer.getData("systemVariable/workflowId");
    const dataTemplate = e.dataTransfer.getData("systemVariable/dataTemplate");
    const workflowId = wfId == "" ? undefined : Number(wfId);

    if (!systemVariableId) {
      console.error("Invalid system variable id");
      return;
    }

    if (props.handleVariableChange === undefined) {
      console.error("handleVariableChange is not defined");
      return;
    }

    props.handleVariableChange({
      systemVariableId: systemVariableId,
      name: name,
      dataType: dataType,
      workflowId: workflowId,
      dataTemplate: dataTemplate,
    } as systemVariable);

    track("System Variable Dropped to variable input");
  }

  return (
    <>
      {/* variables disabled, show children as is */}
      {props.disableVariableInput && props.children}
      {!props.disableVariableInput && <div onDrop={handleDrop}>{props.children}</div>}
    </>
  );
}
