import {
  Filter20Regular as ChannelFilterIcon,
  ArrowBounce20Regular as ChannelBalanceFilterIcon,
  BuildingRetailMoney20Regular as ExchangeFilterIcon,
  Check20Regular as MempoolFilterIcon,
} from "@fluentui/react-icons";
import styles from "components/workflow/LogDetails/FilterLog/filterLog.module.scss";
import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import {
  Channel,
  FilterAction,
  WorkflowRunLogDetailAction,
} from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import {
  AndClause,
  deserialiseQuery,
  deserialiseQueryFromString,
  injectSystemVariablesIntoFilter,
  OrClause,
} from "features/sidebar/sections/filter/filter";
import FilterComponent from "features/sidebar/sections/filter/FilterComponent";
import { AllChannelsColumns } from "features/channels/channelsColumns.generated";
import { ChannelsFilterTemplate } from "features/channels/channelsDefaults";
import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";
import ParentsLog from "components/workflow/LogDetails/ParentsLog/ParentsLog";
import {
  ChannelBalanceFilterColumns,
  EventsFilterTemplate,
} from "components/workflow/nodes/channelBalanceEventsFilter/ChannelBalanceEventFilterNode";
import ChannelList from "components/workflow/LogDetails/ChannelList/ChannelList";
import { AnyObject } from "utils/types";
import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";
import useTranslations from "services/i18n/useTranslations";
import { MempoolFilterColumns } from "components/workflow/nodes/mempoolFilter/MempoolFilterNode";
import { ExchangeFilterColumns } from "components/workflow/nodes/exchangeFilter/ExchangeFilterNode";

export function FilterLog(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();
  const filters = deserialiseQueryFromString(props.parameters || '{"$and":[]}') as AndClause | OrClause;
  const filterActions = props.actions as FilterAction[];

  injectSystemVariablesIntoFilter(filters, "", JSON.parse(props.systemVariables || "{}"));

  const filteredChannels: Array<Channel> = [];
  (filterActions || []).forEach((action) => {
    (action.filteredChannels || []).forEach((chan) => {
      filteredChannels.push(chan);
    });
  });

  const outgoingChannels: Array<Channel> = [];
  (filterActions || []).forEach((action) => {
    (action.outgoingChannels || []).forEach((chan) => {
      outgoingChannels.push(chan);
    });
  });

  return (
    <LogDetailsWrapper
      type={LogDetailType.filter}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<ChannelFilterIcon />}
    >
      <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.filter} />
      <LogContainer title={"Filters"} type={LogDetailType.filter}>
        {filters.length !== 0 && (
          <FilterComponent
            filters={filters}
            columns={AllChannelsColumns}
            defaultFilter={ChannelsFilterTemplate}
            child={false}
            onFilterUpdate={() => {
              return null;
            }}
            editingDisabled={true}
            allowVariableInput={true}
          />
        )}
        {filters.length === 0 && <div className={styles.noFilters}>{"No filter"}</div>}
      </LogContainer>
      <div className={styles.channelsContainer}>
        <ChannelList
          title={"Non-Matching Channels"}
          channels={filteredChannels}
          workflowNodeId={props.id}
          actionType={LogDetailType.filter}
        />
        <ChannelList
          title={"Matching Channels"}
          channels={outgoingChannels}
          workflowNodeId={props.id}
          actionType={LogDetailType.filter}
          onRight
        />
      </div>
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

export function ExchangeFilterLog(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();
  const filterProps = JSON.parse(props.parameters || "{}") as { filterClauses: AnyObject };
  const filters = deserialiseQuery(filterProps?.filterClauses || { $and: [] }) as AndClause | OrClause;
  const filterActions = props.actions as FilterAction[];

  const filteredChannels: Array<Channel> = [];
  (filterActions || []).forEach((action) => {
    (action.filteredChannels || []).forEach((chan) => {
      filteredChannels.push(chan);
    });
  });

  const outgoingChannels: Array<Channel> = [];
  (filterActions || []).forEach((action) => {
    (action.outgoingChannels || []).forEach((chan) => {
      outgoingChannels.push(chan);
    });
  });

  return (
    <LogDetailsWrapper
      type={LogDetailType.filter}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<ExchangeFilterIcon />}
    >
      <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.filter} />
      <LogContainer title={"Filters"} type={LogDetailType.filter}>
        {filters.length !== 0 && (
          <FilterComponent
            filters={filters}
            columns={ExchangeFilterColumns}
            defaultFilter={EventsFilterTemplate}
            child={false}
            onFilterUpdate={() => {
              return null;
            }}
            editingDisabled={true}
          />
        )}
        {filters.length === 0 && <div className={styles.noFilters}>{"No filter"}</div>}
      </LogContainer>
      <div className={styles.channelsContainer}>
        <ChannelList
          title={"Non-Matching Channels"}
          channels={filteredChannels}
          workflowNodeId={props.id}
          actionType={LogDetailType.filter}
        />
        <ChannelList
          title={"Matching Channels"}
          channels={outgoingChannels}
          workflowNodeId={props.id}
          actionType={LogDetailType.filter}
          onRight
        />
      </div>
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

export function MempoolFilterLog(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();
  const filterProps = JSON.parse(props.parameters || "{}") as { filterClauses: AnyObject };
  const filters = deserialiseQuery(filterProps?.filterClauses || { $and: [] }) as AndClause | OrClause;
  const filterActions = props.actions as FilterAction[];

  const filteredChannels: Array<Channel> = [];
  (filterActions || []).forEach((action) => {
    (action.filteredChannels || []).forEach((chan) => {
      filteredChannels.push(chan);
    });
  });

  const outgoingChannels: Array<Channel> = [];
  (filterActions || []).forEach((action) => {
    (action.outgoingChannels || []).forEach((chan) => {
      outgoingChannels.push(chan);
    });
  });

  return (
    <LogDetailsWrapper
      type={LogDetailType.filter}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<MempoolFilterIcon />}
    >
      <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.filter} />
      <LogContainer title={"Filters"} type={LogDetailType.filter}>
        {filters.length !== 0 && (
          <FilterComponent
            filters={filters}
            columns={MempoolFilterColumns}
            defaultFilter={EventsFilterTemplate}
            child={false}
            onFilterUpdate={() => {
              return null;
            }}
            editingDisabled={true}
          />
        )}
        {filters.length === 0 && <div className={styles.noFilters}>{"No filter"}</div>}
      </LogContainer>
      <div className={styles.channelsContainer}>
        <ChannelList
          title={"Non-Matching Channels"}
          channels={filteredChannels}
          workflowNodeId={props.id}
          actionType={LogDetailType.filter}
        />
        <ChannelList
          title={"Matching Channels"}
          channels={outgoingChannels}
          workflowNodeId={props.id}
          actionType={LogDetailType.filter}
          onRight
        />
      </div>
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

export function ChannelBalanceEventFilterLog(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();
  const filterProps = JSON.parse(props.parameters || "{}") as { filterClauses: AnyObject };
  const filters = deserialiseQuery(filterProps?.filterClauses || { $and: [] }) as AndClause | OrClause;
  const filterActions = props.actions as FilterAction[];

  const filteredChannels: Array<Channel> = [];
  (filterActions || []).forEach((action) => {
    (action.filteredChannels || []).forEach((chan) => {
      filteredChannels.push(chan);
    });
  });

  const outgoingChannels: Array<Channel> = [];
  (filterActions || []).forEach((action) => {
    (action.outgoingChannels || []).forEach((chan) => {
      outgoingChannels.push(chan);
    });
  });

  return (
    <LogDetailsWrapper
      type={LogDetailType.filter}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<ChannelBalanceFilterIcon />}
    >
      <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.filter} />
      <LogContainer title={"Filters"} type={LogDetailType.filter}>
        {filters.length !== 0 && (
          <FilterComponent
            filters={filters}
            columns={ChannelBalanceFilterColumns}
            defaultFilter={EventsFilterTemplate}
            child={false}
            onFilterUpdate={() => {
              return null;
            }}
            editingDisabled={true}
          />
        )}
        {filters.length === 0 && <div className={styles.noFilters}>{"No filter"}</div>}
      </LogContainer>
      <div className={styles.channelsContainer}>
        <ChannelList
          title={"Non-Matching Channels"}
          channels={filteredChannels}
          workflowNodeId={props.id}
          actionType={LogDetailType.filter}
        />
        <ChannelList
          title={"Matching Channels"}
          channels={outgoingChannels}
          workflowNodeId={props.id}
          actionType={LogDetailType.filter}
          onRight
        />
      </div>
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
