// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go


import { ColumnMetaData } from "features/table/types";
import { WorkflowRunLog } from "pages/workflowRunLogs/workflowRunLogsTypes";

export const AllWorkflowRunLogColumns: ColumnMetaData<WorkflowRunLog>[] = [
	{
		heading: "Start Time",
		type: "DateCell",
		key: "bootTime",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Logs Captured",
		type: "BooleanCell",
		key: "captureLogs",
		valueType: "boolean",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Workflow Log ID",
		type: "NumericCell",
		key: "workflowLogId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Name",
		type: "TextCell",
		key: "workflowName",
		valueType: "string",
		suffix: "",
		locked: true,
		supportedBy: [0, 1],
	},
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const WorkflowRunLogsSortableColumns: Array<keyof WorkflowRunLog> = [
	"bootTime",
	"captureLogs",
	"workflowLogId",
	"workflowName",
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const WorkflowRunLogsFilterableColumns: Array<keyof WorkflowRunLog> = [
	"bootTime",
	"captureLogs",
	"workflowLogId",
	"workflowName",
];