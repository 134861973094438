export interface settings {
  defaultDateRange: string;
  defaultLanguage: "en" | "nl";
  preferredTimezone: string;
  weekStartsOn: "saturday" | "sunday" | "monday";
  torqUuid: string;
  mixpanelOptOut: boolean;
  slackOAuthToken: string;
  slackBotAppToken: string;
  telegramHighPriorityCredentials: string;
  telegramLowPriorityCredentials: string;
  discordToken: string;
  emailAddress: string;
  notificationEmailAddress: string;
  torqGrpcApiEnabled: boolean;
  torqGrpcApiPort: number;
  fediGrpcApiEnabled: boolean;
  fediGrpcApiPort: number;
  fediGrpcApiNodeId: number;
  krakenApiKey?: string;
  krakenSecret?: string;
  smtpHost: string;
  smtpPort: number;
  smtpUsername: string;
  smtpPassword: string;
  smtpFrom: string;
  emergencyRecoverBackupEnabled: boolean;
  emergencyRecoverBackupEmail: string;
  emergencyRecoverBackupGithubToken: string;
  emergencyRecoverBackupDropboxToken: string;
  scorechainApiKey: string;
}
export interface updateSettingsRequest {
  defaultDateRange: string;
  defaultLanguage: "en" | "nl";
  preferredTimezone: string;
  weekStartsOn: "saturday" | "sunday" | "monday";
  mixpanelOptOut: boolean;
}

export interface timeZone {
  name: string;
}

export interface torqNodeSettings {
  nodeId: number;
  name?: string;
  implementation: number;
  status: number;
  nodeCssColour?: string;
  chain: number;
  network: number;
  publicKey: string;
  channelStatus?: number;
  alias?: string;
}

export interface nodeConfiguration {
  nodeId: number;
  name?: string;
  implementation: number;
  grpcAddress?: string;
  tlsFileName?: string;
  tlsFile?: File | null;
  macaroonFileName?: string;
  macaroonFile?: File | null;
  caCertificateFileName?: string;
  caCertificateFile?: File | null;
  certificateFileName?: string;
  certificateFile?: File | null;
  keyFileName?: string;
  keyFile?: File | null;
  createdOn?: Date;
  updatedOn?: Date;
  status: number;
  pingSystem: number;
  customSettings: number;
  nodeStartDate?: Date;
  nodeCssColour?: string;
  clnPluginEnabled: boolean;
  clnPluginGrpcAddress?: string;
}

export enum systemVariableDataType {
  number = 0,
  text = 1,
  date = 2,
}

export interface systemVariable {
  systemVariableId: number;
  name: string;
  dataType: systemVariableDataType;
  dataTemplate?: string;
  workflowId?: number;
  status?: number;
}

export interface numberMap<T> {
  [key: number]: T;
}

export interface stringMap<T> {
  [key: string]: T;
}

export interface services {
  torqUuid: string;
  version: string;
  payCheckPending: boolean;
  trialing: boolean;
  fedi: boolean;
  gRpc: boolean;
  payProduct: number;
  payStatus: number;
  payStatusString: string;
  payEndDate: Date;
  payBasePath: string;
  mempoolUrl: string;
  bitcoinNetworks: Map<number, string>;
  mainService: torqService;
  torqServices: torqService[];
  nodeServices: nodeService[];
  serviceMismatches: serviceMismatch[];
}

export interface torqService {
  status: number;
  statusString: string;
  bootTime: string | null;
  nodeId: number;
  type: number;
  typeString: string;
  uptimePercentageString: string;
  uptime: number;
}

export interface nodeService {
  implementation: number;
  implementationString: string;
  status: number;
  statusString: string;
  bootTime: string | null;
  nodeId: number;
  type: number;
  typeString: string;
  uptimePercentageString: string;
  uptime: number;
}

export interface lightningRequest {
  nodeId: number;
}

export interface nodeWalletBalances {
  request: lightningRequest;
  totalBalance: number;
  confirmedBalance: number;
  unconfirmedBalance: number;
  lockedBalance: number;
  reservedBalanceAnchorChan: number;
}

export interface nodeAddress {
  addr: string;
  network: string;
}
export interface nodeInformation {
  nodeId: number;
  publicKey: string;
  status: nodeStatus;
  addresses: nodeAddress[];
  alias: string;
  name: string;
}
export interface nodeSettings {
  nodeId: number;
  publicKey: string;
  chain: number;
  network: number;
  // only populated when it's a node managed by Torq
  name?: string;
  // only populated when there is a channel
  channelStatus?: number;
  alias?: string;
}

export enum nodeStatus {
  inactive = 0,
  active = 1,
}

export interface serviceMismatch {
  type: number;
  typeString: string;
  status: number;
  statusString: string;
  desiredStatus: number;
  desiredStatusString: string;
  nodeId: number;
  bitcoinNetwork: number;
  failureTime: Date;
}

export const PayStatusUnknown = 0;
export const PayStatusNoTorqNodesRegistered = 1;
export const PayStatusServiceError = 2;
// Any PayStatus 10 <= number <= 200 is ok (rest is not ok)
export const PayStatusTrialingFallback = 10;
export const PayStatusTrialingProfessional = 11;
export const PayStatusTrialingProfessionalPlus = 12;
export const PayStatusTrialingOrganization = 13;
export const PayStatusTrialingEnterprise = 14;
export const PayStatusFree = 109;
export const PayStatusProfessional = 111;
export const PayStatusProfessionalPlus = 112;
export const PayStatusOrganization = 113;
export const PayStatusEnterprise = 114;
// Any PayStatus number above 200 is locked out
export const PayStatusUnknownPastDue = 200;
export const PayStatusServiceErrorPastDue = 202;
export const PayStatusProfessionalEnded = 211;
export const PayStatusProfessionalPlusEnded = 212;
export const PayStatusOrganizationEnded = 213;
export const PayStatusEnterpriseEnded = 214;

export const PayProductUnknown = 0;
export const PayProductFree = 9;
export const PayProductFallback = 10;
export const PayProductProfessional = 11;
export const PayProductProfessionalPlus = 12;
export const PayProductOrganization = 13;
export const PayProductEnterprise = 14;
