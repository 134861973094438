import React, { useContext, useEffect, useState } from "react";
import { BuildingRetailMoney20Regular as ExchangeFilterIcon, Save16Regular as SaveIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import Form from "components/forms/form/Form";
import Socket from "components/forms/socket/Socket";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import { SelectWorkflowNodeLinks, SelectWorkflowNodes, useUpdateNodeMutation } from "pages/WorkflowPage/workflowApi";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { useSelector } from "react-redux";
import FilterComponent from "features/sidebar/sections/filter/FilterComponent";
import { AndClause, deserialiseQuery, FilterInterface, OrClause } from "features/sidebar/sections/filter/filter";
import Spinny from "features/spinny/Spinny";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import { toastCategory } from "features/toast/Toasts";
import ToastContext from "features/toast/context";
import { ColumnMetaData } from "features/table/types";
import { Implementation } from "types/api";

type ExchangeFilterNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export type exchangeData = {
  priceCurrency: string;
  candleOpen: number;
  candleClose: number;
  candleHigh: number;
  candleLow: number;
  candleVolumeWeightedAverage: number;
  candleVolume: number;
  candleCount: number;
  candleEndTime: number;
  // orderbook data?
  balanceBitcoin: number;
  balanceCurrency: number;
  standardDeviation5minutes: number;
  standardDeviation15minutes: number;
  standardDeviation60minutes: number;
};

export type ExchangeFilterConfiguration = {
  filterClauses: AndClause | OrClause;
};

export const ExchangeFilterColumns: ColumnMetaData<exchangeData>[] = [
  {
    heading: "Currency",
    key: "priceCurrency",
    type: "TextCell",
    valueType: "string",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
  {
    heading: "Candle Open Price",
    key: "candleOpen",
    type: "NumericCell",
    valueType: "number",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
  {
    heading: "Candle Close Price",
    key: "candleClose",
    type: "NumericCell",
    valueType: "number",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
  {
    heading: "Candle High Price",
    key: "candleHigh",
    type: "NumericCell",
    valueType: "number",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
  {
    heading: "Candle Low Price",
    key: "candleLow",
    type: "NumericCell",
    valueType: "number",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
  {
    heading: "Candle Volume Weighted Average Price",
    key: "candleVolumeWeightedAverage",
    type: "NumericCell",
    valueType: "number",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
  {
    heading: "Candle Volume",
    key: "candleVolume",
    type: "NumericCell",
    valueType: "number",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
  {
    heading: "Candle Count",
    key: "candleCount",
    type: "NumericCell",
    valueType: "number",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
  {
    heading: "Candle End Time",
    key: "candleEndTime",
    type: "DateCell",
    valueType: "date",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
  {
    heading: "Balance Bitcoin",
    key: "balanceBitcoin",
    type: "NumericCell",
    valueType: "number",
    suffix: "sat",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
  {
    heading: "Balance Currency",
    key: "balanceCurrency",
    type: "NumericCell",
    valueType: "number",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
  {
    heading: "Standard Deviation 5 Minutes",
    key: "standardDeviation5minutes",
    type: "NumericCell",
    valueType: "number",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
  {
    heading: "Standard Deviation 15 Minutes",
    key: "standardDeviation15minutes",
    type: "NumericCell",
    valueType: "number",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
  {
    heading: "Standard Deviation 60 Minutes",
    key: "standardDeviation60minutes",
    type: "NumericCell",
    valueType: "number",
    supportedBy: [Implementation.CLN, Implementation.LND],
  },
];

export const ExchangeFilterTemplate: FilterInterface = {
  funcName: "gte",
  category: "number",
  parameter: 1,
  key: "balanceBitcoin",
};

export function ExchangeFilterNode({ ...wrapperProps }: ExchangeFilterNodeProps) {
  const { t } = useTranslations();
  const toastRef = React.useContext(ToastContext);
  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;

  const [updateNode] = useUpdateNodeMutation();

  const [configuration, setConfiguration] = useState<ExchangeFilterConfiguration>({
    filterClauses: deserialiseQuery(wrapperProps.parameters.filterClauses || { $and: [] }) as AndClause | OrClause,
  });

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    if (
      Array.from(JSON.stringify(wrapperProps.parameters)).sort().join("") !==
      Array.from(JSON.stringify(configuration)).sort().join("")
    ) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [configuration, wrapperProps.parameters]);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }

    setProcessing(true);
    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: configuration,
    }).finally(() => {
      setProcessing(false);
    });
  }

  const { childLinks } = useSelector(
    SelectWorkflowNodeLinks({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeId: wrapperProps.workflowVersionNodeId,
      stage: wrapperProps.stage,
    }),
  );

  const channelIds =
    childLinks
      ?.filter((n) => {
        return n.childInput === "channels";
      })
      ?.map((link) => link.parentWorkflowVersionNodeId) ?? [];

  const channels = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: channelIds,
    }),
  );

  const handleFilterUpdate = (filter: AndClause | OrClause) => {
    setConfiguration((prev) => ({
      ...prev,
      filterClauses: filter,
    }));
  };

  return (
    <WorkflowNodeWrapper
      {...wrapperProps}
      headerIcon={<ExchangeFilterIcon />}
      colorVariant={NodeColorVariant.accent1}
      outputName={"channels"}
    >
      <Form onSubmit={handleSubmit} intercomTarget={"exchange-filter-node-form"}>
        <Socket
          collapsed={wrapperProps.visibilitySettings.collapsed}
          label={t.channels}
          selectedNodes={channels || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          inputName={"channels"}
          editingDisabled={editingDisabled}
        />
        {configuration.filterClauses !== undefined && (
          <FilterComponent
            filters={configuration.filterClauses}
            columns={ExchangeFilterColumns}
            defaultFilter={ExchangeFilterTemplate}
            child={false}
            onFilterUpdate={handleFilterUpdate}
            editingDisabled={editingDisabled}
          />
        )}
        <Button
          intercomTarget={"workflow-node-save"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing || editingDisabled}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
      </Form>
    </WorkflowNodeWrapper>
  );
}
