import { ShareScreenStop20Regular as Icon } from "@fluentui/react-icons";
import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import {
  HtlcInterceptionBlockerAction,
  WorkflowRunLogDetailAction,
  htlcBlockedStatus,
} from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import ParentsLog from "components/workflow/LogDetails/ParentsLog/ParentsLog";
import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";
import useTranslations from "services/i18n/useTranslations";
import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";

export default function HtlcInterceptionBlockerLog(props: WorkflowRunLogDetailAction) {
  const data = props?.actions ? (props.actions[0] as HtlcInterceptionBlockerAction) : undefined;
  const { t } = useTranslations();

  return (
    <LogDetailsWrapper
      type={LogDetailType.action}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.action} />
      <LogContainer title={t.htlcLog.HTLCBlockStatus} type={LogDetailType.action}>
        <div>
          {data?.blockedStatus == htlcBlockedStatus.blocked && <b>{t.htlcLog.blocked}</b>}
          {data?.blockedStatus == htlcBlockedStatus.continued && <b>{t.htlcLog.continued}</b>}
          {(!data || data?.blockedStatus == htlcBlockedStatus.notActivated) && <span>{t.htlcLog.notActivated}</span>}
        </div>
      </LogContainer>
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
