import {
  useAddSystemVariableMutation,
  useDeleteSystemVariableMutation,
  useGetSystemVariableQuery,
  useUpdateSystemVariableMutation,
} from "apiSlice";
import styles from "features/system/SystemVariable.module.scss";
import Button, { ButtonPosition, ButtonRow, ColorVariant } from "components/buttons/Button";
import useTranslations from "services/i18n/useTranslations";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Input from "components/forms/input/Input";
import { systemVariable, systemVariableDataType } from "apiTypes";
import { FormErrors, mergeServerError, ServerErrorType } from "components/errors/errors";
import {
  Save20Regular as SaveIcon,
  AppsList24Regular as SystemVariableHeaderIcon,
  Delete20Regular as TrashIcon,
} from "@fluentui/react-icons";
import { toastCategory } from "features/toast/Toasts";
import ToastContext from "features/toast/context";
import { userEvents } from "utils/userEvents";
import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { Form, InputSizeVariant, RadioChips, Select, TextArea } from "components/forms/forms";
import { AllChannelsColumns } from "features/channels/channelsColumns.generated";
import { AllInvoicesColumns } from "features/transact/Invoices/invoicesColumns.generated";
import { AllForwardsDataColumns } from "features/forwards/forwardsDataColumns";
import { AllPaymentsColumns } from "features/transact/Payments/paymentsColumns.generated";
import { TemplateOnChainTransactionDataSelection } from "features/transact/OnChain/onChainDefaults";
import { SelectOption } from "components/forms/select/Select";
import ErrorSummary from "components/errors/ErrorSummary";
import { IsServerErrorResult } from "utils/typeChecking";
import Spinny from "features/spinny/Spinny";

export default function SystemVariableModal() {
  const { t } = useTranslations();
  const { track } = userEvents();
  const navigate = useNavigate();

  const toastRef = React.useContext(ToastContext);

  const { workflowId, systemVariableId } = useParams();

  const wfId = workflowId == undefined || workflowId == "" ? 0 : parseInt(workflowId);

  const svId = systemVariableId == undefined || systemVariableId == "" ? 0 : parseInt(systemVariableId);

  const modalTitle =
    wfId != 0
      ? svId == 0
        ? t.modal.title.createWorkflowSystemVariable
        : t.modal.title.updateWorkflowSystemVariable
      : svId == 0
      ? t.modal.title.createSystemVariable
      : t.modal.title.updateSystemVariable;

  const systemVariableTemplate = {
    systemVariableId: 0,
    name: "",
    dataType: systemVariableDataType.number,
    dataTemplate: "",
    status: 1,
    workflowId: wfId,
  };

  const [systemVariableState, setSystemVariableState] = useState<systemVariable>(systemVariableTemplate);

  const [formErrorState, setFormErrorState] = React.useState({} as FormErrors);

  const { data: systemVariableData } = useGetSystemVariableQuery(
    { systemVariableId: svId },
    {
      skip: !systemVariableId || svId == 0,
    },
  );

  const [updateMutation, { error: updateError, isLoading: updateIsLoading, data: updateResponse }] =
    useUpdateSystemVariableMutation();

  const [addMutation, { error: addError, isLoading: addIsLoading, data: addResponse }] = useAddSystemVariableMutation();

  const [deleteMutation, { error: deleteError, isLoading: deleteIsLoading, isSuccess: deleteSuccess }] =
    useDeleteSystemVariableMutation();

  const formRef = useRef<HTMLFormElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (IsServerErrorResult(updateError)) {
      setFormErrorState(mergeServerError(updateError.data, formErrorState));
    }
    if (updateError && "data" in updateError && updateError.data) {
      const err = mergeServerError(updateError.data as ServerErrorType, {});
      if (err?.server && err.server.length > 0) {
        for (const se of err.server) {
          if (se.description !== undefined && se.description !== "") {
            toastRef?.current?.addToast(se.description || "", toastCategory.error);
          }
        }
      }
    }
  }, [updateError]);

  useEffect(() => {
    if (IsServerErrorResult(addError)) {
      setFormErrorState(mergeServerError(addError.data, formErrorState));
    }
    if (addError && "data" in addError && addError.data) {
      const err = mergeServerError(addError.data as ServerErrorType, {});
      if (err?.server && err.server.length > 0) {
        for (const se of err.server) {
          if (se.description !== undefined && se.description !== "") {
            toastRef?.current?.addToast(se.description || "", toastCategory.error);
          }
        }
      }
    }
  }, [addError]);

  useEffect(() => {
    if (IsServerErrorResult(deleteError)) {
      setFormErrorState(mergeServerError(deleteError.data, formErrorState));
    }
    if (deleteError && "data" in deleteError && deleteError.data) {
      const err = mergeServerError(deleteError.data as ServerErrorType, {});
      if (err?.server && err.server.length > 0) {
        for (const se of err.server) {
          if (se.description !== undefined && se.description !== "") {
            toastRef?.current?.addToast(se.description || "", toastCategory.error);
          }
        }
      }
    }
  }, [deleteError]);

  useEffect(() => {
    if (updateResponse && (updateResponse.systemVariableId || "") !== "") {
      closeAndReset();
    }
  }, [updateResponse]);

  useEffect(() => {
    if ((addResponse && (addResponse.systemVariableId || "") !== "") || deleteSuccess) {
      closeAndReset();
    }
  }, [addResponse, deleteSuccess]);

  const closeAndReset = () => {
    navigate(-1);
  };

  React.useEffect(() => {
    setSystemVariableState(systemVariableData || systemVariableTemplate);
  }, [systemVariableData]);

  const handleNameChange = (value: string) => {
    setSystemVariableState({ ...systemVariableState, name: value });
  };

  const handleTemplateChange = (value: string) => {
    setSystemVariableState({
      ...systemVariableState,
      dataTemplate: value,
    });
  };

  function setSystemVariableType(type: systemVariableDataType) {
    setSystemVariableState({ ...systemVariableState, dataType: type });
  }

  function handleDelete() {
    setFormErrorState({} as FormErrors);
    if (systemVariableState.systemVariableId && systemVariableState.systemVariableId !== 0) {
      deleteMutation(systemVariableState.systemVariableId);
      track("System Variable Deleted");
    }
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const updatedSystemVariableState = { ...systemVariableState, status: 1 };

    if (systemVariableState.workflowId == 0) {
      updatedSystemVariableState.workflowId = undefined;
    }
    setSystemVariableState(updatedSystemVariableState);

    setFormErrorState({} as FormErrors);
    if (!systemVariableState.systemVariableId || systemVariableState.systemVariableId == 0) {
      addMutation(updatedSystemVariableState);
      track("System Variable Added");
    } else {
      updateMutation(updatedSystemVariableState);
      track("System Variable Updated - " + updatedSystemVariableState.systemVariableId);
    }
  }

  type TemplateDataOption = {
    templateVarName: string;
    label: string;
  };

  const templateChannelDataSelection: TemplateDataOption[] = [
    ...(AllChannelsColumns.map((c) => {
      return {
        label: c.heading,
        templateVarName: "{{Channels." + c.key + "}}",
      } as TemplateDataOption;
    }) as TemplateDataOption[]),
  ];

  const templateInvoiceDataSelection: TemplateDataOption[] = [
    ...(AllInvoicesColumns.map((c) => {
      return {
        label: c.heading,
        templateVarName: "{{Invoice." + c.key + "}}",
      } as TemplateDataOption;
    }) as TemplateDataOption[]),
  ];

  const templateForwardDataSelection: TemplateDataOption[] = [
    ...(AllForwardsDataColumns.map((c) => {
      return {
        label: c.heading,
        templateVarName: "{{Forward." + c.key + "}}",
      } as TemplateDataOption;
    }) as TemplateDataOption[]),
  ];

  const templatePaymentDataSelection: TemplateDataOption[] = [
    ...(AllPaymentsColumns.map((c) => {
      return {
        label: c.heading,
        templateVarName: "{{Payment." + c.key + "}}",
      } as TemplateDataOption;
    }) as TemplateDataOption[]),
  ];

  const templateNodeDataSelection: TemplateDataOption[] = [
    {
      label: "Name",
      templateVarName: "{{Nodes.Name}}",
    },
    {
      label: "Alias",
      templateVarName: "{{Nodes.Alias}}",
    },
    {
      label: "Public Key",
      templateVarName: "{{Nodes.PublicKey}}",
    },
    {
      label: "Network",
      templateVarName: "{{Nodes.Network}}",
    },
    {
      label: "Chain",
      templateVarName: "{{Nodes.Chain}}",
    },
  ];

  const templateHtlcDataSelection: TemplateDataOption[] = [
    {
      label: "Automatic Fail Block Height",
      templateVarName: "{{Htlc.automaticFailBlockHeight}}",
    },
    {
      label: "Event Time",
      templateVarName: "{{Htlc.eventTime}}",
    },
    {
      label: "Incoming Lnd Short Channel ID",
      templateVarName: "{{Htlc.lndShortChannelId}}",
    },
    {
      label: "Incoming Amount msat",
      templateVarName: "{{Htlc.incomingAmountMsat}}",
    },
    {
      label: "Incoming Expiration Block Height",
      templateVarName: "{{Htlc.incomingExpirationBlockHeight}}",
    },
    {
      label: "Payment Hash",
      templateVarName: "{{Htlc.paymentHash}}",
    },
    {
      label: "Outgoing Lnd Short Channel ID",
      templateVarName: "{{Htlc.outgoingRequestedChannelId}}",
    },
    {
      label: "Outgoing Amount msat",
      templateVarName: "{{Htlc.outgoingAmountMsat}}",
    },
    {
      label: "Outgoing Expiration Block Height",
      templateVarName: "{{Htlc.outgoingExpirationBlockHeight}}",
    },
    {
      label: "Onion Blob",
      templateVarName: "{{Htlc.onionBlob}}",
    },
  ];

  const textAreaId = "systemVariableTemplateInput";

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleTemplateVarSelect(item: any) {
    //add the variable to cursor selection
    const textarea = document.getElementById(textAreaId) as HTMLTextAreaElement;
    if (textarea && typeof textarea.selectionStart === "number" && typeof textarea.selectionEnd === "number") {
      const { selectionStart, selectionEnd } = textarea;
      const start = Math.min(selectionStart, selectionEnd);
      const end = Math.max(selectionStart, selectionEnd);

      const dataTemplate =
        systemVariableState.dataTemplate?.slice(0, start) + item.value + systemVariableState.dataTemplate?.slice(end);

      setSystemVariableState({ ...systemVariableState, dataTemplate: dataTemplate });
    } else {
      //add to end if cursor not in text
      const dataTemplate = `${systemVariableTemplate.dataTemplate} ${item.value}`;

      setSystemVariableState({ ...systemVariableState, dataTemplate: dataTemplate });
    }
  }

  return (
    <PopoutPageTemplate title={modalTitle} show={true} icon={<SystemVariableHeaderIcon />} onClose={closeAndReset}>
      <Form onSubmit={handleSubmit} ref={formRef} intercomTarget={"system-variable-form"}>
        <div className={styles.systemVariableModalWrapper}>
          <div>
            <span id="name">
              <Input
                intercomTarget={"system-variable-name-input"}
                label={t.systemVariable.variableName}
                type={"text"}
                sizeVariant={InputSizeVariant.small}
                value={systemVariableState?.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNameChange(e.target.value)}
                placeholder="Name"
                name="name"
                errors={formErrorState}
              />
            </span>
          </div>
          <div>
            <RadioChips
              label={t.type}
              sizeVariant={InputSizeVariant.small}
              vertical={true}
              groupName={"system-variable-type-switch"}
              options={[
                {
                  label: t.systemVariable.number,
                  id: "system-variable-type-switch-number",
                  checked: systemVariableState.dataType === systemVariableDataType.number,
                  onChange: () => setSystemVariableType(systemVariableDataType.number),
                },
                {
                  label: t.systemVariable.text,
                  id: "system-variable-type-switch-text",
                  checked: systemVariableState.dataType === systemVariableDataType.text,
                  onChange: () => setSystemVariableType(systemVariableDataType.text),
                },
                {
                  label: t.systemVariable.date,
                  id: "system-variable-type-switch-date",
                  checked: systemVariableState.dataType === systemVariableDataType.date,
                  onChange: () => setSystemVariableType(systemVariableDataType.date),
                },
              ]}
            />
          </div>
          <div>
            <Select
              intercomTarget={"notification-template-node-var-select"}
              options={templateNodeDataSelection.map((s) => {
                return {
                  label: s.label,
                  value: s.templateVarName,
                } as SelectOption;
              })}
              value={""}
              placeholder={<span dangerouslySetInnerHTML={{ __html: t.systemVariableVarSelect.node }}></span>}
              onChange={handleTemplateVarSelect}
              sizeVariant={InputSizeVariant.small}
            />
            <Select
              intercomTarget={"notification-template-channel-var-select"}
              options={templateChannelDataSelection.map((s) => {
                return {
                  label: s.label,
                  value: s.templateVarName,
                } as SelectOption;
              })}
              value={""}
              placeholder={<span dangerouslySetInnerHTML={{ __html: t.systemVariableVarSelect.channel }}></span>}
              onChange={handleTemplateVarSelect}
              sizeVariant={InputSizeVariant.small}
            />

            <Select
              intercomTarget={"notification-template-htlc-var-select"}
              options={templateHtlcDataSelection.map((s) => {
                return {
                  label: s.label,
                  value: s.templateVarName,
                } as SelectOption;
              })}
              value={""}
              placeholder={<span dangerouslySetInnerHTML={{ __html: t.systemVariableVarSelect.htlc }}></span>}
              onChange={handleTemplateVarSelect}
              sizeVariant={InputSizeVariant.small}
            />

            <Select
              intercomTarget={"notification-template-forward-var-select"}
              options={templateForwardDataSelection.map((s) => {
                return {
                  label: s.label,
                  value: s.templateVarName,
                } as SelectOption;
              })}
              value={""}
              placeholder={<span dangerouslySetInnerHTML={{ __html: t.systemVariableVarSelect.forward }}></span>}
              onChange={handleTemplateVarSelect}
              sizeVariant={InputSizeVariant.small}
            />

            <Select
              intercomTarget={"notification-template-invoice-var-select"}
              options={templateInvoiceDataSelection.map((s) => {
                return {
                  label: s.label,
                  value: s.templateVarName,
                } as SelectOption;
              })}
              value={""}
              placeholder={<span dangerouslySetInnerHTML={{ __html: t.systemVariableVarSelect.invoice }}></span>}
              onChange={handleTemplateVarSelect}
              sizeVariant={InputSizeVariant.small}
            />

            <Select
              intercomTarget={"notification-template-payment-var-select"}
              options={templatePaymentDataSelection.map((s) => {
                return {
                  label: s.label,
                  value: s.templateVarName,
                } as SelectOption;
              })}
              value={""}
              placeholder={<span dangerouslySetInnerHTML={{ __html: t.systemVariableVarSelect.payment }}></span>}
              onChange={handleTemplateVarSelect}
              sizeVariant={InputSizeVariant.small}
            />

            <Select
              intercomTarget={"notification-template-transaction-var-select"}
              options={TemplateOnChainTransactionDataSelection.map((s) => {
                return {
                  label: s.label,
                  value: s.templateVarName,
                } as SelectOption;
              })}
              value={""}
              placeholder={
                <span dangerouslySetInnerHTML={{ __html: t.systemVariableVarSelect.onChainTransaction }}></span>
              }
              onChange={handleTemplateVarSelect}
              sizeVariant={InputSizeVariant.small}
            />
          </div>
          <div>
            <span id="template">
              <TextArea
                id={textAreaId}
                intercomTarget={"system-variable-template-input"}
                label={t.systemVariable.variableDefinition}
                value={systemVariableState?.dataTemplate}
                className={styles.tallTextArea}
                sizeVariant={InputSizeVariant.small}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleTemplateChange(e.target.value)}
                name="template"
                errors={formErrorState}
                helpText={t.systemVariable.templateHelpText}
              />
            </span>
          </div>
          <div>
            <ButtonRow>
              <Button
                id={"save-system-variable-button"}
                intercomTarget={"system-variable-save-button"}
                buttonColor={ColorVariant.success}
                ref={buttonRef}
                disabled={updateIsLoading || addIsLoading}
                icon={updateIsLoading || addIsLoading ? <Spinny /> : <SaveIcon />}
                type={"submit"}
                buttonPosition={ButtonPosition.fullWidth}
              >
                {svId == 0 ? t.add : t.save}
              </Button>
              <Button
                id={"delete-system-variable-button"}
                intercomTarget={"system-variable-delete-button"}
                buttonColor={ColorVariant.error}
                disabled={deleteIsLoading || svId == 0}
                icon={deleteIsLoading ? <Spinny /> : <TrashIcon />}
                onClick={() => {
                  handleDelete();
                }}
                type={"button"}
                buttonPosition={ButtonPosition.fullWidth}
              >
                {t.delete}
              </Button>
            </ButtonRow>
          </div>
        </div>
        <ErrorSummary errors={formErrorState} />
      </Form>
    </PopoutPageTemplate>
  );
}
