import {
  AddSquare20Regular as AddIcon,
  Save20Regular as SaveIcon,
  Copy20Regular as CopyIcon,
} from "@fluentui/react-icons";
import { torqApi, useGenerateOtpQuery, useGetServicesQuery } from "apiSlice";
import { useAppDispatch } from "store/hooks";
import Page from "layout/Page";
import Button, { ColorVariant, ButtonPosition, CopyButton } from "components/buttons/Button";
import styles from "features/settings/settings.module.css";
import Select, { SelectOption } from "components/forms/select/Select";
import React, { useState } from "react";
import { defaultStaticRangesFn } from "features/timeIntervalSelect/customRanges";
import {
  useGetAllNodeSettingsQuery,
  useGetSettingsQuery,
  useGetTimeZonesQuery,
  useUpdateSettingsMutation,
} from "apiSlice";

import {
  useAddManualWorkflowTriggerMutation,
} from "pages/WorkflowPage/workflowApi";
import { useWorkflowData } from "pages/WorkflowPage/workflowHooks";
import { settings } from "apiTypes";
import { toastCategory } from "features/toast/Toasts";
import ToastContext from "features/toast/context";
import useTranslations from "services/i18n/useTranslations";
import { supportedLangs } from "config/i18nConfig";
import { Form, Input, InputRow } from "components/forms/forms";
import { useLocation, useNavigate } from "react-router-dom";
import { ADD_TORQ_NODE } from "constants/routes";
import { userEvents } from "utils/userEvents";
import NodeStatusDropdown from "features/settings/NodeStatus";
import Footer from "components/footer/Footer";
import { Implementation } from "types/api";
import Spinny from "features/spinny/Spinny";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";

function Settings() {
  const { t, setLang } = useTranslations();
  const navigate = useNavigate();
  const location = useLocation();
  const { track } = userEvents();
  const { data: settingsData } = useGetSettingsQuery();
  const { data: nodeConfigurations } = useGetAllNodeSettingsQuery();
  const { data: timeZones = [] } = useGetTimeZonesQuery();
  const [updateSettings] = useUpdateSettingsMutation();
  const toastRef = React.useContext(ToastContext);

  const [generateOtp, setGenerateOtp] = React.useState(false);

  const otpResponse = useGenerateOtpQuery(undefined, { skip: !generateOtp });

  const [emergencyRecoverBackupDataChanged, setEmergencyRecoverBackupDataChanged] = React.useState(false);
  const [settingsState, setSettingsStateWithoutSideeffect] = React.useState({} as settings);
  const setSettingsState = (newSettings: settings) => {
    if (newSettings.smtpHost != settingsState.smtpHost
      || newSettings.smtpPort != settingsState.smtpPort
      || newSettings.smtpUsername != settingsState.smtpUsername
      || newSettings.smtpPassword != settingsState.smtpPassword
      || newSettings.smtpFrom != settingsState.smtpFrom
      || newSettings.emergencyRecoverBackupEnabled != settingsState.emergencyRecoverBackupEnabled
      || newSettings.emergencyRecoverBackupEmail != settingsState.emergencyRecoverBackupEmail
      || newSettings.emergencyRecoverBackupGithubToken != settingsState.emergencyRecoverBackupGithubToken
      || newSettings.emergencyRecoverBackupDropboxToken != settingsState.emergencyRecoverBackupDropboxToken) {
      setEmergencyRecoverBackupDataChanged(true);
    }
    setSettingsStateWithoutSideeffect(newSettings);
  };
  const dispatch = useAppDispatch();

  const { data: servicesData, isSuccess: servicesDataSuccess } = useGetServicesQuery();
  const [fedi, setFedi] = useState<boolean>(false);
  const [gRPC, setGRPC] = useState<boolean>(false);

  React.useEffect(() => {
    if (servicesDataSuccess && servicesData) {
      setFedi(servicesData.fedi);
      setGRPC(servicesData.gRpc);
    }
  }, [servicesDataSuccess, servicesData]);

  React.useEffect(() => {
    if (settingsData) {
      setSettingsStateWithoutSideeffect(settingsData);
    }
  }, [settingsData]);

  const defaultDateRangeLabels: {
    label: string;
    code: string;
  }[] = defaultStaticRangesFn(0);

  const defaultDateRangeOptions: SelectOption[] = defaultDateRangeLabels.map((dsr) => ({
    value: dsr.code,
    label: dsr.label,
  }));

  const preferredTimezoneOptions: SelectOption[] = timeZones.map((tz) => ({
    value: tz.name,
    label: tz.name,
  }));

  const weekStartsOnOptions: SelectOption[] = [
    { label: t.saturday, value: "saturday" },
    { label: t.sunday, value: "sunday" },
    { label: t.monday, value: "monday" },
  ];

  // When adding a language also add it to web/src/config/i18nConfig.js
  const languageOptions: SelectOption[] = [
    { label: supportedLangs.en, value: "en" },
    { label: supportedLangs.nl, value: "nl" },
  ];

  const yesNoOptions: SelectOption[] = [
    { value: 1, label: t.yes },
    { value: 0, label: t.no },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDefaultDateRangeChange = (combiner: any) => {
    setSettingsState({ ...settingsState, defaultDateRange: combiner.value });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDefaultLanguageRangeChange = (combiner: any) => {
    setSettingsState({ ...settingsState, defaultLanguage: combiner.value });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePreferredTimezoneChange = (combiner: any) => {
    setSettingsState({
      ...settingsState,
      preferredTimezone: combiner.value,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleWeekStartsOnChange = (combiner: any) => {
    setSettingsState({ ...settingsState, weekStartsOn: combiner.value });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTorqGrpcApiEnabledChange = (combiner: any) => {
    setSettingsState({ ...settingsState, torqGrpcApiEnabled: Boolean(combiner.value) });
  };

  const handleTorqGrpcApiPortChange = (value: number) => {
    setSettingsState({ ...settingsState, torqGrpcApiPort: value });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFediGrpcApiEnabledChange = (combiner: any) => {
    setSettingsState({ ...settingsState, fediGrpcApiEnabled: Boolean(combiner.value) });
  };

  const handleFediGrpcApiPortChange = (value: number) => {
    setSettingsState({ ...settingsState, fediGrpcApiPort: value });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFediGrpcApiNodeIdChange = (combiner: any) => {
    setSettingsState({ ...settingsState, fediGrpcApiNodeId: combiner.value });
  };

  const handleSlackOAuthTokenChange = (value: string) => {
    setSettingsState({ ...settingsState, slackOAuthToken: value });
  };

  const handleSlackBotAppTokenChange = (value: string) => {
    setSettingsState({ ...settingsState, slackBotAppToken: value });
  };

  const handleTelegramHighPriorityCredentialsChange = (value: string) => {
    setSettingsState({ ...settingsState, telegramHighPriorityCredentials: value });
  };

  const handleTelegramLowPriorityCredentialsChange = (value: string) => {
    setSettingsState({ ...settingsState, telegramLowPriorityCredentials: value });
  };

  const handleDiscordTokenChange = (value: string) => {
    setSettingsState({ ...settingsState, discordToken: value });
  };

  const handleEmailAddressChange = (value: string) => {
    setSettingsState({ ...settingsState, emailAddress: value });
  };

  const handleNotificationEmailAddressChange = (value: string) => {
    setSettingsState({ ...settingsState, notificationEmailAddress: value });
  };

  const handleSmtpHostChange = (value: string) => {
    setSettingsState({ ...settingsState, smtpHost: value });
  };

  const handleSmtpPortChange = (value: number) => {
    setSettingsState({ ...settingsState, smtpPort: value });
  };

  const handleSmtpUsernameChange = (value: string) => {
    setSettingsState({ ...settingsState, smtpUsername: value });
  };

  const handleSmtpPasswordChange = (value: string) => {
    setSettingsState({ ...settingsState, smtpPassword: value });
  };

  const handleSmtpFromChange = (value: string) => {
    setSettingsState({ ...settingsState, smtpFrom: value });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEmergencyRecoverBackupEnabledChange = (combiner: any) => {
    setSettingsState({ ...settingsState, emergencyRecoverBackupEnabled: Boolean(combiner.value) });
  };

  const handleEmergencyRecoverBackupEmailChange = (value: string) => {
    setSettingsState({ ...settingsState, emergencyRecoverBackupEmail: value });
  };

  const handleEmergencyRecoverBackupGithubToken = (value: string) => {
    setSettingsState({ ...settingsState, emergencyRecoverBackupGithubToken: value });
  };

  const handleEmergencyRecoverBackupDropboxToken = (value: string) => {
    setSettingsState({ ...settingsState, emergencyRecoverBackupDropboxToken: value });
  };

  const handleKrakenAPIKeyChange = (value: string) => {
    setSettingsState({ ...settingsState, krakenApiKey: value });
  };

  const handleKrakenSecretChange = (value: string) => {
    setSettingsState({ ...settingsState, krakenSecret: value });
  };

  const handleScorechainApiKeyChange = (value: string) => {
    setSettingsState({ ...settingsState, scorechainApiKey: value });
  };

  const submitPreferences = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateSettings(settingsState);
    setLang(settingsState?.defaultLanguage);
    dispatch(torqApi.util.resetApiState());
    toastRef?.current?.addToast(t.toast.settingsSaved, toastCategory.success);
  };

  const handleOtpClick = () => {
    if (generateOtp) {
      otpResponse.refetch();
    } else {
      setGenerateOtp(true);
    }
  };

  const { workflow, workflowVersion, workflowVersionTriggerNodeId } = useWorkflowData("-7", "1");
  const [triggerWorkflow, { isLoading: runIsProcessing }] = useAddManualWorkflowTriggerMutation();
  const handleEmergencyRecoverBackupManualTrigger = () => {
    track("Emergency Recover Backup Workflow Manually Triggered On Settings Page", {
      workflowId: workflow?.workflowId,
      workflowVersionId: workflowVersion?.workflowVersionId,
      workflowVersionTriggerNodeId: workflowVersionTriggerNodeId,
    });

    triggerWorkflow({
      type: WorkflowNodeType.StageTrigger,
      workflowVersionId: workflowVersion?.workflowVersionId || 0,
      workflowId: workflow?.workflowId || 0,
      workflowVersionNodeId: workflowVersionTriggerNodeId || 0,
    })
      .then(() => {
        toastRef?.current && toastRef.current.addToast(t.workflowDetails.manualTriggerSuccess, toastCategory.success);
      })
      .catch(() => {
        toastRef?.current && toastRef.current.addToast(t.workflowDetails.manualTriggerError, toastCategory.error);
      });
  }

  return (
    <Page>
      <React.Fragment>
        <div className={styles.settingsPage}>
          <div className={styles.settingsColumn}>
            <div className={styles.settingsWrapper} data-intercom-target="settings-general-settings-card">
              <h3 className={styles.sectionHeader}>{t.settings}</h3>

              <Form onSubmit={submitPreferences} className={styles.settingsForm} intercomTarget={"settings-form"}>
                <div className={styles.settingsSubSection} data-intercom-target={"settings-emailAddress-section"}>
                  <Input
                    intercomTarget="settings-emailAddress"
                    label={t.emailAddress}
                    value={settingsState?.emailAddress || ""}
                    required={true}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailAddressChange(e.target.value)}
                    autoComplete={"email"}
                    aria-autocomplete={"list"}
                  />
                </div>
                <Select
                  intercomTarget="settings-default-date-range"
                  label={t.defaultDateRange}
                  onChange={handleDefaultDateRangeChange}
                  options={defaultDateRangeOptions}
                  value={defaultDateRangeOptions.find((dd) => dd.value === settingsState?.defaultDateRange) || ""}
                />
                <Select
                  intercomTarget="settings-default-languag"
                  label={t.language}
                  onChange={handleDefaultLanguageRangeChange}
                  options={languageOptions}
                  value={languageOptions.find((lo) => lo.value === settingsState?.defaultLanguage) || ""}
                />
                <Select
                  intercomTarget="settings-preferred-timezone"
                  label={t.preferredTimezone}
                  onChange={handlePreferredTimezoneChange}
                  options={preferredTimezoneOptions}
                  value={preferredTimezoneOptions.find((tz) => tz.value === settingsState?.preferredTimezone) || ""}
                />
                <Select
                  intercomTarget="settings-week-starts-on"
                  label={t.weekStartsOn}
                  onChange={handleWeekStartsOnChange}
                  options={weekStartsOnOptions}
                  value={weekStartsOnOptions.find((dd) => dd.value === settingsState?.weekStartsOn) || ""}
                />

                {gRPC && (
                  <>
                    <h4 className={styles.subSectionHeader}>{t.torqApi}</h4>
                    <div className={styles.settingsSubSection} data-intercom-target={"settings-torq-api-section"}>
                      <Select
                        intercomTarget="settings-torq-grpc-api-enabled"
                        label={t.torqGrpcApiEnabled}
                        onChange={handleTorqGrpcApiEnabledChange}
                        options={yesNoOptions}
                        value={yesNoOptions.find((o) => Boolean(o.value) === settingsState?.torqGrpcApiEnabled) || 0}
                      />
                      <Input
                        intercomTarget="settings-torq-grpc-api-port"
                        label={t.grpcApiPort}
                        value={settingsState?.torqGrpcApiPort || 50051}
                        type={"number"}
                        disabled={settingsData?.torqGrpcApiEnabled}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleTorqGrpcApiPortChange(parseInt(e.target.value))
                        }
                        min={1}
                        max={99999}
                        infoText={t.grpcApiPortInfo}
                      />
                    </div>
                  </>
                )}

                {fedi && (
                  <>
                    <h4 className={styles.subSectionHeader}>{t.fediGateWayApi}</h4>
                    <div className={styles.settingsSubSection} data-intercom-target={"settings-fedi-api-section"}>
                      <Select
                        intercomTarget="settings-fedi-grpc-api-enabled"
                        label={t.grpcApiEnabled}
                        onChange={handleFediGrpcApiEnabledChange}
                        options={yesNoOptions}
                        value={yesNoOptions.find((o) => Boolean(o.value) === settingsState?.fediGrpcApiEnabled) || 0}
                        isDisabled={!settingsState?.fediGrpcApiEnabled && !settingsState?.fediGrpcApiNodeId}
                        helpText={t.grpcApiFediEnabledInfo}
                      />
                      <Input
                        intercomTarget="settings-fedi-grpc-api-port"
                        label={t.grpcApiPort}
                        value={settingsState?.fediGrpcApiPort || 50052}
                        type={"number"}
                        disabled={settingsData?.fediGrpcApiEnabled}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleFediGrpcApiPortChange(parseInt(e.target.value))
                        }
                        min={1}
                        max={99999}
                        infoText={t.grpcApiPortInfo}
                      />
                      <Select
                        intercomTarget="settings-fedi-grpc-api-node"
                        label={t.fediGateWayApiNode}
                        onChange={handleFediGrpcApiNodeIdChange}
                        isDisabled={settingsData?.fediGrpcApiEnabled}
                        helpText={t.grpcApiFediNodeInfo}
                        options={[
                          {
                            value: 0,
                            label: "-",
                          },
                        ].concat(
                          nodeConfigurations?.map((nodeConfiguration) => ({
                            value: nodeConfiguration.nodeId,
                            label: nodeConfiguration.name || "Node " + nodeConfiguration.nodeId,
                            isDisabled: nodeConfiguration.implementation != Implementation.LND,
                          })) || [],
                        )}
                        value={
                          nodeConfigurations
                            ?.map((nodeConfiguration) => ({
                              value: nodeConfiguration.nodeId,
                              label: nodeConfiguration.name,
                            }))
                            ?.find((node) => node.value === settingsState?.fediGrpcApiNodeId) || {
                            value: 0,
                            label: "-",
                          }
                        }
                      />
                    </div>
                  </>
                )}

                <h4 className={styles.subSectionHeader}>{t.notifications}</h4>
                <div className={styles.settingsSubSection} data-intercom-target={"settings-notification-section"}>
                  <Input
                    intercomTarget="settings-slack-oauth-token"
                    label={t.slackOAuthToken}
                    value={settingsState?.slackOAuthToken || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSlackOAuthTokenChange(e.target.value)}
                  />
                  <Input
                    intercomTarget="settings-slack-bot-app-token"
                    label={t.slackBotAppToken}
                    value={settingsState?.slackBotAppToken || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSlackBotAppTokenChange(e.target.value)}
                  />
                  <Input
                    intercomTarget="settings-telegram-high-priority-credentials"
                    label={t.telegramHighPriorityCredentials}
                    value={settingsState?.telegramHighPriorityCredentials || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleTelegramHighPriorityCredentialsChange(e.target.value)
                    }
                  />
                  <Input
                    intercomTarget="settings-telegram-low-priority-credentials"
                    label={t.telegramLowPriorityCredentials}
                    value={settingsState?.telegramLowPriorityCredentials || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleTelegramLowPriorityCredentialsChange(e.target.value)
                    }
                  />
                  <Input
                    intercomTarget="settings-discord-token"
                    label={t.discordToken}
                    value={settingsState?.discordToken || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDiscordTokenChange(e.target.value)}
                  />
                  <Input
                    intercomTarget="settings-notification-email-address"
                    label={t.emailAddress}
                    value={settingsState?.notificationEmailAddress || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNotificationEmailAddressChange(e.target.value)}
                  />
                </div>
                <h4 className={styles.subSectionHeader}>{t.smtp}</h4>
                <div className={styles.settingsSubSection} data-intercom-target={"settings-smtp-section"}>
                  <Input
                    intercomTarget="settings-smtp-host"
                    label={t.host}
                    value={settingsState?.smtpHost || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSmtpHostChange(e.target.value)}
                  />
                  <Input
                    intercomTarget="settings-smtp-port"
                    label={t.port}
                    value={settingsState?.smtpPort || ""}
                    type={"number"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleSmtpPortChange(parseInt(e.target.value))
                    }
                    min={1}
                    max={99999}
                  />
                  <Input
                    intercomTarget="settings-smtp-username"
                    label={t.username}
                    value={settingsState?.smtpUsername || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSmtpUsernameChange(e.target.value)}
                  />
                  <Input
                    intercomTarget="settings-smtp-password"
                    label={t.password}
                    value={settingsState?.smtpPassword || ""}
                    type={"password"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSmtpPasswordChange(e.target.value)}
                  />
                  <Input
                    intercomTarget="settings-smtp-from"
                    label={t.from}
                    value={settingsState?.smtpFrom || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSmtpFromChange(e.target.value)}
                  />
                </div>
                <h4 className={styles.subSectionHeader}>{t.emergencyRecoverBackup}</h4>
                <div className={styles.settingsSubSection} data-intercom-target={"settings-emergency-recover-backup-section"}>
                  <Select
                    intercomTarget="settings-emergency-recover-backup-enabled"
                    label={t.emergencyRecoverBackupEnabled}
                    onChange={handleEmergencyRecoverBackupEnabledChange}
                    options={yesNoOptions}
                    value={yesNoOptions.find((o) => Boolean(o.value) === settingsState?.emergencyRecoverBackupEnabled) || 0}
                  />
                  <Input
                    intercomTarget="settings-emergency-recover-backup-emailAddress"
                    label={t.emailAddress}
                    value={settingsState?.emergencyRecoverBackupEmail || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmergencyRecoverBackupEmailChange(e.target.value)}
                    autoComplete={"email"}
                    aria-autocomplete={"list"}
                  />
                  <Input
                    intercomTarget="settings-emergency-recover-backup-github-token"
                    label={t.emergencyRecoverBackupGithubToken}
                    value={settingsState?.emergencyRecoverBackupGithubToken || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmergencyRecoverBackupGithubToken(e.target.value)}
                  />
                  <Input
                    intercomTarget="settings-emergency-recover-backup-dropbox-token"
                    label={t.emergencyRecoverBackupDropboxToken}
                    value={settingsState?.emergencyRecoverBackupDropboxToken || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmergencyRecoverBackupDropboxToken(e.target.value)}
                  />
                  <Button
                    intercomTarget="settings-emergency-recover-backup-manual-button"
                    type={"button"}
                    buttonColor={ColorVariant.accent1}
                    buttonPosition={ButtonPosition.fullWidth}
                    onClick={handleEmergencyRecoverBackupManualTrigger}
                    icon={runIsProcessing && <Spinny />}
                    disabled={emergencyRecoverBackupDataChanged || settingsState?.emergencyRecoverBackupEnabled === false || runIsProcessing}
                  >
                    {t.emergencyRecoverBackupManualTrigger}
                  </Button>
                </div>
                <h4 className={styles.subSectionHeader}>{t.exchanges}</h4>
                <div className={styles.settingsSubSection} data-intercom-target={"settings-exchanges-kraken-section"}>
                  <Input
                    intercomTarget="settings-kraken-api-key"
                    label={t.krakenAPIKey}
                    value={settingsState?.krakenApiKey || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleKrakenAPIKeyChange(e.target.value)}
                  />
                  <Input
                    intercomTarget="settings-slack-bot-app-token"
                    label={t.krakenSecret}
                    value={settingsState?.krakenSecret || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleKrakenSecretChange(e.target.value)}
                  />
                </div>
                <h4 className={styles.subSectionHeader}>{t.scorechain}</h4>
                <div className={styles.settingsSubSection} data-intercom-target={"settings-scorechain-section"}>
                  <Input
                    intercomTarget="settings-scorechain-api-key"
                    label={t.scorechainApiKey}
                    value={settingsState?.scorechainApiKey || ""}
                    type={"text"}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleScorechainApiKeyChange(e.target.value)}
                  />
                </div>
                <Button
                  intercomTarget="settings-save-button"
                  type={"submit"}
                  icon={<SaveIcon />}
                  buttonColor={ColorVariant.success}
                  buttonPosition={ButtonPosition.fullWidth}
                >
                  {t.save}
                </Button>
              </Form>
            </div>

            <div className={styles.settingsWrapper}>
              <div className={styles.settingsForm}>
                <InputRow
                  button={
                    <CopyButton
                      copyText={otpResponse?.data}
                      intercomTarget={"copy-Otp"}
                      icon={<CopyIcon />}
                      // disabled={!otpResponse?.data}
                    />
                  }
                >
                  <Input label={t.otp} value={otpResponse?.data || ""} />
                </InputRow>
                <Button
                  intercomTarget="settings-generate-otp-button"
                  type={"button"}
                  buttonColor={ColorVariant.accent1}
                  buttonPosition={ButtonPosition.fullWidth}
                  onClick={handleOtpClick}
                >
                  {t.newOtp}
                </Button>
              </div>
            </div>

            <div className={styles.nodeSettingsWrapper} data-intercom-target="settings-node-settings-card">
              <h3 className={styles.sectionHeader}>{t.header.nodes}</h3>
              {nodeConfigurations &&
                nodeConfigurations?.map((nodeConfiguration) => (
                  <NodeStatusDropdown
                    key={"node-dropdown-" + nodeConfiguration.nodeId}
                    nodeId={nodeConfiguration.nodeId}
                    nodeName={nodeConfiguration.name || "Node " + nodeConfiguration.nodeId}
                    status={nodeConfiguration.status}
                  />
                ))}
              <Button
                buttonColor={ColorVariant.success}
                onClick={() => {
                  track("Navigate to Add Node", { location: location?.pathname });
                  navigate(ADD_TORQ_NODE, { state: { background: location } });
                }}
                icon={<AddIcon />}
                intercomTarget={"settings-add-node-button"}
              >
                {t.addNode}
              </Button>
            </div>
            <Footer dashboard={false} />
          </div>
        </div>
      </React.Fragment>
    </Page>
  );
}

export default Settings;
