import styles from "features/templates/tablePageTemplate/table-page-template.module.scss";
import * as d3 from "d3";

const ft = d3.format(",.0f");

type PaymentsHeaderProps = {
  totalCount: number;
  totalFees: number;
  totalAmount: number;
};

function PaymentsHeader(props: PaymentsHeaderProps) {
  return (
    <>
      <div className={styles.card}>
        <div className={styles.heading}>Number of Payments</div>
        <div className={styles.cardRow}>
          <div className={styles.rowValue} data-testid={"number-of-payments"}>{props?.totalCount && ft(props?.totalCount)}</div>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.heading}>Total Sent (Sats)</div>
        <div className={styles.cardRow}>
          <div className={styles.rowValue} data-testid={"total-sent"}>{props?.totalAmount && ft(props?.totalAmount)}</div>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.heading}>Total Fee Paid</div>
        <div className={styles.cardRow}>
          <div className={styles.rowValue} data-testid={"total-fee-paid"}>{props?.totalFees && ft(props?.totalFees)}</div>
        </div>
      </div>
    </>
  );
}

export default PaymentsHeader;
