import {
  Add20Regular as TransactionIcon,
  Options20Regular as OptionsIcon,
  ArrowSync20Regular as RefreshIcon,
  ArrowDownload20Regular as DownloadCsvIcon,
} from "@fluentui/react-icons";
import { NEW_PAYMENT } from "constants/routes";
import Button, { ColorVariant } from "components/buttons/Button";
import Table from "features/table/Table";
import TablePageTemplate, {
  TableControlsButtonGroup,
  TableControlSection,
} from "features/templates/tablePageTemplate/TablePageTemplate";
import { useState } from "react";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { Payment } from "features/transact/Payments/types";
import {
  DefaultPaymentView,
  FailureReasonLabels,
  FilterablePaymentsColumns,
  PaymentsFilterTemplate,
  PaymentsSortTemplate,
  SortablePaymentsColumns,
  StatusTypeLabels,
} from "features/transact/Payments/paymentDefaults";
import { AllPaymentsColumns } from "features/transact/Payments/paymentsColumns.generated";
import { usePagination } from "components/table/pagination/usePagination";
import { useGetTableViewsQuery, useUpdateTableViewMutation } from "features/viewManagement/viewsApiSlice";
import { useAppSelector } from "store/hooks";
import { selectPaymentsView, selectViews } from "features/viewManagement/viewSlice";
import ViewsSidebar from "features/viewManagement/ViewsSidebar";
import { selectActiveNetwork } from "features/network/networkSlice";
import useTranslations from "services/i18n/useTranslations";
import { TableResponses, ViewResponse } from "features/viewManagement/types";
import { userEvents } from "utils/userEvents";
import { useGetPaymentsQuery, useGetPaymentsSummaryQuery, useLazyGetPaymentsDownloadQuery } from "features/transact/Payments/paymentsApi";
import PaymentsHeader from "features/transact/Payments/PaymentsHeader";
import paymentsCellRenderer from "features/transact/Payments/paymentsCellRenderer";
import useLocalStorage from "utils/useLocalStorage";
import { createCsvFile } from "utils/JsonTableToCsv";
import * as Routes from "constants/routes";
import useRefreshShortcut from "utils/refreshHook";

function useMaximums(data: Array<Payment>): Payment | undefined {
  if (!data.length) {
    return undefined;
  }

  return data.reduce((prev: Payment, current: Payment) => {
    return {
      ...prev,
      alias: "Max",
      paymentIndex: Math.max(prev.paymentIndex, current.paymentIndex),
      value: Math.max(prev.value, current.value),
      fee: Math.max(prev.fee, current.fee),
      ppm: Math.max(prev.ppm, current.ppm),
      paymentHash: Math.max(prev.paymentHash, current.paymentHash),
      paymentPreimage: Math.max(prev.paymentPreimage, current.paymentPreimage),
      countFailedAttempts: Math.max(prev.countFailedAttempts, current.countFailedAttempts),
      countSuccessfulAttempts: Math.max(prev.countSuccessfulAttempts, current.countSuccessfulAttempts),
      secondsInFlight: Math.max(prev.secondsInFlight, current.secondsInFlight),
    };
  });
}

function PaymentsPage() {
  const { t } = useTranslations();
  const { track } = userEvents();
  const navigate = useNavigate();
  const location = useLocation();

  const activeNetwork = useAppSelector(selectActiveNetwork);
  const { isSuccess } = useGetTableViewsQuery<{ isSuccess: boolean }>();
  const { viewResponse, selectedViewIndex } = useAppSelector(selectPaymentsView);
  const paymentsViews = useAppSelector(selectViews)("payments");
  const [updateTableView] = useUpdateTableViewMutation();

  const [getPagination, limit, offset] = usePagination("payments");
  const [viewRefreshInterval, setViewRefreshInterval] = useLocalStorage("paymentsRefreshInterval", 30);

  const paymentsResponse = useGetPaymentsQuery(
    {
      limit: limit,
      offset: offset,
      order: viewResponse.view.sortBy,
      filter: viewResponse.view.filters ? viewResponse.view.filters : undefined,
      network: activeNetwork,
    },
    { skip: !isSuccess, pollingInterval: viewRefreshInterval * 1000 },
  );

  const [getPaymentsForDownload] = useLazyGetPaymentsDownloadQuery();

  let data = paymentsResponse.data?.data || [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data = data.map((payment: any) => {
    const failureReason = FailureReasonLabels.get(payment.failure_reason) || "";
    const status = StatusTypeLabels.get(payment.status) || "";

    return {
      ...payment,
      failureReason: failureReason,
      status: status,
    };
  });

  const maxRow = useMaximums(data);

  const paymentsSummaryResponse = useGetPaymentsSummaryQuery(
    {
      filter: viewResponse.view.filters ? viewResponse.view.filters : undefined,
      network: activeNetwork,
    },
    { skip: !isSuccess, pollingInterval: viewRefreshInterval * 1000 },
  );

  useRefreshShortcut([paymentsSummaryResponse.refetch, paymentsResponse.refetch]);

  // Logic for toggling the sidebar
  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  const closeSidebarHandler = () => {
    setSidebarExpanded(false);
    track("Toggle Table Sidebar", { page: "Payments" });
  };

  function handleNameChange(name: string) {
    const view = paymentsViews.views[selectedViewIndex] as ViewResponse<TableResponses>;
    if (view.id) {
      updateTableView({
        id: view.id,
        view: { ...view.view, title: name },
      });
    }
  }

  const tableControls = (
    <TableControlSection intercomTarget={"table-page-controls"}>
      <TableControlsButtonGroup intercomTarget={"table-page-controls-left"}>
        <Button
          intercomTarget="new-payment"
          buttonColor={ColorVariant.success}
          hideMobileText={true}
          icon={<TransactionIcon />}
          onClick={() => {
            track("Navigate to New Payment");
            navigate(NEW_PAYMENT, { state: { background: location } });
          }}
        >
          {t.newPayment}
        </Button>
      </TableControlsButtonGroup>
      <TableControlsButtonGroup intercomTarget={"table-page-controls-right"}>
        <Button
          buttonColor={ColorVariant.primary}
          intercomTarget="download-csv"
          title={t.download}
          icon={<DownloadCsvIcon />}
          onClick={async () => {
            track("Downloads Table as CSV", {
              downloadTablePage: "Transactions Payments",
              downloadTableViewTitle: viewResponse.view.title,
              downloadTableColumns: viewResponse.view.columns,
              downloadTableFilters: viewResponse.view.filters,
              downloadTableSortBy: viewResponse.view.sortBy,
            });
            const result = await getPaymentsForDownload(
              {
                order: viewResponse.view.sortBy,
                filter: viewResponse.view.filters ? viewResponse.view.filters : undefined,
                network: activeNetwork,
              });
            createCsvFile(result?.data?.data || [], viewResponse.view.title || "Payments");
          }}
        />
        <Button
          intercomTarget="refresh-table"
          buttonColor={ColorVariant.primary}
          icon={<RefreshIcon />}
          onClick={() => {
            track("Refresh Table", { page: "Payments" });
            paymentsSummaryResponse.refetch();
            paymentsResponse.refetch();
          }}
        />
        <Button
          intercomTarget="table-settings"
          onClick={() => {
            setSidebarExpanded(!sidebarExpanded);
            track("Toggle Table Sidebar", { page: "Payments" });
          }}
          icon={<OptionsIcon />}
          hideMobileText={true}
          id={"tableControlsButton"}
        >
          {t.views}
        </Button>
      </TableControlsButtonGroup>
    </TableControlSection>
  );

  const sidebar = (
    <ViewsSidebar
      onExpandToggle={closeSidebarHandler}
      expanded={sidebarExpanded}
      viewResponse={viewResponse}
      selectedViewIndex={selectedViewIndex}
      allColumns={AllPaymentsColumns}
      defaultView={DefaultPaymentView}
      filterableColumns={FilterablePaymentsColumns}
      filterTemplate={PaymentsFilterTemplate}
      sortableColumns={SortablePaymentsColumns}
      sortByTemplate={PaymentsSortTemplate}
      setViewRefreshInterval={(seconds: number) => {
        setViewRefreshInterval(seconds);
      }}
      viewRefreshInterval={viewRefreshInterval}
    />
  );

  const breadcrumbs = [
    <span key="b1">{t.transactions}</span>,
    <Link key="b2" to={`/${Routes.TRANSACTIONS}/${Routes.PAYMENTS}`}>
      {t.payments}
    </Link>,
  ];

  const tableSummary = (
    <PaymentsHeader
      totalCount={paymentsSummaryResponse?.data?.totalCount || 0}
      totalFees={paymentsSummaryResponse?.data?.totalFees || 0}
      totalAmount={paymentsSummaryResponse?.data?.totalAmount || 0}
    />
  );

  return (
    <TablePageTemplate
      title={viewResponse.view.title}
      breadcrumbs={breadcrumbs}
      tableSummary={tableSummary}
      sidebarExpanded={sidebarExpanded}
      sidebar={sidebar}
      tableControls={tableControls}
      pagination={getPagination(paymentsSummaryResponse?.data?.totalCount || 0)}
      onNameChange={handleNameChange}
      isDraft={viewResponse.id === undefined}
    >
      <Table
        intercomTarget={"payments-table"}
        cellRenderer={paymentsCellRenderer}
        data={data}
        activeColumns={viewResponse.view.columns || []}
        isLoading={paymentsResponse.isLoading || paymentsResponse.isFetching || paymentsResponse.isUninitialized}
        maxRow={maxRow}
      />
    </TablePageTemplate>
  );
}

export default PaymentsPage;
