import { Molecule24Regular as ModalIcon } from "@fluentui/react-icons";
import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import useTranslations from "services/i18n/useTranslations";
import { useNavigate, useParams } from "react-router-dom";
import NodeSettings from "features/settings/NodeSettings";
// import ToastContext from "features/toast/context";
// import { userEvents } from "utils/userEvents";

function NewAddressModal() {
  const { t } = useTranslations();
  // const { track } = userEvents();
  const navigate = useNavigate();
  // Get the params from the URL
  const { torqNodeId } = useParams();

  const modalTitle = torqNodeId ? t.updateNode : t.addNode;

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <PopoutPageTemplate title={modalTitle} show={true} onClose={handleClose} icon={<ModalIcon />}>
      <NodeSettings nodeId={Number(torqNodeId)} addMode={!torqNodeId} handleClose={handleClose} />
    </PopoutPageTemplate>
  );
}

export default NewAddressModal;
