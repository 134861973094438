import { ColumnMetaData } from "features/table/types";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import DateNodeColourCell from "components/table/cells/dateNodeColourCell/DateNodeColourCell";
import { Payment } from "features/transact/Payments/types";

export default function paymentsCellRenderer(
  row: Payment,
  rowIndex: number,
  column: ColumnMetaData<Payment>,
  columnIndex: number,
  isTotalsRow?: boolean,
  maxRow?: Payment,
): JSX.Element {
  if ((isTotalsRow === undefined || !isTotalsRow) && column.key === "date") {
    return (
      <DateNodeColourCell
        key={column.key.toString() + rowIndex}
        nodeId={row.nodeId}
        color={row.nodeCssColour}
        value={row.date}
      />
    );
  }
  return DefaultCellRenderer(row, rowIndex, column, columnIndex, false, maxRow);
}
