import { ArrowEnter20Regular as Icon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeButtonWrapper from "components/workflow/nodeButtonWrapper/NodeButtonWrapper";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";

export function ChannelOpenInterceptionTriggerNodeButton(props: { disabled?: boolean }) {
  const { t } = useTranslations();

  return (
    <WorkflowNodeButtonWrapper
      disabled={props.disabled}
      intercomTarget={"channel-open-interception-trigger-node-button"}
      colorVariant={NodeColorVariant.primary}
      nodeType={WorkflowNodeType.ChannelOpenInterceptionTrigger}
      icon={<Icon />}
      title={t.workflowNodes.channelOpenInterceptionTrigger}
    />
  );
}
