import classNames from "classnames";
import { memo } from "react";
import styles from "components/table/cells/cell.module.scss";
import { ColorVariant, SizeVariant } from "components/buttons/Button";
import { ArrowSync12Regular as RefreshIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import { userEvents } from "utils/userEvents";
import Button from "components/buttons/Button";
import { useCheckChannelRequestsMutation, usePullChannelRequestsMutation } from "features/lsp/lspApi";

interface ChannelRequestOrderCellProps {
  orderId?: string;
  request: boolean;
  color?: string;
  nodeId: number;
  className?: string;
}

function ChannelRequestOrderCell(props: ChannelRequestOrderCellProps) {
  const { t } = useTranslations();
  const { track } = userEvents();

  const [pull, { isLoading: isPullLoading }] = usePullChannelRequestsMutation();
  const [check, { isLoading: isCheckLoading }] = useCheckChannelRequestsMutation();

  const content = (
    <>
      <div className={classNames(styles.current, styles.text)}>{props.orderId}</div>
      <div className={styles.actionButtons}>
        {props.request && (props.orderId || "") !== "" && (
          <Button
            intercomTarget={"refresh-pull-channel-navigate"}
            key={"buttons-node-refresh-pull"}
            icon={<RefreshIcon />}
            hideMobileText={true}
            buttonSize={SizeVariant.tiny}
            buttonColor={ColorVariant.accent1}
            disabled={isPullLoading}
            onClick={() => {
              pull();
              track("Pull Channel Requests", {
                orderId: props.orderId,
              });
            }}
          >
            {t.refresh}
          </Button>
        )}
        {!props.request && (props.orderId || "") !== "" && (
          <Button
            intercomTarget={"refresh-check-channel-navigate"}
            key={"buttons-node-refresh-check"}
            icon={<RefreshIcon />}
            hideMobileText={true}
            buttonSize={SizeVariant.tiny}
            buttonColor={ColorVariant.accent1}
            disabled={isCheckLoading}
            onClick={() => {
              check();
              track("Check Channel Requests", {
                orderId: props.orderId,
              });
            }}
          >
            {t.refresh}
          </Button>
        )}
        {(props.orderId || "") === "" && t.Error}
      </div>
    </>
  );

  return (
    <div className={classNames(styles.cell, styles.alignLeft, styles.nodeColourCell, props.className)}>
      <div
        className={styles.colorBar}
        style={{
          backgroundColor: props.color,
        }}
      />
      <div className={classNames(styles.content, styles.nodeColourCellWrapper)}>{content}</div>
    </div>
  );
}
const ChannelRequestOrderCellMemo = memo(ChannelRequestOrderCell);
export default ChannelRequestOrderCellMemo;
