import { ArrowSplit20Regular as SwitchIcon, Merge20Regular as AndIcon } from "@fluentui/react-icons";
import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import {
  Channel,
  HtlcChannelJoinAction,
  WorkflowRunLogDetailAction,
} from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import ParentsLog from "components/workflow/LogDetails/ParentsLog/ParentsLog";
import ChannelList from "components/workflow/LogDetails/ChannelList/ChannelList";
import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";
import useTranslations from "services/i18n/useTranslations";

export function HtlcChannelSplitLog(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();
  return (
    <LogDetailsWrapper
      type={LogDetailType.filter}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<SwitchIcon />}
    >
      <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.filter} />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

export function HtlcChannelJoin(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();
  const outputChannels =
    (props.actions as Array<HtlcChannelJoinAction>)?.find((a) => a.outputChannels)?.outputChannels ||
    new Array<Channel>();
  return (
    <LogDetailsWrapper
      type={LogDetailType.filter}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<AndIcon />}
    >
      <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.filter} />
      <ChannelList
        title={t.outputChannels}
        channels={outputChannels}
        workflowNodeId={props.id}
        actionType={LogDetailType.filter}
      />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
