import { useEffect, useState } from "react";
import Fuse from "fuse.js";
import { Input } from "components/forms/forms";

export default function useSearchInput<T>(
  data: Array<T>,
  options?: Fuse.IFuseOptions<T>,
): { searchInput: JSX.Element; data: Array<T> } {
  const [searchValue, setSearchValue] = useState<string>();
  const [searchValueThrottle, setSearchValueThrottle] = useState<string>();

  const fuseOptions: Fuse.IFuseOptions<T> = {
    keys: ["peerAlias"],
    minMatchCharLength: 2,
    threshold: 0.4,
    ...options,
  };

  const fuse = new Fuse(data, fuseOptions);
  const searchData = searchValue ? fuse.search(searchValue || "").map((result) => result.item) : data;

  // Create a throttle effect for searchValue update
  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchValue(searchValueThrottle);
    }, 150);
    return () => clearTimeout(timer);
  }, [searchValueThrottle]);

  const SearchInput = (
    <Input
      placeholder={"Search"}
      style={{ width: "150px" }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValueThrottle(e.target.value);
      }}
    />
  );

  return { searchInput: SearchInput, data: searchData };
}
