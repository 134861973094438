import { Merge20Regular as JoinIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeButtonWrapper from "components/workflow/nodeButtonWrapper/NodeButtonWrapper";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";

export function HtlcChannelJoinNodeButton(props: { disabled?: boolean }) {
  const { t } = useTranslations();

  return (
    <WorkflowNodeButtonWrapper
      disabled={props.disabled}
      intercomTarget={"htlc-channel-join-node-button"}
      colorVariant={NodeColorVariant.accent1}
      nodeType={WorkflowNodeType.HtlcChannelJoin}
      icon={<JoinIcon />}
      title={t.htlcChannelJoin}
    />
  );
}
