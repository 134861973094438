import { DesktopArrowRight20Regular as ApiClientIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeButtonWrapper from "components/workflow/nodeButtonWrapper/NodeButtonWrapper";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";

export function ApiClientNodeButton(props: { disabled?: boolean }) {
  const { t } = useTranslations();

  return (
    <WorkflowNodeButtonWrapper
      disabled={props.disabled}
      intercomTarget={"api-client-node-button"}
      colorVariant={NodeColorVariant.accent3}
      nodeType={WorkflowNodeType.ApiClient}
      icon={<ApiClientIcon />}
      title={t.workflowNodes.apiClient}
    />
  );
}
