import { ViewResponse } from "features/viewManagement/types";
import { Peer } from "features/peers/peersTypes";

import { FilterInterface } from "features/sidebar/sections/filter/filter";
import { ColumnMetaData } from "features/table/types";
import { AllPeersColumns, PeersFilterableColumns, PeersSortableColumns } from "features/peers/peersColumns.generated";
import { OrderBy } from "features/sidebar/sections/sort/SortSection";
import { Implementation } from "types/api";

const defaultColumns: Array<keyof Peer> = ["peerNodeAlias", "connectionStatus", "uptime", "tags"];

export const DefaultPeersColumns = defaultColumns.map((col) => AllPeersColumns.find((x) => x.key === col)) as Array<
  ColumnMetaData<Peer>
>;

export const FilterablePeersColumns = AllPeersColumns.filter((column: ColumnMetaData<Peer>) =>
  PeersFilterableColumns.includes(column.key),
);

export const PeersFilterTemplate: FilterInterface = {
  funcName: "gte",
  category: "number",
  parameter: "0",
  key: "value",
  supportedBy: [Implementation.LND, Implementation.CLN],
};

export const PeersSortTemplate: Array<OrderBy> = [
  {
    key: "uptime",
    direction: "asc",
  },
];

export const DefaultPeersView: ViewResponse<Peer> = {
  page: "peers",
  dirty: true,
  view: {
    title: "Peers",
    columns: DefaultPeersColumns,
    sortBy: PeersSortTemplate,
  },
};

export const SortablePeersColumns = AllPeersColumns.filter((c) => PeersSortableColumns.includes(c.key));
