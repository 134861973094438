import { ColumnMetaData } from "features/table/types";
import { ChannelRequest, LspChannelRequestState } from "features/lsp/lspTypes";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import LightningInvoiceCell from "components/table/cells/lightningInvoiceCell/LightningInvoiceCell";
import cellStyles from "components/table/cells/cell.module.scss";
import ChannelRequestOrderCell from "components/table/cells/channelRequestOrderCell/ChannelRequestOrderCell";
import ChannelRequestErrorCell from "components/table/cells/channelRequestErrorCell/ChannelRequestErrorCell";

export default function channelRequestCellRenderer(
  row: ChannelRequest,
  rowIndex: number,
  column: ColumnMetaData<ChannelRequest>,
  columnIndex: number,
  isTotalsRow?: boolean,
  maxRow?: ChannelRequest,
): JSX.Element {
  if (column.key === "bolt11Invoice") {
    if (row.channelRequestState === LspChannelRequestState.Created && (row.requestingTorqNodeId || 0) !== 0) {
      return (
        <LightningInvoiceCell
          key={column.key.toString() + rowIndex}
          torqNodeId={row.requestingTorqNodeId || 0}
          bolt11Invoice={row.bolt11Invoice}
        />
      );
    }
  }
  if (column.key === "errorResponse" && (row.errorResponse || "") !== "") {
    return (
      <ChannelRequestErrorCell
        errorMessage={row.errorResponse}
        color={row.requestingTorqNodeCssColour}
        key={"channelRequestErrorCell" + rowIndex}
      />
    );
  }
  if (column.key === "orderId" && (row.requestingTorqNodeId || 0) !== 0) {
    return (
      <ChannelRequestOrderCell
        orderId={row.orderId}
        request={true}
        color={row.requestingTorqNodeCssColour}
        nodeId={row.requestingTorqNodeId || 0}
        className={cellStyles.locked}
        key={"channelRequestOrderCell" + rowIndex}
      />
    );
  }
  if (column.key === "orderId" && (row.respondingTorqNodeId || 0) !== 0) {
    return (
      <ChannelRequestOrderCell
        orderId={row.orderId}
        request={false}
        color={row.respondingTorqNodeCssColour}
        nodeId={row.respondingTorqNodeId || 0}
        className={cellStyles.locked}
        key={"channelRequestOrderCell" + rowIndex}
      />
    );
  }
  return DefaultCellRenderer(row, rowIndex, column, columnIndex, false, maxRow);
}
