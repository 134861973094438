import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { ArrowRouting20Regular as OpenChannelsIcon, Save16Regular as SaveIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import { SelectWorkflowNodeLinks, SelectWorkflowNodes, useUpdateNodeMutation } from "pages/WorkflowPage/workflowApi";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { NumberFormatValues } from "react-number-format";
import { useSelector } from "react-redux";
import { Form, Input, InputSizeVariant, RadioChips, Socket } from "components/forms/forms";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import Spinny from "features/spinny/Spinny";
import Note, { NoteType } from "features/note/Note";
import styles from "features/channels/openChannel/openChannel.module.scss";
import Switch, { SwitchSize } from "components/forms/switch/Switch";
import { systemVariable, systemVariableDataType } from "apiTypes";
import SystemVariableInputWrapper from "components/common/systemVariableInputWrapper/SystemVariableInputWrapper";
import { WorkflowVersionNodeSystemVariablesMap } from "pages/WorkflowPage/workflowTypes";
import { checkNodeInputsDirty } from "components/workflow/nodes/nodeFunctions";

type OpenChannelsNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export type OpenChannelsParameters = {
  feeRate?: number;
  satPerVbyte?: number;
  localFundingAmount: number;
  pushSat?: number;
  private: boolean;
  closeAddress?: string;
};

export function OpenChannelsNode({ ...wrapperProps }: OpenChannelsNodeProps) {
  const { t } = useTranslations();

  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;
  const toastRef = React.useContext(ToastContext);

  const [updateNode] = useUpdateNodeMutation();

  const [configuration, setConfiguration] = useState<OpenChannelsParameters>({
    feeRate: 0,
    satPerVbyte: undefined,
    localFundingAmount: 0,
    pushSat: undefined,
    private: false,
    closeAddress: undefined,
    ...wrapperProps.parameters,
  });

  const [systemVariables, setSystemVariables] = useState<WorkflowVersionNodeSystemVariablesMap>(
    {} as WorkflowVersionNodeSystemVariablesMap,
  );

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!processing) {
      setSystemVariables(wrapperProps.systemVariables || {});
    }
  }, [wrapperProps.systemVariables, processing]);

  useEffect(() => {
    if (!processing) {
      setDirty(
        checkNodeInputsDirty(wrapperProps.parameters, configuration, wrapperProps.systemVariables, systemVariables),
      );
    }
  }, [configuration, wrapperProps.parameters, processing, wrapperProps.systemVariables, systemVariables]);

  function setLocalFundingAmount(localFundingAmount?: number) {
    let capacity = 0;
    if (localFundingAmount !== undefined) {
      capacity = localFundingAmount;
    }
    return setConfiguration((prev) => ({
      ...prev,
      localFundingAmount: capacity,
    }));
  }

  function setPushSat(pushSat?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      pushSat: pushSat,
    }));
  }

  function setPrivate(priv: boolean) {
    return setConfiguration((prev) => ({
      ...prev,
      private: priv,
    }));
  }

  function setCloseAddress(closeAddress?: string) {
    return setConfiguration((prev) => ({
      ...prev,
      closeAddress: closeAddress,
    }));
  }

  function setFeeRate(feeRate?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      feeRate: feeRate,
    }));
  }

  function setSatPerVbyte(satPerVbyte?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      satPerVbyte: satPerVbyte,
    }));
  }

  function setVariable(variable: systemVariable | undefined, fieldName: string) {
    setSystemVariables((prev) => ({
      ...prev,
      [fieldName]: variable,
    }));
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }
    setProcessing(true);
    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: configuration,
      systemVariables: systemVariables,
    }).finally(() => {
      setProcessing(false);
    });
  }

  const { childLinks } = useSelector(
    SelectWorkflowNodeLinks({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeId: wrapperProps.workflowVersionNodeId,
      stage: wrapperProps.stage,
    }),
  );

  const channelIds =
    childLinks
      ?.filter((n) => {
        return n.childInput === "channels";
      })
      ?.map((link) => link.parentWorkflowVersionNodeId) ?? [];

  const channels = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: channelIds,
    }),
  );

  return (
    <WorkflowNodeWrapper {...wrapperProps} headerIcon={<OpenChannelsIcon />} colorVariant={NodeColorVariant.accent3}>
      <Form onSubmit={handleSubmit} intercomTarget={"open-channels-node-form"}>
        <Socket
          intercomTarget={"open-channels-node-channels"}
          collapsed={wrapperProps.visibilitySettings.collapsed}
          label={t.channels}
          selectedNodes={channels || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          inputName={"channels"}
          editingDisabled={editingDisabled}
        />
        <RadioChips
          label={t.Fee}
          sizeVariant={InputSizeVariant.small}
          vertical={true}
          groupName={"open-channels-node-fee-switch-" + wrapperProps.workflowVersionNodeId}
          options={[
            {
              label: t.mempool.fastest,
              id: "open-channels-node-fee-switch-fastestFee-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.feeRate === 0,
              onChange: () => setFeeRate(0),
            },
            {
              label: t.mempool.halfHour,
              id: "open-channels-node-fee-switch-halfHourFee-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.feeRate === 1,
              onChange: () => setFeeRate(1),
            },
            {
              label: t.mempool.hour,
              id: "open-channels-node-fee-switch-hourFee-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.feeRate === 2,
              onChange: () => setFeeRate(2),
            },
            {
              label: t.mempool.economy,
              id: "open-channels-node-fee-switch-economyFee-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.feeRate === 3,
              onChange: () => setFeeRate(3),
            },
            {
              label: t.mempool.minimum,
              id: "open-channels-node-fee-switch-minimumFee-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.feeRate === 4,
              onChange: () => setFeeRate(4),
            },
            {
              label: t.Fixed,
              id: "close-channels-node-fee-switch-custom-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.feeRate === 5,
              onChange: () => setFeeRate(5),
            },
          ]}
          editingDisabled={editingDisabled}
        />
        <SystemVariableInputWrapper
          variable={systemVariables["SatPerVbyte"]}
          handleVariableChange={(variable) => {
            setVariable(variable, "SatPerVbyte");
            setSatPerVbyte(undefined);
          }}
          inputDataType={systemVariableDataType.number}
          editingDisabled={editingDisabled}
          hidden={configuration.feeRate !== 5}
        >
          <Input
            intercomTarget={"open-channels-node-sat-per-vbyte-input"}
            label={t.Fixed + " sat/vbyte"}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={configuration.satPerVbyte}
            suffix={" sat/vbyte"}
            onValueChange={(values: NumberFormatValues) => {
              setSatPerVbyte(values.floatValue);
            }}
          />
        </SystemVariableInputWrapper>
        <SystemVariableInputWrapper
          variable={systemVariables["LocalFundingAmount"]}
          handleVariableChange={(variable) => {
            setVariable(variable, "LocalFundingAmount");
            setLocalFundingAmount(undefined);
          }}
          inputDataType={systemVariableDataType.number}
          editingDisabled={editingDisabled}
        >
          <Input
            intercomTarget={"open-channels-node-capacity-input"}
            label={t.capacity}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={configuration.localFundingAmount}
            suffix={" sats"}
            onValueChange={(values: NumberFormatValues) => {
              setLocalFundingAmount(values.floatValue);
            }}
          />
        </SystemVariableInputWrapper>
        <SystemVariableInputWrapper
          variable={systemVariables["PushSat"]}
          handleVariableChange={(variable) => {
            setVariable(variable, "PushSat");
            setPushSat(undefined);
          }}
          inputDataType={systemVariableDataType.number}
          editingDisabled={editingDisabled}
        >
          <Input
            intercomTarget={"open-channels-node-pushSat-input"}
            label={t.PushAmount}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={configuration.pushSat}
            suffix={" sats"}
            onValueChange={(values: NumberFormatValues) => {
              setPushSat(values.floatValue);
            }}
          />
        </SystemVariableInputWrapper>
        <Switch
          intercomTarget={"open-channels-node-force-switch"}
          label={"Private"}
          sizeVariant={SwitchSize.small}
          checked={configuration.private}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPrivate(e.target.checked);
          }}
        />
        <SystemVariableInputWrapper
          variable={systemVariables["CloseAddress"]}
          handleVariableChange={(variable) => {
            setVariable(variable, "CloseAddress");
            setCloseAddress(undefined);
          }}
          inputDataType={systemVariableDataType.text}
          editingDisabled={editingDisabled}
        >
          <Input
            intercomTarget={"open-channels-node-closeAddress-input"}
            label={t.ChannelCloseAddress}
            sizeVariant={InputSizeVariant.small}
            className={styles.single}
            value={configuration.closeAddress}
            onValueChange={(values) => {
              setCloseAddress(values.value);
            }}
          />
        </SystemVariableInputWrapper>
        <Button
          intercomTarget={"open-channels-node-save"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
        <Note title={t.note} noteType={NoteType.info}>
          <p>{t.workflowNodes.openChannelsDescription}</p>
        </Note>
      </Form>
    </WorkflowNodeWrapper>
  );
}
