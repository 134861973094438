import { ArrowSplit20Regular as ArrowSplit20Regular } from "@fluentui/react-icons";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import Socket from "components/forms/socket/Socket";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import { SelectWorkflowNodeLinks, SelectWorkflowNodes } from "pages/WorkflowPage/workflowApi";
import { useSelector } from "react-redux";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import { useContext } from "react";
import useTranslations from "services/i18n/useTranslations";
import { Form } from "components/forms/forms";
import Note, {NoteType} from "features/note/Note";

type HtlcChannelSplitNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export function HtlcChannelSplitNode({ ...wrapperProps }: HtlcChannelSplitNodeProps) {
  const { t } = useTranslations();

  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;

  const { childLinks } = useSelector(
    SelectWorkflowNodeLinks({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeId: wrapperProps.workflowVersionNodeId,
      stage: wrapperProps.stage,
    })
  );

  const incomingChannelIds =
    childLinks
      ?.filter((n) => {
        return n.childInput === "incomingChannels";
      })
      ?.map((link) => link.parentWorkflowVersionNodeId) ?? [];

  const incomingChannels = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: incomingChannelIds,
    })
  );

  const outgoingChannelIds =
    childLinks
      ?.filter((n) => {
        return n.childInput === "outgoingChannels";
      })
      ?.map((link) => link.parentWorkflowVersionNodeId) ?? [];

  const outgoingChannels = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: outgoingChannelIds,
    })
  );

  return (
    <WorkflowNodeWrapper {...wrapperProps} headerIcon={<ArrowSplit20Regular />} colorVariant={NodeColorVariant.accent1}>
      <Form intercomTarget={"htlc-channel-split-node"}>
        <Socket
          collapsed={wrapperProps.visibilitySettings.collapsed}
          label={t.htlcInput}
          selectedNodes={incomingChannels || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          inputName={"channels"}
          editingDisabled={editingDisabled}
        />
        <Socket
          collapsed={wrapperProps.visibilitySettings.collapsed}
          label={t.incomingHTLC}
          selectedNodes={incomingChannels || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          outputName={"incomingChannels"}
          editingDisabled={editingDisabled}
        />
        <Socket
          collapsed={wrapperProps.visibilitySettings.collapsed}
          label={t.outgoingHTLC}
          selectedNodes={outgoingChannels || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          outputName={"outgoingChannels"}
          editingDisabled={editingDisabled}
        />
        <Note title={t.note} noteType={NoteType.info}>
          <p>{t.workflowNodes.htlcChannelSplitDescription}</p>
        </Note>
      </Form>
    </WorkflowNodeWrapper>
  );
}
