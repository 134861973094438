import { Play20Regular as DataSourceOnChainTransactionIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeButtonWrapper from "components/workflow/nodeButtonWrapper/NodeButtonWrapper";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";

export function DataSourceOnChainTransactionNodeButton(props: { disabled?: boolean }) {
  const { t } = useTranslations();

  return (
    <WorkflowNodeButtonWrapper
      disabled={props.disabled}
      intercomTarget={"data-source-on-chain-transaction-node-button"}
      colorVariant={NodeColorVariant.accent2}
      nodeType={WorkflowNodeType.DataSourceOnChainTransaction}
      icon={<DataSourceOnChainTransactionIcon />}
      title={t.workflowNodes.onChainTransaction}
    />
  );
}
