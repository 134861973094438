import { Save16Regular as SaveIcon, Timer16Regular as IntervalTriggerIcon } from "@fluentui/react-icons";
import { useContext, useState } from "react";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import { Form } from "components/forms/forms";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { useUpdateNodeMutation } from "pages/WorkflowPage/workflowApi";
import Spinny from "features/spinny/Spinny";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import { DurationInput, TimeUnits } from "components/common/durationInput/DurationInput";

type IntervalTriggerNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export type IntervalTriggerParameters = {
  seconds: number;
  timeUnit: TimeUnits;
};

// Function for checking if the parameters passed into the node are of type IntervalTriggerParameters
function isIntervalTriggerParameters(parameters: unknown): parameters is IntervalTriggerParameters {
  const p = parameters as IntervalTriggerParameters;
  return p.seconds !== undefined;
}

export function IntervalTriggerNode({ ...wrapperProps }: IntervalTriggerNodeProps) {
  const { t } = useTranslations();
  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;

  const [updateNode] = useUpdateNodeMutation();

  const ONE_HOUR = 60 * 60; // 1 hour

  const parameters = isIntervalTriggerParameters(wrapperProps.parameters)
    ? wrapperProps.parameters
    : { seconds: ONE_HOUR, timeUnit: TimeUnits.hours };

  const [selectedTimeUnit, setSelectedTimeUnit] = useState<TimeUnits>(parameters.timeUnit || TimeUnits.seconds);
  const [seconds, setSeconds] = useState<number>(ONE_HOUR);

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleDurationChange = (seconds: number, timeUnit?: TimeUnits) => {
    setSeconds(seconds);
    if (timeUnit) {
      setSelectedTimeUnit(timeUnit);
    }
  };

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (editingDisabled) {
      return;
    }

    setProcessing(true);
    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: {
        seconds: seconds,
        timeUnit: selectedTimeUnit,
      },
    }).finally(() => {
      setProcessing(false);
    });
  }

  return (
    <WorkflowNodeWrapper {...wrapperProps} headerIcon={<IntervalTriggerIcon />} colorVariant={NodeColorVariant.primary}>
      <Form onSubmit={handleSubmit} intercomTarget={"interval-trigger-form"}>
        <DurationInput
          label={t.TriggerEvery}
          helpText={
            "Field sets the frequency of the trigger. For example 1 minute means the trigger will fire every minute."
          }
          editingDisabled={editingDisabled}
          timeUnit={parameters.timeUnit}
          seconds={parameters.seconds}
          intercomTargetFrequencyInput={"interval-trigger-frequency-input"}
          intercomTargetTimeUnitSelect={"interval-trigger-time-unit-select"}
          onDirtyChange={setDirty}
          onDurationChange={handleDurationChange}
        />
        <Button
          intercomTarget={"interval-trigger-save-button"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing || editingDisabled}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
      </Form>
    </WorkflowNodeWrapper>
  );
}
