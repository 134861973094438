import { useCheckPayStatusMutation, useGetProductsQuery, useGetServicesQuery, useGetSettingsQuery } from "apiSlice";
import { ReactComponent as TorqLogo } from "icons/torq-logo.svg";
import { PersonInfo20Regular as UuidIcon } from "@fluentui/react-icons";
import styles from "pages/selectPlan/SelectPlan.module.scss";
import PriceCard, { LicenseStatus } from "components/priceCard/PriceCard";
import Button, { ButtonPosition, ButtonRow, ColorVariant } from "components/buttons/Button";
import useTranslations from "services/i18n/useTranslations";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Note, { NoteType } from "features/note/Note";

export default function SelectPlan() {
  const { t } = useTranslations();

  const navigate = useNavigate();

  const { refetch: refetchServicesData } = useGetServicesQuery();
  const { data: settingsData } = useGetSettingsQuery(); // refetch: getSettings
  const { data: productsData } = useGetProductsQuery();
  const [checkPayStatus, { data: paid, isLoading: checkPayStatusLoading }] = useCheckPayStatusMutation();
  useEffect(() => {
    refetchServicesData();
  }, [paid]);

  return (
    <div className={styles.selectPlanPageWrapper}>
      <div className={styles.logo}>
        <TorqLogo />
      </div>
      <LicenseStatus />
      <div className={styles.pricePlansWrapper}>
        {productsData?.map((product) => {
          if (product.available) {
            return <PriceCard key={product.productId} {...product} torqUuid={settingsData?.torqUuid} />;
          }
        })}
      </div>
      <div className={styles.back}>
        <ButtonRow>
          <Button
            intercomTarget={"select-plan-back-button"}
            onClick={() => {
              navigate("/");
            }}
            buttonColor={ColorVariant.primary}
          >
            {t.back}
          </Button>
          <Button
            buttonPosition={ButtonPosition.right}
            onClick={() => {
              checkPayStatus();
            }}
            buttonColor={ColorVariant.primary}
            intercomTarget={"check-now-button"}
            disabled={checkPayStatusLoading}
          >
            {t.recheck}
          </Button>
        </ButtonRow>
      </div>
      <Note title={"Customer ID:"} noteType={NoteType.success} className={styles.customerId} icon={<UuidIcon />}>
        <div className={styles.customerIdValue}>{settingsData?.torqUuid}</div>
      </Note>
    </div>
  );
}
