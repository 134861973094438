import { ArrowWrapOff20Regular as CloseChannelsIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeButtonWrapper from "components/workflow/nodeButtonWrapper/NodeButtonWrapper";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";

export function CloseChannelsNodeButton(props: { disabled?: boolean }) {
  const { t } = useTranslations();

  return (
    <WorkflowNodeButtonWrapper
      disabled={props.disabled}
      intercomTarget={"close-channels-node-button"}
      colorVariant={NodeColorVariant.accent3}
      nodeType={WorkflowNodeType.CloseChannels}
      icon={<CloseChannelsIcon />}
      title={t.workflowNodes.closeChannels}
    />
  );
}
