import { ArrowExit20Regular as Icon } from "@fluentui/react-icons";
import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import {
  WorkflowRunLogDetailAction,
  ChannelOpenInterceptionEventFilterAction,
} from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import ParentsLog from "components/workflow/LogDetails/ParentsLog/ParentsLog";
import { AnyObject } from "utils/types";
import { AndClause, OrClause, deserialiseQuery } from "features/sidebar/sections/filter/filter";
import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";
import FilterComponent from "features/sidebar/sections/filter/FilterComponent";
import { ChannelOpenInterceptionFilterColumns, EventsFilterTemplate } from "components/workflow/nodes/channelOpenInterceptionEventFilter/ChannelOpenInterceptionEventFilterNode";
import styles from "components/workflow/LogDetails/FilterLog/filterLog.module.scss";
import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";
import useTranslations from "services/i18n/useTranslations";


export default function ChannelOpenInterceptionEventFilterLog(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();
  const filterProps = JSON.parse(props.parameters || "{}") as { filterClauses: AnyObject };
  const filters = deserialiseQuery(filterProps?.filterClauses || { $and: [] }) as AndClause | OrClause;
  const data = props?.actions ? (props.actions[0] as ChannelOpenInterceptionEventFilterAction) : undefined;

  return (
    <LogDetailsWrapper
      type={LogDetailType.filter}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.filter} />
      <LogContainer title={t.filters} type={LogDetailType.filter}>
        {filters.length !== 0 && (
          <FilterComponent
            filters={filters}
            columns={ChannelOpenInterceptionFilterColumns}
            defaultFilter={EventsFilterTemplate}
            child={false}
            onFilterUpdate={() => {
              return null;
            }}
            editingDisabled={true}
          />
        )}
        {filters.length === 0 && <div className={styles.noFilters}>{t.noFilter}</div>}
      </LogContainer>
      <LogContainer title={t.filterMatch} type={LogDetailType.filter}>
        {data?.outputEvent ? <div>{t.yes}</div> : <div>{t.no}</div>}
      </LogContainer>
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
