import { useEffect } from 'react';

type RefreshFunction = () => void;

function useRefreshShortcut(refreshFunctions: RefreshFunction[]): void {
  const keydownHandler = (event: KeyboardEvent) => {
    const target = event.target as HTMLElement;

    if (
      target instanceof HTMLInputElement ||
      target instanceof HTMLTextAreaElement ||
      target instanceof HTMLSelectElement ||
      target.contentEditable === "true"
    ) {
      return;
    }

    if (event.key === "r" && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey) {
      refreshFunctions.forEach((refreshFunction) => {
        try {
          refreshFunction()
        } catch (error) {
          console.error(error);
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keydownHandler);
    return () => {
      window.removeEventListener("keydown", keydownHandler);
    };
  }, [refreshFunctions]);
}

export default useRefreshShortcut;
