// This is a copy of the WorkflowNodeType enum from the backend and needs to be kept synchronized.
export enum WorkflowNodeType {
  IntervalTrigger,
  ChannelBalanceEventTrigger,
  ChannelFilter,
  RoutingPolicyConfigurator,
  RebalanceConfigurator,
  StageTrigger,
  RebalanceRun,
  RoutingPolicyRun,
  SetVariable,
  FilterOnVariable,
  // Trigger is the main trigger that is used to start the workflow prevent us
  // from having to add and remove links to every trigger.
  Trigger,
  AddTag,
  RemoveTag,
  ChannelOpenEventTrigger,
  ChannelCloseEventTrigger,
  CronTrigger,
  ManualTrigger,
  RoutingPolicyAutoRun,
  RebalanceAutoRun,
  DataSourceTorqChannels,
  ChannelBalanceEventFilter,
  ManualDryRunTrigger,
  CloseChannels,
  OpenChannels,
  ChannelRequest,
  SystemOperation,
  DataSourceHtlcEvent,
  HtlcInterceptionTrigger,
  HtlcInterceptionEventFilter,
  HtlcInterceptionBlocker,
  HtlcChannelJoin,
  HtlcChannelSplit,
  Notification,
  ApiClient,
  ForwardTrigger,
  InvoiceTrigger,
  PaymentTrigger,
  OnChainTransactionTrigger,
  ExchangeFilter,
  MempoolFilter,
  PaymentAutoRun,
  APITrigger,
  ChannelOpenInterceptionTrigger,
  DataSourceChannelOpenInterceptionEvent,
  ChannelOpenInterceptionEventFilter,
  ChannelOpenInterceptionResult,
  DataSourceOnChainTransaction,
  Scorechain,
}

export const TriggerNodeTypes = [
  WorkflowNodeType.IntervalTrigger,
  WorkflowNodeType.ChannelBalanceEventTrigger,
  WorkflowNodeType.StageTrigger,
  WorkflowNodeType.ChannelOpenEventTrigger,
  WorkflowNodeType.ChannelCloseEventTrigger,
  WorkflowNodeType.CronTrigger,
  WorkflowNodeType.HtlcInterceptionTrigger,
  WorkflowNodeType.ForwardTrigger,
  WorkflowNodeType.InvoiceTrigger,
  WorkflowNodeType.PaymentTrigger,
  WorkflowNodeType.OnChainTransactionTrigger,
  WorkflowNodeType.ChannelOpenInterceptionTrigger,
];

export const HTLCNodeTypes = [
  WorkflowNodeType.HtlcInterceptionBlocker,
  WorkflowNodeType.HtlcInterceptionEventFilter,
  WorkflowNodeType.DataSourceHtlcEvent,
  WorkflowNodeType.HtlcInterceptionTrigger,
];
