import { torqApi } from "apiSlice";
import {
  ConnectPeerRequest,
  ConnectPeerResponse,
  DisconnectPeerRequest,
  DisconnectPeerResponse,
  GetPeersQueryParams,
  GetPeersSummaryQueryParams,
  Peer,
  PeersResponse,
  UpdatePeerRequest,
  UpdatePeerResponse,
} from "features/peers/peersTypes";
import { queryParamsBuilder } from "utils/queryParamsBuilder";

export const peersApi = torqApi.injectEndpoints({
  endpoints: (builder) => ({
    connectPeer: builder.mutation<ConnectPeerResponse, ConnectPeerRequest>({
      query: (body) => ({
        url: `peers/connect`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["peers"],
    }),
    disconnectPeer: builder.mutation<DisconnectPeerResponse, DisconnectPeerRequest>({
      query: (body) => ({
        url: `peers/disconnect`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["peers"],
    }),
    reconnectPeer: builder.mutation<DisconnectPeerResponse, DisconnectPeerRequest>({
      query: (body) => ({
        url: `peers/reconnect`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["peers"],
    }),
    updatePeer: builder.mutation<UpdatePeerResponse, UpdatePeerRequest>({
      query: (body) => ({
        url: `peers/update`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["peers"],
    }),
    getPeers: builder.query<PeersResponse, GetPeersQueryParams>({
      query: (params) => "peers" + queryParamsBuilder(params, true),
      providesTags: ["peers"],
    }),
    getPeersSummary: builder.query<number, GetPeersSummaryQueryParams>({
      query: (params) => "peers/summary" + queryParamsBuilder(params, false),
      providesTags: ["peers"],
    }),
    getPeer: builder.query<Peer, { torqNodeId: number; peerNodeId: number; uptimeDays: number }>({
      query: (params) => "peers/" + params.torqNodeId + "/" + params.peerNodeId + "/" + params.uptimeDays,
    }),
  }),
});

// Select a single peer from the list

export const {
  useConnectPeerMutation,
  useDisconnectPeerMutation,
  useReconnectPeerMutation,
  useUpdatePeerMutation,
  useGetPeersQuery,
  useGetPeersSummaryQuery,
  useGetPeerQuery,
} = peersApi;
