export type ChannelRequestParameters = {
  torqNodeId: number;
  peerNodeId?: number;
  lsp?: number;
  lspUrl?: string;
  autoPay?: number;
  autoPayFeeRateMilliMsat?: number;
  autoPayFeeRate?: number;
  autoPaySatPerVbyte?: number;
  autoPayMaximumTotalCost?: number;
  clientBalance?: number;
  lspBalance?: number;
  channelExpiryWeeks?: number;
  fundingTransactionFeeRate?: number;
  fundingTransactionSatPerVbyte?: number;
  token?: string;
  refundOnChainAddress?: string;
  privateChannel: boolean;
};

export type ChannelRequestResponse = {
  orderId: string;
  createdAt?: Date;
  clientBalance?: number;
  lspBalance?: number;
  channelExpiryBlocks?: number;
  channelExpiryTime?: Date;
  orderExpiryTime?: Date;
  orderTotal?: number;
  feeTotal?: number;
  feePerPayment?: number;
  temporaryShortChannelId?: string;
  shortChannelId?: string;
  lspConnectionInfo?: string;
  bolt11Invoice?: string;
  btcAddress?: string;
  lnurlChannel?: string;
  amountPaid?: number;
  nodeConnectionInfo?: string;
  fundingTransactionHash?: string;
  fundingOutputIndex?: number;
  orderState: LspOrderState;
  channelState?: LspChannelState;
  channelRequestState: LspChannelRequestState;
  lspChannelRequestRequestId: number;
  createdOn: Date;
};

export type ChannelRequest = {
  orderId?: string;
  createdAt?: Date;
  clientBalance?: number;
  lspBalance?: number;
  channelExpiryBlocks?: number;
  channelExpiryTime?: Date;
  orderExpiryTime?: Date;
  orderTotal?: number;
  feeTotal?: number;
  feePerPayment?: number;
  temporaryShortChannelId?: string;
  shortChannelId?: string;
  lspConnectionInfo?: string;
  bolt11Invoice?: string;
  btcAddress?: string;
  lnurlChannel?: string;
  amountPaid?: number;
  nodeConnectionInfo?: string;
  fundingTransactionHash?: string;
  fundingOutputIndex?: number;
  orderState?: LspOrderState;
  channelState?: LspChannelState;
  channelRequestState?: LspChannelRequestState;
  lspChannelRequestRequestId?: number;
  createdOn?: Date;
  errorResponse?: string;
  lsp?: Lsp;
  lspName?: string;
  lspUrl?: string;
  requestingTorqNodeId?: number;
  requestingTorqNodeName?: string;
  requestingTorqNodeCssColour?: string;
  respondingTorqNodeId?: number;
  respondingTorqNodeName?: string;
  respondingTorqNodeCssColour?: string;
  peerNodeId: number;
  peerNodeName: string;
  peerNodeCssColour: string;
  privateChannel?: boolean;
  token?: string;
};

export enum Lsp {
  Deezy = 0,
}

export enum LspOrderState {
  Created = "CREATED",
  Completed = "COMPLETED",
  Failed = "FAILED",
}

export enum LspChannelState {
  Opening = "OPENING",
  Opened = "OPENED",
  Closed = "CLOSED",
}

export enum LspChannelRequestState {
  Created = "CREATED",
  UnderFunded = "UNDER_FUNDED",
  Pending = "PENDING",
  Opening = "OPENING",
  Opened = "OPENED",
  Closing = "CLOSING",
  Closed = "CLOSED",
  Failed = "FAILED",
  Refunded = "REFUNDED",
  OfferExpired = "OFFER_EXPIRED",
}

export type ChannelRequestServer = {
  torqNodeName?: string;
  nodeCssColour?: string;
  torqNodeId: number;

  peerNodeId?: number;
  peerNodeAlias?: string;

  supportedVersions?: number[];
  lspUrl?: string;

  host?: string;
  port?: number;
  nodeConnectionInfo?: string;
  website?: string;

  maximumChannelExpiryWeeks?: number;
  maximumChannelExpiryBlocks?: number;

  minimumChannelConfirmations: number;

  minimumOnChainFeeRate: number;
  minimumOnChainFeeRateSatPerVbyte?: number;
  minimumOnChainPaymentConfirmations: number;
  minimumOnChainPaymentSize?: number;

  supportsZeroChannelReserve: boolean;

  minimumInitialClientBalance: number;
  maximumInitialClientBalance: number;
  minimumInitialLspBalance: number;
  maximumInitialLspBalance: number;
  minimumChannelCapacity: number;
  maximumChannelCapacity: number;

  satPerVbyteMultiplier: number;
  capacityFeeRate: number;
  capacityFeeBase: number;
  feePerPayment: number;

  createdOn: Date;
};
