// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go


import { ColumnMetaData } from "features/table/types";
import { Payment } from "features/transact/Payments/types";

export const AllPaymentsColumns: ColumnMetaData<Payment>[] = [
	{
		heading: "Failed Attempts",
		type: "NumericCell",
		key: "countFailedAttempts",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Successful Attempts",
		type: "NumericCell",
		key: "countSuccessfulAttempts",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Date",
		type: "DateCell",
		key: "date",
		valueType: "date",
		suffix: "",
		locked: true,
		supportedBy: [0, 1],
	},
	{
		heading: "Destination",
		type: "LongTextCell",
		key: "destinationPubKey",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Failure Reason",
		type: "TextCell",
		key: "failureReason",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "None", value: "FAILURE_REASON_NONE" },
			{ label: "Timeout", value: "FAILURE_REASON_TIMEOUT" },
			{ label: "No Route", value: "FAILURE_REASON_NO_ROUTE" },
			{ label: "Error", value: "FAILURE_REASON_ERROR" },
			{ label: "Incorrect Payment Details", value: "FAILURE_REASON_INCORRECT_PAYMENT_DETAILS" },
			{ label: "Incorrect Payment Amount", value: "FAILURE_REASON_INCORRECT_PAYMENT_AMOUNT" },
			{ label: "Payment Hash Mismatch", value: "FAILURE_REASON_PAYMENT_HASH_MISMATCH" },
			{ label: "Incorrect Payment Request", value: "FAILURE_REASON_INCORRECT_PAYMENT_REQUEST" },
			{ label: "Unknown", value: "FAILURE_REASON_UNKNOWN" },
		],
	},
	{
		heading: "Fee",
		type: "NumericCell",
		key: "fee",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "MPP",
		type: "BooleanCell",
		key: "isMpp",
		valueType: "boolean",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Rebalance",
		type: "BooleanCell",
		key: "isRebalance",
		valueType: "boolean",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Payment Hash",
		type: "TextCell",
		key: "paymentHash",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Payment Preimage",
		type: "TextCell",
		key: "paymentPreimage",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "PPM",
		type: "NumericCell",
		key: "ppm",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Seconds In Flight",
		type: "DurationCell",
		key: "secondsInFlight",
		valueType: "duration",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Status",
		type: "TextCell",
		key: "status",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "Succeeded", value: "SUCCEEDED" },
			{ label: "In Flight", value: "IN_FLIGHT" },
			{ label: "Failed", value: "FAILED" },
		],
	},
	{
		heading: "Value",
		type: "NumericCell",
		key: "value",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Node ID",
		type: "TextCell",
		key: "nodeId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node Css Colour",
		type: "TextCell",
		key: "nodeCssColour",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Payment Request",
		type: "LongTextCell",
		key: "paymentRequest",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const PaymentsSortableColumns: Array<keyof Payment> = [
	"countFailedAttempts",
	"countSuccessfulAttempts",
	"date",
	"failureReason",
	"fee",
	"isRebalance",
	"ppm",
	"secondsInFlight",
	"status",
	"value",
	"nodeId",
	"nodeCssColour",
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const PaymentsFilterableColumns: Array<keyof Payment> = [
	"countFailedAttempts",
	"countSuccessfulAttempts",
	"date",
	"destinationPubKey",
	"failureReason",
	"fee",
	"isMpp",
	"isRebalance",
	"paymentHash",
	"paymentPreimage",
	"ppm",
	"secondsInFlight",
	"status",
	"value",
	"nodeId",
	"nodeCssColour",
	"paymentRequest",
];