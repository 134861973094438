import classNames from "classnames";
import { Delete16Regular as RemoveIcon } from "@fluentui/react-icons";
import {
  Clause,
  FilterCategoryType,
  FilterClause,
  FilterFunctions,
  FilterInterface,
  FilterParameterType,
} from "features/sidebar/sections/filter/filter";
import styles from "features/sidebar/sections/filter/filter-section.module.scss";
import { useState } from "react";
import { format } from "d3";
import { Input, InputColorVaraint, InputSizeVariant, Select } from "components/forms/forms";
import { TagResponse } from "pages/tags/tagsTypes";
import { useGetTagsQuery } from "pages/tags/tagsApi";
import clone from "clone";
import { formatDuration, intervalToDuration } from "date-fns";
import { DurationInput, TimeUnitFromSeconds, TimeUnits } from "components/common/durationInput/DurationInput";
import useTranslations from "services/i18n/useTranslations";
import { Implementation } from "types/api";
import { WarningRegular as WarningIcon } from "@fluentui/react-icons";
import { systemVariable, systemVariableDataType } from "apiTypes";
import SystemVariableInputWrapper from "components/common/systemVariableInputWrapper/SystemVariableInputWrapper";

const formatter = format(",.0f");
const subSecFormat = format("0.2f");

function formatParameter(value: number) {
  return value % 1 != 0 ? value : formatter(value);
}

const ffLabels = new Map<string, string>([
  ["eq", "is equal to"],
  ["neq", "is not equal to"],
  ["gt", "is greater than"],
  ["gte", "is greater than or equal to"],
  ["lt", "is less than"],
  ["lte", "is less than or equal to"],
  ["like", "contain"],
  ["notLike", "don't contain"],
  ["any", "contain"],
  ["notAny", "don't contain"],
  ["empty", "is empty"],
  ["notEmpty", "is not empty"],
]);

function getFilterFunctions(filterCategory: FilterCategoryType) {
  const filterFuncs = FilterFunctions.get(filterCategory)?.entries();
  if (!filterFuncs) {
    throw new Error("Filter category not found in list of filters");
  }
  return Array.from(filterFuncs, ([key, _]) => ({ value: key, label: ffLabels.get(key) ?? "Label not found" }));
}

interface filterRowInterface {
  index: number;
  child: boolean;
  filterClause: FilterClause;
  filterOptions: Array<{
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any; // TODO: Switch to "number" | "string" | "date" | "boolean" | "array" | "duration" | "enum" | "tag" | undefined
    valueType?: FilterCategoryType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectOptions?: Array<any>;
    suffix: string | undefined;
    supportedBy: Array<Implementation>;
  }>;
  onUpdateFilter: (filters: Clause) => void;
  onRemoveFilter: (index: number) => void;
  handleCombinerChange: () => void;
  combiner?: string;
  editingDisabled?: boolean;
  allowVariableInput?: boolean;
}

function FilterRow({
  index,
  child,
  filterClause,
  filterOptions,
  onUpdateFilter,
  onRemoveFilter,
  handleCombinerChange,
  combiner,
  editingDisabled,
  allowVariableInput,
}: filterRowInterface) {
  const { t } = useTranslations();
  const rowValues = filterClause.filter;
  const [rowExpanded, setRowExpanded] = useState(false);
  const { data: getTags } = useGetTagsQuery();

  const tags = (getTags || []).map((tag: TagResponse) => ({ label: tag.name, value: tag.tagId })) || [];

  const functionOptions = getFilterFunctions(rowValues.category);

  const keyOption = filterOptions.find((item) => item.value === rowValues.key);
  const funcOption = functionOptions.find((item) => item.value === rowValues.funcName);

  const selectData = {
    func: funcOption,
    key: keyOption,
  };

  const parameterVisible = selectData.func?.value !== "empty" && selectData.func?.value !== "notEmpty";

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyChange = (item: any) => {
    if (editingDisabled) {
      return;
    }
    const newRow = { ...rowValues };
    newRow.key = item.value;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newCategory = filterOptions.find((item: any) => item.value === newRow.key)?.valueType || "number";
    switch (newCategory) {
      case "number":
        newRow.parameter = 0;
        newRow.funcName = "gte";
        break;
      case "duration":
        newRow.parameter = 0;
        newRow.funcName = "gte";
        break;
      case "boolean":
        if (newCategory !== newRow.category) {
          newRow.parameter = true;
        }
        newRow.funcName = "eq";
        break;
      case "date": {
        newRow.parameter = new Date().toISOString().slice(0, 10) + "T00:00:00";
        newRow.funcName = "gte";
        break;
      }
      case "array":
        newRow.parameter = [];
        newRow.funcName = "any";
        break;
      case "enum":
        newRow.parameter = [];
        newRow.funcName = "any";
        break;
      case "tag":
        newRow.parameter = [];
        newRow.funcName = "any";
        break;
      default:
        newRow.parameter = "";
        newRow.funcName = "like";
    }
    newRow.category = newCategory;
    const updatedFilterClause = clone(filterClause);
    updatedFilterClause.filter = newRow;
    onUpdateFilter(updatedFilterClause);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFunctionChange = (item: any) => {
    if (editingDisabled) {
      return;
    }
    const updatedFilterClause = clone(filterClause);
    if (item.value === "empty" || item.value === "notEmpty") {
      updatedFilterClause.filter = { ...rowValues, funcName: item.value, parameter: "" };
    } else {
      updatedFilterClause.filter = { ...rowValues, funcName: item.value};
    }
    onUpdateFilter(updatedFilterClause);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getParamValueFromValue(e: any, category: FilterCategoryType): FilterParameterType {
    switch (category) {
      case "number":
        return e.floatValue || 0;
      case "duration":
        if (typeof e === "number") {
          return e;
        } else {
          return e.floatValue || 0;
        }
      case "boolean":
        return e.value || false;
      case "tag":
        return (e as Array<{ value: number }>).map((item) => item.value);
      case "array":
        return (e as Array<{ value: string | number }>).map((item) => item.value);
      case "enum":
        return (e as Array<{ value: string | number }>).map((item) => item.value);
      default:
        return e?.target?.value ? e.target.value : "";
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleParamChange = (e: any) => {
    if (editingDisabled) {
      return;
    }
    const newRow = { ...rowValues };
    newRow.parameter = getParamValueFromValue(e, newRow.category);

    const updatedFilterClause = clone(filterClause);
    updatedFilterClause.filter = newRow;
    onUpdateFilter(updatedFilterClause);
  };

  const handleVariableChange = (variable: systemVariable | undefined) => {
    if (editingDisabled) {
      return;
    }
    const newRow = { ...rowValues };
    newRow.systemVariable = variable;
    // clear the parameter value
    newRow.parameter = getParamValueFromValue({}, newRow.category);
    const updatedFilterClause = clone(filterClause);
    updatedFilterClause.filter = newRow;
    onUpdateFilter(updatedFilterClause);
  };

  const label = filterOptions.find((item) => item.value === rowValues.key)?.label;
  const options = filterOptions.find((item) => item.value === rowValues.key)?.selectOptions || [];
  const suffix = filterOptions.find((item) => item.value === rowValues.key)?.suffix || "";

  function getParameter() {
    // if has variable name, use that
    if (rowValues.systemVariable?.name) {
      return "<" + rowValues.systemVariable.name + ">";
    }

    switch (rowValues.category) {
      case "string":
        return rowValues.parameter as string;
      case "number":
        return formatParameter(rowValues.parameter as number);
      case "duration":
        if ((rowValues.parameter as number) >= 1) {
          try {
            const d = intervalToDuration({ start: 0, end: (rowValues.parameter as number) * 1000 });
            return (
              formatDuration({
                years: d.years,
                months: d.months,
                days: d.days,
                hours: d.hours,
                minutes: d.minutes,
                seconds: d.seconds,
              }) +
              " " +
              suffix
            );
          } catch (e) {
            return `${formatter(rowValues.parameter as number)} seconds ${suffix}`;
          }
        } else if ((rowValues.parameter as number) < 1 && (rowValues.parameter as number) > 0) {
          return `${subSecFormat(rowValues.parameter as number)} seconds ${suffix}`;
        }
        return `0 seconds ${suffix}`;
      case "boolean":
        return !rowValues.parameter ? "False" : "True";
      case "array":
        if (rowValues?.parameter) {
          return (
            (
              options?.filter((item) => {
                return (rowValues.parameter as Array<string | number>).includes(item.value);
              }) || []
            )
              .map((item) => item.label)
              .join(" or ") || "[empty]"
          );
        }
        return "[empty]";
      case "enum":
        if (rowValues?.parameter) {
          return (
            (
              options?.filter((item) => {
                return (rowValues.parameter as Array<string | number>).includes(item.value);
              }) || []
            )
              .map((item) => item.label)
              .join(" or ") || "[empty]"
          );
        }
        return "[empty]";
      case "tag":
        if (rowValues?.parameter) {
          const parameter = rowValues.parameter as Array<string | number>;
          return (
            (parameter || []).map((tagId) => (tags || []).find((t) => t.value === tagId)?.label).join(" or ") ||
            "[empty]"
          );
        }
        return "[empty]";
      default:
        return rowValues.parameter as string;
    }
  }

  return (
    <div className={classNames(styles.filter, { [styles.first]: !index })}>
      <div className={classNames(styles.filterKeyContainer, { [styles.expanded]: rowExpanded })}>
        {index !== 0 && (
          <div
            className={styles.combinerContainer}
            onClick={() => {
              handleCombinerChange();
            }}
          >
            {combiner}
          </div>
        )}
        <div
          className={classNames(styles.filterKeyLabel)}
          onClick={() => setRowExpanded(!rowExpanded)}
          data-intercom-target={"filter-row-summary-label"}
        >
          <span>{label}</span>
          <span className={styles.filterFunctionLabel}>&nbsp;{funcOption?.label}&nbsp;</span>
          <span className={styles.parameterLabel}>{getParameter()}</span>
        </div>
        {!editingDisabled && (
          <div
            className={classNames(styles.removeFilter, styles.desktopRemove)}
            onClick={() => onRemoveFilter(index)}
            data-intercom-target={"remove-filter-row"}
          >
            <RemoveIcon />
          </div>
        )}
      </div>

      <div className={classNames(styles.filterOptions, { [styles.expanded]: rowExpanded })}>
        <Select
          intercomTarget={"filter-row-key-select"}
          options={filterOptions}
          value={selectData.key}
          onChange={handleKeyChange}
          sizeVariant={InputSizeVariant.small}
          colorVariant={child ? InputColorVaraint.primary : InputColorVaraint.accent1}
        />

        {selectData.key && selectData.key?.supportedBy && selectData.key?.supportedBy.length == 1 && (
          <div className={styles.filterSupportWrapper}>
            <div className={styles.filterSupportIcon}>
              <WarningIcon />
            </div>
            <div className={styles.filterSupportText}>
              {t.onlySupportedBy + ": " + (selectData.key?.supportedBy[0] === Implementation.LND ? "LND" : "CLN")}
            </div>
          </div>
        )}
        <div className="filter-function-container">
          <Select
            intercomTarget={"filter-row-function-select"}
            options={functionOptions}
            value={selectData.func}
            onChange={handleFunctionChange}
            sizeVariant={InputSizeVariant.small}
            colorVariant={child ? InputColorVaraint.primary : InputColorVaraint.accent1}
          />
        </div>
        {parameterVisible &&
        <div className={styles.filterParameterContainer}>
          <FilterInputField
            onChange={handleParamChange}
            rowValues={rowValues}
            options={options}
            child={child}
            editingDisabled={editingDisabled}
            suffix={suffix}
            index={index}
            allowVariableInput={allowVariableInput}
            handleVariableChange={handleVariableChange}
          />
        </div>
        }
      </div>
    </div>
  );
}

export default FilterRow;

function FilterInputField(props: {
  onChange: (e: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  rowValues: FilterInterface;
  child: boolean;
  options: Array<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  editingDisabled?: boolean | false;
  suffix?: string;
  index: number;
  allowVariableInput?: boolean;
  handleVariableChange?: (variable: systemVariable | undefined) => void;
}) {
  const { t } = useTranslations();
  const { editingDisabled } = props;
  const { data: getTags } = useGetTagsQuery();
  const tags = (getTags || []).map((tag: TagResponse) => ({ label: tag.name, value: tag.tagId })) || [];

  switch (props.rowValues.category) {
    case "number":
      return (
        <SystemVariableInputWrapper
          variable={props.rowValues.systemVariable}
          handleVariableChange={props.handleVariableChange}
          inputDataType={systemVariableDataType.number}
          editingDisabled={editingDisabled || false}
          disableVariableInput={!props.allowVariableInput}
        >
          <Input
            intercomTarget={"filter-row-parameter-input"}
            disabled={editingDisabled}
            formatted={true}
            thousandSeparator=","
            sizeVariant={InputSizeVariant.small}
            colorVariant={props.child ? InputColorVaraint.primary : InputColorVaraint.accent1}
            defaultValue={props.rowValues.parameter as keyof FilterParameterType}
            onValueChange={(e) => {
              props.onChange(e);
            }}
          />
        </SystemVariableInputWrapper>
      );
    case "duration":
      return (
        <DurationInput
          editingDisabled={editingDisabled}
          timeUnit={TimeUnitFromSeconds(props.rowValues.parameter as number, TimeUnits.days)}
          seconds={props.rowValues.parameter as keyof FilterParameterType}
          timeUnitOptions={[
            { value: TimeUnits.seconds.valueOf(), label: t.seconds },
            { value: TimeUnits.minutes.valueOf(), label: t.minutes },
            { value: TimeUnits.hours.valueOf(), label: t.hours },
            { value: TimeUnits.days.valueOf(), label: t.days },
          ]}
          intercomTargetFrequencyInput={"filter-row-parameter-frequency-input"}
          intercomTargetTimeUnitSelect={"filter-row-parameter-time-unit-select"}
          sizeVariant={InputSizeVariant.small}
          colorVariant={props.child ? InputColorVaraint.primary : InputColorVaraint.accent1}
          onDurationChange={(sec: number) => {
            props.onChange(sec);
          }}
          additionalSuffix={props.suffix}
        />
      );
    case "boolean":
      return (
        <Select
          intercomTarget={"filter-row-parameter-input"}
          isDisabled={editingDisabled}
          options={[
            { label: "True", value: true },
            { label: "False", value: false },
          ]}
          value={[
            { label: "True", value: true },
            { label: "False", value: false },
          ].find((v) => v.value === props.rowValues.parameter)}
          onChange={props.onChange}
          colorVariant={props.child ? InputColorVaraint.primary : InputColorVaraint.accent1}
          sizeVariant={InputSizeVariant.small}
        />
      );
    case "array": {
      return (
        <Select
          intercomTarget={"filter-row-parameter-input"}
          isDisabled={editingDisabled}
          isMulti={true}
          options={props.options}
          value={props.options.filter((item) => {
            return (props.rowValues.parameter as Array<string | number>).includes(item.value);
          })}
          onChange={props.onChange}
          colorVariant={props.child ? InputColorVaraint.primary : InputColorVaraint.accent1}
          sizeVariant={InputSizeVariant.small}
        />
      );
    }
    case "tag": {
      return (
        <Select
          intercomTarget={"filter-row-parameter-input"}
          isDisabled={editingDisabled}
          isMulti={true}
          options={tags}
          value={((props.rowValues.parameter as Array<number>) || []).map((tagId) => {
            return { label: tags.find((o) => o.value === tagId)?.label, value: tagId };
          })}
          onChange={props.onChange}
          colorVariant={props.child ? InputColorVaraint.primary : InputColorVaraint.accent1}
          sizeVariant={InputSizeVariant.small}
        />
      );
    }
    case "enum": {
      return (
        <Select
          intercomTarget={"filter-row-parameter-input"}
          isDisabled={editingDisabled}
          isMulti={true}
          options={props.options}
          value={props.options.filter((item) => {
            return (props.rowValues.parameter as Array<string | number>).includes(item.value);
          })}
          onChange={props.onChange}
          colorVariant={props.child ? InputColorVaraint.primary : InputColorVaraint.accent1}
          sizeVariant={InputSizeVariant.small}
        />
      );
    }
    case "date":
      return (
        <SystemVariableInputWrapper
          variable={props.rowValues.systemVariable}
          handleVariableChange={props.handleVariableChange}
          inputDataType={systemVariableDataType.date}
          editingDisabled={editingDisabled || false}
          disableVariableInput={!props.allowVariableInput}
        >
          <Input
            intercomTarget={"filter-row-parameter-input"}
            disabled={editingDisabled}
            type="datetime-local"
            sizeVariant={InputSizeVariant.small}
            colorVariant={props.child ? InputColorVaraint.primary : InputColorVaraint.accent1}
            value={props.rowValues.parameter as string}
            onChange={props.onChange}
          />
        </SystemVariableInputWrapper>
      );
    default:
      return (
        <SystemVariableInputWrapper
          variable={props.rowValues.systemVariable}
          handleVariableChange={props.handleVariableChange}
          inputDataType={systemVariableDataType.text}
          editingDisabled={editingDisabled || false}
          disableVariableInput={!props.allowVariableInput}
        >
          <Input
            intercomTarget={"filter-row-parameter-input"}
            disabled={editingDisabled}
            type="text"
            sizeVariant={InputSizeVariant.small}
            colorVariant={props.child ? InputColorVaraint.primary : InputColorVaraint.accent1}
            value={props.rowValues.parameter as keyof FilterParameterType}
            onChange={props.onChange}
          />
        </SystemVariableInputWrapper>
      );
  }
}
