// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go


import { ColumnMetaData } from "features/table/types";
import { ExpandedTag } from "pages/tags/tagsTypes";

export const AllTagsColumns: ColumnMetaData<ExpandedTag>[] = [
	{
		heading: "Name",
		type: "TextCell",
		key: "name",
		valueType: "string",
		suffix: "",
		locked: true,
		supportedBy: [0, 1],
	},
	{
		heading: "Category",
		type: "TextCell",
		key: "categoryId",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Applied to",
		type: "NumericCell",
		key: "channels",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Delete",
		type: "EditCell",
		key: "delete",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Edit",
		type: "EditCell",
		key: "edit",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const TagsSortableColumns: Array<keyof ExpandedTag> = [
	"name",
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const TagsFilterableColumns: Array<keyof ExpandedTag> = [
	"name",
];