import { useParams } from "react-router";
import styles from "pages/workflowRunLogDetail/workflowRunLogDetailPage.module.scss";
import useTranslations from "services/i18n/useTranslations";
import { useGetWorkflowRunLogDetailQuery } from "pages/WorkflowPage/workflowApi";
import {
  WorkflowRunLogDetailAction,
  WorkflowRunLogDetailActionDag,
  WorkflowRunLogDetailResponse,
} from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import { useAppSelector } from "store/hooks";
import { selectActiveNetwork } from "features/network/networkSlice";
import DetailsPageTemplate from "features/templates/detailsPageTemplate/DetailsPageTemplate";
import LogDetailsRenderer from "components/workflow/LogDetails/LogDetailsRenderer";
import LogCanvas from "components/workflow/logCanvas/logCanvas";
import { useD3 } from "features/charts/useD3";
import { Selection } from "d3";
import { useEffect, useRef, useState } from "react";
import { actions, dataSources, filters, triggers } from "components/workflow/logCanvas/logCanvasIcons";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";
import { LogStageSelector } from "components/workflow/stages/WorkflowStageSelector";

// returns action, trigger or filter
function getWorkflowNodeStyle(type: WorkflowNodeType) {
  if (actions.includes(type)) {
    return styles.action;
  } else if (triggers.includes(type)) {
    return styles.trigger;
  } else if (filters.includes(type)) {
    return styles.filter;
  } else if (dataSources.includes(type)) {
    return styles.dataSource;
  }
  return styles.unknown;
}

function WorkflowRunLogDetailPage() {
  const { t } = useTranslations();
  const activeNetwork = useAppSelector(selectActiveNetwork);
  const labelRef = useRef<HTMLDivElement>(null);

  const { workflowLogId } = useParams();

  const { data } = useGetWorkflowRunLogDetailQuery<{
    data: WorkflowRunLogDetailResponse;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
  }>({ workflowLogId: parseInt(workflowLogId ?? "0"), network: activeNetwork });

  const [stages, setStages] = useState<number[]>([]);
  const [selectedStage, setSelectedStage] = useState<number>();

  const [rows, setRows] = useState<WorkflowRunLogDetailAction[]>([]);
  const [ancestors, setAncestors] = useState<number[]>([]);
  const [canvas, setCanvas] = useState<LogCanvas | null>(null);

  function handleNodeClick(selectedNode: number, ancestors: number[]) {
    setAncestors(ancestors);
  }

  function handleResetSelection() {
    setAncestors([]);
    canvas?.resetSelection();
  }

  function handleNodeHover(rows: WorkflowRunLogDetailActionDag[], selectedNode: number, top?: number, left?: number) {
    // const canvasBB = ref?.current?.getBoundingClientRect();
    console.log(ref?.current?.offsetLeft);
    labelRef.current?.setAttribute(
      "style",
      `position: absolute; top: ${top ? (top || 0) + (ref?.current?.offsetTop || 0) + 4 : -1000}px; left: ${
        (left || 0) + (ref?.current?.offsetLeft || 0)
      }px; z-index: 1000;`,
    );
    if (labelRef?.current) {
      const row = rows.find((row) => row.id === selectedNode.toString());
      labelRef.current.innerText = row?.name || "";
      labelRef.current.className = `${styles.label} ${getWorkflowNodeStyle(row?.type || 0)}`;
    }
  }

  function handleStageSwitch(stage: number) {
    setSelectedStage(stage);
    setAncestors([]);
  }

  useEffect(() => {
    const stages = data ? Object.keys(data?.detail).map((stage) => parseInt(stage)) : [];
    setStages(stages);
    handleStageSwitch(stages?.length ? stages[0] : 0);
  }, [data?.detail]);

  useEffect(() => {
    const rows: WorkflowRunLogDetailAction[] = [];
    if (!selectedStage) return;
    if (data && data?.detail?.[selectedStage] && data?.detail?.[selectedStage].iterations) {
      for (const iteration in data?.detail[selectedStage].iterations) {
        for (const workflowNodeId in data?.detail[selectedStage].iterations[iteration]) {
          const row = data?.detail[selectedStage].iterations[iteration][workflowNodeId];
          if (row.parentIds?.length === 0) {
            row.parentIds = data?.detail[selectedStage].trigger.parentIds;
          }
          rows.push(data?.detail[selectedStage].iterations[iteration][workflowNodeId]);
        }
      }
    }
    setRows(rows);
  }, [data?.detail, selectedStage]);

  useEffect(() => {
    function navUpdater() {
      // wait for nav to hide
      setTimeout(() => {
        canvas?.resizeChart();
      }, 251);
    }
    window.addEventListener("navHidden", navUpdater);
    return () => {
      window.removeEventListener("navHidden", navUpdater);
    };
  }, [canvas]);

  const ref = useD3(
    (container: Selection<HTMLDivElement, Record<string, never>, HTMLElement, unknown>) => {
      if (!data?.detail) return;
      const canvas = new LogCanvas(container, data?.detail, {
        onClick: handleNodeClick,
        onHover: handleNodeHover,
        stage: selectedStage,
      });
      canvas.draw();
      setCanvas(canvas);
    },
    [data, selectedStage],
  );

  return (
    <DetailsPageTemplate
      breadcrumbs={[t.automation, t.Logs, workflowLogId]}
      title={`${data?.workflow?.name}`}
      pageClassName={styles.workflowRunDetailPageWrapper}
      contentClassName={styles.workflowRunDetailPageContent}
    >
      <>
        <div className={styles.detailsContentWrapper}>
          <div className={styles.detailsTreeWrapper}>
            <div className={styles.detailsTreeContainer} onClick={handleResetSelection}>
              {selectedStage && stages.length > 1 && (
                <LogStageSelector
                  stageNumbers={stages}
                  selectedStage={selectedStage}
                  setSelectedStage={setSelectedStage}
                />
              )}
              <div ref={ref} onClick={(e) => e.stopPropagation()} />
              <div
                ref={labelRef}
                className={styles.label}
                style={{ position: "absolute", top: "-1000px", left: "-1000px" }}
              />
            </div>
          </div>
          <div className={styles.detailsListWrapper}>
            {selectedStage && <LogDetailsRenderer {...data?.detail?.[selectedStage].trigger} />}
            {rows
              .filter((row) => ancestors?.includes(row.id) || !ancestors.length)
              .map((row) => {
                return <LogDetailsRenderer key={"lod-detail-row-" + row.id} {...row} />;
              })}
          </div>
        </div>
      </>
    </DetailsPageTemplate>
  );
}

export default WorkflowRunLogDetailPage;
