import { Merge20Regular as JoinIcon } from "@fluentui/react-icons";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import Socket from "components/forms/socket/Socket";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import {SelectWorkflowNodeLinks, SelectWorkflowNodes, useUpdateNodeMutation} from "pages/WorkflowPage/workflowApi";
import { useSelector } from "react-redux";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import React, {useContext, useEffect, useState} from "react";
import {Form, InputSizeVariant, RadioChips} from "components/forms/forms";
import Note, {NoteType} from "features/note/Note";
import useTranslations from "services/i18n/useTranslations";
import { toastCategory } from "features/toast/Toasts";
import ToastContext from "features/toast/context";
import Button, {ColorVariant, SizeVariant} from "components/buttons/Button";
import { Save16Regular as SaveIcon } from "@fluentui/react-icons";
import Spinny from "features/spinny/Spinny";

type HtlcChannelJoinNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export type HtlcChannelJoinParameters = {
  andOperation: boolean;
};

export function HtlcChannelJoinNode({ ...wrapperProps }: HtlcChannelJoinNodeProps) {
  const { t } = useTranslations();
  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;
  const toastRef = React.useContext(ToastContext);

  const [updateNode] = useUpdateNodeMutation();

  const [configuration, setConfiguration] = useState<HtlcChannelJoinParameters>({
    andOperation: false,
    ...wrapperProps.parameters,
  });

  const { childLinks } = useSelector(
    SelectWorkflowNodeLinks({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeId: wrapperProps.workflowVersionNodeId,
      stage: wrapperProps.stage,
    })
  );

  const inputs1 =
    childLinks
      ?.filter((n) => {
        return n.childInput === "input1";
      })
      ?.map((link) => link.parentWorkflowVersionNodeId) ?? [];

  const input1 = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: inputs1,
    })
  );

  const inputs2 =
    childLinks
      ?.filter((n) => {
        return n.childInput === "input2";
      })
      ?.map((link) => link.parentWorkflowVersionNodeId) ?? [];

  const input2 = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: inputs2,
    })
  );

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    setDirty(
      JSON.stringify(wrapperProps.parameters, Object.keys(wrapperProps.parameters).sort()) !==
      JSON.stringify(configuration, Object.keys(configuration).sort())
    );
  }, [configuration, wrapperProps.parameters]);

  function setAndOperation(andOperation: boolean) {
    return setConfiguration((prev) => ({
      ...prev,
      andOperation: andOperation,
    }));
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }
    setProcessing(true);
    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: configuration,
    }).finally(() => {
      setProcessing(false);
    });
  }

  return (
    <WorkflowNodeWrapper
      {...wrapperProps}
      headerIcon={<JoinIcon />}
      colorVariant={NodeColorVariant.accent1}
      outputName={"channels"}
    >
      <Form onSubmit={handleSubmit} intercomTarget={"htlc-channel-join-node"}>
        <Socket
          collapsed={wrapperProps.visibilitySettings.collapsed}
          selectedNodes={input1 || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          inputName={"input1"}
          editingDisabled={editingDisabled}
        />
        <Socket
          collapsed={wrapperProps.visibilitySettings.collapsed}
          selectedNodes={input2 || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          inputName={"input2"}
          editingDisabled={editingDisabled}
        />
        <RadioChips
          label={t.workflowNodes.joinOperation}
          sizeVariant={InputSizeVariant.small}
          vertical={true}
          groupName={"htlc-channel-join-switch-" + wrapperProps.workflowVersionNodeId}
          options={[
            {
              label: t.workflowNodes.andOperation,
              id: "htlc-channel-join-switch-and-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.andOperation,
              onChange: () => setAndOperation(true),
            },
            {
              label: t.workflowNodes.orOperation,
              id: "htlc-channel-join-switch-or-" + wrapperProps.workflowVersionNodeId,
              checked: !configuration.andOperation,
              onChange: () => setAndOperation(false),
            },
          ]}
          editingDisabled={editingDisabled}
        />
        <Button
          intercomTarget={"htlc-channel-join-node-save"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
        <Note title={t.note} noteType={NoteType.info}>
          <p>{t.workflowNodes.htlcChannelJoinDescription}</p>
        </Note>
      </Form>
    </WorkflowNodeWrapper>
  );
}
