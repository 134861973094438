import React, { useContext, useEffect, useState } from "react";
import {
  ArrowExit20Regular as ChannelOpenInterceptionEventFilterIcon,
  Save16Regular as SaveIcon,
} from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import Form from "components/forms/form/Form";
import Socket from "components/forms/socket/Socket";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import { SelectWorkflowNodeLinks, SelectWorkflowNodes, useUpdateNodeMutation } from "pages/WorkflowPage/workflowApi";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { useSelector } from "react-redux";
import FilterComponent from "features/sidebar/sections/filter/FilterComponent";
import { AndClause, deserialiseQuery, FilterInterface, OrClause } from "features/sidebar/sections/filter/filter";
import Spinny from "features/spinny/Spinny";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import { toastCategory } from "features/toast/Toasts";
import ToastContext from "features/toast/context";
import { ColumnMetaData } from "features/table/types";
import { Implementation } from "types/api";
import { ChannelOpenInterception } from "features/channelOpenInterception/channelOpenInterceptionTypes";

type FilterEventsNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export type ChannelOpenInterceptionEventFilterConfiguration = {
  filterClauses: AndClause | OrClause;
};

export const EventsFilterTemplate: FilterInterface = {
  funcName: "like",
  category: "string",
  parameter: "",
  key: "publicKey",
  supportedBy: [Implementation.LND, Implementation.CLN],
};

export const CommitmentTypeLabels = new Map<string, string>([
  ["0", "Unknown"],
  ["1", "Legacy"],
  ["2", "Static Remote Key"],
  ["3", "Anchors"],
  ["4", "Script Enforced Lease"],
  ["5", "Simple Taproot"],
]);

export const ChannelOpenInterceptionFilterColumns: ColumnMetaData<ChannelOpenInterception>[] = [
  {
    heading: "Peer Public Key",
    type: "LongTextCell",
    key: "publicKey",
    valueType: "string",
    suffix: "",
    supportedBy: [Implementation.LND, Implementation.CLN],
  },
  {
    heading: "Public Key",
    type: "LongTextCell",
    key: "peerPublicKey",
    valueType: "string",
    suffix: "",
    supportedBy: [Implementation.LND, Implementation.CLN],
  },
  {
    heading: "Funding Amount (Sat)",
    type: "BarCell",
    key: "fundingAmountSat",
    valueType: "number",
    supportedBy: [Implementation.LND, Implementation.CLN],
  },
  {
    heading: "Push Amount (Msat)",
    type: "BarCell",
    key: "pushAmountMsat",
    valueType: "number",
    supportedBy: [Implementation.LND, Implementation.CLN],
  },
  {
    heading: "Dust Limit (Sat)",
    type: "BarCell",
    key: "dustLimitSat",
    valueType: "number",
    supportedBy: [Implementation.LND, Implementation.CLN],
  },
  {
    heading: "Max Value In Flight (Msat)",
    type: "BarCell",
    key: "maxValueInFlightMsat",
    valueType: "number",
    supportedBy: [Implementation.LND, Implementation.CLN],
  },
  {
    heading: "Channel Reserve (Sat)",
    type: "BarCell",
    key: "channelReserveSat",
    valueType: "number",
    supportedBy: [Implementation.LND, Implementation.CLN],
  },
  {
    heading: "Fee Per Kw",
    type: "BarCell",
    key: "feePerKw",
    valueType: "number",
    supportedBy: [Implementation.LND, Implementation.CLN],
  },
  {
    heading: "CSV Delay",
    type: "BarCell",
    key: "csvDelay",
    valueType: "number",
    supportedBy: [Implementation.LND, Implementation.CLN],
  },
  {
    heading: "Max Accepted Htlcs",
    type: "BarCell",
    key: "maxAcceptedHtlcs",
    valueType: "number",
    supportedBy: [Implementation.LND, Implementation.CLN],
  },
  {
    heading: "Commitment Type",
    type: "TextCell",
    key: "commitmentType",
    valueType: "enum",
    selectOptions: [
      { label: CommitmentTypeLabels.get("0") || "", value: "0" },
      { label: CommitmentTypeLabels.get("1") || "", value: "1" },
      { label: CommitmentTypeLabels.get("2") || "", value: "2" },
      { label: CommitmentTypeLabels.get("3") || "", value: "3" },
      { label: CommitmentTypeLabels.get("4") || "", value: "4" },
      { label: CommitmentTypeLabels.get("5") || "", value: "5" },
    ],
    supportedBy: [Implementation.LND],
  },
  {
    heading: "Wants Zero Conf",
    type: "BooleanCell",
    key: "wantsZeroConf",
    valueType: "boolean",
    supportedBy: [Implementation.LND],
  },
  {
    heading: "Wants Scid Alias",
    type: "BooleanCell",
    key: "wantsScidAlias",
    valueType: "boolean",
    supportedBy: [Implementation.LND],
  },
];

export function ChannelOpenInterceptionEventFilterNode({ ...wrapperProps }: FilterEventsNodeProps) {
  const { t } = useTranslations();
  const toastRef = React.useContext(ToastContext);
  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;

  const [updateNode] = useUpdateNodeMutation();

  const [configuration, setConfiguration] = useState<ChannelOpenInterceptionEventFilterConfiguration>({
    filterClauses: deserialiseQuery(wrapperProps.parameters.filterClauses || { $and: [] }) as AndClause | OrClause,
  });

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    if (
      Array.from(JSON.stringify(wrapperProps.parameters)).sort().join("") !==
      Array.from(JSON.stringify(configuration)).sort().join("")
    ) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [configuration, wrapperProps.parameters]);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }

    setProcessing(true);
    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: configuration,
    }).finally(() => {
      setProcessing(false);
    });
  }

  const { childLinks } = useSelector(
    SelectWorkflowNodeLinks({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeId: wrapperProps.workflowVersionNodeId,
      stage: wrapperProps.stage,
    }),
  );

  const channelIds =
    childLinks
      ?.filter((n) => {
        return n.childInput === "channelOpenInterceptionEvent";
      })
      ?.map((link) => link.parentWorkflowVersionNodeId) ?? [];

  const channels = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: channelIds,
    }),
  );

  const handleFilterUpdate = (filter: AndClause | OrClause) => {
    setConfiguration((prev) => ({
      ...prev,
      filterClauses: filter,
    }));
  };

  return (
    <WorkflowNodeWrapper
      {...wrapperProps}
      headerIcon={<ChannelOpenInterceptionEventFilterIcon />}
      colorVariant={NodeColorVariant.accent1}
      outputName={"channelOpenInterceptionEvent"}
    >
      <Form onSubmit={handleSubmit} intercomTarget={"channel-open-interception-event-filter-node-form"}>
        <Socket
          collapsed={wrapperProps.visibilitySettings.collapsed}
          label={t.channelOpenInterceptionEventInput}
          selectedNodes={channels || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          inputName={"channelOpenInterceptionEvent"}
          editingDisabled={editingDisabled}
        />
        {configuration.filterClauses !== undefined && (
          <FilterComponent
            filters={configuration.filterClauses}
            columns={ChannelOpenInterceptionFilterColumns}
            defaultFilter={EventsFilterTemplate}
            child={false}
            onFilterUpdate={handleFilterUpdate}
            editingDisabled={editingDisabled}
          />
        )}
        <Button
          intercomTarget={"workflow-node-save"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing || editingDisabled}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
      </Form>
    </WorkflowNodeWrapper>
  );
}
