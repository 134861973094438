import { QuestionCircle20Regular as GenericIcon } from "@fluentui/react-icons";
import { WorkflowRunLogDetailAction } from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";
import {
  RebalanceAutoRunLog,
  RebalanceConfiguratorLog,
  RebalanceRunLog,
} from "components/workflow/LogDetails/RebalanceLog/RebalanceLog";
import { AddTagLog, RemoveTagLog } from "components/workflow/LogDetails/TagLog/TagLog";
import {
  ChannelPolicyAutoRunLog,
  ChannelPolicyConfiguratorLog,
  ChannelPolicyRunLog,
} from "components/workflow/LogDetails/ChannelPolicyLog/ChannelPolicyLog";
import { GenericLog } from "components/workflow/LogDetails/GenericLog/GenericLog";
import {
  BalanceChangeTriggerLogs,
  ChannelCloseTriggerLogs,
  ChannelOpenTriggerLogs,
  CronTriggerLogs,
  ForwardTriggerLog,
  HtlcInterceptionTriggerLog,
  IntervalTriggerLogs,
  InvoiceTriggerLog,
  ManualDryRunTriggerLogs,
  ManualTriggerLogs,
  OnChainTransactionTriggerLog,
  PaymentTriggerLog,
  ChannelOpenInterceptionTriggerLog,
} from "components/workflow/LogDetails/TriggerLogs/TriggerLogs";
import { OpenChannelLog } from "components/workflow/LogDetails/OpenChannelLog/OpenChannelLog";
import { CloseChannelLog } from "components/workflow/LogDetails/CloseChannelLog/CloseChannelLog";
import { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import { SystemOperationLog } from "components/workflow/LogDetails/SystemOperationLog/SystemOperationLog";
import {
  ChannelBalanceEventFilterLog,
  ExchangeFilterLog,
  FilterLog,
  MempoolFilterLog,
} from "components/workflow/LogDetails/FilterLog/FilterLog";
import {
  ChannelDataSourceLog,
  HtlcEventDataSourceLog,
  ChannelOpenInterceptionEventDataSourceLog,
  OnChainTransactionDataSourceLog,
} from "components/workflow/LogDetails/DataSourceLogs/DataSourceLogs";
import { HtlcChannelJoin, HtlcChannelSplitLog } from "components/workflow/LogDetails/HtlcChannelLogs/HtlcChannelLogs";
import HtlcInterceptionBlockerLog from "components/workflow/LogDetails/HtlcInterceptionBlockerLog/HtlcInterceptionBlockerLog";
import HtlcInterceptionEventFilterLog from "components/workflow/LogDetails/HtlcInterceptionEventFilterLog/HtlcInterceptionEventFilterLog";
import { NotificationLog } from "components/workflow/LogDetails/NotificationLog/NotificationLog";
import { ApiClientLog } from "components/workflow/LogDetails/ApiClientLog/ApiClientLog";
import { PaymentAutoRunLog } from "components/workflow/LogDetails/PaymentLog/PaymentLog";
import ChannelOpenInterceptionEventFilterLog from "components/workflow/LogDetails/ChannelOpenInterceptionEventFilterLog/ChannelOpenInterceptionEventFilterLog";
import ChannelOpenInterceptionResultLog from "components/workflow/LogDetails/ChannelOpenInterceptionResultLog/ChannelOpenInterceptionResultLog";
import ScorechainLog from "components/workflow/LogDetails/ScorechainLog/ScorechainLog";

export default function LogDetailsRenderer(props: WorkflowRunLogDetailAction) {
  switch (props.type) {
    case WorkflowNodeType.IntervalTrigger:
      return <IntervalTriggerLogs {...props} />;
    case WorkflowNodeType.ChannelBalanceEventTrigger:
      return <BalanceChangeTriggerLogs {...props} />;
    case WorkflowNodeType.CronTrigger:
      return <CronTriggerLogs {...props} />;
    case WorkflowNodeType.ManualTrigger:
      return <ManualTriggerLogs {...props} />;
    case WorkflowNodeType.ManualDryRunTrigger:
      return <ManualDryRunTriggerLogs {...props} />;
    case WorkflowNodeType.ChannelOpenEventTrigger:
      return <ChannelOpenTriggerLogs {...props} />;
    case WorkflowNodeType.ChannelCloseEventTrigger:
      return <ChannelCloseTriggerLogs {...props} />;
    case WorkflowNodeType.DataSourceTorqChannels:
      return <ChannelDataSourceLog {...props} />;
    case WorkflowNodeType.ChannelFilter:
      return <FilterLog {...props} />;
    case WorkflowNodeType.RebalanceAutoRun:
      return <RebalanceAutoRunLog {...props} />;
    case WorkflowNodeType.RebalanceConfigurator:
      return <RebalanceConfiguratorLog {...props} />;
    case WorkflowNodeType.RebalanceRun:
      return <RebalanceRunLog {...props} />;
    case WorkflowNodeType.AddTag:
      return <AddTagLog {...props} />;
    case WorkflowNodeType.RemoveTag:
      return <RemoveTagLog {...props} />;
    case WorkflowNodeType.RoutingPolicyAutoRun:
      return <ChannelPolicyAutoRunLog {...props} />;
    case WorkflowNodeType.RoutingPolicyConfigurator:
      return <ChannelPolicyConfiguratorLog {...props} />;
    case WorkflowNodeType.RoutingPolicyRun:
      return <ChannelPolicyRunLog {...props} />;
    case WorkflowNodeType.OpenChannels:
      return <OpenChannelLog {...props} />;
    case WorkflowNodeType.CloseChannels:
      return <CloseChannelLog {...props} />;
    case WorkflowNodeType.ChannelBalanceEventFilter:
      return <ChannelBalanceEventFilterLog {...props} />;
    case WorkflowNodeType.SystemOperation:
      return <SystemOperationLog {...props} />;
    case WorkflowNodeType.HtlcInterceptionTrigger:
      return <HtlcInterceptionTriggerLog {...props} />;
    case WorkflowNodeType.DataSourceHtlcEvent:
      return <HtlcEventDataSourceLog {...props} />;
    case WorkflowNodeType.HtlcInterceptionEventFilter:
      return <HtlcInterceptionEventFilterLog {...props} />;
    case WorkflowNodeType.HtlcInterceptionBlocker:
      return <HtlcInterceptionBlockerLog {...props} />;
    case WorkflowNodeType.HtlcChannelJoin:
      return <HtlcChannelJoin {...props} />;
    case WorkflowNodeType.HtlcChannelSplit:
      return <HtlcChannelSplitLog {...props} />;
    case WorkflowNodeType.Notification:
      return <NotificationLog {...props} />;
    case WorkflowNodeType.ApiClient:
      return <ApiClientLog {...props} />;
    case WorkflowNodeType.ForwardTrigger:
      return <ForwardTriggerLog {...props} />;
    case WorkflowNodeType.InvoiceTrigger:
      return <InvoiceTriggerLog {...props} />;
    case WorkflowNodeType.PaymentTrigger:
      return <PaymentTriggerLog {...props} />;
    case WorkflowNodeType.OnChainTransactionTrigger:
      return <OnChainTransactionTriggerLog {...props} />;
    case WorkflowNodeType.ExchangeFilter:
      return <ExchangeFilterLog {...props} />;
    case WorkflowNodeType.MempoolFilter:
      return <MempoolFilterLog {...props} />;
    case WorkflowNodeType.PaymentAutoRun:
      return <PaymentAutoRunLog {...props} />;
    case WorkflowNodeType.ChannelOpenInterceptionTrigger:
      return <ChannelOpenInterceptionTriggerLog {...props} />;
    case WorkflowNodeType.DataSourceChannelOpenInterceptionEvent:
      return <ChannelOpenInterceptionEventDataSourceLog {...props} />;
    case WorkflowNodeType.ChannelOpenInterceptionEventFilter:
      return <ChannelOpenInterceptionEventFilterLog {...props} />;
    case WorkflowNodeType.ChannelOpenInterceptionResult:
      return <ChannelOpenInterceptionResultLog {...props} />;
    case WorkflowNodeType.DataSourceOnChainTransaction:
      return <OnChainTransactionDataSourceLog {...props} />;
    case WorkflowNodeType.Scorechain:
      return <ScorechainLog {...props} />;
    default:
      console.log("type not implemented: " + props.type?.toString());
      return <GenericLog {...props} icon={<GenericIcon />} actionType={LogDetailType.default} />;
  }
}
