// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go


import { ColumnMetaData } from "features/table/types";
import { WorkflowRunLogDetailAction } from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";

export const AllWorkflowRunLogDetailColumns: ColumnMetaData<WorkflowRunLogDetailAction>[] = [
	{
		heading: "Actions",
		type: "TextCell",
		key: "actions",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Changed On",
		type: "DateCell",
		key: "changedOn",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Errors",
		type: "TextCell",
		key: "errors",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node ID",
		type: "NumericCell",
		key: "id",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Iteration",
		type: "NumericCell",
		key: "iteration",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Name",
		type: "TextCell",
		key: "name",
		valueType: "string",
		suffix: "",
		locked: true,
		supportedBy: [0, 1],
	},
	{
		heading: "Parameters",
		type: "TextCell",
		key: "parameters",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Parent ID(s)",
		type: "TextCell",
		key: "parentIds",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Stage",
		type: "NumericCell",
		key: "stage",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node Type",
		type: "NumericCell",
		key: "type",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const WorkflowRunLogDetailSortableColumns: Array<keyof WorkflowRunLogDetailAction> = [
	"changedOn",
	"id",
	"iteration",
	"name",
	"stage",
	"type",
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const WorkflowRunLogDetailFilterableColumns: Array<keyof WorkflowRunLogDetailAction> = [
	"changedOn",
	"id",
	"iteration",
	"name",
	"stage",
	"type",
];