import classNames from "classnames";
import { memo } from "react";
import styles from "components/table/cells/cell.module.scss";
import useTranslations from "services/i18n/useTranslations";

interface ChannelRequestErrorCellProps {
  // clientMode: boolean;
  errorMessage?: string;
  color?: string;
  className?: string;
}

function ChannelRequestErrorCell(props: ChannelRequestErrorCellProps) {
  const { t } = useTranslations();
  // const { track } = userEvents();
  //
  // const retryButton = (
  //   <>
  //     <div className={styles.actionButtons}>
  //       {props.clientMode && (
  //         <Button
  //           intercomTarget={"refresh-pull-channel-navigate"}
  //           key={"buttons-node-refresh-pull"}
  //           icon={<RefreshIcon />}
  //           hideMobileText={true}
  //           buttonSize={SizeVariant.tiny}
  //           buttonColor={ColorVariant.accent1}
  //           onClick={() => {
  //             track("Retry Channel Request", {
  //               orderId: props.orderId,
  //             });
  //           }}
  //         >
  //           {t.refresh}
  //         </Button>
  //       )}
  //     </div>
  //   </>
  // );

  try {
    const parsedErrorResponse = JSON.parse(props.errorMessage || "");
    if (parsedErrorResponse.error.code === -32700) {
      return (
        <div className={classNames(styles.cell, styles.alignLeft, props.className)}>
          <div className={classNames(styles.content, styles.nodeColourCellWrapper)}>{t.errors.parse}</div>
        </div>
      );
    }
    if (parsedErrorResponse.error.code === -32600) {
      return (
        <div className={classNames(styles.cell, styles.alignLeft, props.className)}>
          <div className={classNames(styles.content, styles.nodeColourCellWrapper)}>
            {t.errors.invalidRequest} ({parsedErrorResponse.error.data})
          </div>
        </div>
      );
    }
    if (parsedErrorResponse.error.code === -32601) {
      return (
        <div className={classNames(styles.cell, styles.alignLeft, props.className)}>
          <div className={classNames(styles.content, styles.nodeColourCellWrapper)}>
            {t.errors.methodNotFound} ({parsedErrorResponse.error.data})
          </div>
        </div>
      );
    }
    if (parsedErrorResponse.error.code === -32602) {
      return (
        <div className={classNames(styles.cell, styles.alignLeft, props.className)}>
          <div className={classNames(styles.content, styles.nodeColourCellWrapper)}>
            {t.errors.invalidParams} ({parsedErrorResponse.error.data})
          </div>
        </div>
      );
    }
    if (parsedErrorResponse.error.code === -32603) {
      return (
        <div className={classNames(styles.cell, styles.alignLeft, props.className)}>
          <div className={classNames(styles.content, styles.nodeColourCellWrapper)}>
            {t.errors.internal} ({parsedErrorResponse.error.data})
          </div>
        </div>
      );
    }
  } catch (error) {
    return (
      <div className={classNames(styles.cell, styles.alignLeft, props.className)}>
        <div className={classNames(styles.content, styles.nodeColourCellWrapper)}>{t.Error}</div>
      </div>
    );
  }
}
const ChannelRequestErrorCellMemo = memo(ChannelRequestErrorCell);
export default ChannelRequestErrorCellMemo;
