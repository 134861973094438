import cellStyles from "components/table/cells/cell.module.scss";
import { ColumnMetaData } from "features/table/types";
import { workflowListItem } from "pages/WorkflowPage/workflowTypes";
import DefaultCellRenderer from "features/table/DefaultCellRenderer";
import WorkflowCell from "components/table/cells/workflow/WorkflowCell";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { DocumentFlowchartRegular as CaptureLogsIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import { userEvents } from "utils/userEvents";
import { useUpdateWorkflowMutation } from "pages/WorkflowPage/workflowApi";
import classNames from "classnames";
import styles from "components/table/cells/workflow/workflow_cell.module.scss";
import { Input, InputSizeVariant } from "components/forms/forms";
import { NumberFormatValues } from "react-number-format";
import { ChangeEvent, useEffect, useState } from "react";

export default function workflowCellRenderer(
  row: workflowListItem,
  rowIndex: number,
  column: ColumnMetaData<workflowListItem>,
  columnIndex: number,
  isTotalsRow?: boolean,
  maxRow?: workflowListItem,
): JSX.Element {
  const { t } = useTranslations();
  const { track } = userEvents();
  const [updateWorkflow] = useUpdateWorkflowMutation();

  const [workflowLogRetentionDays, setWorkflowLogRetentionDays] = useState<number | undefined>(undefined);

  useEffect(() => {
    setWorkflowLogRetentionDays(row.workflowLogRetentionDays === -1 ? undefined : row.workflowLogRetentionDays);
  }, [row]);

  function toggleWorkflowCaptureLogs() {
    track("Workflow Capture Logs", {
      workflowId: row.workflowId,
      workflowVersionId: row.latestVersion,
      workflowName: row.workflowName,
    });
    updateWorkflow({ workflowId: row.workflowId, captureLogs: !row.workflowCaptureLogs });
  }

  function createLogRetentionHandler() {
    return (e: NumberFormatValues) => {
      track("Workflow Log Retention", {
        workflowId: row.workflowId,
        workflowVersionId: row.latestVersion,
        workflowName: row.workflowName,
      });
      let days = e.floatValue;
      setWorkflowLogRetentionDays(days);
      if (days === undefined) {
        days = -1;
      }
      updateWorkflow({ workflowId: row.workflowId, logRetentionDays: days });
    };
  }

  function createAPIAliasHandler() {
    return (event: ChangeEvent<HTMLInputElement>) => {
      track("Workflow API Alias", {
        workflowId: row.workflowId,
        workflowVersionId: row.latestVersion,
        workflowName: row.workflowName,
      });
      updateWorkflow({ workflowId: row.workflowId, apiAlias: event.target.value });
    };
  }

  if (column.key === "workflowName") {
    return (
      <WorkflowCell
        name={row.workflowName}
        workflowId={row.workflowId}
        workflowVersionId={row.latestVersion}
        captureLogs={row.workflowCaptureLogs}
        className={cellStyles.locked}
        key={"workflow-" + row.workflowId + "-" + rowIndex}
      />
    );
  }
  if (column.key === "workflowCaptureLogs") {
    return (
      <div className={classNames(cellStyles.cell, cellStyles.alignLeft, styles.channelCellWrapper)}>
        <div className={styles.workflowCellContent}>
          <Button
            key={"workflow-capture-logs-" + row.workflowId + "-" + rowIndex}
            intercomTarget={"workflow-cell-capture-logs-workflow-button"}
            icon={<CaptureLogsIcon />}
            buttonSize={SizeVariant.small}
            buttonColor={row.workflowCaptureLogs ? ColorVariant.success : ColorVariant.warning}
            onClick={toggleWorkflowCaptureLogs}
          >
            {row.workflowCaptureLogs ? t.loggingActive : t.loggingInactive}
          </Button>
        </div>
      </div>
    );
  }
  if (column.key === "workflowLogRetentionDays") {
    return (
      <div className={classNames(cellStyles.cell, cellStyles.alignLeft, styles.channelCellWrapper)}>
        <div className={styles.workflowCellContent}>
          <Input
            key={"workflow-log-retention-" + row.workflowId + "-" + rowIndex}
            intercomTarget={"workflow-cell-log-retention"}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={workflowLogRetentionDays}
            suffix={" days"}
            onValueChange={createLogRetentionHandler()}
          />
        </div>
      </div>
    );
  }
  if (column.key === "workflowApiAlias") {
    return (
      <div className={classNames(cellStyles.cell, cellStyles.alignLeft, styles.channelCellWrapper)}>
        <div className={styles.workflowCellContent}>
          <Input
            key={"workflow-api-alias-" + row.workflowId + "-" + rowIndex}
            intercomTarget={"workflow-cell-API-alias-" + row.workflowId + "-" + rowIndex}
            sizeVariant={InputSizeVariant.small}
            className={styles.single}
            value={row.workflowApiAlias}
            onChange={createAPIAliasHandler()}
          />
        </div>
      </div>
    );
  }

  // Use the defualt
  return DefaultCellRenderer(row, rowIndex, column, columnIndex, false, maxRow);
}
