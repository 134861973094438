import { Tag } from "pages/tags/tagsTypes";
import {
  ActiveNetwork,
  BaseQueryCollectionParams,
  BaseSummaryQueryCollectionParams,
  ChannelPeersOnly,
  Pagination,
  TorqNodeId,
  UptimeDays,
} from "types/api";

export type Peer = {
  torqNodeId: number;
  torqNodeAlias: string;
  torqNodeName: string;
  torqPublicKey: string;
  nodeCssColour: string;
  peerNodeId: number;
  peerNodeAlias: string;
  peerNodePublicKey: string;
  address: string;
  connectionStatus: ConnectionStatus;
  setting: NodeConnectionSetting;
  secondsConnected: number;
  dateLastConnected?: Date;
  secondsDisconnected: number;
  dateLastDisconnected?: Date;
  openChannelCount: number;
  closedChannelCount: number;
  uptime: number;
  tags: Tag[];
};

export type ConnectPeerRequest = {
  nodeId: number;
  connectionString: string;
  network: number;
};

export type ConnectPeerResponse = {
  success: boolean;
};

export type DisconnectPeerRequest = {
  nodeId: number;
  torqNodeId: number;
};

export type DisconnectPeerResponse = {
  success: boolean;
};

export type UpdatePeerRequest = {
  nodeId: number;
  torqNodeId: number;
};

export type UpdatePeerResponse = {
  success: boolean;
};

export enum ConnectionStatus {
  Disconnected = "Disconnected",
  Connected = "Connected",
}

export enum NodeConnectionSetting {
  AlwaysReconnect = "AlwaysReconnect",
  DisableReconnect = "DisableReconnect",
}

// Add NodeConnectionSetting to integer
export const NodeConnectionSettingInt = {
  [NodeConnectionSetting.AlwaysReconnect]: 0,
  [NodeConnectionSetting.DisableReconnect]: 1,
};

export type GetPeersQueryParams = BaseQueryCollectionParams &
  ActiveNetwork &
  TorqNodeId &
  UptimeDays &
  ChannelPeersOnly;

export type GetPeersSummaryQueryParams = BaseSummaryQueryCollectionParams &
  ActiveNetwork &
  TorqNodeId &
  ChannelPeersOnly;

export type PeersResponse = {
  data: Array<Peer>;
  pagination: Pagination;
};
