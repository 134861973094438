import { Options20Regular as OptionsIcon } from "@fluentui/react-icons";
import Button, { ColorVariant, ButtonWrapper } from "components/buttons/Button";
import { useEffect, useState } from "react";
import Input from "components/forms/input/Input";
import { ProgressStepState } from "features/progressTabs/ProgressHeader";
import { ProgressTabContainer } from "features/progressTabs/ProgressTab";
import { SectionContainer } from "features/section/SectionContainer";
import { NumericFormat } from "react-number-format";
import styles from "features/transact/NewPayment/newPayments.module.scss";
import { PaymentType, PaymentTypeLabel } from "features/transact/NewPayment/types";
import { DecodedInvoice } from "types/api";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";
import useTranslations from "services/i18n/useTranslations";
import ChannelSelect, {
  ChannelOpt,
  FilterChannelOptions,
  IsChannelOption,
} from "components/forms/select/channelSelect/ChannelSelect";
import { channel } from "features/channels/channelsTypes";
import { selectActiveNetwork } from "features/network/networkSlice";
import { useGetChannelsQuery } from "apiSlice";
import { useAppSelector } from "store/hooks";

type InvoicePaymentProps = {
  selectedNodeId: number;
  decodedInvoice: DecodedInvoice;
  destinationType: PaymentType;
  destination: string;
  sendJsonMessage: SendJsonMessage;
  // TODO: remove set states in favour of onChange methods
  // This component shouldn't really know anything about it's parent
  setStepIndex: (index: number) => void;
  setDestState: (state: ProgressStepState) => void;
  setConfirmState: (state: ProgressStepState) => void;
  setProcessState: (state: ProgressStepState) => void;
  onAmountChange: (amount: number) => void;
};
const DefaultTimeoutSeconds = 60;

export default function InvoicePayment(props: InvoicePaymentProps) {
  const { t } = useTranslations();
  const [expandAdvancedOptions, setExpandAdvancedOptions] = useState(false);
  const [amountSat, setAmountSat] = useState<number | undefined>(undefined);
  const [feeLimit, setFeeLimit] = useState<number | undefined>(
    Math.floor(props.decodedInvoice.valueMsat / 1000000) || 100,
  );
  const activeNetwork = useAppSelector(selectActiveNetwork);
  const channelsResponse = useGetChannelsQuery<{
    data: Array<channel>;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
  }>({ network: activeNetwork });
  const [timeOutSecs, setTimeOutSecs] = useState(DefaultTimeoutSeconds);
  const [channelOptions, setChannelOptions] = useState<Array<ChannelOpt>>();
  const [selectedChannelId, setSelectedChannelId] = useState<number | undefined>(undefined);
  const [manualChannelSelection, setManualChannelSelection] = useState<boolean>(false);

  useEffect(() => {
    if (channelsResponse?.data) {
      setChannelOptions(FilterChannelOptions(channelsResponse.data, props.selectedNodeId));
    }
  }, [channelsResponse?.data, props.selectedNodeId]);

  useEffect(() => {
    if (!manualChannelSelection) {
      setSelectedChannelId(undefined);
    } else if (
      channelOptions &&
      channelOptions.length >= 1 &&
      !selectedChannelId &&
      !channelOptions.find((c) => c.value === selectedChannelId)
    ) {
      setSelectedChannelId(channelOptions[0].value);
    }
  }, [channelOptions, manualChannelSelection]);

  function lnAmountField() {
    if (props.decodedInvoice.valueMsat !== 0) {
      return props.decodedInvoice.valueMsat / 1000;
    }

    const handleAmountChange = (amount: string) => {
      const amountNumber = parseInt(amount);
      setAmountSat(amountNumber);
      props.onAmountChange(amountNumber);
    };

    return (
      <NumericFormat
        className={styles.amountInput}
        datatype={"number"}
        value={amountSat}
        placeholder={"0 sat"}
        onValueChange={(values) => handleAmountChange(values.value)}
        thousandSeparator=","
        suffix={" sat"}
      />
    );
  }

  return (
    <ProgressTabContainer>
      <div className={styles.amountWrapper}>
        {props.destinationType && (
          <span className={styles.destinationType}>{PaymentTypeLabel[props.destinationType] + " Detected"}</span>
        )}
        <div className={styles.amount}>{lnAmountField()}</div>
        <div className={styles.label}>To</div>
        <div className={styles.destinationPreview}>{props.decodedInvoice.nodeAlias}</div>
      </div>
      <SectionContainer
        title={"Advanced Options"}
        icon={OptionsIcon}
        expanded={expandAdvancedOptions}
        handleToggle={() => setExpandAdvancedOptions(!expandAdvancedOptions)}
      >
        <Input
          label={"Fee limit"}
          type={"number"}
          value={feeLimit}
          onChange={(e) => {
            setFeeLimit(e.target.valueAsNumber);
          }}
        />
        <Input
          label={"Timeout (Seconds)"}
          type={"number"}
          value={timeOutSecs}
          onChange={(e) => setTimeOutSecs(e.target.valueAsNumber)}
        />
        <ChannelSelect
          intercomTarget={"pay-invoice-channel-input"}
          channelOptions={channelOptions}
          selectedChannelId={selectedChannelId}
          allowAutomatic={true}
          manualSelectionSwitch={(manual) => {
            setManualChannelSelection(manual);
          }}
          onChange={(newValue: unknown) => {
            if (IsChannelOption(newValue)) {
              setSelectedChannelId(newValue.value);
            }
          }}
        />
      </SectionContainer>

      <ButtonWrapper
        className={styles.customButtonWrapperStyles}
        leftChildren={
          <Button
            intercomTarget={"payment-back-button"}
            onClick={() => {
              props.setStepIndex(0);
              props.setDestState(ProgressStepState.completed);
              props.setConfirmState(ProgressStepState.active);
            }}
            buttonColor={ColorVariant.primary}
          >
            {"Back"}
          </Button>
        }
        rightChildren={
          <Button
            intercomTarget={"payment-confirm-button"}
            onClick={() => {
              props.sendJsonMessage({
                type: "newPayment",
                NewPaymentRequest: {
                  nodeId: props.selectedNodeId,
                  // If the destination is not a pubkey, use it as an invoice
                  invoice: props.destination,
                  // If the destination is a pubkey send it as a dest input
                  // dest: destination.match(LightningNodePubkeyRegEx) ? destination : undefined,
                  amtMSat: amountSat ? amountSat * 1000 : undefined, // 1 sat = 1000 msat
                  timeOutSecs: timeOutSecs,
                  feeLimitMsat: feeLimit ? feeLimit * 1000 : 1000 * 1000, // 1 sat = 1000 msat
                  allowSelfPayment: true, //allowSelfPayment
                  outgoingChannelId: selectedChannelId,
                },
              });
              props.setStepIndex(2);
              props.setConfirmState(ProgressStepState.completed);
              props.setProcessState(ProgressStepState.processing);
            }}
            buttonColor={ColorVariant.success}
          >
            {t.confirm}
          </Button>
        }
      />
    </ProgressTabContainer>
  );
}
