import { FilterExecution } from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import styles from "components/workflow/LogDetails/FilterExecutionPopup/filterExecutionPopup.module.scss";
import { FilterFunctions } from "features/sidebar/sections/filter/filter";
import classNames from "classnames";
import { ReactElement } from "react";

const ffLabels = new Map<string, string>([
  ["eq", "=="],
  ["neq", "!="],
  ["gt", ">"],
  ["gte", ">="],
  ["lt", "<"],
  ["lte", "<="],
  ["like", "contains"],
  ["notLike", "!contains"],
  ["any", "contains"],
  ["notAny", "!contains"],
]);

function getFilterFunctions(filterCategory: string, key: string) {
  const filterFuncs = FilterFunctions.get(filterCategory);
  if (!filterFuncs) {
    return "???";
  }

  if (filterFuncs.has(key)) {
    return ffLabels.get(key) ?? "???";
  }

  return "???";
}

export default function FilterExectuionPopup(props: {
  executions: FilterExecution[] | undefined;
  children: ReactElement;
  headerName: string;
  rightSide?: boolean;
}) {
  return (
    <div className={styles.hoverTarget}>
      {props.executions && props.executions?.length && (
        <div className={styles.executionHoverWrapper}>
          <div className={classNames(styles.executionHoverContainer, { [styles.rightSide]: props.rightSide })}>
            <div className={styles.executionHoverHeader}>{"Filter execution for: " + props.headerName}</div>
            <div className={styles.executionHoverBody}>
              {props.executions?.map((execution, execIndex) => (
                <div key={"execution-" + execution.systemVariableName + execIndex} className={styles.filterExecution}>
                  <div className={styles.filterExecutionColumn}>
                    <div className={styles.executionParameterName}>{execution.key}</div>
                    <div className={styles.executionValue}>{execution.dataValueString || execution.dataValueFloat}</div>
                  </div>
                  <div className={styles.filterExecutionColumn}>
                    <div>{getFilterFunctions(execution.category, execution.funcName)}</div>
                    <div>{getFilterFunctions(execution.category, execution.funcName)}</div>
                  </div>

                  <div className={styles.filterExecutionColumn}>
                    <div>
                      {execution.systemVariableName || execution.filterValueString || execution.filterValueFloat}
                    </div>
                    <div>{execution.filterValueString || execution.filterValueFloat}</div>
                  </div>

                  <div className={styles.filterExecutionColumn}>
                    <div>=</div>
                    <div>=</div>
                  </div>

                  <div className={styles.filterExecutionColumn}>
                    <br />
                    <div
                      className={classNames(
                        styles.channelItemParameterResult,
                        execution.result ? styles.matched : styles.notMatched,
                      )}
                    >
                      {execution.result ? "true" : "false"}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {props.children}
    </div>
  );
}
