import styles from "components/table/cells/cell.module.scss";
import classNames from "classnames";
import useTranslations from "services/i18n/useTranslations";
import { memo } from "react";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { userEvents } from "utils/userEvents";
import { Add20Regular as TransactionIcon } from "@fluentui/react-icons";
import { NEW_PAYMENT } from "constants/routes";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

interface LightningInvoiceCellProps {
  torqNodeId: number;
  bolt11Invoice?: string;
  className?: string;
}

function LightningInvoiceCell(props: LightningInvoiceCellProps) {
  const { t } = useTranslations();
  const { track } = userEvents();
  const navigate = useNavigate();
  const location = useLocation();

  const content = (
    <>
      <Button
        intercomTarget="pay-ln-invoice"
        buttonColor={ColorVariant.success}
        hideMobileText={true}
        icon={<TransactionIcon />}
        buttonSize={SizeVariant.small}
        onClick={() => {
          track("Navigate to New Payment");
          navigate(NEW_PAYMENT, {
            state: {
              background: location,
              torqNodeIdParameter: props.torqNodeId,
              destinationParameter: props.bolt11Invoice,
            },
          });
        }}
      >
        {t.payInvoice}
      </Button>
    </>
  );

  return (
    <div className={classNames(styles.cell, styles.alignLeft, props.className)}>
      <div className={classNames(styles.content)}>{content}</div>
    </div>
  );
}
const LightningInvoiceCellMemo = memo(LightningInvoiceCell);
export default LightningInvoiceCellMemo;
