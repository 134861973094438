import { torqApi } from "apiSlice";
import {
  ChannelRequest,
  ChannelRequestParameters,
  ChannelRequestResponse,
  ChannelRequestServer,
} from "features/lsp/lspTypes";
import { ActiveNetwork, ActivePageMode, PageMode } from "types/api";
import { queryParamsBuilder } from "utils/queryParamsBuilder";

export const lspApi = torqApi.injectEndpoints({
  endpoints: (builder) => ({
    getChannelRequests: builder.query<ChannelRequest[], ActiveNetwork & ActivePageMode>({
      query: (params) => ({
        url:
          `lsp/channelRequests` +
          (params.pageMode == PageMode.Client ? `/purchases` : "/sales") +
          queryParamsBuilder(params),
        method: "GET",
      }),
      providesTags: ["channelRequests"],
    }),
    getChannelRequestServers: builder.query<ChannelRequestServer[], ActiveNetwork & ActivePageMode>({
      query: (params) => ({
        url:
          `lsp/channelRequestServers` +
          (params.pageMode == PageMode.Client ? `/providers` : "/offers") +
          queryParamsBuilder(params),
        method: "GET",
      }),
      providesTags: ["channelRequestServers"],
    }),
    getChannelRequestServer: builder.query<ChannelRequestServer, { torqNodeId: number; peerNodeId?: number }>({
      query: (params) => ({
        url: `lsp/channelRequestServer/${params.torqNodeId}/peer/${params.peerNodeId}`,
        method: "GET",
      }),
    }),
    updateChannelRequest: builder.mutation<ChannelRequest, string>({
      query: (orderId) => ({
        url: `lsp/channelRequest/${orderId}`,
        method: "GET",
      }),
      invalidatesTags: ["channelRequests"],
    }),
    channelRequest: builder.mutation<ChannelRequestResponse, ChannelRequestParameters>({
      query: (req) => ({
        url: `lsp/channelRequest`,
        method: "POST",
        body: req,
      }),
      invalidatesTags: ["channelRequests"],
    }),
    pullChannelRequests: builder.mutation<void, void>({
      query: () => `lsp/pullChannelRequests`,
      invalidatesTags: ["channelRequests"],
    }),
    checkChannelRequests: builder.mutation<void, void>({
      query: () => `lsp/checkChannelRequests`,
      invalidatesTags: ["channelRequests"],
    }),
    channelRequestServer: builder.mutation<ChannelRequestServer, ChannelRequestServer>({
      query: (req) => ({
        url: `lsp/channelRequestServer`,
        method: "POST",
        body: req,
      }),
      invalidatesTags: ["channelRequestServers"],
    }),
  }),
});

// Select a single peer from the list

export const {
  useGetChannelRequestsQuery,
  useGetChannelRequestServersQuery,
  useGetChannelRequestServerQuery,
  useUpdateChannelRequestMutation,
  useChannelRequestMutation,
  useChannelRequestServerMutation,
  usePullChannelRequestsMutation,
  useCheckChannelRequestsMutation,
} = lspApi;
