import {
  Play20Regular as NodeEnabledIcon,
  Pause20Regular as NodeDisabledIcon,
  Edit20Regular as UpdateIcon,
} from "@fluentui/react-icons";
import styles from "features/settings/nodeStatus.module.scss";
import Button, { ButtonPosition, ColorVariant } from "components/buttons/Button";
import { userEvents } from "utils/userEvents";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslations from "services/i18n/useTranslations";
import Collapse from "features/collapse/Collapse";
import { useState } from "react";
import classNames from "classnames";

type NodeStatusProps = {
  nodeId: number;
  nodeName: string;
  status: number;
};

export default function NodeStatusDropdown(props: NodeStatusProps) {
  const { track } = userEvents();
  const { t } = useTranslations();
  const location = useLocation();
  const navigate = useNavigate();
  const [collapsed] = useState<boolean>(true);
  return (
    <div
      className={classNames(styles.nodeStatusDropdown, { [styles.disabled]: props.status === 0 })}
      // onClick={() => setCollapsed(!collapsed)}
      onClick={() => {
        track("Navigate to Update Node");
        navigate("/node/" + props.nodeId, { state: { background: location } });
      }}
    >
      <div className={styles.nodeStatusHeader}>
        <UpdateIcon />
        <div className={styles.nodeStatusHeaderTitle}>{props.nodeName}</div>
        <div className={styles.statusIconWrapper}>
          {props.status === 0 ? <NodeDisabledIcon /> : <NodeEnabledIcon />}
        </div>
      </div>
      <Collapse collapsed={collapsed} animate={true}>
        <div className={styles.nodeStatusBody}>
          <Button
            onClick={() => {
              track("Navigate to Update Node");
              navigate("/node/" + props.nodeId, { state: { background: location } });
            }}
            buttonColor={ColorVariant.primary}
            buttonPosition={ButtonPosition.fullWidth}
            icon={<UpdateIcon />}
            intercomTarget={"settings-update-node-button"}
          >
            {t.update}
          </Button>
        </div>
      </Collapse>
    </div>
  );
}
