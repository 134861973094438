import {
  Options20Regular as OptionsIcon,
  ArrowDownload20Regular as DownloadCsvIcon,
  ArrowSync20Regular as RefreshIcon,
  Add20Regular as NewChannelRequestIcon,
} from "@fluentui/react-icons";
import TablePageTemplate, {
  TableControlSection,
  TableControlsButtonGroup,
} from "features/templates/tablePageTemplate/TablePageTemplate";
import { ChannelRequest } from "features/lsp/lspTypes";
import useTranslations from "services/i18n/useTranslations";
import Table from "features/table/Table";
import {
  ChannelRequestsFilterTemplate,
  ChannelRequestsSortTemplate,
  FilterableChannelRequestsColumns,
  SortableChannelRequestsColumns,
  DefaultChannelRequestsView,
} from "features/lsp/channelRequest/client/channelRequestsDefaults";
import { AllChannelRequestsColumns } from "features/lsp/channelRequest/client/channelRequestsColumns.generated";
import { useGetChannelRequestsQuery } from "features/lsp/lspApi";
import { useAppSelector } from "store/hooks";
import { useGetTableViewsQuery, useUpdateTableViewMutation } from "features/viewManagement/viewsApiSlice";
import { selectChannelRequestsViews, selectViews } from "features/viewManagement/viewSlice";
import ViewsSidebar from "features/viewManagement/ViewsSidebar";
import { useEffect, useState } from "react";
import { useFilterData, useSortData } from "features/viewManagement/hooks";
import { selectActiveNetwork } from "features/network/networkSlice";
import { TableResponses, ViewResponse } from "features/viewManagement/types";
import { createCsvFile } from "utils/JsonTableToCsv";
import Button, { ColorVariant } from "components/buttons/Button";
import channelRequestCellRenderer from "features/lsp/channelRequest/client/channelRequestCellRenderer";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { userEvents } from "utils/userEvents";
import { usePaginateData, usePagination } from "components/table/pagination/usePagination";
import useSearchInput from "utils/searchFilter";
import { PageMode } from "types/api";
import useLocalStorage from "utils/useLocalStorage";

function ChannelRequestsPage(props: { purchases: boolean }): JSX.Element {
  const { t } = useTranslations();
  const { track } = userEvents();
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const { isSuccess } = useGetTableViewsQuery<{ isSuccess: boolean }>();
  const activeNetwork = useAppSelector(selectActiveNetwork);
  const [updateTableView] = useUpdateTableViewMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const { viewResponse, selectedViewIndex } = useAppSelector(selectChannelRequestsViews);
  const channelRequestsView = useAppSelector(selectViews)("channelRequests");
  const [viewRefreshInterval, setViewRefreshInterval] = useLocalStorage("channelRequestsRefreshInterval", 30);

  const channelRequestsResponse = useGetChannelRequestsQuery<{
    data: Array<ChannelRequest>;
    isLoading: boolean;
    isFetching: boolean;
    isUninitialized: boolean;
    isSuccess: boolean;
  }>(
    { network: activeNetwork, pageMode: props.purchases ? PageMode.Client : PageMode.Server },
    { skip: !isSuccess, pollingInterval: viewRefreshInterval * 1000 },
  );

  const filteredData = useFilterData(channelRequestsResponse.data, viewResponse.view.filters);
  const data = useSortData(filteredData, viewResponse.view.sortBy);
  const [getPaginator, limit, offset, setOffset] = usePagination("channelRequestsTable", 200);
  const { searchInput: SearchInput, data: searchData } = useSearchInput(data, {
    keys: ["lspName", "orderId", "torqNodeName"],
  });
  const paginatedData = usePaginateData(searchData, limit, offset);

  useEffect(() => {
    setOffset(0);
  }, [viewResponse.view.filters]);

  // Logic for toggling the sidebar
  const closeSidebarHandler = () => {
    setSidebarExpanded(false);
    track("Toggle Table Sidebar", { page: props.purchases ? "ChannelRequestPurchases" : "ChannelRequestSales" });
  };

  function handleNameChange(name: string) {
    const view = channelRequestsView.views[selectedViewIndex] as ViewResponse<TableResponses>;
    if (view.id) {
      updateTableView({
        id: view.id,
        view: { ...view.view, title: name },
      });
    }
  }

  const tableControls = (
    <TableControlSection intercomTarget={"table-page-controls"}>
      <TableControlsButtonGroup intercomTarget={"table-page-controls-left"}>
        {props.purchases && (
          <Button
            intercomTarget={"new-channelRequest-button"}
            buttonColor={ColorVariant.success}
            hideMobileText={true}
            icon={<NewChannelRequestIcon />}
            onClick={() => {
              track("Navigate to New Channel Request");
              navigate("/channel-request/0/peer/0", { state: { background: location } });
            }}
          >
            {t.channelRequest}
          </Button>
        )}
        {SearchInput}
      </TableControlsButtonGroup>
      <TableControlsButtonGroup intercomTarget={"table-page-controls-right"}>
        <Button
          intercomTarget={"download-csv"}
          buttonColor={ColorVariant.primary}
          title={t.download}
          hideMobileText={true}
          icon={<DownloadCsvIcon />}
          onClick={() => {
            track("Downloads Table as CSV", {
              downloadTablePage: "ChannelRequests",
              downloadTableViewTitle: viewResponse.view?.title,
              downloadTableColumns: viewResponse.view?.columns,
              downloadTableFilters: viewResponse.view?.filters,
              downloadTableSortBy: viewResponse.view?.sortBy,
            });
            createCsvFile(data, viewResponse.view.title || "ChannelRequests");
          }}
        />
        <Button
          intercomTarget={"refresh-table"}
          buttonColor={ColorVariant.primary}
          icon={<RefreshIcon />}
          onClick={() => {
            track("Refresh Table", { page: "ChannelRequests" });
            channelRequestsResponse.refetch();
          }}
        />
        <Button
          intercomTarget={"table-settings"}
          onClick={() => {
            track("Toggle Table Sidebar", { page: "ChannelRequests" });
            setSidebarExpanded(!sidebarExpanded);
          }}
          icon={<OptionsIcon />}
          id={"tableControlsButton"}
        >
          {t.views}
        </Button>
      </TableControlsButtonGroup>
    </TableControlSection>
  );

  const sidebar = (
    <ViewsSidebar
      onExpandToggle={closeSidebarHandler}
      expanded={sidebarExpanded}
      viewResponse={viewResponse}
      selectedViewIndex={selectedViewIndex}
      allColumns={AllChannelRequestsColumns}
      defaultView={DefaultChannelRequestsView}
      filterableColumns={FilterableChannelRequestsColumns}
      filterTemplate={ChannelRequestsFilterTemplate}
      sortableColumns={SortableChannelRequestsColumns}
      sortByTemplate={ChannelRequestsSortTemplate}
      setViewRefreshInterval={(seconds: number) => {
        setViewRefreshInterval(seconds);
      }}
      viewRefreshInterval={viewRefreshInterval}
    />
  );

  const breadcrumbs = [t.manage, <span key="b1">{t.channelRequests}</span>];

  return (
    <TablePageTemplate
      title={viewResponse.view.title}
      titleContent={""}
      breadcrumbs={breadcrumbs}
      sidebarExpanded={sidebarExpanded}
      sidebar={sidebar}
      tableControls={tableControls}
      onNameChange={handleNameChange}
      isDraft={viewResponse.id === undefined}
      pagination={getPaginator(data.length)}
    >
      <Table
        intercomTarget={"channelRequests-table"}
        cellRenderer={channelRequestCellRenderer}
        data={paginatedData}
        activeColumns={viewResponse.view.columns || []}
        isLoading={
          channelRequestsResponse.isLoading ||
          channelRequestsResponse.isFetching ||
          channelRequestsResponse.isUninitialized
        }
      />
    </TablePageTemplate>
  );
}

export default ChannelRequestsPage;
