import React, { useContext, useEffect, useState } from "react";
import { CheckmarkCircle20Regular as Icon, Save16Regular as SaveIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import { SelectWorkflowNodeLinks, SelectWorkflowNodes, useUpdateNodeMutation } from "pages/WorkflowPage/workflowApi";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { NumberFormatValues } from "react-number-format";
import { useSelector } from "react-redux";
import { Form, Input, InputSizeVariant, Select, Socket } from "components/forms/forms";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import Spinny from "features/spinny/Spinny";
import styles from "features/channels/openChannel/openChannel.module.scss";
import { systemVariable, systemVariableDataType } from "apiTypes";
import SystemVariableInputWrapper from "components/common/systemVariableInputWrapper/SystemVariableInputWrapper";
import { WorkflowVersionNodeSystemVariablesMap } from "pages/WorkflowPage/workflowTypes";
import { checkNodeInputsDirty } from "components/workflow/nodes/nodeFunctions";
import Note, { NoteType } from "features/note/Note";

type ScorechainNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

enum LockTransactionType {
  Never,
  Fraud,
  FraudAndSuspicious,
}

export type ScorechainParameters = {
  thresholdSuspicious?: number;
  thresholdFraud?: number;
  lockTransaction?: LockTransactionType;
};

export function ScorechainNode({ ...wrapperProps }: ScorechainNodeProps) {
  const { t } = useTranslations();

  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;
  const toastRef = React.useContext(ToastContext);

  const [updateNode] = useUpdateNodeMutation();

  const [configuration, setConfiguration] = useState<ScorechainParameters>({
    thresholdSuspicious: 80,
    thresholdFraud: 50,
    lockTransaction: LockTransactionType.Never,
    ...wrapperProps.parameters,
  });

  const [systemVariables, setSystemVariables] = useState<WorkflowVersionNodeSystemVariablesMap>(
    {} as WorkflowVersionNodeSystemVariablesMap,
  );


  const lockTransactionOptions: Array<{ value: LockTransactionType; label: string | undefined }> = [
    {
      label: "Never",
      value: LockTransactionType.Never,
    },
    {
      label: "Only Fraud",
      value: LockTransactionType.Fraud,
    },
    {
      label: "Fraud and Suspicious",
      value: LockTransactionType.FraudAndSuspicious,
    },
  ];

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!processing) {
      setSystemVariables(wrapperProps.systemVariables || {});
    }
  }, [wrapperProps.systemVariables, processing]);

  useEffect(() => {
    if (!processing) {
      setDirty(
        checkNodeInputsDirty(wrapperProps.parameters, configuration, wrapperProps.systemVariables, systemVariables),
      );
    }
  }, [configuration, wrapperProps.parameters, processing, wrapperProps.systemVariables, systemVariables]);

  function setThresholdSuspicious(threshold?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      thresholdSuspicious: threshold,
    }));
  }

  function setThresholdFraud(threshold?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      thresholdFraud: threshold,
    }));
  }

  function setVariable(variable: systemVariable | undefined, fieldName: string) {
    setSystemVariables((prev) => ({
      ...prev,
      [fieldName]: variable,
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function setLockTransaction(option: any) {
    return setConfiguration((prev) => ({
      ...prev,
      lockTransaction: option.value,
    }));
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }
    setProcessing(true);
    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: configuration,
      systemVariables: systemVariables,
    }).finally(() => {
      setProcessing(false);
    });
  }

  const { childLinks } = useSelector(
    SelectWorkflowNodeLinks({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeId: wrapperProps.workflowVersionNodeId,
      stage: wrapperProps.stage,
    }),
  );

  const parentNodeIds = childLinks?.map((link) => link.parentWorkflowVersionNodeId) ?? [];
  const parentNodes = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: parentNodeIds,
    })
  );

  return (
    <WorkflowNodeWrapper {...wrapperProps} headerIcon={<Icon />} colorVariant={NodeColorVariant.accent3}>
      <Form onSubmit={handleSubmit} intercomTarget={"scorechain-node-form"}>
        <Socket
            collapsed={wrapperProps.visibilitySettings.collapsed}
            label={t.Targets}
            selectedNodes={parentNodes || []}
            workflowVersionId={wrapperProps.workflowVersionId}
            workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
            inputName={"onChainTransaction"}
            editingDisabled={editingDisabled}
        />
        <SystemVariableInputWrapper
          variable={systemVariables["ThresholdSuspicious"]}
          handleVariableChange={(variable) => {
            setVariable(variable, "ThresholdSuspicious");
            setThresholdSuspicious(undefined);
          }}
          inputDataType={systemVariableDataType.number}
          editingDisabled={editingDisabled}
        >
          <Input
            intercomTarget={"scorechain-node-threshold-suspicious-input"}
            label={t.workflowNodes.scorechainThresholdSuspicious}
            helpText={t.workflowNodes.scorechainThresholdSuspiciousHelpText}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={configuration.thresholdSuspicious}
            onValueChange={(values: NumberFormatValues) => {
              setThresholdSuspicious(values.floatValue);
            }}
          />
        </SystemVariableInputWrapper>
        <SystemVariableInputWrapper
          variable={systemVariables["ThresholdFraud"]}
          handleVariableChange={(variable) => {
            setVariable(variable, "ThresholdFraud");
            setThresholdFraud(undefined);
          }}
          inputDataType={systemVariableDataType.number}
          editingDisabled={editingDisabled}
        >
          <Input
            intercomTarget={"scorechain-node-threshold-fraud-input"}
            label={t.workflowNodes.scorechainThresholdFraud}
            helpText={t.workflowNodes.scorechainThresholdFraudHelpText}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={configuration.thresholdFraud}
            onValueChange={(values: NumberFormatValues) => {
              setThresholdFraud(values.floatValue);
            }}
          />
        </SystemVariableInputWrapper>
        <Select
          intercomTarget={"scorechain-node-lock-transaction-select"}
          options={lockTransactionOptions}
          value={lockTransactionOptions.find((option) => option.value === configuration.lockTransaction)}
          onChange={setLockTransaction}
          label={t.workflowNodes.scorechainLockTransaction}
          sizeVariant={InputSizeVariant.small}
        />
        <Note title={t.note} noteType={NoteType.info}>
          <p>Verified: {configuration.thresholdSuspicious?configuration.thresholdSuspicious+1:systemVariables["ThresholdSuspicious"]?.name} - 100</p>
          <p>Suspicious: {configuration.thresholdFraud?configuration.thresholdFraud+1:systemVariables["ThresholdFraud"]?.name} - {configuration.thresholdSuspicious?configuration.thresholdSuspicious:systemVariables["ThresholdSuspicious"]?.name}</p>
          <p>Fraud: 0 - {configuration.thresholdFraud?configuration.thresholdFraud:systemVariables["ThresholdFraud"]?.name}</p>
        </Note>
        <Button
          intercomTarget={"scorechain-node-save"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
      </Form>
    </WorkflowNodeWrapper>
  );
}
