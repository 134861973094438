import { ActionError } from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";
import styles from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer.module.scss";

type ErrorsLogContainerProps = {
  errors: ActionError[] | undefined;
};

export function ErrorsLogContainer(props: ErrorsLogContainerProps) {
  const errors = props.errors;

  if (errors && errors.length > 0) {
    return (
      <LogContainer title={"Errors"} type={LogDetailType.error}>
        {errors?.map((e, i) => (
          <div key={i} className={styles.errorRow}>
            <span>{e.errorMessage}</span>
            {(e.torqNode || e.channel) && (
              <span>
                (
                {e.torqNode && (
                  <span>
                    Node: <i>{e.torqNode?.name}</i>
                  </span>
                )}
                {e.torqNode && e.channel && <span>, </span>}
                {e.channel && (
                  <span>
                    Channel to: <i>{e.channel?.peerAlias}</i>
                  </span>
                )}
                )
              </span>
            )}
          </div>
        ))}
      </LogContainer>
    );
  }
  return null;
}
