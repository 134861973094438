import React, { useContext, useEffect, useState } from "react";
import { ClipboardNote20Regular as Icon, Save16Regular as SaveIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import Form from "components/forms/form/Form";
import Socket from "components/forms/socket/Socket";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import { SelectWorkflowNodeLinks, SelectWorkflowNodes, useUpdateNodeMutation, SelectWorkflowTriggerNodes } from "pages/WorkflowPage/workflowApi";
import { useSelector } from "react-redux";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import Spinny from "features/spinny/Spinny";
import { InputSizeVariant, TextArea } from "components/forms/forms";
import Tag, { TagColor, TagSize } from "components/tags/Tag";

type TagProps = Omit<WorkflowNodeProps, "colorVariant">;

export type ChannelOpenInterceptionResultParameters = {
  rejectReason?: string;
};

export function ChannelOpenInterceptionResultNode({ ...wrapperProps }: TagProps) {
  const { t } = useTranslations();

  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;
  const toastRef = React.useContext(ToastContext);

  const [updateNode] = useUpdateNodeMutation();

  const [configuration, setConfiguration] = useState<ChannelOpenInterceptionResultParameters>({
    rejectReason: "",
    ...wrapperProps.parameters,
  });

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    setDirty(
      JSON.stringify(wrapperProps.parameters, Object.keys(wrapperProps.parameters).sort()) !==
        JSON.stringify(configuration, Object.keys(configuration).sort()),
    );
  }, [configuration, wrapperProps.parameters]);

  function setRejectReason(reason: string) {
    return setConfiguration((prev) => ({
      ...prev,
      rejectReason: reason,
    }));
  }

  const { childLinks } = useSelector(
    SelectWorkflowNodeLinks({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeId: wrapperProps.workflowVersionNodeId,
      stage: wrapperProps.stage,
    })
  );

  const parentNodeIds = childLinks?.map((link) => link.parentWorkflowVersionNodeId) ?? [];
  const parentNodes = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: parentNodeIds,
    })
  );

  const triggerNodes = useSelector(
    SelectWorkflowTriggerNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
    })
  );
  const defaultAccept = triggerNodes?.[0].parameters?.defaultAccept;

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }
    setProcessing(true);
    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: configuration,
    }).finally(() => {
      setProcessing(false);
    });
  }

  return (
    <WorkflowNodeWrapper
      {...wrapperProps}
      heading={t.workflowNodes.channelOpenInterceptionResult}
      headerIcon={<Icon />}
      colorVariant={NodeColorVariant.accent3}
    >
      <Form onSubmit={handleSubmit} intercomTarget={"channel-open-interception-result-node-form"}>
        <Socket
          collapsed={wrapperProps.visibilitySettings.collapsed}
          label={t.Targets}
          selectedNodes={parentNodes || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          inputName={"channelOpenInterceptionEvent"}
          editingDisabled={editingDisabled}
        />
        <Tag
          label={defaultAccept
            ? t.workflowNodes.channelOpenInterceptionResultDeny
            : t.workflowNodes.channelOpenInterceptionResultAccept
          }
          tagId={0}
          deletable={false}
          sizeVariant={TagSize.normal}
          colorVariant={defaultAccept ? TagColor.error : TagColor.success}
        />
        {(defaultAccept) == true &&
        <TextArea
          intercomTarget={"channel-open-interception-trigger-node-defaultRejectReason"}
          label={t.workflowNodes.channelOpenInterceptionResultRejectReason}
          sizeVariant={InputSizeVariant.normal}
          value={configuration.rejectReason}
          rows={3}
          onChange={(e) => {
            setRejectReason(e.target.value);
          }}
        />
        }
        <Button
          intercomTarget={"channel-open-interception-result-node-save-button"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing || editingDisabled}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
      </Form>
    </WorkflowNodeWrapper>
  );
}
