import styles from "components/workflow/LogDetails/LogContainer/logContainer.module.scss";
import { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import classNames from "classnames";

const typeClass = new Map<LogDetailType, string>([
  [LogDetailType.action, styles.action],
  [LogDetailType.filter, styles.filter],
  [LogDetailType.trigger, styles.trigger],
  [LogDetailType.dataSource, styles.dataSource],
  [LogDetailType.dryRun, styles.dryRun],
  [LogDetailType.error, styles.error],
  [LogDetailType.warning, styles.warning],
]);

type LogContainerProps = {
  title?: string;
  count?: number;
  type: LogDetailType;
  children: React.ReactNode;
};

export function LogContainer(props: LogContainerProps) {
  return (
    <div className={classNames(styles.outgoingChannels, typeClass.get(props.type || LogDetailType.default))}>
      <div className={styles.headingWrapper}>
        <div className={styles.channelsHeading}>{props.title}</div>
        {props.count !== undefined && <div className={styles.channelsHeadingCount}>{props.count}</div>}
      </div>
      <div className={styles.channelsBody}>{props.children}</div>
    </div>
  );
}
