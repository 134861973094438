import { useContext, useEffect, useState } from "react";
import useTranslations from "services/i18n/useTranslations";
import styles from "pages/WorkflowPage/workflow_page.module.scss";
import PageTitle from "features/templates/PageTitle";
import { Link, useParams } from "react-router-dom";
import { WORKFLOWS, MANAGE } from "constants/routes";
import { useWorkflowData } from "pages/WorkflowPage/workflowHooks";
import { SelectWorkflowTriggerNodes, useGetActiveEventTriggerNodesQuery, useUpdateWorkflowMutation } from "pages/WorkflowPage/workflowApi";
import WorkflowSidebar from "components/workflow/sidebar/WorkflowSidebar";
import { WorkflowCanvases } from "components/workflow/canvas/WorkflowCanvasStages";
import { StageSelector } from "components/workflow/stages/WorkflowStageSelector";
import WorkflowControls from "pages/WorkflowPage/WorkflowControls";
import { Status } from "constants/backend";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import Note, { NoteType } from "features/note/Note";
import { userEvents } from "utils/userEvents";
import { RtqToServerError } from "components/errors/errors";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import { useSelector } from "react-redux";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";

function WorkflowPage() {
  const { t } = useTranslations();
  const { track } = userEvents();
  const toastRef = useContext(ToastContext);
  // Fetch the workflow data
  const { workflowId, version } = useParams();
  const { workflow, workflowVersion, stageNumbers, workflowVersionTriggerNodeId } = useWorkflowData(
    workflowId,
    version,
  );

  const [selectedStage, setSelectedStage] = useState<number>(1); //setSelectedStage
  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(false);

  const triggers = useSelector(
    SelectWorkflowTriggerNodes({ workflowId: parseInt(workflowId || "0"), version: parseInt(version || "0") }),
  );

  function selectStage(stage: number) {
    track("Workflow Select Stage", {
      workflowId: workflowId,
      workflowVersion: version,
      workflowStage: stage,
    });
    setSelectedStage(stage);
  }

  const [updateWorkflow, { error: updateNameError }] = useUpdateWorkflowMutation();

  function handleWorkflowNameChange(name: string) {
    track("Workflow Update Name", {
      workflowId: workflow?.workflowId,
      workflowVersionId: workflowVersion?.workflowVersionId,
      workflowName: workflow?.name,
    });
    updateWorkflow({ workflowId: parseInt(workflowId || "0"), name: name });
  }

  useEffect(() => {
    const err = RtqToServerError(updateNameError);
    if (!err) return;
    if (!err.errors?.fields?.name[0].description) return;
    toastRef?.current && toastRef.current.addToast(err.errors?.fields?.name[0].description || "", toastCategory.error);
  }, [updateNameError]);

  const breadcrumbs = [
    <Link to={`/${MANAGE}/${WORKFLOWS}`} key={"workflowsLink"}>
      {t.workflows}
    </Link>,
    workflow?.name,
  ];

  const isHTLCTrigger =
    triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.HtlcInterceptionTrigger) !== -1;
  const isChannelOpenInterceptionTrigger =
    triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.ChannelOpenInterceptionTrigger) !== -1;
  const isOnChainTransactionTrigger =
    triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.OnChainTransactionTrigger) !== -1;

  const activeChannelOpenInterceptionTriggerNodes = useGetActiveEventTriggerNodesQuery(
    {
      nodeType: WorkflowNodeType.ChannelOpenInterceptionTrigger
    });
  const isAnyChannelOpenInterceptionTriggerActive = activeChannelOpenInterceptionTriggerNodes?.data != null && activeChannelOpenInterceptionTriggerNodes?.data.length > 0;
  const disableActivateForChannelOpenInterceptionTrigger = workflow?.status === Status.Inactive && isAnyChannelOpenInterceptionTriggerActive && isChannelOpenInterceptionTrigger;

  const activeOnChainTransactionTriggerNodes = useGetActiveEventTriggerNodesQuery(
    {
      nodeType: WorkflowNodeType.OnChainTransactionTrigger
    });
  const isAnyOnChainTransactionTriggerActive = activeOnChainTransactionTriggerNodes?.data != null && activeOnChainTransactionTriggerNodes?.data.length > 0;
  const disableActivateForOnChainTransactionTrigger = workflow?.status === Status.Inactive && isAnyOnChainTransactionTriggerActive && isOnChainTransactionTrigger;

  return (
    <WorkflowContext.Provider value={{ workflowStatus: workflow?.status || Status.Inactive }}>
      <div className={styles.contentWrapper}>
        <PageTitle
          breadcrumbs={breadcrumbs}
          title={workflow?.name || ""}
          onNameChange={handleWorkflowNameChange}
          intercomTarget={"workflow-page-title"}
        />
        <WorkflowControls
          stage={selectedStage}
          workflowVersionId={workflowVersion?.workflowVersionId || 0}
          version={workflowVersion?.version || 0}
          sidebarExpanded={sidebarExpanded}
          setSidebarExpanded={setSidebarExpanded}
          workflowId={workflow?.workflowId || 0}
          status={workflow?.status || Status.Inactive}
          workflowVersionTriggerNodeId={workflowVersionTriggerNodeId || 0}
        />
        <div className={styles.tableWrapper}>
          <div className={styles.tableContainer}>
            <div className={styles.tableExpander}>
              <WorkflowCanvases
                selectedStage={selectedStage}
                workflowVersionId={workflowVersion?.workflowVersionId || 0}
                workflowId={workflow?.workflowId || 0}
                version={workflowVersion?.version || 0}
              />
              <StageSelector
                stageNumbers={stageNumbers}
                selectedStage={selectedStage}
                setSelectedStage={selectStage}
                workflowVersionId={workflowVersion?.workflowVersionId || 0}
                workflowId={workflow?.workflowId || 0}
                version={workflowVersion?.version || 0}
                editingDisabled={workflow?.status === Status.Active}
                addStageDisabled={isChannelOpenInterceptionTrigger || isHTLCTrigger || isOnChainTransactionTrigger}
              />
              {workflow?.status === Status.Active && (
                <div className={styles.workflowStatusNote}>
                  <Note title={t.note} noteType={NoteType.warning} dismissible={true}>
                    <p>{t.toast.cannotModifyWorkflowActive}</p>
                  </Note>
                </div>
              )}
              {disableActivateForOnChainTransactionTrigger && (
                <div className={styles.workflowStatusNote}>
                  <Note title={t.note} noteType={NoteType.warning} dismissible={true}>
                    <p>{t.toast.cannotActivateWorkflowBecauseOnChainTransactionTriggerAlreadyActive}</p>
                  </Note>
                </div>
              )}
              {disableActivateForChannelOpenInterceptionTrigger && (
                <div className={styles.workflowStatusNote}>
                  <Note title={t.note} noteType={NoteType.warning} dismissible={true}>
                    <p>{t.toast.cannotActivateWorkflowBecauseChannelOpenInterceptionTriggerAlreadyActive}</p>
                  </Note>
                </div>
              )}
            </div>
          </div>
        </div>
        <WorkflowSidebar
          expanded={sidebarExpanded}
          setExpanded={setSidebarExpanded}
          triggers={triggers}
          workflowId={workflow?.workflowId || 0}
        />
      </div>
    </WorkflowContext.Provider>
  );
}

export default WorkflowPage;
