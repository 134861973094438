import { ArrowExit20Regular as HtlcInterceptionEventFilterIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeButtonWrapper from "components/workflow/nodeButtonWrapper/NodeButtonWrapper";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";

export function HtlcInterceptionEventFilterNodeButton(props: { disabled?: boolean }) {
  const { t } = useTranslations();

  return (
    <WorkflowNodeButtonWrapper
      disabled={props.disabled}
      intercomTarget={"htlc-interception-event-filter-node-button"}
      colorVariant={NodeColorVariant.accent1}
      nodeType={WorkflowNodeType.HtlcInterceptionEventFilter}
      icon={<HtlcInterceptionEventFilterIcon />}
      title={t.workflowNodes.htlcInterceptionEventFilter}
      parameters={`{"$and": []}`}
    />
  );
}
