import React, { useContext, useEffect, useState } from "react";
import { ReceiptSparkles20Regular as InvoiceConfiguratorIcon, Save16Regular as SaveIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import { SelectWorkflowNodeLinks, SelectWorkflowNodes, useUpdateNodeMutation } from "pages/WorkflowPage/workflowApi";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { NumberFormatValues } from "react-number-format";
import { useSelector } from "react-redux";
import Spinny from "features/spinny/Spinny";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import { Input, InputSizeVariant, Socket, Form, RadioChips } from "components/forms/forms";
import { RebalanceConfiguration } from "components/workflow/nodes/rebalanceAutoRun/RebalanceAutoRunNode";

type PaymentAutoRunNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export type PaymentAttemptConfiguration = {
  exchange: string;
  amount?: number;
  maximumCostMsat?: number;
  maximumCostMilliMsat?: number;
};

export function PaymentAutoRunNode({ ...wrapperProps }: PaymentAutoRunNodeProps) {
  const { t } = useTranslations();
  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;
  const toastRef = React.useContext(ToastContext);

  const [updateNode] = useUpdateNodeMutation();

  const [configuration, setConfiguration] = useState<PaymentAttemptConfiguration>({
    exchange: "kraken",
    amount: undefined,
    maximumCostMsat: undefined,
    maximumCostMilliMsat: undefined,
    ...wrapperProps.parameters,
  });

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    setDirty(
      JSON.stringify(wrapperProps.parameters, Object.keys(wrapperProps.parameters).sort()) !==
        JSON.stringify(configuration, Object.keys(configuration).sort()),
    );
  }, [configuration, wrapperProps.parameters]);

  // const [maximumCostSat, setMaximumCostSat] = useState<number | undefined>(
  //   (wrapperProps.parameters as InvoiceConfiguration).maximumCostMsat
  //     ? ((wrapperProps.parameters as InvoiceConfiguration).maximumCostMsat || 0) / 1000
  //     : undefined
  // );

  function createChangeHandler(key: keyof PaymentAttemptConfiguration) {
    return (e: NumberFormatValues) => {
      setConfiguration((prev) => ({
        ...prev,
        [key]: e.floatValue,
      }));
    };
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }

    setProcessing(true);
    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: configuration,
    }).finally(() => {
      setProcessing(false);
    });
  }

  const { childLinks } = useSelector(
    SelectWorkflowNodeLinks({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeId: wrapperProps.workflowVersionNodeId,
      stage: wrapperProps.stage,
    }),
  );

  const channelId =
    childLinks
      ?.filter((n) => {
        return n.childInput === "channels";
      })
      ?.map((link) => link.parentWorkflowVersionNodeId) ?? [];

  const channels = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: channelId,
    }),
  );

  return (
    <WorkflowNodeWrapper
      {...wrapperProps}
      headerIcon={<InvoiceConfiguratorIcon />}
      colorVariant={NodeColorVariant.accent3}
    >
      <Form onSubmit={handleSubmit} intercomTarget={"invoice-auto-run-node-form"}>
        <Socket
          collapsed={wrapperProps.visibilitySettings.collapsed}
          label={t.Sources}
          selectedNodes={channels || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          inputName={"channels"}
          outputName={"channels"}
          editingDisabled={editingDisabled}
        />
        <RadioChips
          label={t.exchange}
          sizeVariant={InputSizeVariant.small}
          groupName={"exchange-switch-" + wrapperProps.workflowVersionNodeId}
          options={[
            {
              label: "Kraken",
              id: "focus-switch-kraken-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.exchange === "kraken",
              onChange: () =>
                setConfiguration((prev) => ({
                  ...prev,
                  ["focus" as keyof RebalanceConfiguration]: "incomingChannels",
                })),
            },
          ]}
          editingDisabled={editingDisabled}
        />
        <Input
          intercomTarget={"invoice-auto-run-node-amount-input"}
          formatted={true}
          value={configuration.amount}
          thousandSeparator={","}
          suffix={" sat"}
          onValueChange={createChangeHandler("amount")}
          label={t.amountSat}
          sizeVariant={InputSizeVariant.small}
          disabled={editingDisabled}
        />
        {/*<Input*/}
        {/*  formatted={true}*/}
        {/*  value={maximumCostSat}*/}
        {/*  thousandSeparator={","}*/}
        {/*  suffix={" sat"}*/}
        {/*  onValueChange={createChangeMsatHandler("maximumCostMsat")}*/}
        {/*  label={t.maximumCostSat}*/}
        {/*  sizeVariant={InputSizeVariant.small}*/}
        {/*  disabled={editingDisabled}*/}
        {/*/>*/}
        <Input
          intercomTarget={"invoice-auto-run-node-ppm-input"}
          formatted={true}
          value={configuration.maximumCostMilliMsat}
          thousandSeparator={","}
          suffix={" ppm"}
          onValueChange={createChangeHandler("maximumCostMilliMsat")}
          label={t.maximumCostMilliMsat}
          sizeVariant={InputSizeVariant.small}
          disabled={editingDisabled}
        />
        {/*<Input*/}
        {/*  formatted={true}*/}
        {/*  value={invoice.maximumConcurrency}*/}
        {/*  thousandSeparator={","}*/}
        {/*  onValueChange={createChangeHandler("maximumConcurrency")}*/}
        {/*  label={t.maximumConcurrency}*/}
        {/*  sizeVariant={InputSizeVariant.small}*/}
        {/*/>*/}
        <Button
          intercomTarget={"invoice-auto-run-node-save-button"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing || editingDisabled}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
      </Form>
    </WorkflowNodeWrapper>
  );
}
