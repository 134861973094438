import { ColumnMetaData } from "features/table/types";
import { OnChainTx, OnChainTemplateDataOption } from "features/transact/OnChain/types";
import { ViewResponse } from "features/viewManagement/types";
import {   FilterInterface } from "features/sidebar/sections/filter/filter";
import {
  AllOnChainTransactionsColumns,
  OnChainTransactionsSortableColumns,
  OnChainTransactionsFilterableColumns,
} from "features/transact/OnChain/onChainColumns.generated";
import { OrderBy } from "features/sidebar/sections/sort/SortSection";
import { Implementation } from "types/api";

const defaultColumns: Array<keyof OnChainTx> = [
  "blockHeight",
  "blockTime",
  "amountSat",
  "totalFeesSat",
  "hash",
  "outputIndex",
  "address",
  "nodeName",
  "chainalysisStatus",
  "inputHash",
];

export const DefaultOnChainColumns = defaultColumns.map((col) =>
  AllOnChainTransactionsColumns.find((x) => x.key === col),
) as Array<ColumnMetaData<OnChainTx>>;

export const SortableOnChainColumns = AllOnChainTransactionsColumns.filter((column: ColumnMetaData<OnChainTx>) =>
  OnChainTransactionsSortableColumns.includes(column.key),
);

export const FilterableOnChainColumns = AllOnChainTransactionsColumns.filter((column: ColumnMetaData<OnChainTx>) =>
  OnChainTransactionsFilterableColumns.includes(column.key),
);

export const OnChainSortTemplate: Array<OrderBy> = [
  {
    key: "blockHeight",
    direction: "desc",
  },
];

export const OnChainFilterTemplate: FilterInterface = {
  funcName: "gte",
  category: "number",
  parameter: 0,
  key: "amountSat",
  supportedBy: [Implementation.LND, Implementation.CLN],
};

export const DefaultOnChainView: ViewResponse<OnChainTx> = {
  page: "onChain",
  dirty: true,
  view: {
    title: "Draft View",
    columns: DefaultOnChainColumns,
    sortBy: OnChainSortTemplate,
  },
};

export const TemplateOnChainTransactionDataSelection: OnChainTemplateDataOption[] = [
  {
    label: "Block Hash",
    templateVarName: "{{Transaction.blockHash}}",
  },
  {
    label: "Block Height",
    templateVarName: "{{Transaction.blockHeight}}",
  },
  {
    label: "Block Time",
    templateVarName: "{{Transaction.blockTime}}",
  },
  {
    label: "Chainalysis Status",
    templateVarName: "{{Transaction.chainalysisStatus}}",
  },
  {
    label: "Fees",
    templateVarName: "{{Transaction.totalFeesSat}}",
  },
  {
    label: "Raw Hex",
    templateVarName: "{{Transaction.rawHex}}",
  },
  {
    label: "Transaction Hash",
    templateVarName: "{{Transaction.hash}}",
  },
];
