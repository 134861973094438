import { Input, InputRow, InputSizeVariant, InputColorVaraint, Select } from "components/forms/forms";
import { secondsInDay, secondsInHour, secondsInMinute, secondsInMonth, secondsInWeek, secondsInYear } from "date-fns";
import { useEffect, useState } from "react";
import { NumberFormatValues } from "react-number-format";
import useTranslations from "services/i18n/useTranslations";

export enum TimeUnits {
  seconds = 1,
  minutes = secondsInMinute,
  hours = secondsInHour,
  days = secondsInDay,
  weeks = secondsInWeek,
  months = secondsInMonth,
  years = secondsInYear,
}

export function TimeUnitFromSeconds(seconds: number, maxTimeUnit?: TimeUnits): TimeUnits {
  let lastTimeUnit: TimeUnits = TimeUnits.seconds;
  for (const tu in TimeUnits) {
    if (typeof TimeUnits[tu] === "number") {
      if (seconds < parseInt(TimeUnits[tu])) {
        return lastTimeUnit;
      }
      lastTimeUnit = TimeUnits[tu as keyof typeof TimeUnits];
      if (lastTimeUnit == maxTimeUnit) {
        break;
      }
    }
  }
  return lastTimeUnit;
}

type timeUnitOption = { value: number; label: string };

// A function for checking that the option passed into the handle select function is a timeUnitOptions
function isTimeUnit(option: unknown): option is timeUnitOption {
  return (option as timeUnitOption).value !== undefined;
}

// function that converts between time units
export function ConvertTimeUnits(from: TimeUnits, to: TimeUnits, value: number): number {
  return (value * from) / to;
}

const ONE_HOUR = 60 * 60; // 1 hour

export type DurationInputProps = {
  label?: string;
  helpText?: string;
  editingDisabled?: boolean;
  seconds?: number;
  timeUnit?: TimeUnits;
  timeUnitOptions?: timeUnitOption[];
  intercomTargetFrequencyInput: string;
  intercomTargetTimeUnitSelect: string;
  additionalSuffix?: string;
  sizeVariant?: InputSizeVariant;
  colorVariant?: InputColorVaraint;
  onDirtyChange?: (dirty: boolean) => void;
  onDurationChange: (seconds: number, timeUnit?: TimeUnits) => void;
};

export function DurationInput(props: DurationInputProps) {
  const { t } = useTranslations();

  const [selectedTimeUnit, setSelectedTimeUnit] = useState<TimeUnits>(props.timeUnit || TimeUnits.seconds);

  const [frequency, setFrequency] = useState<number>(
    ConvertTimeUnits(TimeUnits.seconds, selectedTimeUnit, props.seconds || 0)
  );
  const [seconds, setSeconds] = useState<number>(ONE_HOUR);

  const timeUnitOptions = props.timeUnitOptions?.every((o) => isTimeUnit(o))
    ? props.timeUnitOptions
    : [
        { value: TimeUnits.seconds.valueOf(), label: t.seconds },
        { value: TimeUnits.minutes.valueOf(), label: t.minutes },
        { value: TimeUnits.hours.valueOf(), label: t.hours },
      ];

  const selectedOption = timeUnitOptions.find((option) => option.value === selectedTimeUnit);

  useEffect(() => {
    // if the original parameters are different from the current parameters, set dirty to true
    if (props.onDirtyChange) {
      if (props.seconds !== seconds || props.timeUnit !== selectedTimeUnit) {
        props.onDirtyChange(true);
      } else {
        props.onDirtyChange(false);
      }
    }
  }, [selectedOption, seconds, frequency, selectedTimeUnit, props]);

  useEffect(() => {
    const sec = ConvertTimeUnits(selectedTimeUnit, TimeUnits.seconds, frequency);
    setSeconds(sec);
    //return seconds to parent
    props.onDurationChange(sec, selectedTimeUnit);
  }, [frequency, selectedTimeUnit]);

  function handleFrequencyChange(values: NumberFormatValues) {
    const value = values.floatValue ? values.floatValue : 0;
    setFrequency(value);
  }

  function handleTimeUnitChange(newValue: unknown) {
    if (isTimeUnit(newValue)) {
      setFrequency(ConvertTimeUnits(selectedTimeUnit, newValue.value, frequency));
      setSelectedTimeUnit(newValue.value);
    }
  }

  return (
    <InputRow>
      <div style={{ flexGrow: 1 }}>
        <Input
          intercomTarget={props.intercomTargetFrequencyInput}
          disabled={props.editingDisabled}
          formatted={true}
          value={frequency}
          thousandSeparator={true}
          suffix={` ${selectedOption?.label}${props.additionalSuffix ? " " + props.additionalSuffix : ""}`}
          onValueChange={handleFrequencyChange}
          label={props.label}
          helpText={props.helpText}
          sizeVariant={props.sizeVariant ? props.sizeVariant : undefined}
          colorVariant={props.colorVariant ? props.colorVariant : undefined}
        />
      </div>
      <Select
        intercomTarget={props.intercomTargetTimeUnitSelect}
        isDisabled={props.editingDisabled}
        options={timeUnitOptions}
        onChange={handleTimeUnitChange}
        value={selectedOption}
        sizeVariant={props.sizeVariant ? props.sizeVariant : undefined}
        colorVariant={props.colorVariant ? props.colorVariant : undefined}
      />
    </InputRow>
  );
}
