import panelStyles from "features/panel/panel.module.scss";
import classNames from "classnames";
import { ReactNode } from "react";
import { PanelRendererFunction } from "features/panel/types";

export type PanelProp<T> = {
  panel: T;
  panelIndex: number;
  panelRenderer: PanelRendererFunction<T>;
};

function Row<T>(props: PanelProp<T>) {
  // Adds empty cells at the start and end of each row. This is to give the table a buffer at each end.
  const panelContent: Array<ReactNode> = [];
  panelContent.push(props.panelRenderer(props.panel, props.panelIndex));
  return (
    <div className={classNames(panelStyles.panel, "panel-" + props.panelIndex)} key={"panel-" + props.panelIndex}>
      {panelContent}
    </div>
  );
}

export default Row;
