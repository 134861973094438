import {
  Eye16Regular as InspectIcon,
} from "@fluentui/react-icons";
import styles from "components/common/longTextWithCopy/longTextWithCopy.module.scss";
import cellStyles from "components/table/cells/cell.module.scss";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { ColorVariant, LinkButton, SizeVariant } from "components/buttons/Button";
import useTranslations from "services/i18n/useTranslations";
import { userEvents } from "utils/userEvents";
import { memo } from "react";

interface ChannelLinkCell {
  shortChannelId: string;
  channelId: number;
  nodeId: number;
}

function ChannelLinkCell(props: ChannelLinkCell) {
  const { t } = useTranslations();
  const { track } = userEvents();
  const location = useLocation();

  return (
    <div className={classNames(styles.longText, cellStyles.cell)}>
      <div>
        <div className={classNames(styles.action, styles.view)}>
          <InspectIcon />
          <span className={classNames(styles.content)}>{props.shortChannelId}</span>
        </div>
        <LinkButton
          intercomTarget={"inspect-channel-navigate"}
          key={"buttons-node-inspect"}
          state={{ background: location }}
          to={"/analyse/inspect/" + props.channelId + "/node/" + props.nodeId}
          icon={<InspectIcon />}
          hideMobileText={true}
          buttonSize={SizeVariant.tiny}
          buttonColor={ColorVariant.accent1}
          onClick={() => {
            track("Navigate to Inspect Channel", {
              channelId: props.channelId,
            });
          }}
        >
          {t.inspect}
        </LinkButton>
      </div>
    </div>
  );
}
const ChannelLinkCellMemo = memo(ChannelLinkCell);
export default ChannelLinkCellMemo;
