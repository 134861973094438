import { WorkflowRunLogDetailAction } from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import ParentsLog from "components/workflow/LogDetails/ParentsLog/ParentsLog";
import { LogParameters } from "components/workflow/LogDetails/Parameters/LogParameters";
import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";
import useTranslations from "services/i18n/useTranslations";

export function GenericLog(props: WorkflowRunLogDetailAction & { icon: React.ReactNode; actionType?: LogDetailType }) {
  const { t } = useTranslations();
  return (
    <LogDetailsWrapper
      type={props.actionType || LogDetailType.default}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={props.icon}
    >
      {props.parentIds && (
        <ParentsLog title={t.parents} parentIds={props.parentIds} type={props.actionType || LogDetailType.default} />
      )}
      <LogParameters
        parameters={props.parameters || ""}
        systemVariables={props.systemVariables || ""}
        colorType={props.actionType || LogDetailType.default}
      />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
