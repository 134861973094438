import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { DesktopArrowRight20Regular as ApiClientIcon, Save16Regular as SaveIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import { SelectWorkflowNodeLinks, SelectWorkflowNodes, useUpdateNodeMutation } from "pages/WorkflowPage/workflowApi";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import {
  Form,
  Input,
  InputRow,
  InputSizeVariant,
  RadioChips,
  Select,
  Socket,
  Switch,
  TextArea,
} from "components/forms/forms";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import { useSelector } from "react-redux";
import Spinny from "features/spinny/Spinny";
import { ServerErrorResultType, ToastErrStringFromServerError } from "components/errors/errors";
import { SelectOption } from "components/forms/select/Select";
import { AllChannelsColumns } from "features/channels/channelsColumns.generated";
import { SwitchSize } from "components/forms/switch/Switch";
import { AllInvoicesColumns } from "features/transact/Invoices/invoicesColumns.generated";
import { AllPaymentsColumns } from "features/transact/Payments/paymentsColumns.generated";
import { TemplateOnChainTransactionDataSelection } from "features/transact/OnChain/onChainDefaults";
import { AllForwardsDataColumns } from "features/forwards/forwardsDataColumns";
import { checkSystemVariablesDirty } from "components/workflow/nodes/nodeFunctions";
import SystemVariableTextAreaWrapper, {
  getSystemVariablesFromText,
  makeSystemVariableReference,
} from "components/common/systemVariableInputWrapper/SystemVariableTextAreaWrapper";
import { WorkflowVersionNodeSystemVariablesMap } from "pages/WorkflowPage/workflowTypes";
import { systemVariable } from "apiTypes";

type ApiClientNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

// Same as notification sendtype!
enum SendType {
  EachChannel,
  EachNode,
  Single,
}

enum ApiClientProtocolType {
  Http,
  Https,
}

enum ApiClientMethodType {
  Get,
  Post,
}

export type ApiClientRequestParam = {
  key: string;
  value: string;
};

export type ApiClientParameters = {
  sendType: SendType;
  protocol: ApiClientProtocolType;
  method: ApiClientMethodType;
  host: string;
  port: number | undefined;
  path: string;
  getRequestParameters?: ApiClientRequestParam[];
  postRequestBody?: string;
  contentTypeHeader?: string;
  addQuotesToVariables?: boolean;
  addBracketsToVariables?: boolean;
  formatVariables?: boolean;
};

type TemplateDataOption = {
  templateVarName: string;
  label: string;
};

const TemplateChannelDataSelection: TemplateDataOption[] = [
  ...(AllChannelsColumns.map((c) => {
    return {
      label: c.heading,
      templateVarName: "{{Channels." + c.key + "}}",
    } as TemplateDataOption;
  }) as TemplateDataOption[]),
];

const TemplateInvoiceDataSelection: TemplateDataOption[] = [
  ...(AllInvoicesColumns.map((c) => {
    return {
      label: c.heading,
      templateVarName: "{{Invoice." + c.key + "}}",
    } as TemplateDataOption;
  }) as TemplateDataOption[]),
];

const TemplateForwardDataSelection: TemplateDataOption[] = [
  ...(AllForwardsDataColumns.map((c) => {
    return {
      label: c.heading,
      templateVarName: "{{Forward." + c.key + "}}",
    } as TemplateDataOption;
  }) as TemplateDataOption[]),
];

const TemplatePaymentDataSelection: TemplateDataOption[] = [
  ...(AllPaymentsColumns.map((c) => {
    return {
      label: c.heading,
      templateVarName: "{{Payment." + c.key + "}}",
    } as TemplateDataOption;
  }) as TemplateDataOption[]),
];

const TemplateNodeDataSelection: TemplateDataOption[] = [
  {
    label: "Name",
    templateVarName: "{{Nodes.Name}}",
  },
  {
    label: "Alias",
    templateVarName: "{{Nodes.Alias}}",
  },
  {
    label: "Public Key",
    templateVarName: "{{Nodes.PublicKey}}",
  },
  {
    label: "Network",
    templateVarName: "{{Nodes.Network}}",
  },
  {
    label: "Chain",
    templateVarName: "{{Nodes.Chain}}",
  },
];

const TemplateHtlcDataSelection: TemplateDataOption[] = [
  {
    label: "Automatic Fail Block Height",
    templateVarName: "{{Htlc.automaticFailBlockHeight}}",
  },
  {
    label: "Event Time",
    templateVarName: "{{Htlc.eventTime}}",
  },
  {
    label: "Incoming Lnd Short Channel ID",
    templateVarName: "{{Htlc.lndShortChannelId}}",
  },
  {
    label: "Incoming Amount msat",
    templateVarName: "{{Htlc.incomingAmountMsat}}",
  },
  {
    label: "Incoming Expiration Block Height",
    templateVarName: "{{Htlc.incomingExpirationBlockHeight}}",
  },
  {
    label: "Payment Hash",
    templateVarName: "{{Htlc.paymentHash}}",
  },
  {
    label: "Outgoing Lnd Short Channel ID",
    templateVarName: "{{Htlc.outgoingRequestedChannelId}}",
  },
  {
    label: "Outgoing Amount msat",
    templateVarName: "{{Htlc.outgoingAmountMsat}}",
  },
  {
    label: "Outgoing Expiration Block Height",
    templateVarName: "{{Htlc.outgoingExpirationBlockHeight}}",
  },
  {
    label: "Onion Blob",
    templateVarName: "{{Htlc.onionBlob}}",
  },
];

export function ApiClientNode({ ...wrapperProps }: ApiClientNodeProps) {
  const { t } = useTranslations();

  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;
  const toastRef = React.useContext(ToastContext);

  const postBodyTextAreaId = "postBodyInput" + wrapperProps.workflowVersionNodeId.toString();

  const [updateNode] = useUpdateNodeMutation();

  const protocolOptions: Array<{ value: ApiClientProtocolType; label: string | undefined }> = [
    {
      label: "http",
      value: ApiClientProtocolType.Http,
    },
    {
      label: "https",
      value: ApiClientProtocolType.Https,
    },
  ];

  const methodOptions: Array<{ value: ApiClientMethodType; label: string | undefined }> = [
    {
      label: "GET",
      value: ApiClientMethodType.Get,
    },
    {
      label: "POST",
      value: ApiClientMethodType.Post,
    },
  ];

  const contentTypeOptions: Array<{ value: string; label: string | undefined }> = [
    {
      label: "application/json",
      value: "application/json",
    },
    {
      label: "text/plain",
      value: "text/plain",
    },
  ];

  const [configuration, setConfiguration] = useState<ApiClientParameters>({
    sendType: SendType.EachChannel,
    protocol: ApiClientProtocolType.Http,
    method: ApiClientMethodType.Get,
    host: "",
    port: 80,
    path: "/",
    getRequestParameters: [{ key: "", value: "" } as ApiClientRequestParam],
    postRequestBody: "{\n\n}",
    contentTypeHeader: "",
    addQuotesToVariables: false,
    addBracketsToVariables: false,
    formatVariables: false,
    ...wrapperProps.parameters,
  });

  const [systemVariables, setSystemVariables] = useState<WorkflowVersionNodeSystemVariablesMap>(
    {} as WorkflowVersionNodeSystemVariablesMap,
  );

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [focusedGetRequestParameterIndex, setFocusedGetRequestParameterIndex] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!processing) {
      // remove/add variables based on the text
      const text = configuration.postRequestBody || "";

      // remove if not in text
      for (const ref in systemVariables) {
        if (text.indexOf("{{System." + ref + "}}") === -1) {
          setVariable(undefined, ref);
        }
      }

      // add new variables from text if they don't exist
      const newVariables = getSystemVariablesFromText(text);
      for (const ref in newVariables) {
        if (!systemVariables[ref]) {
          setVariable(newVariables[ref], ref);
        }
      }
    }
  }, [configuration.postRequestBody, processing]);

  useEffect(() => {
    if (!processing) {
      setSystemVariables(wrapperProps.systemVariables || {});
    }
  }, [wrapperProps.systemVariables, processing]);

  useEffect(() => {
    //check similarity
    const getParamsDirty = !configuration.getRequestParameters?.every((param, index) => {
      const wprp = wrapperProps.parameters.getRequestParameters as ApiClientRequestParam[];
      if (!wprp) {
        return false;
      }

      // the configuration has one more empty parameter at the end
      if (index === wprp?.length && !param.key && !param.value) {
        return true;
      }
      if (wprp?.length <= index || param.key !== wprp[index].key || param.value !== wprp[index].value) {
        return false;
      }
      return true;
    });
    const otherParamsDirty =
      JSON.stringify(
        wrapperProps.parameters,
        Object.keys(wrapperProps.parameters)
          .filter((val) => val != "getRequestParameters")
          .sort(),
      ) !==
      JSON.stringify(
        configuration,
        Object.keys(configuration)
          .filter((val) => val != "getRequestParameters")
          .sort(),
      );

    if (!processing) {
      setDirty(
        getParamsDirty || otherParamsDirty || checkSystemVariablesDirty(wrapperProps.systemVariables, systemVariables),
      );
    }
  }, [configuration, wrapperProps.parameters, processing, wrapperProps.systemVariables, systemVariables]);

  useEffect(() => {
    //always have one empty request parameter in the end

    let requestParameters = [...(configuration.getRequestParameters || [{ key: "", value: "" }])];
    requestParameters = requestParameters.filter((param) => param.key !== "" || param.value !== "");
    requestParameters.push({ key: "", value: "" } as ApiClientRequestParam);

    if (requestParameters.length !== configuration.getRequestParameters?.length) {
      setConfiguration((prev) => ({
        ...prev,
        getRequestParameters: [...requestParameters],
      }));
    }
  }, [configuration.getRequestParameters]);

  const { childLinks } = useSelector(
    SelectWorkflowNodeLinks({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeId: wrapperProps.workflowVersionNodeId,
      stage: wrapperProps.stage,
    }),
  );

  const parentNodeIds = childLinks?.map((link) => link.parentWorkflowVersionNodeId) ?? [];
  const parentNodes = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: parentNodeIds,
    }),
  );

  function setSendType(type?: SendType) {
    return setConfiguration((prev) => ({
      ...prev,
      sendType: type ? type : SendType.EachChannel,
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function setProtocol(protocolOpt: any) {
    let port = configuration.port;
    if (protocolOpt.value == ApiClientProtocolType.Http) {
      //for http, set default port
      port = 80;
    }
    if (protocolOpt.value == ApiClientProtocolType.Https) {
      //for https, set default port
      port = 443;
    }
    return setConfiguration((prev) => ({
      ...prev,
      port: port,
      protocol: protocolOpt.value,
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function setMethod(methodOpt: any) {
    let contentTypeHeader = configuration.contentTypeHeader;
    let addBracketsToVariables = configuration.addBracketsToVariables;
    let addQuotesToVariables = configuration.addQuotesToVariables;

    // default content type for post request
    if (methodOpt.value == ApiClientMethodType.Post && !contentTypeHeader) {
      contentTypeHeader = "application/json";
    }
    if (methodOpt.value == ApiClientMethodType.Get) {
      addBracketsToVariables = false;
      addQuotesToVariables = false;
    }

    return setConfiguration((prev) => ({
      ...prev,
      method: methodOpt.value,
      addBracketsToVariables: addBracketsToVariables,
      addQuotesToVariables: addQuotesToVariables,
      contentTypeHeader: contentTypeHeader,
    }));
  }

  function setHost(host?: string) {
    setConfiguration((prev) => ({
      ...prev,
      host: host ? host : "",
    }));
  }

  function setPort(port?: number) {
    setConfiguration((prev) => ({
      ...prev,
      port: port ? port : undefined,
    }));
  }

  function setPath(path?: string) {
    if (path?.substring(0, 1) !== "/") {
      path = "/" + path;
    }
    setConfiguration((prev) => ({
      ...prev,
      path: path ? path : "/",
    }));
  }

  function setVariable(variable: systemVariable | undefined, fieldName: string) {
    if (variable === undefined) {
      const newSystemVariables = { ...systemVariables };
      delete newSystemVariables[fieldName];
      setSystemVariables(newSystemVariables);
      return;
    }
    setSystemVariables((prev) => ({
      ...prev,
      [fieldName]: variable,
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleTemplateVarSelect(item: any) {
    if (configuration.method == ApiClientMethodType.Post) {
      // add the variable to post request body

      const postBodyTextArea = document.getElementById(postBodyTextAreaId) as HTMLTextAreaElement;
      if (
        postBodyTextArea &&
        typeof postBodyTextArea.selectionStart === "number" &&
        typeof postBodyTextArea.selectionEnd === "number"
      ) {
        const { selectionStart, selectionEnd } = postBodyTextArea;
        const start = Math.min(selectionStart, selectionEnd);
        const end = Math.max(selectionStart, selectionEnd);

        setPostRequestBody(
          configuration.postRequestBody?.slice(0, start) + item.value + configuration.postRequestBody?.slice(end),
        );
      } else {
        //add to end if cursor not in text
        setPostRequestBody(`${configuration.postRequestBody} ${item.value}`);
      }
    } else if (configuration.method == ApiClientMethodType.Get && focusedGetRequestParameterIndex != undefined) {
      const requestParameters = [...(configuration.getRequestParameters || [{ key: "", value: "" }])];

      const index = focusedGetRequestParameterIndex;

      if (index >= requestParameters.length) {
        //no parameter with that index
        return;
      }

      requestParameters[index].value = requestParameters[index].value + item.value;
      setConfiguration((prev) => ({
        ...prev,
        getRequestParameters: [...requestParameters],
      }));
    }
  }

  function setGetRequestParameter(key: string | undefined, value: string | undefined, index: number) {
    let requestParameters = configuration?.getRequestParameters?.map((param, i) => {
      let k = param.key;
      let v = param.value;

      if (key != undefined && i === index) {
        k = key;
      }
      if (value != undefined && i === index) {
        v = value;
      }
      return {
        key: k,
        value: v,
      };
    });

    if (requestParameters == undefined) {
      requestParameters = [{ key: "", value: "" }];
    }

    setConfiguration((prev) => ({
      ...prev,
      getRequestParameters: requestParameters,
    }));
  }

  function setPostRequestBody(postRequestBody?: string) {
    setConfiguration((prev) => ({
      ...prev,
      postRequestBody: postRequestBody ? postRequestBody : "",
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function setPostRequestContentType(contentTypeOpt?: any) {
    setConfiguration((prev) => ({
      ...prev,
      contentTypeHeader: contentTypeOpt?.value ? contentTypeOpt.value : "",
    }));
  }

  function setAddBracketsToVariables(addBracketsToVariables?: boolean) {
    setConfiguration((prev) => ({
      ...prev,
      addBracketsToVariables: addBracketsToVariables ? addBracketsToVariables : false,
    }));
  }

  function setAddQuotesToVariables(addQuotesToVariables?: boolean) {
    setConfiguration((prev) => ({
      ...prev,
      addQuotesToVariables: addQuotesToVariables ? addQuotesToVariables : false,
    }));
  }

  function setFormatVariables(formatVariables?: boolean) {
    setConfiguration((prev) => ({
      ...prev,
      formatVariables: formatVariables ? formatVariables : false,
    }));
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }
    setProcessing(true);

    //copy the configuration and remove the empty get request parameters
    const conf = JSON.parse(JSON.stringify(configuration));
    conf.getRequestParameters = conf.getRequestParameters?.filter(
      (param: { key: string; value: string }) => param.key !== "" || param.value !== "",
    );

    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: conf,
      systemVariables: systemVariables,
    })
      .unwrap()
      .catch((error: ServerErrorResultType) => {
        const errMsg = ToastErrStringFromServerError(error, "Errors in node parameters", "Invalid node parameters");
        toastRef?.current?.addToast(errMsg, toastCategory.error);
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  return (
    <WorkflowNodeWrapper
      {...wrapperProps}
      heading={t.workflowNodes.apiClient}
      headerIcon={<ApiClientIcon />}
      colorVariant={NodeColorVariant.accent3}
    >
      <Form onSubmit={handleSubmit} intercomTarget={"api-client-node-form"}>
        <Socket
          collapsed={wrapperProps.visibilitySettings.collapsed}
          selectedNodes={parentNodes || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          inputName={"channels"}
          editingDisabled={editingDisabled}
        />
        <RadioChips
          label={t.apiClientRequestSendType}
          sizeVariant={InputSizeVariant.small}
          vertical={true}
          groupName={"send-type-switch-" + wrapperProps.workflowVersionNodeId}
          options={[
            {
              label: t.notificationSendTypeSelect.eachChannel,
              id: "send-type-switch-eachChannel-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.sendType === SendType.EachChannel,
              onChange: () => setSendType(SendType.EachChannel),
            },
            {
              label: t.notificationSendTypeSelect.eachNode,
              id: "send-type-switch-eachNode-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.sendType === SendType.EachNode,
              onChange: () => setSendType(SendType.EachNode),
            },
            {
              label: t.notificationSendTypeSelect.single,
              id: "send-type-switch-single-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.sendType === SendType.Single,
              onChange: () => setSendType(SendType.Single),
            },
          ]}
          editingDisabled={editingDisabled}
        />
        <InputRow>
          <Select
            intercomTarget={"api-client-protocol-select"}
            options={protocolOptions}
            value={protocolOptions.find((option) => option.value === configuration.protocol)}
            onChange={setProtocol}
            label={t.apiClientProtocol}
            sizeVariant={InputSizeVariant.small}
          />
          <Select
            intercomTarget={"api-client-method-select"}
            options={methodOptions}
            value={methodOptions.find((option) => option.value === configuration.method)}
            onChange={setMethod}
            label={t.apiClientMethod}
            sizeVariant={InputSizeVariant.small}
          />
        </InputRow>
        <InputRow>
          <Input
            intercomTarget="api-client-host-input"
            label={t.host}
            type={"text"}
            sizeVariant={InputSizeVariant.small}
            value={configuration.host || ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHost(e.target.value)}
          />
          <Input
            intercomTarget={"api-client-port-input"}
            label={t.port}
            type={"number"}
            sizeVariant={InputSizeVariant.small}
            value={configuration.port}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setPort(e.target.valueAsNumber);
            }}
          />
        </InputRow>
        <Input
          intercomTarget="api-client-path-input"
          label={t.apiClientPath}
          type={"text"}
          sizeVariant={InputSizeVariant.small}
          value={configuration.path || "/"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPath(e.target.value)}
        />
        <Select
          label={t.variables}
          intercomTarget={"api-client-template-node-var-select"}
          options={TemplateNodeDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.sendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.node }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />
        <Select
          intercomTarget={"api-client-template-channel-var-select"}
          options={TemplateChannelDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.sendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.channel }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />
        <Select
          intercomTarget={"api-client-template-htlc-var-select"}
          options={TemplateHtlcDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.sendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.htlc }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />
        <Select
          intercomTarget={"api-client-template-invoice-var-select"}
          options={TemplateInvoiceDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.sendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.invoice }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />
        <Select
          intercomTarget={"api-client-template-forward-var-select"}
          options={TemplateForwardDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.sendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.forward }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />
        <Select
          intercomTarget={"api-client-template-payment-var-select"}
          options={TemplatePaymentDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.sendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.payment }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />
        <Select
          intercomTarget={"api-client-template-onchain-transaction-var-select"}
          options={TemplateOnChainTransactionDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.sendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.onChainTransaction }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />
        <Switch
          intercomTarget={"api-client-format-variables-switch"}
          label={t.formatVariables}
          sizeVariant={SwitchSize.small}
          checked={configuration.formatVariables}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFormatVariables(e.target.checked);
          }}
        />
        {configuration.method == ApiClientMethodType.Get &&
          configuration.getRequestParameters?.map((param, index) => {
            let label: string | undefined;
            if (index === 0) {
              label = t.apiClientGetRequestParameters;
            }
            return (
              <InputRow key={"request-parameter" + index}>
                <Input
                  intercomTarget={`api-client-request-param-name-${index}-input`}
                  label={label}
                  type={"text"}
                  sizeVariant={InputSizeVariant.small}
                  value={param.key || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setGetRequestParameter(e.target.value, undefined, index);
                  }}
                  placeholder={t.name}
                  onFocus={() => {
                    setFocusedGetRequestParameterIndex(index);
                  }}
                />
                <Input
                  intercomTarget={`api-client-request-param-value-${index}-input`}
                  type={"text"}
                  sizeVariant={InputSizeVariant.small}
                  value={param.value || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setGetRequestParameter(undefined, e.target.value, index);
                  }}
                  placeholder={t.value}
                  onFocus={() => {
                    setFocusedGetRequestParameterIndex(index);
                  }}
                />
              </InputRow>
            );
          })}
        {configuration.method == ApiClientMethodType.Post && (
          <>
            <Switch
              intercomTarget={"api-client-add-quotes-switch"}
              label={t.addQuotesToVariables}
              sizeVariant={SwitchSize.small}
              checked={configuration.addQuotesToVariables}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setAddQuotesToVariables(e.target.checked);
              }}
            />
            <Switch
              intercomTarget={"api-client-add-brackets-switch"}
              label={t.addBracketsToVariables}
              sizeVariant={SwitchSize.small}
              checked={configuration.addBracketsToVariables}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setAddBracketsToVariables(e.target.checked);
              }}
            />
            <Select
              intercomTarget={"api-client-content-type-select"}
              options={contentTypeOptions}
              value={contentTypeOptions.find((option) => option.value === configuration.contentTypeHeader)}
              onChange={setPostRequestContentType}
              label={t.apiClientPostContentType}
              sizeVariant={InputSizeVariant.small}
            />
            <SystemVariableTextAreaWrapper
              handleVariableChange={(variable: systemVariable | undefined) => {
                if (variable) {
                  const variableReference = makeSystemVariableReference(variable);
                  // insert the variable into text
                  handleTemplateVarSelect({ value: `{{System.${variableReference}}}` });
                  setVariable(variable, variableReference);
                }
              }}
              editingDisabled={editingDisabled}
              disableVariableInput={editingDisabled}
            >
              <TextArea
                id={postBodyTextAreaId}
                intercomTarget="api-client-post-request-body-input"
                label={t.apiClientPostRequestBody}
                helpText={t.apiClientPostBodyHelp}
                sizeVariant={InputSizeVariant.normal}
                value={configuration.postRequestBody}
                rows={10}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setPostRequestBody(e.target.value);
                }}
              />
            </SystemVariableTextAreaWrapper>
          </>
        )}
        <Button
          intercomTarget={"api-client-node-save"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
      </Form>
    </WorkflowNodeWrapper>
  );
}
