import { WorkflowRunLogDetailAction } from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import { Settings20Regular as Icon } from "@fluentui/react-icons";
import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import ParentsLog from "components/workflow/LogDetails/ParentsLog/ParentsLog";
import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";
import useTranslations from "services/i18n/useTranslations";

export function SystemOperationLog(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();
  return (
    <LogDetailsWrapper
      type={LogDetailType.default}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      {props.parentIds && <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.default} />}
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
