import { ViewResponse } from "features/viewManagement/types";

import { FilterInterface } from "features/sidebar/sections/filter/filter";
import { ColumnMetaData } from "features/table/types";
import {
  AllWorkflowRunLogColumns,
  WorkflowRunLogsFilterableColumns,
  WorkflowRunLogsSortableColumns,
} from "pages/workflowRunLogs/workflowRunLogs.generated";
import { OrderBy } from "features/sidebar/sections/sort/SortSection";
import { WorkflowRunLog } from "pages/workflowRunLogs/workflowRunLogsTypes";

const defaultColumns: Array<keyof WorkflowRunLog> = ["workflowName", "workflowLogId", "bootTime", "captureLogs"];

export const DefaultWorkflowLogsColumns = defaultColumns.map((col) =>
  AllWorkflowRunLogColumns.find((x) => x.key === col),
) as Array<ColumnMetaData<WorkflowRunLog>>;

export const FilterableWorkflowLogsColumns = AllWorkflowRunLogColumns.filter((column: ColumnMetaData<WorkflowRunLog>) =>
  WorkflowRunLogsFilterableColumns.includes(column.key),
);

export const SortableWorflowRunLogsColumns = AllWorkflowRunLogColumns.filter(
  (column: ColumnMetaData<WorkflowRunLog>) => {
    return WorkflowRunLogsSortableColumns.includes(column.key);
  },
);

export const WorkflowLogsFilterTemplate: FilterInterface = {
  funcName: "like",
  category: "string",
  parameter: "",
  key: "workflowName",
};

export const WorkflowRunLogsSortTemplate: Array<OrderBy> = [
  {
    key: "bootTime",
    direction: "desc",
  },
];

export const DefaultWorkflowRunLogsView: ViewResponse<WorkflowRunLog> = {
  page: "workflowRunLogs",
  dirty: true,
  view: {
    title: "Workflow Logs",
    columns: DefaultWorkflowLogsColumns,
    sortBy: WorkflowRunLogsSortTemplate,
  },
};

export const SortableWorkflowRunLogsColumns = AllWorkflowRunLogColumns.filter((c) =>
  WorkflowRunLogsSortableColumns.includes(c.key),
);
