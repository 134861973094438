export type Product = {
  productId: ProductIds;
  productName: string;
  productDescription: string;
  available: boolean;
  checkoutLink?: string;
  features: Array<string>;
  limits: Limits;
  monthlyPriceId?: string;
  monthlyPrice?: number;
  yearlyPriceId?: string;
  yearlyPrice?: number;
  eligible?: boolean;
};

type Limits = {
  maxNodes: number;
  maxChannels: number;
  maxCapacity: number;
};

export enum ProductIds {
  PayProductUnknown = 0,
  PayProductFree = 9,
  PayProductFallback = 10,
  PayProductProfessional = 11,
  PayProductProfessionalPlus = 12,
  PayProductOrganization = 13,
  PayProductEnterprise = 14,
}
