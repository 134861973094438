import { Play20Regular as DataSourceTorqChannelsIcon } from "@fluentui/react-icons";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";

type DataSourceHtlcEventNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export function DataSourceHtlcEventNode({ ...wrapperProps }: DataSourceHtlcEventNodeProps) {
  return (
    <WorkflowNodeWrapper
      {...wrapperProps}
      headerIcon={<DataSourceTorqChannelsIcon />}
      colorVariant={NodeColorVariant.accent2}
      outputName={"channels"}
    >
    </WorkflowNodeWrapper>
  );
}
