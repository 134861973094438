import { ExpandedTag } from "pages/tags/tagsTypes";
import { ViewResponse } from "features/viewManagement/types";
import { AllTagsColumns } from "pages/tags/tagsPage/tagsColumns.generated";
import { ColumnMetaData } from "features/table/types";

const defaultColumns: Array<keyof ExpandedTag> = ["name", "categoryId", "channels", "delete", "edit"];

export const DefaultTagsColumns = defaultColumns.map((col) => AllTagsColumns.find((x) => x.key === col)) as Array<
  ColumnMetaData<ExpandedTag>
>;

export const DefaultTagsView: ViewResponse<ExpandedTag> = {
  page: "invoices",
  dirty: true,
  view: {
    title: "Draft View",
    columns: DefaultTagsColumns,
  },
};
