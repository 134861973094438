import {
  Pause20Regular as DeactivateIcon,
  Play20Regular as ActivateIcon,
  PuzzlePiece20Regular as NodesIcon,
} from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import { TableControlsButtonGroup, TableControlSection } from "features/templates/tablePageTemplate/TablePageTemplate";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import {
  SelectWorkflowTriggerNodes,
  useAddManualDryRunWorkflowTriggerMutation,
  useAddManualWorkflowTriggerMutation,
  useUpdateWorkflowMutation,
  useGetActiveEventTriggerNodesQuery,
} from "pages/WorkflowPage/workflowApi";
import { Status } from "constants/backend";
import { userEvents } from "utils/userEvents";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";
import Spinny from "features/spinny/Spinny";
import ToastContext from "features/toast/context";
import { useContext } from "react";
import { toastCategory } from "features/toast/Toasts";
import { useSelector } from "react-redux";

type WorkflowControlsProps = {
  workflowVersionId: number;
  version: number;
  stage: number;
  sidebarExpanded: boolean;
  setSidebarExpanded: (expanded: boolean) => void;
  workflowId: number;
  status: Status;
  workflowVersionTriggerNodeId: number;
};

export default function WorkflowControls(props: WorkflowControlsProps) {
  const { t } = useTranslations();
  const { track } = userEvents();
  const toastRef = useContext(ToastContext);
  const [updateWorkflow, { isLoading: updateIsLoading }] = useUpdateWorkflowMutation();

  const [triggerWorkflow, { isLoading: runIsProcessing }] = useAddManualWorkflowTriggerMutation();
  const [dryRunWorkflow, { isLoading: dryRunIsProcessing }] = useAddManualDryRunWorkflowTriggerMutation();

  const triggers = useSelector(
    SelectWorkflowTriggerNodes({
      workflowId: parseInt(props.workflowId.toString() || "0"),
      version: parseInt(props.version.toString() || "0"),
    }),
  );
  const isHTLCTrigger =
    triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.HtlcInterceptionTrigger) !== -1;
  const isChannelOpenInterceptionTrigger =
    triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.ChannelOpenInterceptionTrigger) !== -1;
  const isOnChainTransactionTrigger =
    triggers?.findIndex((nodeType) => nodeType.type === WorkflowNodeType.OnChainTransactionTrigger) !== -1;

  const activeChannelOpenInterceptionTriggerNodes = useGetActiveEventTriggerNodesQuery(
    {
      nodeType: WorkflowNodeType.ChannelOpenInterceptionTrigger
    });
  const isAnyChannelOpenInterceptionTriggerActive = activeChannelOpenInterceptionTriggerNodes?.data != null && activeChannelOpenInterceptionTriggerNodes?.data.length > 0;
  const disableActivateForChannelOpenInterceptionTrigger = props.status === Status.Inactive && isAnyChannelOpenInterceptionTriggerActive && isChannelOpenInterceptionTrigger;

  const activeOnChainTransactionTriggerNodes = useGetActiveEventTriggerNodesQuery(
    {
      nodeType: WorkflowNodeType.OnChainTransactionTrigger
    });
  const isAnyOnChainTransactionTriggerActive = activeOnChainTransactionTriggerNodes?.data != null && activeOnChainTransactionTriggerNodes?.data.length > 0;
  const disableActivateForOnChainTransactionTrigger = props.status === Status.Inactive && isAnyOnChainTransactionTriggerActive && isOnChainTransactionTrigger;

  function handleManualTrigger() {
    const confirmed = confirm("Please confirm that you want to run this workflow.");
    if (!confirmed) return;
    track("Workflow Manually Triggered", {
      workflowId: props.workflowId,
      workflowVersionId: props.workflowVersionId,
      workflowVersion: props.version,
      workflowStageNumber: props.stage,
      workflowVersionTriggerNodeId: props.workflowVersionTriggerNodeId,
    });

    triggerWorkflow({
      type: WorkflowNodeType.StageTrigger,
      workflowVersionId: props.workflowVersionId,
      workflowId: props.workflowId,
      workflowVersionNodeId: props.workflowVersionTriggerNodeId,
    })
      .then(() => {
        toastRef?.current && toastRef.current.addToast(t.workflowDetails.manualTriggerSuccess, toastCategory.success);
      })
      .catch(() => {
        toastRef?.current && toastRef.current.addToast(t.workflowDetails.manualTriggerError, toastCategory.error);
      });
  }

  function handleManualDryRunTrigger() {
    track("Workflow Manually Dry Run Triggered", {
      workflowId: props.workflowId,
      workflowVersionId: props.workflowVersionId,
      workflowVersion: props.version,
      workflowStageNumber: props.stage,
      workflowVersionTriggerNodeId: props.workflowVersionTriggerNodeId,
    });

    dryRunWorkflow({
      type: WorkflowNodeType.StageTrigger,
      workflowVersionId: props.workflowVersionId,
      workflowId: props.workflowId,
      workflowVersionNodeId: props.workflowVersionTriggerNodeId,
    })
      .then(() => {
        toastRef?.current && toastRef.current.addToast(t.workflowDetails.manualTriggerSuccess, toastCategory.success);
      })
      .catch(() => {
        toastRef?.current && toastRef.current.addToast(t.workflowDetails.manualTriggerError, toastCategory.error);
      });
  }

  return (
    <TableControlSection intercomTarget={"workflow-controls"}>
      <TableControlsButtonGroup intercomTarget={"workflow-controls-left"}>
        <Button
          intercomTarget={"activate-workflow-button"}
          buttonColor={props.status === Status.Active ? ColorVariant.warning : ColorVariant.success}
          hideMobileText={true}
          icon={props.status === Status.Active ? <DeactivateIcon /> : <ActivateIcon />}
          disabled={updateIsLoading || disableActivateForChannelOpenInterceptionTrigger || disableActivateForOnChainTransactionTrigger}
          onClick={() => {
            track("Workflow Toggle Status", {
              workflowStatus: props.status === Status.Active ? "Inactive" : "Active",
              workflowId: props.workflowId,
            });
            if (props.status === Status.Inactive && !confirm(t.workflowDetails.confirmWorkflowActivate)) {
              return;
            }
            if (props.status === Status.Active && !confirm(t.workflowDetails.confirmWorkflowDeactivate)) {
              return;
            }
            updateWorkflow({
              workflowId: props.workflowId,
              status: props.status === Status.Active ? Status.Inactive : Status.Active,
            });
          }}
        >
          {props.status === Status.Active ? t.deactivate : t.activate}
        </Button>
        <Button
          intercomTarget={"workflow-trigger-manual-dry-run-button"}
          icon={dryRunIsProcessing && <Spinny />}
          disabled={dryRunIsProcessing || isHTLCTrigger || isChannelOpenInterceptionTrigger || isOnChainTransactionTrigger}
          buttonSize={SizeVariant.normal}
          buttonColor={ColorVariant.primary}
          hideMobileText={true}
          onClick={handleManualDryRunTrigger}
        >
          {t.workflowNodes.dryRun}
        </Button>
        <Button
          intercomTarget={"workflow-trigger-manual-button"}
          icon={runIsProcessing && <Spinny />}
          disabled={runIsProcessing || isHTLCTrigger || isChannelOpenInterceptionTrigger || isOnChainTransactionTrigger}
          buttonSize={SizeVariant.normal}
          buttonColor={ColorVariant.warning}
          hideMobileText={true}
          onClick={handleManualTrigger}
        >
          {t.workflowNodes.run}
        </Button>
      </TableControlsButtonGroup>
      <TableControlsButtonGroup intercomTarget={"workflow-controls-right"}>
        <Button
          intercomTarget={"activate-workflow-configuration-button"}
          buttonColor={ColorVariant.primary}
          hideMobileText={true}
          id={"tableControlsButton"}
          icon={<NodesIcon />}
          onClick={() => {
            track("Workflow Toggle Sidebar");
            props.setSidebarExpanded(!props.sidebarExpanded);
          }}
        >
          {t.configurations}
        </Button>
      </TableControlsButtonGroup>
    </TableControlSection>
  );
}
