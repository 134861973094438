import useTranslations from "services/i18n/useTranslations";
import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";
import styles from "components/workflow/LogDetails/Parameters/logParameters.module.scss";
import { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import { getLogParameterMapping } from "components/workflow/LogDetails/Parameters/LogParameterMapping";
import { WorkflowVersionNodeSystemVariablesMap } from "pages/WorkflowPage/workflowTypes";
import SystemVariableInputWrapper from "components/common/systemVariableInputWrapper/SystemVariableInputWrapper";
import { systemVariable } from "apiTypes";

type parametersType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type LogParametersProps = {
  parameters: string;
  systemVariables: string;
  colorType?: LogDetailType;
  nodeType?: string;
  localizationObjects?: string[];
};

export function LogParameters(props: LogParametersProps) {
  const { t } = useTranslations();
  const params = JSON.parse(props.parameters || "{}") as parametersType;
  const paramKeys = Object.keys(params);

  const sysVars = JSON.parse(props.systemVariables || "{}") as WorkflowVersionNodeSystemVariablesMap;

  // add sys var keys to paramKeys
  for (const sysVarKey in sysVars) {
    const sysVarKeyLower = sysVarKey.charAt(0).toLowerCase() + sysVarKey.slice(1);
    if (!paramKeys.includes(sysVarKeyLower)) {
      // the first letter to lower case to match the json key
      paramKeys.push(sysVarKeyLower);
    }
  }

  const paramValueMap = getLogParameterMapping(t, props.nodeType);

  return (
    <LogContainer title={"Parameters"} type={props.colorType || LogDetailType.default}>
      <div className={styles.parametersWrapper}>
        {paramKeys.map((key) => {
          // label from localization
          let locObj = t;
          for (const obj of props.localizationObjects || []) {
            locObj = locObj[obj];
          }

          const label = locObj[key] ? locObj[key] : key;

          if (params[key] === true) {
            params[key] = "yes";
          } else if (params[key] === false) {
            params[key] = "no";
          }

          let value = params[key] !== undefined ? t[params[key]] : "";

          if (paramValueMap && paramValueMap[key] && paramValueMap[key][params[key]]) {
            value = paramValueMap[key][params[key]];
          }

          // use system variable if exists
          // assume json name and GO field name are the same case insensitive!
          for (const sysVarKey in sysVars) {
            if (sysVarKey.toLowerCase() === key.toLowerCase()) {
              const sysVar = sysVars[sysVarKey] as systemVariable;
              value = (
                <SystemVariableInputWrapper
                  variable={sysVar}
                  inputDataType={sysVar.dataType}
                  editingDisabled={true}
                  hoverStartRight
                  hoverLeftTranslate="-280px"
                />
              );
            }
          }

          return (
            <div key={key} className={styles.parameter}>
              <div className={styles.label}> {label ? label : key}</div>
              <div className={styles.value}>{value ? value : params[key]}</div>
            </div>
          );
        })}
      </div>
    </LogContainer>
  );
}
