import styles from "features/templates/tablePageTemplate/table-page-template.module.scss";
import * as d3 from "d3";

const ft = d3.format(",.0f");

type InvoicesHeaderProps = {
  totalCount: number;
  totalAmount: number;
  totalPaidAmount: number;
};

function InvoicesHeader(props: InvoicesHeaderProps) {
  return (
    <>
      <div className={styles.card}>
        <div className={styles.heading}>Number of Payments</div>
        <div className={styles.cardRow}>
          <div className={styles.rowValue} data-testid={"number-of-payments"}>{props?.totalCount && ft(props?.totalCount)}</div>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.heading}>Total Amount (Sats)</div>
        <div className={styles.cardRow}>
          <div className={styles.rowValue} data-testid={"total-amount"}>{props?.totalAmount && ft(props?.totalAmount)}</div>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.heading}>Total Paid (Sats)</div>
        <div className={styles.cardRow}>
          <div className={styles.rowValue} data-testid={"total-paid"}>{props?.totalPaidAmount && ft(props?.totalPaidAmount)}</div>
        </div>
      </div>
    </>
  );
}

export default InvoicesHeader;
