// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go


import { ColumnMetaData } from "features/table/types";
import { ChannelPending } from "features/channelsPending/channelsPendingTypes";

export const AllChannelPendingColumns: ColumnMetaData<ChannelPending>[] = [
	{
		heading: "Accepting Node ID",
		type: "TextCell",
		key: "acceptingNodeId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Capacity",
		type: "NumericCell",
		key: "channelCapacity",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Channel ID",
		type: "TextCell",
		key: "channelId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Channel Status",
		type: "TextCell",
		key: "channelStatus",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "Opening", value: "Opening" },
			{ label: "Open", value: "Open" },
			{ label: "Closing", value: "Closing" },
			{ label: "Cooperative Closed", value: "Cooperative Closed" },
			{ label: "Local Force Closed", value: "Local Force Closed" },
			{ label: "Remote Force Closed", value: "Remote Force Closed" },
			{ label: "Breach Closed", value: "Breach Closed" },
			{ label: "Funding Cancelled Closed", value: "Funding Cancelled Closed" },
			{ label: "Abandoned Closed", value: "Abandoned Closed" },
		],
	},
	{
		heading: "Closing Date",
		type: "DateCell",
		key: "closedOn",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Closing Date Delta",
		type: "DurationCell",
		key: "closedOnSecondsDelta",
		valueType: "duration",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Closing BlockHeight",
		type: "NumericCell",
		key: "closingBlockHeight",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Closing BlockHeight Delta",
		type: "NumericCell",
		key: "closingBlockHeightDelta",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Closing Alias",
		type: "AliasCell",
		key: "closingNodeAlias",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Closing Node ID",
		type: "TextCell",
		key: "closingNodeId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Closing Transaction",
		type: "LongTextCell",
		key: "closingTransactionHash",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Funding Date",
		type: "DateCell",
		key: "fundedOn",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Funding Date Delta",
		type: "DurationCell",
		key: "fundedOnSecondsDelta",
		valueType: "duration",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Funding BlockHeight",
		type: "NumericCell",
		key: "fundingBlockHeight",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Funding BlockHeight Delta",
		type: "NumericCell",
		key: "fundingBlockHeightDelta",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Funding Node Alias",
		type: "AliasCell",
		key: "fundingNodeAlias",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Funding Transaction",
		type: "LongTextCell",
		key: "fundingTransactionHash",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Initiating Node ID",
		type: "TextCell",
		key: "initiatingNodeId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "LND Short Channel ID",
		type: "LongTextCell",
		key: "lndShortChannelId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node Alias",
		type: "AliasCell",
		key: "nodeAlias",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node Css Colour",
		type: "TextCell",
		key: "nodeCssColour",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node ID",
		type: "TextCell",
		key: "nodeId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node Implementation",
		type: "TextCell",
		key: "nodeImplementation",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "LND", value: "0" },
			{ label: "CLN", value: "1" },
		],
	},
	{
		heading: "Node Name",
		type: "TextCell",
		key: "nodeName",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node Public Key",
		type: "TextCell",
		key: "nodePublicKey",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Peer Node Alias",
		type: "AliasCell",
		key: "peerNodeAlias",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Peer Node Css Colour",
		type: "TextCell",
		key: "peerNodeCssColour",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Peer Node ID",
		type: "TextCell",
		key: "peerNodeId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Peer Node Implementation",
		type: "TextCell",
		key: "peerNodeImplementation",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "LND", value: "0" },
			{ label: "CLN", value: "1" },
		],
	},
	{
		heading: "Peer Node Name",
		type: "TextCell",
		key: "peerNodeName",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Peer Node Public Key",
		type: "TextCell",
		key: "peerNodePublicKey",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Short Channel ID",
		type: "LongTextCell",
		key: "shortChannelId",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Tags",
		type: "TagsCell",
		key: "tags",
		valueType: "tag",
		suffix: "",
		supportedBy: [0, 1],
	},
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const ChannelsPendingSortableColumns: Array<keyof ChannelPending> = [
	"acceptingNodeId",
	"channelCapacity",
	"channelId",
	"channelStatus",
	"closedOn",
	"closedOnSecondsDelta",
	"closingBlockHeight",
	"closingBlockHeightDelta",
	"closingNodeAlias",
	"closingNodeId",
	"closingTransactionHash",
	"fundedOn",
	"fundedOnSecondsDelta",
	"fundingBlockHeight",
	"fundingBlockHeightDelta",
	"fundingNodeAlias",
	"fundingTransactionHash",
	"initiatingNodeId",
	"lndShortChannelId",
	"nodeAlias",
	"nodeCssColour",
	"nodeId",
	"nodeImplementation",
	"nodeName",
	"nodePublicKey",
	"peerNodeAlias",
	"peerNodeCssColour",
	"peerNodeId",
	"peerNodeImplementation",
	"peerNodeName",
	"peerNodePublicKey",
	"shortChannelId",
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const ChannelsPendingFilterableColumns: Array<keyof ChannelPending> = [
	"acceptingNodeId",
	"channelCapacity",
	"channelId",
	"channelStatus",
	"closedOn",
	"closedOnSecondsDelta",
	"closingBlockHeight",
	"closingBlockHeightDelta",
	"closingNodeAlias",
	"closingNodeId",
	"closingTransactionHash",
	"fundedOn",
	"fundedOnSecondsDelta",
	"fundingBlockHeight",
	"fundingBlockHeightDelta",
	"fundingNodeAlias",
	"fundingTransactionHash",
	"initiatingNodeId",
	"lndShortChannelId",
	"nodeAlias",
	"nodeCssColour",
	"nodeId",
	"nodeImplementation",
	"nodeName",
	"nodePublicKey",
	"peerNodeAlias",
	"peerNodeCssColour",
	"peerNodeId",
	"peerNodeImplementation",
	"peerNodeName",
	"peerNodePublicKey",
	"shortChannelId",
	"tags",
];