import { ArrowRouting20Regular as Icon } from "@fluentui/react-icons";
import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import { WorkflowRunLogDetailAction } from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import { LogParameters } from "components/workflow/LogDetails/Parameters/LogParameters";
import ParentsLog from "components/workflow/LogDetails/ParentsLog/ParentsLog";
import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";
import useTranslations from "services/i18n/useTranslations";

export function OpenChannelLog(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();
  return (
    <LogDetailsWrapper
      type={LogDetailType.action}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.action} />
      <LogParameters
        parameters={props.parameters || ""}
        systemVariables={props.systemVariables || ""}
        colorType={LogDetailType.action}
        nodeType={"openChannel"}
        localizationObjects={["openCloseChannel"]}
      />
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
