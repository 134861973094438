import { Tag } from "pages/tags/tagsTypes";
import { Workflow } from "pages/WorkflowPage/workflowTypes";

export type NodeSettings = {
  nodeId: number;
  chain: number;
  network: number;
  publicKey: string;
  name?: string;
  channelStatus?: number;
  alias?: string;
};

export type FilterExecution = {
  torqNode?: NodeSettings;
  channel?: Channel;
  key: string;
  dataValueString: string;
  dataValueFloat: number;
  filterValueString: string;
  filterValueFloat: number;
  result: boolean;
  category: string;
  funcName: string;
  systemVariableName?: string;
};

export type Channel = {
  torqNodeId: number;
  nodeAlias?: string;
  nodeName: string;
  nodeColour?: string;
  peerNodeId: number;
  peerAlias: string;
  channelId: number;
  shortChannelId: string;
  executions?: FilterExecution[];
};

export type HtlcInterceptedEvent = {
  eventTime: Date;
  torqNodeId: number;
  channelId: number;
  lndShortChannelId: number;
  htlcId: number;
  incomingAmountMsat: number;
  incomingExpirationBlockHeight: number;
  paymentHash: string;
  outgoingRequestedChannelId?: number;
  outgoingRequestedLndShortChannelId?: number;
  outgoingAmountMsat?: number;
  outgoingExpirationBlockHeight?: number;
  onionBlob: string;
  automaticFailBlockHeight: number;
};

export type ChannelOpenInterceptionEvent = {
  eventTime: Date;
  torqNodeId: number;

  publicKey: string;
  peerPublicKey: string;
  fundingAmountSat: number;
  pushAmountMsat: number;
  dustLimitSat: number;
  maxValueInFlightMsat: number;
  channelReserveSat: number;
  feePerKw: number;
  csvDelay: number;
  maxAcceptedHtlcs: number;
  commitmentType: string;
  wantsZeroConf: boolean;
  wantsScidAlias: boolean;

  defaultAcceptChannelOpen?: boolean;
  defaultRejectReason?: string;
  acceptChannelOpen?: boolean;
  rejectReason?: string;
};

export type TransactionEvent = {
  eventTime: Date;
  torqNodeId: number;

  timestamp: Date;
  transactionHash: string;
  amount: number;
  blockHash: string;
  blockHeight: number;
  totalFees: number;
  destinationAddresses: string[];
  rawTransactionHex: string;
  label: string;
};

export type HtlcChannelJoinAction = {
  andOperation: boolean;
  input1Channels: Channel[];
  input2Channels: Channel[];
  outputChannels: Channel[];
};

export type LogActionType =
  | SourceAction[]
  | FilterAction[]
  | TagAction[]
  | UntagAction[]
  | DataSourceHtlcInterceptedEventAction[]
  | HtlcChannelJoinAction[]
  | HtlcInterceptionBlockerAction[]
  | NotificationAction[]
  | ApiClientCallAction[]
  | ChannelOpenInterceptionTriggerAction[]
  | DataSourceChannelOpenInterceptionEventAction[]
  | ChannelOpenInterceptionEventFilterAction[]
  | ChannelOpenInterceptionEventResultAction[]
  | DataSourceOnChainTransactionAction[]
  | ScorechainAction[];

export type WorkflowRunLogDetailAction = {
  changedOn: Date;
  id: number;
  type: number;
  parentIds?: number[];
  childIds?: number[];
  stage: number;
  iteration: number;
  name: string;
  parameters?: string;
  systemVariables?: string;
  errors?: ActionError[];
  actions?: LogActionType;
};

export type WorkflowRunLogDetailActionDag = {
  changedOn: Date;
  id: string;
  type: number;
  parentIds?: string[];
  childIds?: number[];
  stage: number;
  iteration: number;
  name: string;
  parameters?: string;
  errors?: ActionError[];
  actions?: LogActionType;
  selected?: boolean;
};

export type WorkflowRunLogDetailIteration = {
  [workflowVersionNodeId: string]: WorkflowRunLogDetailAction;
};

export type WorkflowRunLogDetailStage = {
  [iteration: string]: WorkflowRunLogDetailIteration;
};

export type WorkflowRunLogDetail = {
  [stage: number]: {
    trigger: WorkflowRunLogDetailAction;
    iterations: WorkflowRunLogDetailStage;
  };
};
export type WorkflowRunLogDetailResponse = {
  detail: WorkflowRunLogDetail;
  workflow: Workflow;
};

export type ActionError = {
  errorMessage: string;
  torqNode?: NodeSettings;
  channel?: Channel;
};

export type TagAction = {
  torqNode?: NodeSettings;
  tag: Tag;
  channel?: Channel;
  peerNode?: NodeSettings;
};

export type UntagAction = {
  torqNode?: NodeSettings;
  tag: Tag;
  channel?: Channel;
  peerNode?: NodeSettings;
};

export type FilterAction = {
  outgoingChannels: Channel[];
  filteredChannels: Channel[];
};

export type SourceAction = {
  source: string;
  outgoingChannels: Channel[];
};

export type DataSourceHtlcInterceptedEventAction = {
  incomingChannel?: Channel;
  outgoingChannel?: Channel;
  htlcInterceptedEvent: HtlcInterceptedEvent;
};

export enum htlcBlockedStatus {
  blocked = 0,
  continued = 1,
  notActivated = 2,
}

export type HtlcInterceptionBlockerAction = {
  torqNode?: NodeSettings;
  channel?: Channel;
  htlcId: number;
  blockedStatus: htlcBlockedStatus;
};

export enum CommunicationTargetType {
  CommunicationTelegramHighPriority = 0,
  CommunicationTelegramLowPriority = 1,
  CommunicationSlack = 2,
  CommunicationDiscord = 3,
  CommunicationEmail = 4,
}

export type NotificationAction = {
  messages: string[];
  targetNotificationChannels: CommunicationTargetType[];
  warnings: string[];
  source: string;
  outgoingChannels: Channel[];
};

export type ApiClientCallResponse = {
  requestPayload: string;
  responseBody: string;
  httpStatus: number;
};

export type ApiClientCallAction = {
  responses: ApiClientCallResponse[];
  payloadTemplate: string;
  address: string;
  method: string;
  warnings: string[];
  source: string;
  outgoingChannels: Channel[];
};

export type ChannelOpenInterceptionTriggerAction = {
  defaultAccept: boolean;
  defaultRejectReason?: string;
};

export type DataSourceChannelOpenInterceptionEventAction = {
  channelOpenInterceptionEvent: ChannelOpenInterceptionEvent;
};

export type ChannelOpenInterceptionEventFilterAction = {
  inputEvent: ChannelOpenInterceptionEvent;
  outputEvent?: ChannelOpenInterceptionEvent;
};

export type ChannelOpenInterceptionEventResultAction = {
  inputEvent: ChannelOpenInterceptionEvent;
  accept: boolean;
  rejectReason?: string;
};

export interface workflowSystemVariableLink {
  workflowSystemVariableLinkId: number;
  reference: string;
  workflowVersionNodeId: number;
  systemVariableId: number;
}

export type OnChainTransactionData = {
  torqNodeId: number;
  transactionHash: string;
}

export type DataSourceOnChainTransactionAction = {
  onChainTransactionData: OnChainTransactionData[];
};

export enum ChainalysisStatus {
  Pending = 0,
  Disabled = 1,
  Verified = 2,
  Suspicious = 3,
  Fraud = 4,
}

export type ScorechainAction = {
  onChainTransactionData: OnChainTransactionData;
  chainalysisStatus: ChainalysisStatus;
};
