import React, { useContext, useEffect, useState } from "react";
import useTranslations from "services/i18n/useTranslations";
import { ArrowEnter20Regular as Icon } from "@fluentui/react-icons";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import Note, { NoteType } from "features/note/Note";
import Form from "components/forms/form/Form";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import ToastContext from "features/toast/context";
import { useUpdateNodeMutation } from "pages/WorkflowPage/workflowApi";
import { toastCategory } from "features/toast/Toasts";
import Spinny from "features/spinny/Spinny";
import { Save16Regular as SaveIcon } from "@fluentui/react-icons";
import { InputSizeVariant, TextArea, RadioChips } from "components/forms/forms";

type ChannelOpenInterceptionTriggerNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export type ChannelOpenInterceptionTriggerParameters = {
  defaultAccept?: boolean;
  defaultRejectReason?: string;
};

export function ChannelOpenInterceptionTriggerNode({ ...wrapperProps }: ChannelOpenInterceptionTriggerNodeProps) {
  const { t } = useTranslations();

  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;
  const toastRef = React.useContext(ToastContext);

  const [updateNode] = useUpdateNodeMutation();

  const [configuration, setConfiguration] = useState<ChannelOpenInterceptionTriggerParameters>({
    defaultAccept: true,
    defaultRejectReason: "",
    ...wrapperProps.parameters,
  });

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    setDirty(
      JSON.stringify(wrapperProps.parameters, Object.keys(wrapperProps.parameters).sort()) !==
        JSON.stringify(configuration, Object.keys(configuration).sort()),
    );
  }, [configuration, wrapperProps.parameters]);

  function setDefaultAccept(accept: boolean) {
    return setConfiguration((prev) => ({
      ...prev,
      defaultAccept: accept,
    }));
  }

  function setDefaultRejectReason(text?: string) {
    return setConfiguration((prev) => ({
      ...prev,
      defaultRejectReason: text ? text : "",
    }));
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }
    setProcessing(true);
    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: configuration,
    }).finally(() => {
      setProcessing(false);
    });
  }

  return (
    <WorkflowNodeWrapper
      {...wrapperProps}
      name={t.workflowNodes.channelOpenInterceptionTrigger}
      headerIcon={<Icon />}
      colorVariant={NodeColorVariant.primary}
    >
      <div style={{ flexGrow: 1 }}>
        <Note title={t.note} noteType={NoteType.info}>
          <p>{t.workflowNodes.channelOpenInterceptionTriggerNodeDescription}</p>
        </Note>
      </div>
      <Form onSubmit={handleSubmit} intercomTarget={"channel-open-interception-trigger-node-form"}>
        <RadioChips
          label={t.workflowNodes.channelOpenInterceptionTriggerDefaultAccept}
          sizeVariant={InputSizeVariant.normal}
          vertical={false}
          groupName={"channel-open-interception-trigger-node-accept-default-switch"}
          options={[
            {
              label: t.workflowNodes.channelOpenInterceptionTriggerDefaultAcceptDeny,
              id: "channel-open-interception-trigger-node-defaultAcceptDeny",
              checked: !configuration.defaultAccept,
              onChange: () => setDefaultAccept(false),
            },
            {
              label: t.workflowNodes.channelOpenInterceptionTriggerDefaultAcceptAllow,
              id: "channel-open-interception-trigger-node-defaultAcceptAllow",
              checked: configuration.defaultAccept,
              onChange: () => setDefaultAccept(true),
            },
          ]}
        />
        {!configuration.defaultAccept &&
        <TextArea
          intercomTarget={"channel-open-interception-trigger-node-defaultRejectReason"}
          label={t.workflowNodes.channelOpenInterceptionTriggerDefaultRejectReason}
          sizeVariant={InputSizeVariant.normal}
          value={configuration.defaultRejectReason}
          rows={3}
          onChange={(e) => {
            setDefaultRejectReason(e.target.value);
          }}
        />
        }
        <Button
          intercomTarget={"channel-open-interception-trigger-node-save-button"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing || editingDisabled}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
      </Form>
    </WorkflowNodeWrapper>
  );
}
