import { Play20Regular as Icon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeButtonWrapper from "components/workflow/nodeButtonWrapper/NodeButtonWrapper";
import { WorkflowNodeType } from "pages/WorkflowPage/constants";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";

export function DataSourceChannelOpenInterceptionEventNodeButton(props: { disabled?: boolean }) {
  const { t } = useTranslations();

  return (
    <WorkflowNodeButtonWrapper
      disabled={props.disabled}
      intercomTarget={"channel-open-interception-event-node-button"}
      colorVariant={NodeColorVariant.accent2}
      nodeType={WorkflowNodeType.DataSourceChannelOpenInterceptionEvent}
      icon={<Icon />}
      title={t.workflowNodes.channelOpenInterceptionEvent}
    />
  );
}
