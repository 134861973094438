import React, { useContext, useEffect, useState } from "react";
import { MailAlert20Regular as NotificationIcon, Save16Regular as SaveIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import { SelectWorkflowNodeLinks, SelectWorkflowNodes, useUpdateNodeMutation } from "pages/WorkflowPage/workflowApi";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { Form, RadioChips, InputSizeVariant, Socket, Switch, TextArea, InputRow, Select } from "components/forms/forms";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import { useSelector } from "react-redux";
import Spinny from "features/spinny/Spinny";
import { SwitchSize } from "components/forms/switch/Switch";
import { SelectOption } from "components/forms/select/Select";
import { AllChannelsColumns } from "features/channels/channelsColumns.generated";
import { ServerErrorResultType, ToastErrStringFromServerError } from "components/errors/errors";
import { AllInvoicesColumns } from "features/transact/Invoices/invoicesColumns.generated";
import { AllPaymentsColumns } from "features/transact/Payments/paymentsColumns.generated";
import { TemplateOnChainTransactionDataSelection } from "features/transact/OnChain/onChainDefaults";
import { AllForwardsDataColumns } from "features/forwards/forwardsDataColumns";
import SystemVariableTextAreaWrapper, {
  getSystemVariablesFromText,
  makeSystemVariableReference,
} from "components/common/systemVariableInputWrapper/SystemVariableTextAreaWrapper";
import { systemVariable } from "apiTypes";
import { WorkflowVersionNodeSystemVariablesMap } from "pages/WorkflowPage/workflowTypes";
import { checkNodeInputsDirty } from "components/workflow/nodes/nodeFunctions";

type NotificationNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export enum CommunicationTargetType {
  TelegramHigh,
  TelegramLow,
  Slack,
  Discord,
  Email,
}

enum NotificationSendType {
  EachChannel,
  EachNode,
  Single,
}

type NotificationTemplateDataOption = {
  templateVarName: string;
  label: string;
};

const NotificationTemplateChannelDataSelection: NotificationTemplateDataOption[] = [
  ...(AllChannelsColumns.map((c) => {
    return {
      label: c.heading,
      templateVarName: "{{Channels." + c.key + "}}",
    } as NotificationTemplateDataOption;
  }) as NotificationTemplateDataOption[]),
];

const NotificationTemplateInvoiceDataSelection: NotificationTemplateDataOption[] = [
  ...(AllInvoicesColumns.map((c) => {
    return {
      label: c.heading,
      templateVarName: "{{Invoice." + c.key + "}}",
    } as NotificationTemplateDataOption;
  }) as NotificationTemplateDataOption[]),
];

const NotificationTemplateForwardDataSelection: NotificationTemplateDataOption[] = [
  ...(AllForwardsDataColumns.map((c) => {
    return {
      label: c.heading,
      templateVarName: "{{Forward." + c.key + "}}",
    } as NotificationTemplateDataOption;
  }) as NotificationTemplateDataOption[]),
];

const NotificationTemplatePaymentDataSelection: NotificationTemplateDataOption[] = [
  ...(AllPaymentsColumns.map((c) => {
    return {
      label: c.heading,
      templateVarName: "{{Payment." + c.key + "}}",
    } as NotificationTemplateDataOption;
  }) as NotificationTemplateDataOption[]),
];

const NotificationTemplateNodeDataSelection: NotificationTemplateDataOption[] = [
  {
    label: "Name",
    templateVarName: "{{Nodes.Name}}",
  },
  {
    label: "Alias",
    templateVarName: "{{Nodes.Alias}}",
  },
  {
    label: "Public Key",
    templateVarName: "{{Nodes.PublicKey}}",
  },
  {
    label: "Network",
    templateVarName: "{{Nodes.Network}}",
  },
  {
    label: "Chain",
    templateVarName: "{{Nodes.Chain}}",
  },
];

const NotificationTemplateHtlcDataSelection: NotificationTemplateDataOption[] = [
  {
    label: "Automatic Fail Block Height",
    templateVarName: "{{Htlc.automaticFailBlockHeight}}",
  },
  {
    label: "Event Time",
    templateVarName: "{{Htlc.eventTime}}",
  },
  {
    label: "Incoming Lnd Short Channel ID",
    templateVarName: "{{Htlc.lndShortChannelId}}",
  },
  {
    label: "Incoming Amount msat",
    templateVarName: "{{Htlc.incomingAmountMsat}}",
  },
  {
    label: "Incoming Expiration Block Height",
    templateVarName: "{{Htlc.incomingExpirationBlockHeight}}",
  },
  {
    label: "Payment Hash",
    templateVarName: "{{Htlc.paymentHash}}",
  },
  {
    label: "Outgoing Lnd Short Channel ID",
    templateVarName: "{{Htlc.outgoingRequestedLndShortChannelId}}",
  },
  {
    label: "Outgoing Amount msat",
    templateVarName: "{{Htlc.outgoingAmountMsat}}",
  },
  {
    label: "Outgoing Expiration Block Height",
    templateVarName: "{{Htlc.outgoingExpirationBlockHeight}}",
  },
  {
    label: "Onion Blob",
    templateVarName: "{{Htlc.onionBlob}}",
  },
];

export type NotificationParameters = {
  textInput?: string;
  emailSubject?: string;
  notificationChannels?: CommunicationTargetType[];
  notificationSendType?: NotificationSendType;
};

export function NotificationNode({ ...wrapperProps }: NotificationNodeProps) {
  const { t } = useTranslations();

  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;
  const toastRef = React.useContext(ToastContext);
  const textAreaId = "notificationTextInput" + wrapperProps.workflowVersionNodeId.toString();
  const subjectTextAreaId = "notificationSubjectTextInput" + wrapperProps.workflowVersionNodeId.toString();
  const [isSubjectFocused, setIsSubjectFocused] = useState(false);

  const [updateNode] = useUpdateNodeMutation();

  const [configuration, setConfiguration] = useState<NotificationParameters>({
    textInput: "",
    emailSubject: "",
    notificationChannels: [],
    notificationSendType: NotificationSendType.EachChannel,
    ...wrapperProps.parameters,
  });

  const [systemVariables, setSystemVariables] = useState<WorkflowVersionNodeSystemVariablesMap>(
    {} as WorkflowVersionNodeSystemVariablesMap,
  );

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!processing) {
      // remove/add variables based on the text
      const text = configuration.textInput || "";

      // remove if not in text
      for (const ref in systemVariables) {
        if (text.indexOf("{{System." + ref + "}}") === -1) {
          setVariable(undefined, ref);
        }
      }

      // add new variables from text if they don't exist
      const newVariables = getSystemVariablesFromText(text);
      for (const ref in newVariables) {
        if (!systemVariables[ref]) {
          setVariable(newVariables[ref], ref);
        }
      }
    }
  }, [configuration.textInput, processing]);

  useEffect(() => {
    if (!processing) {
      setSystemVariables(wrapperProps.systemVariables || {});
    }
  }, [wrapperProps.systemVariables, processing]);

  useEffect(() => {
    if (!processing) {
      setDirty(
        checkNodeInputsDirty(wrapperProps.parameters, configuration, wrapperProps.systemVariables, systemVariables),
      );
    }
  }, [configuration, wrapperProps.parameters, processing, wrapperProps.systemVariables, systemVariables]);

  const { childLinks } = useSelector(
    SelectWorkflowNodeLinks({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeId: wrapperProps.workflowVersionNodeId,
      stage: wrapperProps.stage,
    }),
  );

  const parentNodeIds = childLinks?.map((link) => link.parentWorkflowVersionNodeId) ?? [];
  const parentNodes = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: parentNodeIds,
    }),
  );

  function setText(text?: string) {
    return setConfiguration((prev) => ({
      ...prev,
      textInput: text ? text : "",
    }));
  }

  function setEmailSubject(text?: string) {
    return setConfiguration((prev) => ({
      ...prev,
      emailSubject: text ? text : "",
    }));
  }

  function toggleNotificationChannel(channel: CommunicationTargetType) {
    if (configuration.notificationChannels?.includes(channel)) {
      return setConfiguration((prev) => ({
        ...prev,
        notificationChannels: prev.notificationChannels?.filter((c: CommunicationTargetType) => c !== channel),
      }));
    } else {
      const channels = configuration.notificationChannels ? configuration.notificationChannels : [];
      return setConfiguration((prev) => ({
        ...prev,
        notificationChannels: [...channels, channel],
      }));
    }
  }

  function setSendType(type?: NotificationSendType) {
    return setConfiguration((prev) => ({
      ...prev,
      notificationSendType: type ? type : NotificationSendType.EachChannel,
    }));
  }

  function setVariable(variable: systemVariable | undefined, fieldName: string) {
    if (variable === undefined) {
      const newSystemVariables = { ...systemVariables };
      delete newSystemVariables[fieldName];
      setSystemVariables(newSystemVariables);
      return;
    }
    setSystemVariables((prev) => ({
      ...prev,
      [fieldName]: variable,
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleTemplateVarSelect(item: any) {
    //add the variable to cursor selection in message
    const textarea = document.getElementById(textAreaId) as HTMLTextAreaElement;
    if (!isSubjectFocused && textarea && typeof textarea.selectionStart === "number" && typeof textarea.selectionEnd === "number") {
      const { selectionStart, selectionEnd } = textarea;
      const start = Math.min(selectionStart, selectionEnd);
      const end = Math.max(selectionStart, selectionEnd);
      setText(configuration.textInput?.slice(0, start) + item.value + configuration.textInput?.slice(end));
      return
    }
    //add the variable to cursor selection in subject
    const subjectTextarea = document.getElementById(subjectTextAreaId) as HTMLTextAreaElement;
    if (isSubjectFocused && subjectTextarea && typeof subjectTextarea.selectionStart === "number" && typeof subjectTextarea.selectionEnd === "number") {
      const { selectionStart, selectionEnd } = subjectTextarea;
      const start = Math.min(selectionStart, selectionEnd);
      const end = Math.max(selectionStart, selectionEnd);
      setEmailSubject(configuration.emailSubject?.slice(0, start) + item.value + configuration.emailSubject?.slice(end));
      return
    }
    //add to end of message if cursor not in text
    setText(`${configuration.textInput} ${item.value}`);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }
    setProcessing(true);
    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: configuration,
      systemVariables: systemVariables,
    })
      .unwrap()
      .catch((error: ServerErrorResultType) => {
        const errMsg = ToastErrStringFromServerError(error, "Errors in node parameters", "Invalid node parameters");
        toastRef?.current?.addToast(errMsg, toastCategory.error);
      })
      .finally(() => {
        setProcessing(false);
      });
  }

  return (
    <WorkflowNodeWrapper
      {...wrapperProps}
      heading={t.workflowNodes.notifier}
      headerIcon={<NotificationIcon />}
      colorVariant={NodeColorVariant.accent3}
    >
      <Form onSubmit={handleSubmit} intercomTarget={"notification-node-form"}>
        <Socket
          collapsed={wrapperProps.visibilitySettings.collapsed}
          selectedNodes={parentNodes || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          inputName={"channels"}
          editingDisabled={editingDisabled}
        />
        <InputRow>
          <RadioChips
            label={t.notificationSendType}
            sizeVariant={InputSizeVariant.small}
            vertical={true}
            groupName={"send-type-switch-" + wrapperProps.workflowVersionNodeId}
            options={[
              {
                label: t.notificationSendTypeSelect.eachChannel,
                id: "send-type-switch-eachChannel-" + wrapperProps.workflowVersionNodeId,
                checked: configuration.notificationSendType === NotificationSendType.EachChannel,
                onChange: () => setSendType(NotificationSendType.EachChannel),
              },
              {
                label: t.notificationSendTypeSelect.eachNode,
                id: "send-type-switch-eachNode-" + wrapperProps.workflowVersionNodeId,
                checked: configuration.notificationSendType === NotificationSendType.EachNode,
                onChange: () => setSendType(NotificationSendType.EachNode),
              },
              {
                label: t.notificationSendTypeSelect.single,
                id: "send-type-switch-single-" + wrapperProps.workflowVersionNodeId,
                checked: configuration.notificationSendType === NotificationSendType.Single,
                onChange: () => setSendType(NotificationSendType.Single),
              },
            ]}
            editingDisabled={editingDisabled}
          />
          <div>
            <Switch
              sizeVariant={SwitchSize.small}
              intercomTarget={"notification-node-telegram-low-switch"}
              label={t.notificationChannels.telegramLow}
              checked={configuration.notificationChannels?.includes(CommunicationTargetType.TelegramLow)}
              onChange={() => {
                toggleNotificationChannel(CommunicationTargetType.TelegramLow);
              }}
            />
            <Switch
              sizeVariant={SwitchSize.small}
              intercomTarget={"notification-node-telegram-high-switch"}
              label={t.notificationChannels.telegramHigh}
              checked={configuration.notificationChannels?.includes(CommunicationTargetType.TelegramHigh)}
              onChange={() => {
                toggleNotificationChannel(CommunicationTargetType.TelegramHigh);
              }}
            />
            <Switch
              sizeVariant={SwitchSize.small}
              intercomTarget={"notification-node-slack-switch"}
              label={t.notificationChannels.slack}
              checked={configuration.notificationChannels?.includes(CommunicationTargetType.Slack)}
              onChange={() => {
                toggleNotificationChannel(CommunicationTargetType.Slack);
              }}
            />
            <Switch
              sizeVariant={SwitchSize.small}
              intercomTarget={"notification-node-discord-switch"}
              label={t.notificationChannels.discord}
              checked={configuration.notificationChannels?.includes(CommunicationTargetType.Discord)}
              onChange={() => {
                toggleNotificationChannel(CommunicationTargetType.Discord);
              }}
            />
            <Switch
              sizeVariant={SwitchSize.small}
              intercomTarget={"notification-node-email-switch"}
              label={t.notificationChannels.email}
              checked={configuration.notificationChannels?.includes(CommunicationTargetType.Email)}
              onChange={() => {
                toggleNotificationChannel(CommunicationTargetType.Email);
              }}
            />
          </div>
        </InputRow>
        <Select
          intercomTarget={"notification-template-node-var-select"}
          options={NotificationTemplateNodeDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.notificationSendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.node }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />
        <Select
          intercomTarget={"notification-template-channel-var-select"}
          options={NotificationTemplateChannelDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.notificationSendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.channel }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />

        <Select
          intercomTarget={"notification-template-htlc-var-select"}
          options={NotificationTemplateHtlcDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.notificationSendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.htlc }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />

        <Select
          intercomTarget={"notification-template-forward-var-select"}
          options={NotificationTemplateForwardDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.notificationSendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.forward }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />

        <Select
          intercomTarget={"notification-template-invoice-var-select"}
          options={NotificationTemplateInvoiceDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.notificationSendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.invoice }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />

        <Select
          intercomTarget={"notification-template-payment-var-select"}
          options={NotificationTemplatePaymentDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.notificationSendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.payment }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />

        <Select
          intercomTarget={"notification-template-transaction-var-select"}
          options={TemplateOnChainTransactionDataSelection.map((s) => {
            return {
              label: s.label,
              value: s.templateVarName,
              isDisabled: configuration.notificationSendType == undefined,
            } as SelectOption;
          })}
          value={""}
          placeholder={<span dangerouslySetInnerHTML={{ __html: t.notificationVarSelect.onChainTransaction }}></span>}
          onChange={handleTemplateVarSelect}
          sizeVariant={InputSizeVariant.small}
        />
        {configuration.notificationChannels?.includes(CommunicationTargetType.Email) &&
        <SystemVariableTextAreaWrapper
          handleVariableChange={(variable: systemVariable | undefined) => {
            if (variable) {
              const variableReference = makeSystemVariableReference(variable);
              // insert the variable into text
              handleTemplateVarSelect({ value: `{{System.${variableReference}}}` });
              setVariable(variable, variableReference);
            }
          }}
          editingDisabled={editingDisabled}
          disableVariableInput={editingDisabled}
        >
          <TextArea
            id={subjectTextAreaId}
            intercomTarget={"notification-node-subject"}
            label={t.notificationSubjectLabel}
            helpText={t.notificationSubjectHelp}
            sizeVariant={InputSizeVariant.normal}
            value={configuration.emailSubject as string}
            rows={1}
            placeholder={t.notificationSubjectPlaceholder}
            onChange={(e) => {
              setEmailSubject(e.target.value);
            }}
            onFocus={() => {
              setIsSubjectFocused(true);
            }}
          />
        </SystemVariableTextAreaWrapper>
        }
        <SystemVariableTextAreaWrapper
          handleVariableChange={(variable: systemVariable | undefined) => {
            if (variable) {
              const variableReference = makeSystemVariableReference(variable);
              // insert the variable into text
              handleTemplateVarSelect({ value: `{{System.${variableReference}}}` });
              setVariable(variable, variableReference);
            }
          }}
          editingDisabled={editingDisabled}
          disableVariableInput={editingDisabled}
        >
          <TextArea
            id={textAreaId}
            intercomTarget={"notification-node-text"}
            label={t.notificationMessageLabel}
            helpText={t.notificationMessageHelp}
            sizeVariant={InputSizeVariant.normal}
            value={configuration.textInput as string}
            rows={10}
            placeholder={t.notificationMessagePlaceholder}
            onChange={(e) => {
              setText(e.target.value);
            }}
            onFocus={() => {
              setIsSubjectFocused(false);
            }}
          />
        </SystemVariableTextAreaWrapper>

        <Button
          intercomTarget={"notification-node-save"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing}
          onClick={() => {
            if (!configuration.notificationChannels?.includes(CommunicationTargetType.Email)) {
              setEmailSubject("");
            }
          }}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
      </Form>
    </WorkflowNodeWrapper>
  );
}
