import { Play20Regular as Icon } from "@fluentui/react-icons";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import Note, { NoteType } from "features/note/Note";
import useTranslations from "services/i18n/useTranslations";

type DataSourceOnChainTransactionNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export function DataSourceOnChainTransactionNode({ ...wrapperProps }: DataSourceOnChainTransactionNodeProps) {
  const { t } = useTranslations();

  return (
    <WorkflowNodeWrapper
      {...wrapperProps}
      headerIcon={<Icon />}
      colorVariant={NodeColorVariant.accent2}
      outputName={"onChainTransaction"}
    >
    <Note title={t.note} noteType={NoteType.info}>
      <p>{t.workflowNodes.onChainTransactionDataSourceNote}</p>
    </Note>
    </WorkflowNodeWrapper>
  );
}
