export const ROOT = "/";

export const LOGIN = "login";
export const BOOTSTRAPPING = "bootstrapping";
export const SERVICES = "services";
export const COOKIELOGIN = "cookie-login";
export const LOGOUT = "logout";

export const ANALYSE = "analyse";
export const MANAGE = "manage";
export const FORWARDS = "forwards";
export const TAGS = "tags";
export const FORWARDS_CUSTOM_VIEW = `${FORWARDS}/:viewId`;
export const CHANNELS = "channels";
export const OPEN_CHANNELS = "open-channels";
export const PENDING_CHANNELS = "pending-channels";
export const CLOSED_CHANNELS = "closed-channels";
export const INSPECT_CHANNEL = "/analyse/inspect/:chanId/node/:nodeId";
export const FORWARDS_SUMMARY = "forwards-summary";

export const TRANSACTIONS = "transactions";
export const PAYMENTS = "payments";
export const INVOICES = "invoices";
export const TXO = "txo";
export const PEERS = "peers";
export const LSP = "lsp";
export const LSP_CLIENT_CHANNEL_REQUEST_PURCHASES = "lsp-client-channel-request-purchases";
export const LSP_SERVER_CHANNEL_REQUEST_PROVIDERS = "lsp-server-channel-request-providers";
export const LSP_CLIENT_CHANNEL_REQUEST_SALES = "lsp-client-channel-request-sales";
export const LSP_SERVER_CHANNEL_REQUEST_OFFERS = "lsp-server-channel-request-offers";
export const ALL = "all";

export const SETTINGS = "/settings";

// modals
export const NEW_INVOICE = "/new-invoice";
export const NEW_PAYMENT = "/new-payment";
export const NEW_ADDRESS = "/new-address";
export const UPDATE_CHANNEL = "/update-channel";
export const OPEN_CHANNEL = "/open-channel";
export const CLOSE_CHANNEL = "/close-channel";
export const TAG = "/create-tag";
export const UPDATE_TAG = "/update-tag/:tagId";
export const TAG_CHANNEL = "/tag-channel/:channelId/node/:nodeId";
export const TAG_NODE = "/tag-peer/:peerId/node/:nodeId";
export const MESSAGE_VERIFICATION = "/message-verification";
export const CONNECT_PEER = "/connect-peer";
export const UPDATE_PEER = "/edit-peer";
export const ADD_TORQ_NODE = "/node/new";
export const UPDATE_TORQ_NODE = "/node/:torqNodeId";
export const NODE_CONNECTION_STATUS = "/node-connection-status";
export const LSP_CHANNEL_REQUEST = "/channel-request/:torqNodeId/peer/:peerNodeId";
export const LSP_CHANNEL_REQUEST_SERVER = "/channel-request-server/:torqNodeId/peer/:peerNodeId";
export const SYSTEM_VARIABLE = "/system-variable";
export const ADD_SYSTEM_VARIABLE = SYSTEM_VARIABLE + "/new/:workflowId";
export const UPDATE_SYSTEM_VARIABLE = SYSTEM_VARIABLE + "/update/:workflowId/:systemVariableId";

// Automation
export const WORKFLOWS = "workflows";
export const WORKFLOW = "workflows/:workflowId/versions/:version";
export const WORKFLOW_RUN_LOGS = "workflow-run-logs";
export const WORKFLOW_RUN_LOG_DETAIL = `${WORKFLOW_RUN_LOGS}/:workflowLogId`;

export const MOVE_FUNDS = "move-funds";
