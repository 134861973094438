import Button, { ColorVariant, ButtonWrapper } from "components/buttons/Button";
import { ProgressTabContainer } from "features/progressTabs/ProgressTab";
import styles from "features/transact/NewPayment/newPayments.module.scss";
import { SendOnChainResponse } from "types/api";
import classNames from "classnames";
import { useEffect } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { ProgressStepState } from "features/progressTabs/ProgressHeader";
import { format } from "d3";
import {
  DetailsContainer,
  DetailsRow,
  DetailsRowLinkAndCopy,
} from "features/templates/popoutPageTemplate/popoutDetails/PopoutDetails";
import { StatusIcon } from "features/templates/popoutPageTemplate/popoutDetails/StatusIcon";
import { useGetServicesQuery } from "apiSlice";
import { RtqToServerError } from "components/errors/errors";

const f = format(",.0f");

type OnChainPaymentResponseProps = {
  response?: {
    data?: SendOnChainResponse;
    isUninitialized: boolean;
    isSuccess?: boolean;
    isLoading?: boolean;
    isError?: boolean;
    error?: FetchBaseQueryError | SerializedError;
  };
  amount: number;
  selectedNodeId: number;
  setProcessState: (state: ProgressStepState) => void;
  destination: string;
  clearPaymentFlow: () => void;
};

export function OnChainPaymentResponse(props: OnChainPaymentResponseProps) {
  useEffect(() => {
    if (props.response?.isSuccess) {
      props.setProcessState(ProgressStepState.completed);
    } else if (props.response?.isError) {
      props.setProcessState(ProgressStepState.error);
    }
  }, [props.response]);

  const { data: servicesData } = useGetServicesQuery();
  const mempoolUrl = servicesData?.mempoolUrl + `/tx/${props.response?.data}`;

  return (
    <ProgressTabContainer>
      {props.response?.isSuccess && (
        <div className={classNames(styles.paymentStatusMessage)}>
          <div className={styles.amountPaid}>{`${f(props.amount)} sat`}</div>
          <div className={styles.amountPaidText}>{`On-chain payment broadcasted`}</div>
        </div>
      )}
      {props.response?.isError && (
        <div className={classNames(styles.paymentStatusMessage)}>
          <div className={styles.amountPaidText}>{`Failed on-chain payment`}</div>
        </div>
      )}
      <StatusIcon state={props.response?.isSuccess ? "success" : props.response?.isError ? "error" : "processing"} />
      {props.response?.isSuccess && (
        <div>
          <DetailsContainer>
            <DetailsRow label={"Destination:"}>{props.destination}</DetailsRow>
          </DetailsContainer>
          <DetailsContainer>
            <DetailsRowLinkAndCopy label={"Transaction ID:"} copy={props.response?.data} link={mempoolUrl}>
              {props.response?.data}
            </DetailsRowLinkAndCopy>
          </DetailsContainer>
        </div>
      )}
      {props.response?.isError && (
        <div>
          <DetailsContainer>
            {RtqToServerError(props?.response?.error)?.errors?.server?.map((e, i) => {
              return (
                <DetailsRow key={i} label={"Error:"}>
                  {e?.description}
                </DetailsRow>
              );
            })}
          </DetailsContainer>
        </div>
      )}
      <ButtonWrapper
        className={styles.customButtonWrapperStyles}
        rightChildren={
          <Button
            intercomTarget={"new-payment-reset-button"}
            onClick={() => {
              props.clearPaymentFlow();
            }}
            buttonColor={ColorVariant.primary}
          >
            {"New payment"}
          </Button>
        }
      />
    </ProgressTabContainer>
  );
}
