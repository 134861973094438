import { MailAlert20Regular as Icon } from "@fluentui/react-icons";
import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import {
  CommunicationTargetType,
  NotificationAction,
  WorkflowRunLogDetailAction,
} from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import ParentsLog from "components/workflow/LogDetails/ParentsLog/ParentsLog";
import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";
import useTranslations from "services/i18n/useTranslations";
import styles from "components/workflow/LogDetails/NotificationLog/notificationLog.module.scss";
import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";

export function NotificationLog(props: WorkflowRunLogDetailAction) {
  const { t } = useTranslations();
  const params = JSON.parse(props.parameters || "{}");
  const data = props?.actions ? (props.actions[0] as NotificationAction) : undefined;

  return (
    <LogDetailsWrapper
      type={LogDetailType.action}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      <ParentsLog title={t.parents} parentIds={props.parentIds} type={LogDetailType.action} />
      <LogContainer
        title={t.selectedCommChannels}
        type={LogDetailType.action}
        count={params?.notificationChannels?.length}
      >
        {params?.notificationChannels &&
          (params?.notificationChannels as number[]).map((c, i) => {
            let commType = "";

            switch (c) {
              case CommunicationTargetType.CommunicationTelegramLowPriority:
                commType = t.notificationChannels.telegramLow;
                break;
              case CommunicationTargetType.CommunicationTelegramHighPriority:
                commType = t.notificationChannels.telegramHigh;
                break;
              case CommunicationTargetType.CommunicationSlack:
                commType = t.notificationChannels.slack;
                break;
              case CommunicationTargetType.CommunicationDiscord:
                commType = t.notificationChannels.discord;
                break;
              case CommunicationTargetType.CommunicationEmail:
                commType = t.notificationChannels.email;
                break;
              default:
                commType = t.unknown;
            }
            return (
              <div key={i} className={styles.containerRow}>
                {commType}
              </div>
            );
          })}
      </LogContainer>
      <LogContainer title={t.messageTemplate} type={LogDetailType.action}>
        {params?.emailSubject && <div>{params?.emailSubject}</div>}
        <div>{params?.textInput}</div>
      </LogContainer>
      <LogContainer title={t.messages} type={LogDetailType.action} count={!data ? 0 : data?.messages?.length}>
        {data?.messages &&
          data?.messages.map((m, i) => (
            <div key={i} className={styles.containerRow}>
              {m}
            </div>
          ))}
        {!data?.messages?.length && <div></div>}
      </LogContainer>
      {data?.warnings && data?.warnings?.length > 0 && (
        <LogContainer title={t.warnings} type={LogDetailType.warning}>
          {data?.warnings &&
            data?.warnings.map((w, i) => (
              <div key={i} className={styles.containerRow}>
                {w}
              </div>
            ))}
        </LogContainer>
      )}
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
