// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go


import { ColumnMetaData } from "features/table/types";
import { Htlc } from "features/htlcs/htlcsTypes";

export const AllHtlcsColumns: ColumnMetaData<Htlc>[] = [
	{
		heading: "Auto Fail BlockHeight",
		type: "DateCell",
		key: "automaticFailBlockHeight",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "HTLC ID",
		type: "NumericCell",
		key: "htlcId",
		valueType: "number",
		suffix: "",
		locked: true,
		supportedBy: [0, 1],
	},
	{
		heading: "Amount Incoming HTLC",
		type: "BarCell",
		key: "incomingAmountMsat",
		valueType: "number",
		suffix: "msat",
		supportedBy: [0, 1],
	},
	{
		heading: "Expiration BlockHeight Incoming HTLC",
		type: "NumericCell",
		key: "incomingExpirationBlockHeight",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Amount Outgoing HTLC",
		type: "BarCell",
		key: "outgoingAmountMsat",
		valueType: "number",
		suffix: "msat",
		supportedBy: [0, 1],
	},
	{
		heading: "Expiration BlockHeight Outgoing HTLC",
		type: "NumericCell",
		key: "outgoingExpirationBlockHeight",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Payment Hash",
		type: "TextCell",
		key: "paymentHash",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const HtlcsSortableColumns: Array<keyof Htlc> = [
	"automaticFailBlockHeight",
	"htlcId",
	"incomingAmountMsat",
	"incomingExpirationBlockHeight",
	"outgoingAmountMsat",
	"outgoingExpirationBlockHeight",
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const HtlcsFilterableColumns: Array<keyof Htlc> = [
	"automaticFailBlockHeight",
	"incomingAmountMsat",
	"incomingExpirationBlockHeight",
	"outgoingAmountMsat",
	"outgoingExpirationBlockHeight",
	"paymentHash",
];