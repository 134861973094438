// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

import { ColumnMetaData } from "features/table/types";
import { ForwardData } from "features/forwards/forwardsTypes";

export const AllForwardsDataColumns: ColumnMetaData<ForwardData>[] = [
  {
    heading: "Timestamp",
    type: "DateCell",
    key: "timestamp",
    valueType: "date",
    locked: true,
    supportedBy: [0, 1],
  },
  {
    heading: "Incoming Amount",
    type: "NumericCell",
    key: "incomingAmountMsat",
    valueType: "number",
    suffix: "msat",
    supportedBy: [0, 1],
  },
  {
    heading: "Incoming Channel ID",
    type: "NumericCell",
    key: "incomingChannelId",
    valueType: "number",
    supportedBy: [0, 1],
  },
  {
    heading: "Incoming Channel Capacity",
    type: "NumericCell",
    key: "incomingChannelCapacity",
    valueType: "number",
    suffix: "sat",
    supportedBy: [0, 1],
  },
  {
    heading: "Incoming Peer Alias",
    type: "AliasCell",
    key: "incomingPeerAlias",
    valueType: "string",
    supportedBy: [0, 1],
  },
  {
    heading: "Incoming Peer Public Key",
    type: "LongTextCell",
    key: "incomingPeerPublicKey",
    valueType: "string",
    supportedBy: [0, 1],
  },
  {
    heading: "Outgoing Amount",
    type: "NumericCell",
    key: "outgoingAmountMsat",
    valueType: "number",
    suffix: "msat",
    supportedBy: [0, 1],
  },
  {
    heading: "Outgoing Channel ID",
    type: "NumericCell",
    key: "outgoingChannelId",
    valueType: "number",
    supportedBy: [0, 1],
  },
  {
    heading: "Outgoing Channel Capacity",
    type: "NumericCell",
    key: "outgoingChannelCapacity",
    valueType: "number",
    suffix: "sat",
    supportedBy: [0, 1],
  },
  {
    heading: "Outgoing Peer Alias",
    type: "AliasCell",
    key: "outgoingPeerAlias",
    valueType: "string",
    supportedBy: [0, 1],
  },
  {
    heading: "Outgoing Peer Public Key",
    type: "LongTextCell",
    key: "outgoingPeerPublicKey",
    valueType: "string",
    supportedBy: [0, 1],
  },
  {
    heading: "Fee",
    type: "NumericCell",
    key: "feeMsat",
    valueType: "number",
    suffix: "msat",
    supportedBy: [0, 1],
  },
  {
    heading: "Torq Node ID",
    type: "NumericCell",
    key: "torqNodeId",
    valueType: "number",
    supportedBy: [0, 1],
  },
  {
    heading: "Torq Node Name",
    type: "TextCell",
    key: "torqNodeName",
    valueType: "string",
    supportedBy: [0, 1],
  },
  {
    heading: "Torq Node CSS Colour",
    type: "TextCell",
    key: "torqNodeCssColour",
    valueType: "string",
    supportedBy: [0, 1],
  },
  {
    heading: "Add Index",
    type: "NumericCell",
    key: "addIndex",
    valueType: "number",
    supportedBy: [0, 1],
  },
  {
    heading: "Update Index",
    type: "NumericCell",
    key: "updateIndex",
    valueType: "number",
    supportedBy: [0, 1],
  },
];
