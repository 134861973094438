import { ChannelRequestServer } from "features/lsp/lspTypes";
import panelStyles from "features/panel/panel.module.scss";
import classNames from "classnames";
import {
  GlobeRegular as GlobeIcon,
  IotRegular as PeersIcon,
  ArrowBidirectionalLeftRightRegular as SizeIcon,
  ArrowUploadRegular as UpperLimitIcon,
  ArrowDownloadRegular as LowerLimitIcon,
  TimerRegular as LeaseIcon,
  // CalendarLtrRegular as DateIcon,
  ArrowStepInRegular as InIcon,
  ArrowStepOutRegular as OutIcon,
  CheckmarkRegular as CheckmarkIcon,
  EditRegular as EditIcon,
  Add20Regular as NewChannelRequestIcon,
} from "@fluentui/react-icons";
import Button, { ColorVariant } from "components/buttons/Button";
import useTranslations from "services/i18n/useTranslations";
import { userEvents } from "utils/userEvents";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

function FormatSatComma(number: number): string {
  const btcPart = Math.floor(number / 100_000_000);
  const satPart = number % 100_000_000;
  return `${btcPart}.${String(Math.floor((satPart / 1_000_000) % 1_000)).padStart(2, "0")},${String(
    Math.floor((satPart / 1_000) % 1_000),
  ).padStart(3, "0")},${String(satPart % 1_000).padStart(3, "0")}`;
}

export default function channelRequestServerPanelRenderer(
  panel: ChannelRequestServer,
  panelIndex: number,
): JSX.Element {
  const { t } = useTranslations();
  const { track } = userEvents();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div
        id={"channel_request_server_panel_header" + panelIndex}
        className={classNames(panelStyles.panelHeaderWrapper)}
      >
        {panel.peerNodeAlias && (
          <>
            <div className={panelStyles.icon}>
              <PeersIcon />
            </div>
            <div className={panelStyles.title}>{panel.peerNodeAlias}</div>
          </>
        )}
        {!panel.peerNodeAlias && <div className={panelStyles.title}>{panel.torqNodeName}</div>}
      </div>
      <div className={classNames(panelStyles.panelDataRow)}>
        <GlobeIcon /> {panel.website}
      </div>
      <div className={classNames(panelStyles.panelDataRow)}>
        <CheckmarkIcon /> confirmed after {panel.minimumChannelConfirmations} blocks
      </div>
      <div className={classNames(panelStyles.panelDataRowSeparator)}></div>
      <div className={classNames(panelStyles.panelDataRow)}>
        <SizeIcon /> Channel Size
      </div>
      <div className={classNames(panelStyles.panelDataSplitRow)}>
        <div className={classNames(panelStyles.panelDataRowSectionLeft)}>
          <UpperLimitIcon /> Max:
        </div>
        <div className={classNames(panelStyles.panelDataRowSectionRight)}>
          {FormatSatComma(panel.maximumChannelCapacity)} sat
        </div>
      </div>
      <div className={classNames(panelStyles.panelDataSplitRow)}>
        <div className={classNames(panelStyles.panelDataRowSectionLeft)}>
          <LowerLimitIcon /> Min:
        </div>
        <div className={classNames(panelStyles.panelDataRowSectionRight)}>
          {FormatSatComma(panel.minimumChannelCapacity)} sat
        </div>
      </div>
      <div className={classNames(panelStyles.panelDataRowSeparator)}></div>
      <div className={classNames(panelStyles.panelDataRow)}>
        <LeaseIcon /> Lease Duration
      </div>
      <div className={classNames(panelStyles.panelDataSplitRow)}>
        <div className={classNames(panelStyles.panelDataRowSectionLeft)}>
          <UpperLimitIcon /> Max:
        </div>
        <div className={classNames(panelStyles.panelDataRowSectionRight)}>{panel.maximumChannelExpiryWeeks} weeks</div>
      </div>
      <div className={classNames(panelStyles.panelDataRowSeparator)}></div>
      <div className={classNames(panelStyles.panelDataRow)}>
        <InIcon /> Provider Balance Limits
      </div>
      <div className={classNames(panelStyles.panelDataSplitRow)}>
        <div className={classNames(panelStyles.panelDataRowSectionLeft)}>
          <UpperLimitIcon /> Max:
        </div>
        <div className={classNames(panelStyles.panelDataRowSectionRight)}>
          {FormatSatComma(panel.maximumInitialLspBalance)} sat
        </div>
      </div>
      <div className={classNames(panelStyles.panelDataSplitRow)}>
        <div className={classNames(panelStyles.panelDataRowSectionLeft)}>
          <LowerLimitIcon /> Min:
        </div>
        <div className={classNames(panelStyles.panelDataRowSectionRight)}>
          {FormatSatComma(panel.minimumInitialLspBalance)} sat
        </div>
      </div>
      <div className={classNames(panelStyles.panelDataRowSeparator)}></div>
      <div className={classNames(panelStyles.panelDataRow)}>
        <OutIcon /> Purchaser Balance Limits
      </div>
      <div className={classNames(panelStyles.panelDataSplitRow)}>
        <div className={classNames(panelStyles.panelDataRowSectionLeft)}>
          <UpperLimitIcon /> Max:
        </div>
        <div className={classNames(panelStyles.panelDataRowSectionRight)}>
          {FormatSatComma(panel.maximumInitialClientBalance)} sat
        </div>
      </div>
      <div className={classNames(panelStyles.panelDataSplitRow)}>
        <div className={classNames(panelStyles.panelDataRowSectionLeft)}>
          <LowerLimitIcon /> Min:
        </div>
        <div className={classNames(panelStyles.panelDataRowSectionRight)}>
          {FormatSatComma(panel.minimumInitialClientBalance)} sat
        </div>
      </div>
      {panel.peerNodeAlias && (
        <>
          {/*<div className={classNames(panelStyles.panelDataRowSeparator)}></div>*/}
          {/*<div className={classNames(panelStyles.panelDataSplitRow)}>*/}
          {/*  <div className={classNames(panelStyles.panelDataRowSectionLeft)}>*/}
          {/*    <DateIcon /> Age:*/}
          {/*  </div>*/}
          {/*  <div className={classNames(panelStyles.panelDataRowSectionRight)}>*/}
          {/*    {(panel.createdOn).toLocaleString()}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={classNames(panelStyles.panelDataButtonRow)}>
            <Button
              intercomTarget={"new-channelRequest-button"}
              buttonColor={ColorVariant.success}
              hideMobileText={true}
              icon={<NewChannelRequestIcon />}
              onClick={() => {
                track("Navigate to New Channel Request");
                navigate("/channel-request/" + panel.torqNodeId + "/peer/" + (panel.peerNodeId || 0), {
                  state: { background: location },
                });
              }}
            >
              {t.channelRequest}
            </Button>
          </div>
        </>
      )}
      {!panel.peerNodeAlias && (
        <>
          <div className={classNames(panelStyles.panelDataButtonRow)}>
            <Button
              intercomTarget={"edit-channel-request-server-navigate"}
              buttonColor={ColorVariant.success}
              icon={<EditIcon />}
              hideMobileText={true}
              onClick={() => {
                track("Edit Channel Request Server", {
                  torqNodeId: panel.torqNodeId,
                  peerNodeId: panel.peerNodeId,
                });
                navigate("/channel-request-server/" + panel.torqNodeId + "/peer/" + (panel.peerNodeId || 0), {
                  state: { background: location },
                });
              }}
            >
              {t.edit}
            </Button>
          </div>
        </>
      )}
    </>
  );
}
