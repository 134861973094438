import styles from "components/footer/footer.module.css";
import {
  PayProductEnterprise,
  PayProductFallback,
  PayProductFree,
  PayProductOrganization,
  PayProductProfessional,
  PayProductProfessionalPlus,
  PayStatusEnterprise,
  PayStatusProfessionalEnded,
  PayStatusServiceError,
  PayStatusUnknown,
  PayStatusUnknownPastDue,
  services,
} from "apiTypes";
import { CheckmarkRegular as OkIcon, DismissRegular as NokIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import { format } from "date-fns";
import React, { useEffect } from "react";
import { useCheckPayStatusMutation, useGetServicesQuery } from "apiSlice";
import { useNavigate } from "react-router-dom";
import { ColorVariant, SizeVariant } from "components/buttons/buttonVariants";
import Button, { ButtonRow } from "components/buttons/Button";
import { userEvents } from "utils/userEvents";
import Tag, { TagColor } from "components/tags/Tag";
import classNames from "classnames";

type FooterProps = {
  dashboard: boolean;
};

function Footer(props: FooterProps) {
  const { t } = useTranslations();
  const { track } = userEvents();

  const navigate = useNavigate();

  const { data: servicesData, refetch: refetchServicesData } = useGetServicesQuery();
  const [checkPayStatus, { data: paid, isLoading: checkPayStatusLoading }] = useCheckPayStatusMutation();
  const [servicesState, setServicesState] = React.useState<services>({} as services);

  useEffect(() => {
    if (servicesData) {
      setServicesState(servicesData);
    }
  }, [servicesData]);

  useEffect(() => {
    refetchServicesData();
  }, [paid]);

  const formattedDate = servicesState?.payEndDate ? format(new Date(servicesState?.payEndDate), "yyyy-MM-dd") : "";
  const formattedTime = servicesState?.payEndDate ? format(new Date(servicesState?.payEndDate), "HH:mm") : "";

  const isToday = (date: Date): boolean => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const [version = "Unknown", commit = "Unknown"] = servicesState?.version?.split(" | ") ?? [];

  return (
    <footer className={classNames(styles.footer, { [styles.dashboard]: props.dashboard })}>
      <div className={styles.footerRow}>
        <div>© Torq: {version}</div>
        {commit.toLowerCase() != "unknown" && (
          <>
            <div>|</div>
            <div>Commit: {commit}</div>
          </>
        )}
        {servicesState?.payCheckPending && (
          <>
            <div>|</div>
            <div className="servicesOk">
              <>
                <OkIcon /> {t.pending}
              </>
            </div>
          </>
        )}
        {!servicesState?.payCheckPending && (
          <>
            {servicesState?.trialing && (
              <>
                <div>|</div>
                <div className="servicesOk">
                  <>
                    <OkIcon /> {t.trialing}
                  </>
                </div>
              </>
            )}
            {!servicesState?.trialing && (
              <>
                {(servicesState?.payStatus == PayStatusUnknown ||
                  servicesState?.payStatus == PayStatusServiceError ||
                  servicesState?.payStatus >= PayStatusUnknownPastDue) && (
                  <>
                    <div>|</div>
                    <div className="servicesNotOk">
                      {servicesState?.payStatus < PayStatusProfessionalEnded && (
                        <>
                          <NokIcon /> {t.contactSupport}
                        </>
                      )}
                      {servicesState?.payStatus >= PayStatusProfessionalEnded && (
                        <>
                          <NokIcon /> {t.blocked}
                        </>
                      )}
                    </div>
                  </>
                )}
                {servicesState?.payProduct > PayProductFallback && (
                  <>
                    <div>|</div>
                    <div className="servicesOk">
                      {servicesState?.payProduct == PayProductProfessional && (
                        <>
                          <OkIcon /> {t.professional}
                        </>
                      )}
                      {servicesState?.payProduct == PayProductProfessionalPlus && (
                        <>
                          <OkIcon /> {t.professionalPlus}
                        </>
                      )}
                      {servicesState?.payProduct == PayProductOrganization && (
                        <>
                          <OkIcon /> {t.organization}
                        </>
                      )}
                      {servicesState?.payProduct == PayProductEnterprise && (
                        <>
                          <OkIcon /> {t.enterprise}
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
            {servicesState?.payProduct !== PayProductFree && (
              <>
                <div>|</div>
                {isToday(new Date(servicesState?.payEndDate)) && (
                  <Tag label={`Expires Today: ${formattedTime}`} colorVariant={TagColor.error} />
                )}
                {servicesState?.payEndDate &&
                  !isToday(new Date(servicesState?.payEndDate)) &&
                  servicesState?.payStatus <= PayStatusEnterprise && <Tag label={`Expires: ${formattedDate}`} />}
                {servicesState?.payEndDate &&
                  !isToday(new Date(servicesState?.payEndDate)) &&
                  servicesState?.payStatus >= PayStatusUnknownPastDue && (
                    <Tag label={`Expired`} colorVariant={TagColor.error} />
                  )}
              </>
            )}
          </>
        )}
        <div>|</div>
        <ButtonRow>
          <Button
            buttonColor={ColorVariant.success}
            buttonSize={SizeVariant.small}
            onClick={() => {
              track("Navigate to Pay", { location: location?.pathname });
              navigate("/services");
            }}
            intercomTarget={"footer-pay-button"}
          >
            {t.pay}
          </Button>{" "}
          <Button
            buttonColor={ColorVariant.primary}
            buttonSize={SizeVariant.small}
            onClick={() => {
              track("Check Payment Status", { location: location?.pathname });
              checkPayStatus();
            }}
            intercomTarget={"footer-pay-status-button"}
            disabled={checkPayStatusLoading}
          >
            {t.recheck}
          </Button>
        </ButtonRow>
      </div>
    </footer>
  );
}

export default Footer;
