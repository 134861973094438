import useTranslations from "services/i18n/useTranslations";
import Select from "components/forms/select/Select";
import { OptionProps, Props, SingleValueProps, components } from "react-select";
import ChannelOption from "components/forms/select/channelSelect/ChannelOption";
import { channel } from "features/channels/channelsTypes";
import Switch from "components/forms/switch/Switch";
import { useState } from "react";

interface Option {
  label: string;
  value: number;
}

export type ChannelOpt = {
  value: number;
  label: string;
  remoteBalance?: number;
  localBalance?: number;
  capacity?: number;
  peerAlias?: string;
};

export function IsChannelOption(result: unknown): result is ChannelOpt {
  return (
    result !== null &&
    typeof result === "object" &&
    "value" in result &&
    "label" in result &&
    typeof (result as { value: unknown; label: string }).value === "number"
  );
}

export function FilterChannelOptions(channelsResponseData: channel[], fromNodeId: number | undefined): ChannelOpt[] {
  if (!channelsResponseData?.length || !fromNodeId) {
    return [];
  }

  return (
    channelsResponseData
      .filter((c) => [fromNodeId].includes(c.nodeId))
      .map((channel: channel) => {
        return {
          label: channel.shortChannelId,
          value: channel.channelId,
          remoteBalance: channel.remoteBalance,
          localBalance: channel.localBalance,
          capacity: channel.capacity,
          peerAlias: channel.peerAlias,
        };
      })
      // Sort by shortChannelId
      .sort((a: Option, b: Option) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      })
  );
}

export function FilterChannelOptionsStrict(
  channelsResponseData: channel[],
  fromNodeId: number | undefined,
  toNodeId: number | undefined,
): ChannelOpt[] {
  if (!channelsResponseData?.length || !fromNodeId || !toNodeId) {
    return [];
  }

  return (
    channelsResponseData
      .filter((c) => [fromNodeId].includes(c.nodeId))
      .filter((c) => [toNodeId].includes(c.peerNodeId))
      .map((channel: channel) => {
        return {
          label: channel.shortChannelId,
          value: channel.channelId,
          remoteBalance: channel.remoteBalance,
          localBalance: channel.localBalance,
          capacity: channel.capacity,
          peerAlias: channel.peerAlias,
        };
      })
      // Sort by shortChannelId
      .sort((a: Option, b: Option) => {
        if (a.label > b.label) return 1;
        if (a.label < b.label) return -1;
        return 0;
      })
  );
}

type ChannelSelectProps = Props & {
  intercomTarget: string;
  channelOptions: Array<ChannelOpt> | undefined;
  selectedChannelId: number | undefined;
  allowAutomatic?: boolean;
  manualSelectionSwitch?: (manual: boolean) => void; //is channel set automatically by the node
};

export default function ChannelSelect(props: ChannelSelectProps) {
  const { t } = useTranslations();
  //by default manual selection = off (channel automatically selected), if automatic is allowed
  const [manualChannelSelection, setManualChannelSelection] = useState(!props.allowAutomatic);

  const Option = (props: OptionProps) => {
    const channel = props.data as ChannelOpt;
    return (
      <div>
        <components.Option {...props}>
          <ChannelOption
            shortChannelId={channel?.label || ""}
            localBalance={channel?.localBalance || 0}
            remoteBalance={channel?.remoteBalance || 0}
            capacity={channel?.capacity || 0}
            peerAlias={channel?.peerAlias || ""}
          />
        </components.Option>
      </div>
    );
  };

  const SingleValue = (props: SingleValueProps<unknown>) => {
    const channel = props.data as ChannelOpt;
    return (
      <components.SingleValue {...props}>
        <ChannelOption
          shortChannelId={channel?.label || ""}
          localBalance={channel?.localBalance || 0}
          remoteBalance={channel?.remoteBalance || 0}
          capacity={channel?.capacity || 0}
          peerAlias={channel?.peerAlias || ""}
        />
      </components.SingleValue>
    );
  };

  return (
    <div>
      {props.allowAutomatic && (
        <Switch
          label={t.manualChannelSelection}
          intercomTarget={"automatic-switch-channel-input"}
          checked={manualChannelSelection}
          onChange={() => {
            const updatedManualChannelSelection = !manualChannelSelection;
            setManualChannelSelection(updatedManualChannelSelection);
            if (props.manualSelectionSwitch) {
              props.manualSelectionSwitch(updatedManualChannelSelection);
            }
          }}
        />
      )}

      {manualChannelSelection && (
        <Select
          selectComponents={{ Option, SingleValue }}
          intercomTarget={props.intercomTarget}
          label={t.channel}
          onChange={props.onChange}
          options={props.channelOptions}
          value={props.channelOptions?.find((option) => option.value === props.selectedChannelId) || ""}
        />
      )}
    </div>
  );
}
