import { ViewResponse } from "features/viewManagement/types";
import { ChannelRequestServer } from "features/lsp/lspTypes";
import { FilterInterface } from "features/sidebar/sections/filter/filter";
import { ColumnMetaData } from "features/table/types";
import { OrderBy } from "features/sidebar/sections/sort/SortSection";
import {
  AllChannelRequestServersColumns,
  ChannelRequestServersFilterableColumns,
  ChannelRequestServersSortableColumns,
} from "features/lsp/channelRequest/server/channelRequestServersColumns.generated";
import { Implementation } from "types/api";

const defaultColumns: Array<keyof ChannelRequestServer> = ["torqNodeId", "peerNodeId"];

export const DefaultChannelRequestServersColumns = defaultColumns.map((col) =>
  AllChannelRequestServersColumns.find((x) => x.key === col),
) as Array<ColumnMetaData<ChannelRequestServer>>;

export const FilterableChannelRequestServersColumns = AllChannelRequestServersColumns.filter(
  (column: ColumnMetaData<ChannelRequestServer>) => ChannelRequestServersFilterableColumns.includes(column.key),
);

export const ChannelRequestServersFilterTemplate: FilterInterface = {
  funcName: "gte",
  category: "number",
  parameter: "0",
  key: "value",
  supportedBy: [Implementation.LND],
};

export const ChannelRequestServersSortTemplate: Array<OrderBy> = [
  {
    key: "createdOn",
    direction: "desc",
  },
];

export const DefaultChannelRequestServersView: ViewResponse<ChannelRequestServer> = {
  page: "channelRequestServers",
  dirty: true,
  view: {
    title: "Channel Request Servers",
    columns: AllChannelRequestServersColumns,
    sortBy: ChannelRequestServersSortTemplate,
  },
};

export const SortableChannelRequestServersColumns = AllChannelRequestServersColumns.filter((c) =>
  ChannelRequestServersSortableColumns.includes(c.key),
);
