import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { ArrowRouting20Regular as ChannelRequestIcon, Save16Regular as SaveIcon } from "@fluentui/react-icons";
import useTranslations from "services/i18n/useTranslations";
import WorkflowNodeWrapper, { WorkflowNodeProps } from "components/workflow/nodeWrapper/WorkflowNodeWrapper";
import { NodeColorVariant } from "components/workflow/nodes/nodeVariants";
import { SelectWorkflowNodeLinks, SelectWorkflowNodes, useUpdateNodeMutation } from "pages/WorkflowPage/workflowApi";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { NumberFormatValues } from "react-number-format";
import { useSelector } from "react-redux";
import { Input, Socket, Form, RadioChips, InputSizeVariant } from "components/forms/forms";
import { WorkflowContext } from "components/workflow/WorkflowContext";
import { Status } from "constants/backend";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";
import Spinny from "features/spinny/Spinny";
import Note, { NoteType } from "features/note/Note";
import styles from "features/channels/openChannel/openChannel.module.scss";
import Switch, { SwitchSize } from "components/forms/switch/Switch";

type ChannelRequestNodeProps = Omit<WorkflowNodeProps, "colorVariant">;

export type ChannelRequestParameters = {
  torqNodeId: number;
  peerNodeId?: number;
  lsp?: number;
  lspUrl?: string;
  autoPay?: number;
  autoPayFeeRateMilliMsat?: number;
  autoPayFeeRate?: number;
  autoPaySatPerVbyte?: number;
  autoPayMaximumTotalCost?: number;
  clientBalance?: number;
  lspBalance?: number;
  channelExpiryWeeks?: number;
  fundingTransactionFeeRate?: number;
  fundingTransactionSatPerVbyte?: number;
  token?: string;
  refundOnChainAddress?: string;
  privateChannel: boolean;
};

export function ChannelRequestNode({ ...wrapperProps }: ChannelRequestNodeProps) {
  const { t } = useTranslations();

  const { workflowStatus } = useContext(WorkflowContext);
  const editingDisabled = workflowStatus === Status.Active;
  const toastRef = React.useContext(ToastContext);

  const [updateNode] = useUpdateNodeMutation();

  const [configuration, setConfiguration] = useState<ChannelRequestParameters>({
    torqNodeId: 0,
    privateChannel: false,
    ...wrapperProps.parameters,
  });

  const [dirty, setDirty] = useState(false);
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    setDirty(
      JSON.stringify(wrapperProps.parameters, Object.keys(wrapperProps.parameters).sort()) !==
        JSON.stringify(configuration, Object.keys(configuration).sort()),
    );
  }, [configuration, wrapperProps.parameters]);

  function setLsp(lsp?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      lsp: lsp,
    }));
  }

  function setLspUrl(lspUrl?: string) {
    return setConfiguration((prev) => ({
      ...prev,
      lspUrl: lspUrl,
    }));
  }

  function setAutoPay(autoPay?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      autoPay: autoPay,
    }));
  }

  function setAutoPayFeeRateMilliMsat(autoPayFeeRateMilliMsat?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      autoPayFeeRateMilliMsat: autoPayFeeRateMilliMsat,
    }));
  }

  function setAutoPayFeeRate(autoPayFeeRate?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      autoPayFeeRate: autoPayFeeRate,
    }));
  }

  function setAutoPaySatPerVbyte(autoPaySatPerVbyte?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      autoPaySatPerVbyte: autoPaySatPerVbyte,
    }));
  }

  function setAutoPayMaximumTotalCost(autoPayMaximumTotalCost?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      autoPayMaximumTotalCost: autoPayMaximumTotalCost,
    }));
  }

  function setLspBalance(lspBalance?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      lspBalance: lspBalance,
    }));
  }

  function setClientBalance(clientBalance?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      clientBalance: clientBalance,
    }));
  }

  function setChannelExpiryWeeks(channelExpiryWeeks?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      channelExpiryWeeks: channelExpiryWeeks,
    }));
  }

  function setFundingTransactionFeeRate(fundingTransactionFeeRate?: number) {
    return setConfiguration((prev) => ({
      ...prev,
      fundingTransactionFeeRate: fundingTransactionFeeRate,
    }));
  }

  // function setFundingTransactionSatPerVbyte(fundingTransactionSatPerVbyte?: number) {
  //   return setConfiguration((prev) => ({
  //     ...prev,
  //     fundingTransactionSatPerVbyte: fundingTransactionSatPerVbyte,
  //   }));
  // }

  function setToken(token?: string) {
    return setConfiguration((prev) => ({
      ...prev,
      token: token,
    }));
  }

  function setRefundOnChainAddress(refundOnChainAddress?: string) {
    return setConfiguration((prev) => ({
      ...prev,
      refundOnChainAddress: refundOnChainAddress,
    }));
  }

  function setPrivateChannel(privateChannel: boolean) {
    return setConfiguration((prev) => ({
      ...prev,
      privateChannel: privateChannel,
    }));
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (editingDisabled) {
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }
    setProcessing(true);
    updateNode({
      workflowVersionNodeId: wrapperProps.workflowVersionNodeId,
      parameters: configuration,
    }).finally(() => {
      setProcessing(false);
    });
  }

  const { childLinks } = useSelector(
    SelectWorkflowNodeLinks({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeId: wrapperProps.workflowVersionNodeId,
      stage: wrapperProps.stage,
    }),
  );

  const channelIds =
    childLinks
      ?.filter((n) => {
        return n.childInput === "channels";
      })
      ?.map((link) => link.parentWorkflowVersionNodeId) ?? [];

  const channels = useSelector(
    SelectWorkflowNodes({
      version: wrapperProps.version,
      workflowId: wrapperProps.workflowId,
      nodeIds: channelIds,
    }),
  );

  return (
    <WorkflowNodeWrapper {...wrapperProps} headerIcon={<ChannelRequestIcon />} colorVariant={NodeColorVariant.accent3}>
      <Form onSubmit={handleSubmit} intercomTarget={"channel-request-node-form"}>
        <Socket
          intercomTarget={"channel-request-node-channels"}
          collapsed={wrapperProps.visibilitySettings.collapsed}
          label={t.Channels}
          selectedNodes={channels || []}
          workflowVersionId={wrapperProps.workflowVersionId}
          workflowVersionNodeId={wrapperProps.workflowVersionNodeId}
          inputName={"channels"}
          editingDisabled={editingDisabled}
        />
        <RadioChips
          label={t.lsp}
          sizeVariant={InputSizeVariant.small}
          groupName={"channel-request-node-lsp-switch-" + wrapperProps.workflowVersionNodeId}
          options={[
            {
              label: t.deezy,
              id: "channel-request-node-lsp-switch-deezy-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.lsp === 0,
              onChange: () => {
                setLsp(0);
                setLspUrl("");
              },
            },
            {
              label: t.url,
              id: "channel-request-node-lsp-switch-url-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.lsp === -1,
              onChange: () => {
                setLsp(-1);
              },
            },
            {
              label: t.onion,
              id: "channel-request-node-lsp-switch-onion-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.lsp === -2,
              onChange: () => {
                setLsp(-2);
                setLspUrl("");
              },
            },
          ]}
          editingDisabled={editingDisabled}
        />
        {configuration.lsp === -1 && (
          <Input
            intercomTarget={"channel-request-node-lsp-input"}
            label={t.url}
            sizeVariant={InputSizeVariant.small}
            className={styles.single}
            value={configuration.lspUrl}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLsp(undefined);
              setLspUrl(e.target.value);
            }}
          />
        )}
        <RadioChips
          label={t.autoPay}
          sizeVariant={InputSizeVariant.small}
          groupName={"channel-request-node-autoPay-switch"}
          options={[
            {
              label: t.bolt11,
              id: "channel-request-node-autoPay-switch-bolt11-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.autoPay === 0,
              onChange: () => {
                setAutoPay(0);
                setAutoPayFeeRate(undefined);
              },
            },
            {
              label: t.lnurl,
              id: "channel-request-node-autoPay-switch-lnurl-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.autoPay === 1,
              onChange: () => {
                setAutoPay(1);
                setAutoPayFeeRate(undefined);
              },
            },
            {
              label: t.onChainTransaction,
              id: "channel-request-node-autoPay-switch-onchain-transaction-" + wrapperProps.workflowVersionNodeId,
              checked: configuration.autoPay === 2,
              onChange: () => {
                setAutoPay(2);
                setAutoPayFeeRate(undefined);
              },
            },
          ]}
          editingDisabled={editingDisabled}
        />
        {configuration.autoPay === 0 && (
          <>
            <RadioChips
              label={t.Fee}
              sizeVariant={InputSizeVariant.small}
              vertical={true}
              groupName={"channel-request-node-autoPay-fee-switch-"}
              options={[
                {
                  label: t.mempool.fastest,
                  id: "channel-request-node-autoPay-fee-switch-fastestFee-" + wrapperProps.workflowVersionNodeId,
                  checked: configuration.autoPayFeeRate === 0,
                  onChange: () => setAutoPayFeeRate(0),
                },
                {
                  label: t.mempool.halfHour,
                  id: "channel-request-node-autoPay-fee-switch-halfHourFee-" + wrapperProps.workflowVersionNodeId,
                  checked: configuration.autoPayFeeRate === 1,
                  onChange: () => setAutoPayFeeRate(1),
                },
                {
                  label: t.mempool.hour,
                  id: "channel-request-node-autoPay-fee-switch-hourFee-" + wrapperProps.workflowVersionNodeId,
                  checked: configuration.autoPayFeeRate === 2,
                  onChange: () => setAutoPayFeeRate(2),
                },
                {
                  label: t.mempool.economy,
                  id: "channel-request-node-autoPay-fee-switch-economyFee-" + wrapperProps.workflowVersionNodeId,
                  checked: configuration.autoPayFeeRate === 3,
                  onChange: () => setAutoPayFeeRate(3),
                },
                {
                  label: t.mempool.minimum,
                  id: "channel-request-node-autoPay-fee-switch-minimumFee-" + wrapperProps.workflowVersionNodeId,
                  checked: configuration.autoPayFeeRate === 4,
                  onChange: () => setAutoPayFeeRate(4),
                },
                {
                  label: t.Fixed,
                  id: "channel-request-node-autoPay-fee-switch-custom-" + wrapperProps.workflowVersionNodeId,
                  checked: configuration.autoPayFeeRate === 5,
                  onChange: () => setAutoPayFeeRate(5),
                },
              ]}
              editingDisabled={editingDisabled}
            />
            {configuration.autoPayFeeRate === 5 && (
              <Input
                intercomTarget={"channel-request-node-autoPay-sat-per-vbyte-input"}
                label={t.Fixed + " sat/vbyte"}
                sizeVariant={InputSizeVariant.small}
                formatted={true}
                className={styles.single}
                thousandSeparator={","}
                value={configuration.autoPaySatPerVbyte}
                suffix={" sat/vbyte"}
                onValueChange={(values: NumberFormatValues) => {
                  setAutoPaySatPerVbyte(values.floatValue);
                }}
              />
            )}
            <Input
              intercomTarget={"channel-request-node-autoPay-feeRateMilliMsat-input"}
              label={t.maximumCostMilliMsat}
              sizeVariant={InputSizeVariant.small}
              formatted={true}
              className={styles.single}
              thousandSeparator={","}
              value={configuration.autoPayFeeRateMilliMsat}
              suffix={" ppm"}
              onValueChange={(values: NumberFormatValues) => {
                setAutoPayFeeRateMilliMsat(values.floatValue);
              }}
            />
          </>
        )}
        {configuration.autoPay !== undefined && (
          <Input
            intercomTarget={"channel-request-node-autoPay-maximumTotalCost-input"}
            label={t.maximumTotalCost}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={configuration.autoPayMaximumTotalCost}
            suffix={" sat"}
            onValueChange={(values: NumberFormatValues) => {
              setAutoPayMaximumTotalCost(values.floatValue);
            }}
          />
        )}
        <Input
          intercomTarget={"channel-request-node-lspBalance-input"}
          label={t.lspBalance}
          sizeVariant={InputSizeVariant.small}
          formatted={true}
          className={styles.single}
          thousandSeparator={","}
          value={configuration.lspBalance}
          suffix={" sats"}
          onValueChange={(values: NumberFormatValues) => {
            setLspBalance(values.floatValue);
          }}
        />
        <Input
          intercomTarget={"channel-request-node-clientBalance-input"}
          label={t.clientBalance}
          sizeVariant={InputSizeVariant.small}
          formatted={true}
          className={styles.single}
          thousandSeparator={","}
          value={configuration.clientBalance}
          suffix={" sats"}
          onValueChange={(values: NumberFormatValues) => {
            setClientBalance(values.floatValue);
          }}
        />
        <Input
          intercomTarget={"channel-request-node-channelExpiryWeeks-input"}
          label={t.channelExpiry}
          sizeVariant={InputSizeVariant.small}
          formatted={true}
          className={styles.single}
          thousandSeparator={","}
          value={configuration.channelExpiryWeeks}
          suffix={" weeks"}
          onValueChange={(values: NumberFormatValues) => {
            setChannelExpiryWeeks(values.floatValue);
          }}
        />
        <RadioChips
          label={t.fundingTransactionFee}
          sizeVariant={InputSizeVariant.small}
          vertical={true}
          groupName={"channel-request-node-fundingTransaction-fee-switch-"}
          options={[
            {
              label: t.mempool.fastest,
              id: "channel-request-node-fundingTransaction-fee-switch-fastestFee",
              checked: configuration.fundingTransactionFeeRate === 0,
              onChange: () => setFundingTransactionFeeRate(0),
            },
            {
              label: t.mempool.halfHour,
              id: "channel-request-node-fundingTransaction-fee-switch-halfHourFee",
              checked: configuration.fundingTransactionFeeRate === 1,
              onChange: () => setFundingTransactionFeeRate(1),
            },
            {
              label: t.mempool.hour,
              id: "channel-request-node-fundingTransaction-fee-switch-hourFee",
              checked: configuration.fundingTransactionFeeRate === 2,
              onChange: () => setFundingTransactionFeeRate(2),
            },
            {
              label: t.mempool.economy,
              id: "channel-request-node-fundingTransaction-fee-switch-economyFee",
              checked: configuration.fundingTransactionFeeRate === 3,
              onChange: () => setFundingTransactionFeeRate(3),
            },
            {
              label: t.mempool.minimum,
              id: "channel-request-node-fundingTransaction-fee-switch-minimumFee",
              checked: configuration.fundingTransactionFeeRate === 4,
              onChange: () => setFundingTransactionFeeRate(4),
            },
            // {
            //   label: t.Fixed,
            //   id: "channel-request-node-fundingTransaction-fee-switch-custom",
            //   checked: configuration.fundingTransactionFeeRate === 5,
            //   onChange: () => setFundingTransactionFeeRate(5),
            // },
          ]}
        />
        {/*{configuration.fundingTransactionFeeRate === 5 && (*/}
        {/*  <Input*/}
        {/*    intercomTarget={"channel-request-node-fundingTransaction-sat-per-vbyte-input"}*/}
        {/*    label={t.Fixed + " sat/vbyte"}*/}
        {/*    sizeVariant={InputSizeVariant.small}*/}
        {/*    formatted={true}*/}
        {/*    className={styles.single}*/}
        {/*    thousandSeparator={","}*/}
        {/*    value={configuration.fundingTransactionSatPerVbyte}*/}
        {/*    suffix={" sat/vbyte"}*/}
        {/*    onValueChange={(values: NumberFormatValues) => {*/}
        {/*      setFundingTransactionSatPerVbyte(values.floatValue);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
        <Input
          intercomTarget={"channel-request-node-token-input"}
          label={t.token}
          sizeVariant={InputSizeVariant.small}
          className={styles.single}
          value={configuration.token}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setToken(e.target.value);
          }}
        />
        <Input
          intercomTarget={"channel-request-node-refundOnChainAddress-input"}
          label={t.refundOnChainAddress}
          sizeVariant={InputSizeVariant.small}
          className={styles.single}
          value={configuration.refundOnChainAddress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setRefundOnChainAddress(e.target.value);
          }}
        />
        <Switch
          intercomTarget={"channel-request-node-privateChannel-switch"}
          label={t.privateChannel}
          sizeVariant={SwitchSize.small}
          checked={configuration.privateChannel}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setPrivateChannel(e.target.checked);
          }}
        />
        <Button
          intercomTarget={"channel-request-node-save"}
          type="submit"
          buttonColor={ColorVariant.success}
          buttonSize={SizeVariant.small}
          icon={!processing ? <SaveIcon /> : <Spinny />}
          disabled={!dirty || processing}
        >
          {!processing ? t.save.toString() : t.saving.toString()}
        </Button>
        <Note title={t.note} noteType={NoteType.info}>
          <p>{t.workflowNodes.channelRequestDescription}</p>
        </Note>
      </Form>
    </WorkflowNodeWrapper>
  );
}
