import { Tag20Regular as Icon, TagDismiss20Regular as RemoveIcon } from "@fluentui/react-icons";
import LogDetailsWrapper, { LogDetailType } from "components/workflow/LogDetails/LogDetailsWrapper";
import { WorkflowRunLogDetailAction } from "pages/workflowRunLogDetail/workflowRunLogDetailTypes";
import Tag from "components/tags/Tag";
import { LogContainer } from "components/workflow/LogDetails/LogContainer/LogContainer";
import { ErrorsLogContainer } from "components/workflow/LogDetails/ErrorsLogContainer/errorsLogContainer";

type TagLogListParams = {
  removedTags?: Array<{ label: string; value: number }>;
  addedTags?: Array<{ label: string; value: number }>;
  applyTo: string;
};

export function IsTagLogListParams(params: unknown): params is TagLogListParams {
  return (
    params !== null &&
    typeof params === "object" &&
    Object.hasOwn(params, "applyTo") &&
    (Object.hasOwn(params, "removedTags") || Object.hasOwn(params, "addedTags"))
  );
}

function TagLogList(props: TagLogListParams) {
  const removedTags = props?.removedTags?.map((tag, index) => {
    return <Tag key={tag.label + index} label={tag.label} />;
  });
  const addedTags = props?.addedTags?.map((tag, index) => {
    return <Tag key={tag.label + index} label={tag.label} />;
  });
  const appliedToLabel = props.applyTo === "channel" ? "channels" : "nodes";
  return (
    <LogContainer
      title={`Tags applied to ${appliedToLabel}`}
      count={removedTags?.length || addedTags?.length}
      type={LogDetailType.action}
    >
      <div>
        {removedTags}
        {addedTags}
      </div>
    </LogContainer>
  );
}

export function AddTagLog(props: WorkflowRunLogDetailAction) {
  const tagParams = JSON.parse(props?.parameters || "");
  return (
    <LogDetailsWrapper
      type={LogDetailType.action}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<Icon />}
    >
      {IsTagLogListParams(tagParams) && <TagLogList {...tagParams} />}
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}

export function RemoveTagLog(props: WorkflowRunLogDetailAction) {
  const tagParams = JSON.parse(props?.parameters || "");
  return (
    <LogDetailsWrapper
      type={LogDetailType.action}
      name={props.name}
      workflowNodeId={props.id}
      parentIds={props.parentIds}
      icon={<RemoveIcon />}
    >
      {IsTagLogListParams(tagParams) && <TagLogList {...tagParams} />}
      <ErrorsLogContainer errors={props.errors}></ErrorsLogContainer>
    </LogDetailsWrapper>
  );
}
