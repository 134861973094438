import { ServerMultiple20Regular as ChannelRequestServerIcon, Save16Regular as SaveIcon } from "@fluentui/react-icons";
import { useGetActiveNodeSettingsQuery } from "apiSlice";
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import Button, { ButtonPosition, ColorVariant } from "components/buttons/Button";
import styles from "features/lsp/lsp.module.scss";
import { useNavigate } from "react-router";
import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import useTranslations from "services/i18n/useTranslations";
import { ActionMeta } from "react-select";
import { Form, Input, InputSizeVariant, RadioChips, Select } from "components/forms/forms";
import { userEvents } from "utils/userEvents";
import { IsNumericOption } from "utils/typeChecking";
import { useChannelRequestServerMutation, useGetChannelRequestServerQuery } from "features/lsp/lspApi";
import { NumberFormatValues } from "react-number-format";
import { ChannelRequestServer } from "features/lsp/lspTypes";
import Spinny from "features/spinny/Spinny";
import { mergeServerError, ServerErrorType } from "components/errors/errors";
import { toastCategory } from "features/toast/Toasts";
import ToastContext from "features/toast/context";
import { useParams } from "react-router-dom";

function ChannelRequestServerModal() {
  const { t } = useTranslations();
  const { track } = userEvents();

  const toastRef = useContext(ToastContext);

  const navigate = useNavigate();

  const { data: nodeConfigurations } = useGetActiveNodeSettingsQuery();

  const [channelRequestServer, setChannelRequestServer] = useState<ChannelRequestServer>({
    torqNodeName: "",
    nodeCssColour: "",
    torqNodeId: 0,

    peerNodeId: undefined,
    supportedVersions: undefined,
    lspUrl: undefined,

    host: undefined,
    port: undefined,
    nodeConnectionInfo: undefined,
    website: undefined,

    maximumChannelExpiryWeeks: undefined,
    maximumChannelExpiryBlocks: undefined,

    minimumChannelConfirmations: 0,

    minimumOnChainFeeRate: 0,
    minimumOnChainFeeRateSatPerVbyte: undefined,
    minimumOnChainPaymentConfirmations: 0,
    minimumOnChainPaymentSize: undefined,

    supportsZeroChannelReserve: false,

    minimumInitialClientBalance: 0,
    maximumInitialClientBalance: 0,
    minimumInitialLspBalance: 0,
    maximumInitialLspBalance: 0,
    minimumChannelCapacity: 0,
    maximumChannelCapacity: 0,

    satPerVbyteMultiplier: 0,
    capacityFeeRate: 0,
    capacityFeeBase: 0,
    feePerPayment: 0,

    createdOn: new Date(),
  });

  const { torqNodeId: torqNodeIdParam, peerNodeId: peerNodeIdParam } = useParams<{
    torqNodeId: string;
    peerNodeId: string;
  }>();
  const torqNodeId = torqNodeIdParam ? parseInt(torqNodeIdParam) : undefined;
  const peerNodeId = peerNodeIdParam ? parseInt(peerNodeIdParam) : undefined;

  const { data: storedChannelRequestServer } = useGetChannelRequestServerQuery(
    {
      torqNodeId: torqNodeId!,
      peerNodeId: peerNodeId || 0,
    },
    { skip: !torqNodeId || torqNodeId == 0 },
  );

  useEffect(() => {
    if (storedChannelRequestServer) {
      setChannelRequestServer(storedChannelRequestServer);
    }
  }, [storedChannelRequestServer]);

  let nodeConfigurationOptions: Array<{ value: number; label: string | undefined }> = [{ value: 0, label: undefined }];
  if (nodeConfigurations) {
    nodeConfigurationOptions = nodeConfigurations.map((nodeConfiguration) => {
      if (channelRequestServer.torqNodeId === undefined) {
        setTorqNodeId(nodeConfiguration.nodeId);
      }
      return { value: nodeConfiguration.nodeId, label: nodeConfiguration.name };
    });
  }
  const [
    channelRequestServerMutation,
    { error: channelRequestServerError, isLoading: channelRequestServerIsLoading, data: channelRequestServerResponse },
  ] = useChannelRequestServerMutation();

  let peerServer: boolean = false;
  if (channelRequestServer.peerNodeId && channelRequestServer.peerNodeId != 0) {
    peerServer = true;
  }

  const formRef = useRef<HTMLFormElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (channelRequestServerError && "data" in channelRequestServerError && channelRequestServerError.data) {
      const err = mergeServerError(channelRequestServerError.data as ServerErrorType, {});
      if (err?.server && err.server.length > 0) {
        toastRef?.current?.addToast(err.server[0].description || "", toastCategory.error);
      }
    }
  }, [channelRequestServerError]);

  useEffect(() => {
    if (channelRequestServerResponse && channelRequestServerResponse.createdOn) {
      toastRef?.current?.addToast(t.toast.settingsSaved, toastCategory.success);
    }
  }, [channelRequestServerResponse]);

  const closeAndReset = () => {
    navigate(-1);
  };

  function setTorqNodeId(torqNodeId: number) {
    return setChannelRequestServer((prev) => ({
      ...prev,
      torqNodeId: torqNodeId,
    }));
  }

  function setMinimumOnChainFeeRate(feeRate: number) {
    return setChannelRequestServer((prev) => ({
      ...prev,
      minimumOnChainFeeRate: feeRate,
    }));
  }

  function createNumberChangeHandler(key: keyof ChannelRequestServer) {
    return (e: NumberFormatValues) => {
      setChannelRequestServer((prev) => ({
        ...prev,
        [key]: e.floatValue,
      }));
    };
  }

  function createStringChangeHandler(key: keyof ChannelRequestServer) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      setChannelRequestServer((prev) => ({
        ...prev,
        [key]: e.target.value,
      }));
    };
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    channelRequestServerMutation(channelRequestServer);
    track("Channel Request Server", { nodeId: channelRequestServer.torqNodeId });
  }

  return (
    <PopoutPageTemplate
      title={t.channelRequestServer}
      show={true}
      icon={<ChannelRequestServerIcon />}
      onClose={closeAndReset}
    >
      <div className={styles.activeColumns}>
        <Form
          onSubmit={handleSubmit}
          name={"channelRequestServerForm"}
          ref={formRef}
          intercomTarget={"channel-request-server-form"}
        >
          <Select
            intercomTarget={"channel-request-server-node-selection"}
            label={t.node}
            autoFocus={!peerServer}
            onChange={(newValue: unknown, _: ActionMeta<unknown>) => {
              if (!peerServer && IsNumericOption(newValue)) {
                setTorqNodeId(newValue.value);
              }
            }}
            options={nodeConfigurationOptions}
            value={nodeConfigurationOptions.find((option) => option.value === channelRequestServer.torqNodeId)}
          />
          {!peerServer && (
            <>
              <Input
                intercomTarget={"channel-request-server-host-input"}
                label={t.host}
                sizeVariant={InputSizeVariant.small}
                className={styles.single}
                value={channelRequestServer.host}
                onChange={createStringChangeHandler("host")}
              />
              <Input
                intercomTarget={"channel-request-server-port-input"}
                label={t.port}
                sizeVariant={InputSizeVariant.small}
                formatted={true}
                className={styles.single}
                value={channelRequestServer.port}
                onValueChange={createNumberChangeHandler("port")}
              />
            </>
          )}
          {peerServer && (
            <Input
              intercomTarget={"channel-request-server-peerNode-input"}
              label={t.peer}
              sizeVariant={InputSizeVariant.small}
              className={styles.single}
              value={channelRequestServer.peerNodeAlias}
              disabled={peerServer}
            />
          )}
          <Input
            intercomTarget={"channel-request-server-nodeConnectionInfo-input"}
            label={t.connectionInfo}
            sizeVariant={InputSizeVariant.small}
            className={styles.single}
            value={channelRequestServer.nodeConnectionInfo}
            onChange={createStringChangeHandler("nodeConnectionInfo")}
          />
          <Input
            intercomTarget={"channel-request-server-website-input"}
            label={t.website}
            sizeVariant={InputSizeVariant.small}
            className={styles.single}
            value={channelRequestServer.website}
            disabled={peerServer}
            onChange={createStringChangeHandler("website")}
          />
          <Input
            intercomTarget={"channel-request-server-maximumChannelExpiryWeeks-input"}
            label={t.maximumChannelExpiryWeeks}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            value={channelRequestServer.maximumChannelExpiryWeeks}
            disabled={peerServer}
            suffix={" weeks"}
            onValueChange={createNumberChangeHandler("maximumChannelExpiryWeeks")}
          />
          <Input
            intercomTarget={"channel-request-server-maximumChannelExpiryBlocks-input"}
            label={t.maximumChannelExpiryBlocks}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={channelRequestServer.maximumChannelExpiryBlocks}
            disabled={peerServer}
            suffix={" blocks"}
            onValueChange={createNumberChangeHandler("maximumChannelExpiryBlocks")}
          />
          <Input
            intercomTarget={"channel-request-server-minimumChannelConfirmations-input"}
            label={t.minimumChannelConfirmations}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            value={channelRequestServer.minimumChannelConfirmations}
            disabled={peerServer}
            onValueChange={createNumberChangeHandler("minimumChannelConfirmations")}
          />
          {!peerServer && (
            <>
              <RadioChips
                label={t.minimumOnChainFeeRate}
                sizeVariant={InputSizeVariant.small}
                vertical={true}
                groupName={"channel-request-server-minimumOnChainFeeRate-switch"}
                options={[
                  {
                    label: t.mempool.fastest,
                    id: "channel-request-server-minimumOnChainFeeRate-switch-fastestFee",
                    checked: channelRequestServer.minimumOnChainFeeRate === 0,
                    onChange: () => setMinimumOnChainFeeRate(0),
                  },
                  {
                    label: t.mempool.halfHour,
                    id: "channel-request-server-minimumOnChainFeeRate-switch-halfHourFee",
                    checked: channelRequestServer.minimumOnChainFeeRate === 1,
                    onChange: () => setMinimumOnChainFeeRate(1),
                  },
                  {
                    label: t.mempool.hour,
                    id: "channel-request-server-minimumOnChainFeeRate-switch-hourFee",
                    checked: channelRequestServer.minimumOnChainFeeRate === 2,
                    onChange: () => setMinimumOnChainFeeRate(2),
                  },
                  {
                    label: t.mempool.economy,
                    id: "channel-request-server-minimumOnChainFeeRate-switch-economyFee",
                    checked: channelRequestServer.minimumOnChainFeeRate === 3,
                    onChange: () => setMinimumOnChainFeeRate(3),
                  },
                  {
                    label: t.mempool.minimum,
                    id: "channel-request-server-minimumOnChainFeeRate-switch-minimumFee",
                    checked: channelRequestServer.minimumOnChainFeeRate === 4,
                    onChange: () => setMinimumOnChainFeeRate(4),
                  },
                  {
                    label: t.Fixed,
                    id: "channel-request-server-minimumOnChainFeeRate-switch-custom",
                    checked: channelRequestServer.minimumOnChainFeeRate === 5,
                    onChange: () => setMinimumOnChainFeeRate(5),
                  },
                ]}
              />
              {channelRequestServer.minimumOnChainFeeRate === 5 && (
                <Input
                  intercomTarget={"channel-request-server-sat-per-vbyte-input"}
                  label={t.Fixed + " sat/vbyte"}
                  sizeVariant={InputSizeVariant.small}
                  formatted={true}
                  className={styles.single}
                  thousandSeparator={","}
                  value={channelRequestServer.minimumOnChainFeeRateSatPerVbyte}
                  suffix={" sat/vbyte"}
                  onValueChange={createNumberChangeHandler("minimumOnChainFeeRateSatPerVbyte")}
                />
              )}
            </>
          )}
          <Input
            intercomTarget={"channel-request-server-minimumOnChainPaymentConfirmations-input"}
            label={t.minimumOnChainPaymentConfirmations}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            value={channelRequestServer.minimumOnChainPaymentConfirmations}
            disabled={peerServer}
            onValueChange={createNumberChangeHandler("minimumOnChainPaymentConfirmations")}
          />
          <Input
            intercomTarget={"channel-request-server-minimumOnChainPaymentSize-input"}
            label={t.minimumOnChainPaymentSize}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={channelRequestServer.minimumOnChainPaymentSize}
            disabled={peerServer}
            suffix={" sat"}
            onValueChange={createNumberChangeHandler("minimumOnChainPaymentSize")}
          />

          {/* TODO FIXME supportsZeroChannelReserve */}

          <Input
            intercomTarget={"channel-request-server-minimumInitialClientBalance-input"}
            label={t.minimumInitialClientBalance}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={channelRequestServer.minimumInitialClientBalance}
            disabled={peerServer}
            suffix={" sat"}
            onValueChange={createNumberChangeHandler("minimumInitialClientBalance")}
          />
          <Input
            intercomTarget={"channel-request-server-maximumInitialClientBalance-input"}
            label={t.maximumInitialClientBalance}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={channelRequestServer.maximumInitialClientBalance}
            disabled={peerServer}
            suffix={" sat"}
            onValueChange={createNumberChangeHandler("maximumInitialClientBalance")}
          />
          <Input
            intercomTarget={"channel-request-server-minimumInitialLspBalance-input"}
            label={t.minimumInitialLspBalance}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={channelRequestServer.minimumInitialLspBalance}
            disabled={peerServer}
            suffix={" sat"}
            onValueChange={createNumberChangeHandler("minimumInitialLspBalance")}
          />
          <Input
            intercomTarget={"channel-request-server-maximumInitialLspBalance-input"}
            label={t.maximumInitialLspBalance}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={channelRequestServer.maximumInitialLspBalance}
            disabled={peerServer}
            suffix={" sat"}
            onValueChange={createNumberChangeHandler("maximumInitialLspBalance")}
          />
          <Input
            intercomTarget={"channel-request-server-minimumChannelCapacity-input"}
            label={t.minimumChannelCapacity}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={channelRequestServer.minimumChannelCapacity}
            disabled={peerServer}
            suffix={" sat"}
            onValueChange={createNumberChangeHandler("minimumChannelCapacity")}
          />
          <Input
            intercomTarget={"channel-request-server-maximumChannelCapacity-input"}
            label={t.maximumChannelCapacity}
            sizeVariant={InputSizeVariant.small}
            formatted={true}
            className={styles.single}
            thousandSeparator={","}
            value={channelRequestServer.maximumChannelCapacity}
            disabled={peerServer}
            suffix={" sat"}
            onValueChange={createNumberChangeHandler("maximumChannelCapacity")}
          />

          {!peerServer && (
            <>
              <Input
                intercomTarget={"channel-request-server-satPerVbyteMultiplier-input"}
                label={t.satPerVbyteMultiplier}
                sizeVariant={InputSizeVariant.small}
                formatted={true}
                className={styles.single}
                value={channelRequestServer.satPerVbyteMultiplier}
                onValueChange={createNumberChangeHandler("satPerVbyteMultiplier")}
              />
              <Input
                intercomTarget={"channel-request-node-capacityFeeRate-input"}
                label={t.capacityFeeRate}
                sizeVariant={InputSizeVariant.small}
                formatted={true}
                className={styles.single}
                thousandSeparator={","}
                value={channelRequestServer.capacityFeeRate}
                suffix={" ppm"}
                onValueChange={createNumberChangeHandler("capacityFeeRate")}
              />
              <Input
                intercomTarget={"channel-request-server-capacityFeeBase-input"}
                label={t.capacityFeeBase}
                sizeVariant={InputSizeVariant.small}
                formatted={true}
                className={styles.single}
                thousandSeparator={","}
                value={channelRequestServer.capacityFeeBase}
                suffix={" sat"}
                onValueChange={createNumberChangeHandler("capacityFeeBase")}
              />
              <Input
                intercomTarget={"channel-request-server-feePerPayment-input"}
                label={t.feePerPayment}
                sizeVariant={InputSizeVariant.small}
                formatted={true}
                className={styles.single}
                thousandSeparator={","}
                value={channelRequestServer.feePerPayment}
                suffix={" sat"}
                onValueChange={createNumberChangeHandler("feePerPayment")}
              />
            </>
          )}
          <Button
            intercomTarget={"channel-request-submit-button"}
            type={"submit"}
            buttonColor={ColorVariant.primary}
            buttonPosition={ButtonPosition.fullWidth}
            ref={buttonRef}
            disabled={channelRequestServerIsLoading}
            icon={channelRequestServerIsLoading ? <Spinny /> : <SaveIcon />}
          >
            {t.save}
          </Button>
        </Form>
      </div>
    </PopoutPageTemplate>
  );
}

export default ChannelRequestServerModal;
