import styles from "components/workflow/LogDetails/logDetailsWrapper.module.scss";
import { ReactNode } from "react";
import classNames from "classnames";

export enum LogDetailType {
  action = "action",
  filter = "filter",
  trigger = "trigger",
  dryRun = "dryRun",
  dataSource = "dataSource",
  default = "defaultType",
  error = "error",
  warning = "warning",
}

const logDetailTypeClass = new Map<LogDetailType, string>([
  [LogDetailType.action, styles.action],
  [LogDetailType.filter, styles.filter],
  [LogDetailType.trigger, styles.trigger],
  [LogDetailType.dryRun, styles.dryRun],
  [LogDetailType.dataSource, styles.dataSource],
  [LogDetailType.error, styles.error],
  [LogDetailType.warning, styles.warning],
]);

type LogDetailsWrapperProps = {
  workflowNodeId: number;
  name: string;
  parentIds?: number[];
  type?: LogDetailType;
  icon?: ReactNode;
  children?: ReactNode;
};

export default function LogDetailsWrapper(props: LogDetailsWrapperProps) {
  return (
    <div
      key={props.workflowNodeId}
      className={classNames(styles.detailWrapper, logDetailTypeClass.get(props.type || LogDetailType.default))}
      id={`workflow-node-${props.workflowNodeId}`}
    >
      <div className={classNames(styles.detailHeader, { [styles.noBody]: !props.children })}>
        <div className={styles.leftHeaderWrapper}>
          <div className={styles.icon}>{props.icon}</div>
          <div className={styles.name}>{props.name}</div>
        </div>
      </div>
      {props.children && <div className={styles.detailsBody}>{props.children}</div>}
    </div>
  );
}
