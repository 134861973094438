import {
  Options20Regular as OptionsIcon,
  ArrowSync20Regular as RefreshIcon,
  Add20Regular as NewPeerIcon,
} from "@fluentui/react-icons";
import TablePageTemplate, {
  TableControlSection,
  TableControlsButtonGroup,
} from "features/templates/tablePageTemplate/TablePageTemplate";
import useTranslations from "services/i18n/useTranslations";
import Table from "features/table/Table";
import {
  PeersFilterTemplate,
  PeersSortTemplate,
  FilterablePeersColumns,
  SortablePeersColumns,
  DefaultPeersView,
} from "features/peers/peersDefaults";
import { AllPeersColumns } from "features/peers/peersColumns.generated";
import { useGetPeersQuery, useGetPeersSummaryQuery } from "features/peers/peersApi";
import { useAppSelector } from "store/hooks";
import { useGetTableViewsQuery, useUpdateTableViewMutation } from "features/viewManagement/viewsApiSlice";
import { selectPeersViews, selectViews } from "features/viewManagement/viewSlice";
import ViewsSidebar from "features/viewManagement/ViewsSidebar";
import { useState } from "react";
import * as Routes from "constants/routes";
import { selectActiveNetwork } from "features/network/networkSlice";
import { TableResponses, ViewResponse } from "features/viewManagement/types";
import Button, { ColorVariant } from "components/buttons/Button";
import peerCellRenderer from "features/peers/peersCellRenderer";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { userEvents } from "utils/userEvents";
import { usePagination } from "components/table/pagination/usePagination";
import { Input, Select } from "components/forms/forms";
import { ActionMeta } from "react-select";
import { IsNumericOption } from "utils/typeChecking";
import { useGetActiveNodeSettingsQuery } from "apiSlice";
import { NumberFormatValues } from "react-number-format";
import styles from "features/peers/peers.module.scss";
import Switch from "components/forms/switch/Switch";
import useRefreshShortcut from "utils/refreshHook";

function PeersPage() {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const location = useLocation();
  const { track } = userEvents();

  const { isSuccess } = useGetTableViewsQuery<{ isSuccess: boolean }>();
  const { viewResponse, selectedViewIndex } = useAppSelector(selectPeersViews);
  const peersView = useAppSelector(selectViews)("peers");
  const [updateTableView] = useUpdateTableViewMutation();

  const [getPagination, limit, offset] = usePagination("peers");
  const activeNetwork = useAppSelector(selectActiveNetwork);

  const [torqNodeId, setTorqNodeId] = useState<number | undefined>(undefined);
  const [uptimeDays, setUptimeDays] = useState<number>(7);
  const [channelPeersOnly, setChannelPeersOnly] = useState<boolean>(true);

  const {
    data: peersResponse,
    isSuccess: peersObtained,
    refetch: peersRefetch,
    isLoading: peersLoading,
    isFetching: peersFetching,
    isUninitialized: peersUninitialized,
  } = useGetPeersQuery(
    {
      torqNodeId: torqNodeId || 0,
      uptimeDays: uptimeDays,
      channelPeersOnly: channelPeersOnly,
      limit: limit,
      offset: offset,
      order: viewResponse.view.sortBy,
      filter: viewResponse.view.filters ? viewResponse.view.filters : undefined,
      network: activeNetwork,
    },
    { skip: !(torqNodeId && uptimeDays && uptimeDays > 0) || !isSuccess },
  );

  const {
    data: peersSummaryResponse,
    isSuccess: peersSummaryObtained,
    refetch: peersSummaryRefetch,
  } = useGetPeersSummaryQuery(
    {
      torqNodeId: torqNodeId || 0,
      channelPeersOnly: channelPeersOnly,
      filter: viewResponse.view.filters ? viewResponse.view.filters : undefined,
      network: activeNetwork,
    },
    { skip: !torqNodeId || !isSuccess },
  );

  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  // Logic for toggling the sidebar
  const closeSidebarHandler = () => {
    setSidebarExpanded(false);
    track("Toggle Table Sidebar", { page: "Peers" });
  };

  function handleNameChange(name: string) {
    const view = peersView.views[selectedViewIndex] as ViewResponse<TableResponses>;
    if (view.id) {
      updateTableView({
        id: view.id,
        view: { ...view.view, title: name },
      });
    }
  }

  const { data: nodeConfigurations } = useGetActiveNodeSettingsQuery();

  let nodeConfigurationOptions: Array<{ value: number; label: string | undefined }> = [{ value: 0, label: undefined }];
  if (nodeConfigurations) {
    nodeConfigurationOptions = nodeConfigurations.map((nodeConfiguration) => {
      if (torqNodeId === undefined) {
        setTorqNodeId(nodeConfiguration.nodeId);
        if (peersObtained) {
          peersRefetch();
        }
        if (peersSummaryObtained) {
          peersSummaryRefetch();
        }
      }
      return { value: nodeConfiguration.nodeId, label: nodeConfiguration.name };
    });
  }

  useRefreshShortcut([peersRefetch, peersSummaryRefetch]);

  const tableControls = (
    <TableControlSection intercomTarget={"table-page-controls"}>
      <TableControlsButtonGroup intercomTarget={"table-page-controls-left"}>
        <Button
          intercomTarget={"new-peer-button"}
          buttonColor={ColorVariant.success}
          hideMobileText={true}
          icon={<NewPeerIcon />}
          onClick={() => {
            track("Navigate to Connect Peer");
            navigate(Routes.CONNECT_PEER, { state: { background: location } });
          }}
        >
          {t.peersPage.connectPeer}
        </Button>

        <Select
          intercomTarget={"peers-node-selection"}
          className={styles.peersNodeSelection}
          autoFocus={true}
          defaultValue={nodeConfigurationOptions[0]}
          onChange={(newValue: unknown, _: ActionMeta<unknown>) => {
            track("Change Torq Node", { page: "Peers" });
            if (IsNumericOption(newValue)) {
              setTorqNodeId(newValue.value);
              if (peersObtained) {
                peersRefetch();
              }
              if (peersSummaryObtained) {
                peersSummaryRefetch();
              }
            }
          }}
          options={nodeConfigurationOptions}
          value={nodeConfigurationOptions.find((option) => option.value === torqNodeId)}
        />
        <Input
          intercomTarget={"peers-uptime-days-input"}
          className={styles.peersUptimeDaysInput}
          label={t.uptime}
          formatted={true}
          thousandSeparator={","}
          value={uptimeDays}
          suffix={" days"}
          onValueChange={(values: NumberFormatValues) => {
            track("Set Uptime Days", { page: "Peers" });
            setUptimeDays(values.floatValue || 7);
            if (peersObtained) {
              peersRefetch();
            }
            if (peersSummaryObtained) {
              peersSummaryRefetch();
            }
          }}
        />
        <div className={styles.peersChannelPeersOnlySwitch}>
          <Switch
            label={t.channelPeersOnly}
            intercomTarget={"peers-channel-peers-only-switch"}
            checked={channelPeersOnly}
            onChange={() => {
              setChannelPeersOnly(!channelPeersOnly);
            }}
          />
        </div>
      </TableControlsButtonGroup>
      <TableControlsButtonGroup intercomTarget={"table-page-controls-right"}>
        <Button
          intercomTarget={"refresh-table"}
          buttonColor={ColorVariant.primary}
          icon={<RefreshIcon />}
          onClick={() => {
            track("Refresh Table", { page: "Peers" });
            if (peersObtained) {
              peersRefetch();
            }
            if (peersSummaryObtained) {
              peersSummaryRefetch();
            }
          }}
        />
        <Button
          intercomTarget={"table-settings"}
          onClick={() => {
            track("Toggle Table Sidebar", { page: "Peers" });
            setSidebarExpanded(!sidebarExpanded);
          }}
          icon={<OptionsIcon />}
          id={"tableControlsButton"}
        >
          {t.views}
        </Button>
      </TableControlsButtonGroup>
    </TableControlSection>
  );

  const sidebar = (
    <ViewsSidebar
      onExpandToggle={closeSidebarHandler}
      expanded={sidebarExpanded}
      viewResponse={viewResponse}
      selectedViewIndex={selectedViewIndex}
      allColumns={AllPeersColumns}
      defaultView={DefaultPeersView}
      filterableColumns={FilterablePeersColumns}
      filterTemplate={PeersFilterTemplate}
      sortableColumns={SortablePeersColumns}
      sortByTemplate={PeersSortTemplate}
    />
  );

  const breadcrumbs = [t.manage, <span key="b1">{t.peers}</span>];

  return (
    <TablePageTemplate
      title={viewResponse.view.title}
      titleContent={""}
      breadcrumbs={breadcrumbs}
      sidebarExpanded={sidebarExpanded}
      sidebar={sidebar}
      tableControls={tableControls}
      onNameChange={handleNameChange}
      isDraft={viewResponse.id === undefined}
      pagination={getPagination(peersSummaryResponse || 0)}
    >
      <Table
        intercomTarget={"peers-table"}
        cellRenderer={peerCellRenderer}
        data={peersResponse?.data || []}
        activeColumns={viewResponse.view.columns || []}
        isLoading={peersLoading || peersFetching || peersUninitialized}
      />
    </TablePageTemplate>
  );
}

export default PeersPage;
