import PopoutPageTemplate from "features/templates/popoutPageTemplate/PopoutPageTemplate";
import { useNavigate } from "react-router";
import {
  FlashCheckmark20Regular as IndicatorIcon,
  WarningRegular as WarningIcon,
  ErrorCircleRegular as ErrorIcon,
  CheckmarkCircleRegular as CheckIcon,
  PauseRegular as PauseIcon,
  ArrowSync20Regular as RefreshIcon,
  FluentIconsProps,
} from "@fluentui/react-icons";
import { useSearchParams } from "react-router-dom";
import useTranslations from "services/i18n/useTranslations";
import { serviceMismatch, nodeInformation, nodeService, nodeStatus, services, torqService } from "apiTypes";
import { SectionContainer, SectionContainerColor } from "features/section/SectionContainer";
import { useAppSelector } from "store/hooks";
import { selectActiveNetwork } from "features/network/networkSlice";
import styles from "features/connectionStatus/connection-status.module.scss";
import { useState, useEffect } from "react";
import { useGetNodesInformationByCategoryQuery, useGetServicesQuery } from "apiSlice";
import Button, { ButtonPosition, ColorVariant, SizeVariant } from "components/buttons/Button";
import { userEvents } from "utils/userEvents";

//list of service types that are monitored for connection and shown in the connection status modal
const monitoredServiceTypes = [
  "AutomationScheduledTriggerService",
  "AutomationCronService",
  "RebalanceService",
  "NotifierService",
  "SlackService",
  "TelegramHighService",
  "TelegramLowService",
  "DiscordService",
  "LspService",
  "LndServiceChannelEventStream",
  "LndServiceGraphEventStream",
  "LndServiceTransactionStream",
  "LndServiceHtlcEventStream",
  "LndServiceHtlcInterceptorService",
  "LndServiceForwardsService",
  "LndServicePaymentsService",
  "LndServiceInvoiceStream",
  "LndServicePeerEventStream",
  "LndServiceInFlightPaymentsService",
  "LndServiceChannelBalanceCacheService",
  "LndServiceLspOnionService",
  "LndServiceVectorService",
  "LndServiceAmbossService",
  "ClnServiceVectorService",
  "ClnServiceAmbossService",
  "ClnServicePeersService",
  "ClnServiceChannelsService",
  "ClnServiceFundsService",
  "ClnServiceNodesService",
  "ClnServiceClosedChannelsService",
  "ClnServiceForwardsService",
  "ClnServiceInvoicesService",
  "ClnServicePaymentsService",
  "ClnServiceTransactionsService",
  "ClnServiceChannelOpenInterceptorService",
];

export enum servicesConnectionStatus {
  desired,
  someMismatches,
  allMismatches,
  allInactive,
}

//matches the values in internal\services_helpers\constants.go
enum serviceStatus {
  disabled = 0,
  ok = 1,
  pending = 2,
  initializing = 4,
  fault = 5,
}

const serviceStatusIcon = new Map([
  [serviceStatus.disabled, PauseIcon],
  [serviceStatus.ok, CheckIcon],
  [serviceStatus.pending, RefreshIcon],
  [serviceStatus.initializing, RefreshIcon],
  [serviceStatus.fault, ErrorIcon],
]);

const serviceStatusColor = new Map([
  [serviceStatus.disabled, SectionContainerColor.disabled],
  [serviceStatus.ok, SectionContainerColor.success],
  [serviceStatus.pending, SectionContainerColor.primary],
  [serviceStatus.initializing, SectionContainerColor.primary],
  [serviceStatus.fault, SectionContainerColor.error],
]);

const serviceStatusLocString = new Map([
  [serviceStatus.disabled, "disabled"],
  [serviceStatus.ok, "ok"],
  [serviceStatus.pending, "pending"],
  [serviceStatus.initializing, "initializing"],
  [serviceStatus.fault, "fault"],
]);

//common interface for monitored connection status
export interface monitoredServiceGroup {
  node?: nodeInformation;
  monitoredServices: nodeService[] | torqService[];
  monitoredServiceMismatches: serviceMismatch[];
  //connection status for the service group (node or torq services)
  servicesConnectionStatus: servicesConnectionStatus;
}

function mapServiceGroupToMonitoredGroup(
  serviceGroup: nodeService[] | torqService[] | undefined,
  serviceMismatches: serviceMismatch[] | undefined,
  node: nodeInformation | undefined,
): monitoredServiceGroup | undefined {
  if (!serviceGroup) {
    return undefined;
  }

  const monitoredServicesData = serviceGroup.filter(
    (s) => monitoredServiceTypes.includes(s.typeString) && (s.nodeId === node?.nodeId || (!s.nodeId && !node)),
  );
  const monitoredServiceMismatchesData = !serviceMismatches
    ? []
    : serviceMismatches.filter(
        (s) => monitoredServiceTypes.includes(s.typeString) && (s.nodeId === node?.nodeId || (!s.nodeId && !node)),
      );

  let groupConnectionStatus: servicesConnectionStatus;

  // caclulate the connection status of torq services
  // check first if all are inactive
  if (!monitoredServicesData.find((s) => s.status !== serviceStatus.disabled)) {
    groupConnectionStatus = servicesConnectionStatus.allInactive;
  } else {
    switch (monitoredServiceMismatchesData.length) {
      case 0:
        //no mismatches in monitored services
        groupConnectionStatus = servicesConnectionStatus.desired;
        break;
      case monitoredServicesData.length:
        //same amount of mismatches as monitored services, so all are mismatch
        groupConnectionStatus = servicesConnectionStatus.allMismatches;
        break;
      default:
        groupConnectionStatus = servicesConnectionStatus.someMismatches;
        break;
    }
  }

  return {
    node: node ? node : undefined,
    monitoredServices: monitoredServicesData,
    monitoredServiceMismatches: monitoredServiceMismatchesData,
    servicesConnectionStatus: groupConnectionStatus,
  } as monitoredServiceGroup;
}

export function mapNodeServicesDataToMonitoredNodes(
  data: services | undefined,
  nodes: nodeInformation[] | undefined,
): monitoredServiceGroup[] {
  if (!data || !nodes) {
    return [];
  }

  const monitoredNodes: monitoredServiceGroup[] = [];
  nodes.forEach((n) => {
    const monitoredNode = mapServiceGroupToMonitoredGroup(data.nodeServices, data.serviceMismatches, n);
    if (monitoredNode) {
      monitoredNodes.push(monitoredNode);
    }
  });

  return monitoredNodes;
}

function getServicesConnectionStatusSectionTitleColor(
  status: nodeStatus | undefined,
  connectionStatus: servicesConnectionStatus | undefined,
): SectionContainerColor {
  if (status === nodeStatus.inactive) {
    return SectionContainerColor.disabled;
  }
  switch (connectionStatus) {
    case servicesConnectionStatus.desired:
      return SectionContainerColor.success;
    case servicesConnectionStatus.allMismatches:
      return SectionContainerColor.error;
    case servicesConnectionStatus.allInactive:
      return SectionContainerColor.error;
    case servicesConnectionStatus.someMismatches:
      return SectionContainerColor.warning;
    default:
      return SectionContainerColor.error;
  }
}

function getServicesConnectionStatusIcon(
  status: nodeStatus | undefined,
  connectionStatus: servicesConnectionStatus | undefined,
): React.FC<FluentIconsProps> {
  if (status === nodeStatus.inactive) {
    return PauseIcon;
  }
  switch (connectionStatus) {
    case servicesConnectionStatus.desired:
      return CheckIcon;
    case servicesConnectionStatus.allMismatches:
      return ErrorIcon;
    case servicesConnectionStatus.someMismatches:
      return WarningIcon;
    default:
      return ErrorIcon;
  }
}

function connectionStatusModal() {
  const { t } = useTranslations();
  const { track } = userEvents();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const selectedNodeId = parseInt(queryParams.get("nodeId") || "0");

  const activeNetwork = useAppSelector(selectActiveNetwork);
  const [nodeConnectionStatuses, setNodeConnectionStatuses] = useState<monitoredServiceGroup[]>([]);
  const [torqConnectionStatuses, setTorqConnectionStatuses] = useState<monitoredServiceGroup | undefined>(undefined);

  const {
    data: nodes,
    error: torqServicesDataFetchError,
    refetch: refetchNodesData,
  } = useGetNodesInformationByCategoryQuery(activeNetwork);
  const {
    data: servicesData,
    error: nodeServicesDataFetchError,
    refetch: refetchServicesData,
  } = useGetServicesQuery(undefined);

  const [activeSections, setActiveSections] = useState(selectedNodeId === 0 ? [] : [selectedNodeId.toString()]);
  const [fetchError, setFetchError] = useState(false);

  const serviceGroupSectionHandler = (key: string) => {
    //key = nodeId, or "torq" if torq services
    return () => {
      track(`Toggle Connection Status Section`, {
        section: key,
        expanded: !(activeSections.findIndex((n) => n === key) > -1),
      });

      let updatedactiveSections = [...activeSections];

      if (updatedactiveSections.findIndex((n) => n === key) > -1) {
        //hide
        updatedactiveSections = updatedactiveSections.filter((nId) => nId != key);
      } else {
        //show
        updatedactiveSections.push(key);
      }

      setActiveSections(updatedactiveSections);
    };
  };

  useEffect(() => {
    if (nodeServicesDataFetchError || torqServicesDataFetchError) {
      setFetchError(true);
    } else {
      setFetchError(false);
    }

    //arrange the data to show on ui
    setTorqConnectionStatuses(
      mapServiceGroupToMonitoredGroup(servicesData?.torqServices, servicesData?.serviceMismatches, undefined),
    );
    setNodeConnectionStatuses(mapNodeServicesDataToMonitoredNodes(servicesData, nodes));
  }, [servicesData, nodes, nodeServicesDataFetchError, torqServicesDataFetchError]);

  return (
    <PopoutPageTemplate
      title={t.connectionStatus.nodeConnectionStatus}
      show={true}
      onClose={() => navigate(-1)}
      icon={<IndicatorIcon />}
    >
      <div className={styles.connectionStatusContent}>
        <div className={styles.refreshContainer}>
          {fetchError && (
            <div className={styles.errorContainer}>
              <ErrorIcon className={styles.errorIcon} />
              <span>{t.connectionStatus.connectionLost}</span>
            </div>
          )}
          <Button
            intercomTarget="refresh-page-data"
            buttonColor={ColorVariant.primary}
            buttonSize={SizeVariant.small}
            buttonPosition={ButtonPosition.right}
            icon={<RefreshIcon />}
            onClick={() => {
              refetchNodesData();
              refetchServicesData();
            }}
          ></Button>
        </div>
        {!fetchError &&
          torqConnectionStatuses &&
          //combine torq and node services into a single iterable array
          [torqConnectionStatuses]
            .concat(nodeConnectionStatuses)
            .map((serviceGroup: monitoredServiceGroup | undefined) => {
              if (!serviceGroup) return;

              const node = nodes?.find((n) => n.nodeId == serviceGroup?.node?.nodeId);

              //info for torq services
              let sectionKey = "torq";
              let title = t.connectionStatus.torqServices;
              let nodeDesiredStatus = undefined;

              //if is node service, rewrite
              if (node) {
                sectionKey = node.nodeId.toString();
                title = node.name ?? node.alias;
                nodeDesiredStatus = node.status;
              }

              return (
                <SectionContainer
                  key={sectionKey}
                  intercomTarget={"node-connection-status-section"}
                  title={title}
                  icon={getServicesConnectionStatusIcon(nodeDesiredStatus, serviceGroup?.servicesConnectionStatus)}
                  colorVariant={getServicesConnectionStatusSectionTitleColor(
                    nodeDesiredStatus,
                    serviceGroup?.servicesConnectionStatus,
                  )}
                  handleToggle={serviceGroupSectionHandler(sectionKey)}
                  expanded={activeSections.includes(sectionKey)}
                  tightContent={true}
                >
                  {serviceGroup?.monitoredServices &&
                    serviceGroup?.monitoredServices.map((service) => {
                      let sStatus: serviceStatus = service.status;

                      if (
                        service.status != serviceStatus.initializing &&
                        serviceGroup.monitoredServiceMismatches.findIndex((s) => s.type === service.type) > -1
                      ) {
                        sStatus = serviceStatus.fault;
                      }

                      const subSectionKey = sectionKey + "_" + service.type;

                      return (
                        <SectionContainer
                          key={subSectionKey}
                          intercomTarget={"node-connection-status-sub-section"}
                          title={t.connectionStatus.serviceTypeNames[service.typeString]}
                          icon={serviceStatusIcon.get(sStatus) ?? ErrorIcon}
                          colorVariant={serviceStatusColor.get(sStatus)}
                          handleToggle={serviceGroupSectionHandler(subSectionKey)}
                          expanded={activeSections.includes(subSectionKey)}
                        >
                          {t.connectionStatus.statusDescriptions[serviceStatusLocString.get(sStatus)] +
                            "\n" +
                            (service.status != serviceStatus.disabled
                              ? t.connectionStatus.uptime + ": " + service.uptimePercentageString + "%"
                              : "")}
                        </SectionContainer>
                      );
                    })}
                </SectionContainer>
              );
            })}
      </div>
    </PopoutPageTemplate>
  );
}

export default connectionStatusModal;
