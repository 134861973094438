import styles from "components/priceCard/PriceCard.module.scss";
import {
  PayProductEnterprise,
  PayProductFree,
  PayProductOrganization,
  PayProductProfessional,
  PayProductProfessionalPlus,
  PayStatusEnterprise,
  PayStatusEnterpriseEnded,
  PayStatusFree,
  PayStatusNoTorqNodesRegistered,
  PayStatusOrganization,
  PayStatusOrganizationEnded,
  PayStatusProfessional,
  PayStatusProfessionalEnded,
  PayStatusProfessionalPlus,
  PayStatusProfessionalPlusEnded,
  PayStatusServiceError,
  PayStatusServiceErrorPastDue,
  PayStatusTrialingEnterprise,
  PayStatusTrialingFallback,
  PayStatusTrialingOrganization,
  PayStatusTrialingProfessional,
  PayStatusTrialingProfessionalPlus,
  PayStatusUnknown,
  PayStatusUnknownPastDue,
} from "apiTypes";
import { useEffect, useState } from "react";
import { intervalToDuration } from "date-fns";
import { Product, ProductIds } from "pages/selectPlan/planTypes";
import Button, { ButtonPosition, ColorVariant, ExternalLinkButton, SizeVariant } from "components/buttons/Button";
import { InputColorVaraint, Switch } from "components/forms/forms";
import classNames from "classnames";
import { useGetServicesQuery } from "apiSlice";
import useTranslations from "services/i18n/useTranslations";

export type PriceCardProps = Product & { torqUuid?: string };

export default function PriceCard(props: PriceCardProps) {
  const { t } = useTranslations();
  const [yearlyPlan, setYearlyPlan] = useState(true);
  const [monthlyPrice, setMonthlyPrice] = useState<number>();
  const [yearlyPrice, setYearlyPrice] = useState<number>();
  const [discount, setDiscount] = useState<number>(0);
  const [priceId, setPriceId] = useState<string>();

  useEffect(() => {
    if (yearlyPlan) {
      setMonthlyPrice(Math.round((((props?.yearlyPrice || 0) / 12) * 10) / 100) / 10);
      setYearlyPrice((props?.yearlyPrice || 0) / 100);
      setDiscount(Math.round((1 - (props?.yearlyPrice || 0) / ((props?.monthlyPrice || 0) * 12)) * 1000) / 10);
      setPriceId(props?.yearlyPriceId);
    } else {
      setMonthlyPrice((props?.monthlyPrice || 0) / 100);
      setYearlyPrice(((props?.monthlyPrice || 0) / 100) * 12);
      setDiscount(0);
      setPriceId(props?.monthlyPriceId);
    }
  }, [yearlyPlan]);

  const buttonText = props.monthlyPrice ? t.buyNow : t.ContactUs;
  const link = props.monthlyPrice
    ? `${props.checkoutLink}?torqUuid=${props.torqUuid}&priceId=${priceId}&homeUrl=${
        encodeURIComponent(window?.location?.origin) || ""
      }`
    : props.checkoutLink;
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.cardContainer}>
        <div className={styles.name}>{props.productName}</div>
        <div className={styles.subText}>{props.productDescription}</div>
        <ul className={styles.features}>
          {props.features.map((feature) => {
            return (
              <li key={feature} className={styles.feature}>
                {feature}
              </li>
            );
          })}
        </ul>
        {props.monthlyPrice && (
          <Switch
            label={t.payYearly}
            colorVariant={InputColorVaraint.success}
            intercomTarget={"yearly-switch"}
            checked={yearlyPlan}
            onChange={(e) => setYearlyPlan(e.target.checked)}
          />
        )}
        {props.monthlyPrice && (
          <div className={classNames(styles.priceWrapper, { [styles.yearlyPriceSelected]: yearlyPlan })}>
            <div className={styles.monthlyPrice}>
              <div className={styles.price}>{`$${monthlyPrice} / month`}</div>
              {!!discount && <div className={styles.discount}>{`(${discount}% discount)`}</div>}
              {!discount && <div className={styles.discount}>{`(No discount)`}</div>}
            </div>
            <div className={styles.yearlyPrice}>{`$${yearlyPrice} / year`}</div>
          </div>
        )}

        {props.eligible &&
          props.productId != ProductIds.PayProductFree &&
          props.productId != ProductIds.PayProductFallback && (
            <ExternalLinkButton
              href={props.eligible ? link : ""}
              intercomTarget={"buy-now-button"}
              buttonPosition={ButtonPosition.fullWidth}
              buttonColor={ColorVariant.inverted}
              buttonSize={SizeVariant.xl}
            >
              {buttonText}
            </ExternalLinkButton>
          )}
        {!props.eligible && (
          <Button
            disabled={true}
            intercomTarget={"buy-now-not-eligible-button"}
            buttonPosition={ButtonPosition.fullWidth}
            buttonColor={ColorVariant.disabled}
            buttonSize={SizeVariant.xl}
          >
            {t.notEligible}
          </Button>
        )}
      </div>
    </div>
  );
}

const productNameMap = new Map([
  [0, "Unknown"],
  [PayProductFree, "Free"],
  [PayProductProfessional, "Professional"],
  [PayProductProfessionalPlus, "Professional+"],
  [PayProductOrganization, "Organization"],
  [PayProductEnterprise, "Enterprise"],
]);

export function LicenseStatus() {
  const { data: servicesData } = useGetServicesQuery();

  const [statusText, setStatusText] = useState<string>("");
  const [subStatusText, setSubStatusText] = useState<string>("");

  useEffect(() => {
    if (servicesData) {
      const daysLeft = intervalToDuration({
        start: new Date(),
        end: new Date(servicesData.payEndDate || new Date()),
      });
      switch (servicesData.payStatus) {
        case PayStatusUnknown:
        case PayStatusNoTorqNodesRegistered:
          break;
        case PayStatusServiceError:
          setStatusText(`Unable to verify your account.`);
          break;
        case PayStatusTrialingFallback:
        case PayStatusTrialingProfessional:
        case PayStatusTrialingProfessionalPlus:
        case PayStatusTrialingOrganization:
        case PayStatusTrialingEnterprise:
          setStatusText(
            `You have ${
              daysLeft.days && daysLeft.days > 1 ? `${daysLeft.days} days` : `${daysLeft.hours} hours`
            } left in your trial.`,
          );
          break;
        case PayStatusFree:
        case PayStatusProfessional:
        case PayStatusProfessionalPlus:
        case PayStatusOrganization:
        case PayStatusEnterprise:
          setStatusText(`${productNameMap.get(servicesData.payProduct)}`);
          setSubStatusText(`You are subscribed to the ${productNameMap.get(servicesData.payProduct)} plan.`);
          break;
        case PayStatusUnknownPastDue:
        case PayStatusServiceErrorPastDue:
          setStatusText("Check-in expired.");
          break;
        case PayStatusProfessionalEnded:
        case PayStatusProfessionalPlusEnded:
        case PayStatusOrganizationEnded:
        case PayStatusEnterpriseEnded:
          setStatusText("Subscription expired.");
          break;
      }
    }
  }, [servicesData]);

  return (
    <>
      {(statusText != "" || subStatusText != "") && (
        <div className={styles.cardWrapper}>
          <div className={styles.cardContainer}>
            <div className={styles.cardTitle}>{statusText}</div>
            <div className={styles.cardSubText}>{subStatusText}</div>
          </div>
        </div>
      )}
    </>
  );
}
