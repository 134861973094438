// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go


import { ColumnMetaData } from "features/table/types";
import { Peer } from "features/peers/peersTypes";

export const AllPeersColumns: ColumnMetaData<Peer>[] = [
	{
		heading: "Connection Status",
		type: "TextCell",
		key: "connectionStatus",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "Connected", value: "Connected" },
			{ label: "Disconnected", value: "Disconnected" },
		],
	},
	{
		heading: "Closed Channels",
		type: "NumericCell",
		key: "closedChannelCount",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Date Last Connected",
		type: "DateCell",
		key: "dateLastConnected",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Date Last Disconnected",
		type: "DateCell",
		key: "dateLastDisconnected",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node Name",
		type: "TextCell",
		key: "torqNodeName",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node Css Colour",
		type: "TextCell",
		key: "nodeCssColour",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Open Channels",
		type: "NumericCell",
		key: "openChannelCount",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Peer Node Alias",
		type: "AliasCell",
		key: "peerNodeAlias",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Peer Node Public Key",
		type: "TextCell",
		key: "peerNodePublicKey",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Duration Connected",
		type: "DurationCell",
		key: "secondsConnected",
		valueType: "duration",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Duration Disconnected",
		type: "DurationCell",
		key: "secondsDisconnected",
		valueType: "duration",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Tags",
		type: "TagsCell",
		key: "tags",
		valueType: "tag",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Uptime",
		type: "NumericCell",
		key: "uptime",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const PeersSortableColumns: Array<keyof Peer> = [
	"dateLastConnected",
	"dateLastDisconnected",
	"nodeCssColour",
	"peerNodePublicKey",
	"secondsConnected",
	"secondsDisconnected",
	"uptime",
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const PeersFilterableColumns: Array<keyof Peer> = [
	"dateLastConnected",
	"dateLastDisconnected",
	"nodeCssColour",
	"peerNodePublicKey",
	"secondsConnected",
	"secondsDisconnected",
];