// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go


import { ColumnMetaData } from "features/table/types";
import { ChannelRequestServer } from "features/lsp/lspTypes";

export const AllChannelRequestServersColumns: ColumnMetaData<ChannelRequestServer>[] = [
	{
		heading: "Created On",
		type: "DateCell",
		key: "createdOn",
		valueType: "date",
		suffix: "",
		locked: true,
		supportedBy: [0, 1],
	},
	{
		heading: "Node Name",
		type: "TextCell",
		key: "torqNodeName",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Peer Node Alias",
		type: "AliasCell",
		key: "peerNodeAlias",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Website",
		type: "LinkCell",
		key: "website",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const ChannelRequestServersSortableColumns: Array<keyof ChannelRequestServer> = [
	"createdOn",
	"peerNodeAlias",
	"website",
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const ChannelRequestServersFilterableColumns: Array<keyof ChannelRequestServer> = [
	"createdOn",
	"peerNodeAlias",
	"website",
];