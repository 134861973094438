import React, { useState } from "react";
import Pagination from "components/table/pagination/Pagination";
import useLocalStorage from "utils/useLocalStorage";

export function usePagination(
  page: string,
  defaultLimit?: number
): [(total: number) => React.ReactNode, number, number, (offset: number) => void] {
  const [limit, setLimit] = useLocalStorage(`${page.toString()}Limit`, defaultLimit || 100);
  const [offset, setOffset] = useState(0);

  const getPaginator = (total: number) => (
    <Pagination limit={limit} offset={offset} total={total} perPageHandler={setLimit} offsetHandler={setOffset} />
  );

  return [getPaginator, limit, offset, setOffset];
}

export function usePaginateData<T>(data: Array<T>, limit: number, offset: number): Array<T> {
  return data.slice(offset, offset + limit);
}
