import { Children, ReactElement, cloneElement, useContext, useEffect, useState } from "react";
import {
  Delete16Regular as DeleteIcon,
  WindowBulletListRegular as VariablesIconSmall,
  NumberRow20Regular as NumberIcon,
  TextCaseTitle20Regular as TextIcon,
  CalendarClock20Regular as DateIcon,
} from "@fluentui/react-icons";
import Button, { ColorVariant, SizeVariant } from "components/buttons/Button";
import { systemVariable, systemVariableDataType } from "apiTypes";
import classNames from "classnames";
import styles from "components/common/systemVariableInputWrapper/system_variable_input_wrapper.module.scss";
import { userEvents } from "utils/userEvents";
import { GetColorClass, InputColorVaraint } from "components/forms/input/variants";
import labelStyles from "components/forms/label/label.module.scss";
import { GetSizeClass, InputSizeVariant } from "components/forms/variants";
import useTranslations from "services/i18n/useTranslations";
import { FormattedInputProps, InputProps } from "components/forms/input/Input";
import ToastContext from "features/toast/context";
import { toastCategory } from "features/toast/Toasts";

type SystemVariableInputWrapperProps = {
  variable?: systemVariable;
  handleVariableChange?: (variable: systemVariable | undefined) => void;
  inputDataType: systemVariableDataType;
  editingDisabled: boolean;
  hidden?: boolean; // if the input is hidden
  disableVariableInput?: boolean; // if the workflow variable is disabled
  hoverLeftTranslate?: string; // left translate for the hover definition box (px)
  hoverStartRight?: boolean; // if the hover definition box should start from the right of the variable
  children?: ReactElement<HTMLInputElement | InputProps | FormattedInputProps>;
};

export default function SystemVariableInputWrapper(props: SystemVariableInputWrapperProps) {
  const { track } = userEvents();
  const { t } = useTranslations();
  const toastRef = useContext(ToastContext);

  const [isDragover, setIsDragover] = useState(false);

  function handleDragEnter(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    if (!props.editingDisabled) {
      setIsDragover(true);
    }
  }

  function handleDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setIsDragover(false);
  }

  const inputColorClass = GetColorClass(InputColorVaraint.primary);
  let variableColorClass = GetColorClass(InputColorVaraint.accent1);

  if (props.variable) {
    if (props.variable.workflowId) {
      // variable tied to workflow = green
      variableColorClass = GetColorClass(InputColorVaraint.success);
    } else {
      // system wide variable = yellow
      variableColorClass = GetColorClass(InputColorVaraint.warning);
    }
  }

  let dataTypeIcon: ReactElement;
  let variableHelpText = t.systemVariable.inputInfo;

  switch (props.inputDataType) {
    case systemVariableDataType.number:
      dataTypeIcon = <NumberIcon />;
      variableHelpText += t.systemVariable.number;
      break;
    case systemVariableDataType.text:
      dataTypeIcon = <TextIcon />;
      variableHelpText += t.systemVariable.text;
      break;
    case systemVariableDataType.date:
      dataTypeIcon = <DateIcon />;
      variableHelpText += t.systemVariable.date;
      break;
  }

  useEffect(() => {
    if (props.hidden) {
      if (props.handleVariableChange === undefined) {
        console.error("handleVariableChange is not defined");
        return;
      }

      props.handleVariableChange(undefined);
    }
  }, [props.hidden]);

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setIsDragover(false);

    if (props.editingDisabled) {
      // todo change translation to more generic
      toastRef?.current?.addToast(t.toast.cannotModifyWorkflowActive, toastCategory.warn);
      return;
    }
    if (e.dataTransfer.getData("systemVariable/event") !== "add") {
      // todo change translation to more generic
      toastRef?.current?.addToast(t.workflowDetails.cannotDropOnVariableInput, toastCategory.warn);
      return;
    }

    const systemVariableId = Number(e.dataTransfer.getData("systemVariable/systemVariableId"));
    const name = e.dataTransfer.getData("systemVariable/name");
    const dataType = Number(e.dataTransfer.getData("systemVariable/dataType"));
    const wfId = e.dataTransfer.getData("systemVariable/workflowId");
    const dataTemplate = e.dataTransfer.getData("systemVariable/dataTemplate");
    const workflowId = wfId == "" ? undefined : Number(wfId);

    if (!systemVariableId) {
      console.error("Invalid system variable id");
      return;
    }

    if (dataType !== props.inputDataType) {
      toastRef?.current?.addToast(t.toast.wrongWorkflowVariableDatatype, toastCategory.warn);
      return;
    }

    if (props.handleVariableChange === undefined) {
      console.error("handleVariableChange is not defined");
      return;
    }

    props.handleVariableChange({
      systemVariableId: systemVariableId,
      name: name,
      dataType: dataType,
      workflowId: workflowId,
      dataTemplate: dataTemplate,
    } as systemVariable);

    track("System Variable Dropped to variable input");
  }

  function handleEmpty() {
    if (props.editingDisabled) {
      return;
    }

    if (props.handleVariableChange === undefined) {
      console.error("handleVariableChange is not defined");
      return;
    }
    props.handleVariableChange(undefined);
  }

  const labelFromChild = Children.map(props.children, (child?: ReactElement) => {
    if (!child) {
      return "";
    }
    // get the label from the child input
    return (child as ReactElement).props.label;
  });

  const childInputEnhanced = Children.map(props.children, (child?: ReactElement) => {
    if (!child) {
      return <></>;
    }

    return cloneElement(child, {
      leftIcon: <label title={variableHelpText}>{dataTypeIcon}</label>,
      className: classNames(child.props.className, styles.childInput),
    });
  });

  const hoverBox = (
    <div className={styles.variableDefinitionHoverWrapper}>
      <div
        style={{
          left: props.hoverLeftTranslate ? props.hoverLeftTranslate : "-48px",
        }}
        className={styles.variableDefinitionHoverContainer}
      >
        <div className={styles.variableDefinitionHoverHeader}>{t.systemVariable.variableDefinition}</div>
        <div className={styles.variableDefinitionHoverText}>{props.variable?.dataTemplate}</div>
      </div>
    </div>
  );

  return (
    <>
      {/* variables disabled, show children as is */}
      {props.disableVariableInput && props.children}
      {!props.disableVariableInput && (
        <div
          onDrop={handleDrop}
          onDragOver={handleDragEnter}
          onDragLeave={handleDragLeave}
          className={classNames({
            [styles.hidden]: props.hidden || false,
            [styles.dragOver]: isDragover,
          })}
        >
          {/* Input field child */}
          {!props.variable && props.children && (
            <div className={styles.inputWrapper}>
              <div className={styles.inputContainer}>{childInputEnhanced}</div>
            </div>
          )}

          {/* Variable link */}
          {props.variable && (
            <div
              className={classNames({ [styles.variableWrapper]: props.children }, GetSizeClass(InputSizeVariant.small))}
            >
              <div className={labelStyles.labelWrapper}>
                <label className={styles.label}>{labelFromChild}</label>
              </div>
              {/* only if have children, show the input area under the variable */}
              <div className={classNames({ [styles.variableContainer]: props.children }, inputColorClass)}>
                {props.children && (
                  <div className={styles.leftIcon}>
                    <label title={variableHelpText}>{dataTypeIcon}</label>
                  </div>
                )}
                <div className={classNames(styles.variable, variableColorClass)}>
                  <div className={styles.variableText}>
                    {!props.hoverStartRight && hoverBox}
                    <VariablesIconSmall />
                    {props.variable.name}
                    {props.hoverStartRight && hoverBox}
                  </div>
                  {!props.editingDisabled && (
                    <Button
                      icon={<DeleteIcon />}
                      buttonColor={ColorVariant.error}
                      buttonSize={SizeVariant.tiny}
                      onClick={handleEmpty}
                      intercomTarget={"delete-workflow-variable-link"}
                      disabled={props.editingDisabled}
                    ></Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
