// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go


import { ColumnMetaData } from "features/table/types";
import { Invoice } from "features/transact/Invoices/invoiceTypes";

export const AllInvoicesColumns: ColumnMetaData<Invoice>[] = [
	{
		heading: "Paid Amount",
		type: "NumericCell",
		key: "amtPaid",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "CLTV Expiry",
		type: "NumericCell",
		key: "cltvExpiry",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Creation Date",
		type: "DateCell",
		key: "creationDate",
		valueType: "date",
		suffix: "",
		locked: true,
		supportedBy: [0, 1],
	},
	{
		heading: "Destination",
		type: "LongTextCell",
		key: "destinationPubKey",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Expiry",
		type: "DateCell",
		key: "expiry",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Fallback Address",
		type: "LongTextCell",
		key: "fallbackAddr",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Settle State",
		type: "TextCell",
		key: "invoiceState",
		valueType: "enum",
		suffix: "",
		supportedBy: [0, 1],
		selectOptions: [
			{ label: "Open", value: "OPEN" },
			{ label: "Settled", value: "SETTLED" },
			{ label: "Canceled", value: "CANCELED" },
		],
	},
	{
		heading: "AMP",
		type: "BooleanCell",
		key: "isAmp",
		valueType: "boolean",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Keysend",
		type: "BooleanCell",
		key: "isKeysend",
		valueType: "boolean",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Rebalance",
		type: "BooleanCell",
		key: "isRebalance",
		valueType: "boolean",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Memo",
		type: "TextCell",
		key: "memo",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Payment Address",
		type: "LongTextCell",
		key: "paymentAddr",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Payment Request",
		type: "LongTextCell",
		key: "paymentRequest",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Private",
		type: "BooleanCell",
		key: "private",
		valueType: "boolean",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Hash",
		type: "LongTextCell",
		key: "rHash",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Preimage",
		type: "LongTextCell",
		key: "rPreimage",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Settle Date",
		type: "DateCell",
		key: "settleDate",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Updated On",
		type: "DateCell",
		key: "updatedOn",
		valueType: "date",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Value",
		type: "NumericCell",
		key: "value",
		valueType: "number",
		suffix: "sat",
		supportedBy: [0, 1],
	},
	{
		heading: "Node ID",
		type: "TextCell",
		key: "nodeId",
		valueType: "number",
		suffix: "",
		supportedBy: [0, 1],
	},
	{
		heading: "Node Css Colour",
		type: "TextCell",
		key: "nodeCssColour",
		valueType: "string",
		suffix: "",
		supportedBy: [0, 1],
	},
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const InvoicesSortableColumns: Array<keyof Invoice> = [
	"amtPaid",
	"creationDate",
	"expiry",
	"invoiceState",
	"isAmp",
	"isKeysend",
	"isRebalance",
	"memo",
	"private",
	"settleDate",
	"updatedOn",
	"value",
	"nodeId",
	"nodeCssColour",
];


// DO NOT EDIT THIS FILE...
// This File is generated by go:generate
// For more information look at cmd/torq/gen.go

export const InvoicesFilterableColumns: Array<keyof Invoice> = [
	"amtPaid",
	"cltvExpiry",
	"creationDate",
	"destinationPubKey",
	"expiry",
	"fallbackAddr",
	"invoiceState",
	"isAmp",
	"isKeysend",
	"isRebalance",
	"memo",
	"paymentAddr",
	"paymentRequest",
	"private",
	"rHash",
	"rPreimage",
	"settleDate",
	"updatedOn",
	"value",
	"nodeId",
	"nodeCssColour",
];